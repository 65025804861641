import React, { useEffect, useRef, useState } from 'react';
import "./AccountUpdateProfile.css";
import { Link, useNavigate } from 'react-router-dom';
import { AccountSetupButton } from '../../../components/AccountSetupButton/AccountSetupButton';
import gerator_logo from '../../../icons/Gerator/gerator04-1@2x.png';
import feature_icon from '../../../icons/Gerator/account_setup/featured-icon.svg';
import feature_icon1 from '../../../icons/Gerator/account_setup/featured-icon-1.svg';
import feature_icon2 from '../../../icons/Gerator/account_setup/featured-icon-2.svg';
import feature_icon3 from '../../../icons/Gerator/account_setup/featured-icon-3.svg';
import feature_icon4 from '../../../icons/Gerator/account_setup/featured-icon-4.svg';
import mail_icon from '../../../icons/Gerator/account_setup_profile_subscription/mail01-1.svg';
import divider from '../../../icons/Gerator/account_setup_profile_subscription/divider.svg';
import AccountUpdateProfileForm from '../../../components/AccountUpdateProfileForm/AccountUpdateProfileForm';
import { BackToLoginButton } from '../../../components/BackToLoginButton/BackToLoginButton';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from "../../Login/FirebaseClient";
import { useGetCountryTaxQuery } from '../../../api/TaxonomyFormAPI';
import { Provider } from "./AccountUpdateProfileContext";
import { Helmet } from 'react-helmet-async';
import { useLazyGetGoogleProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import support_icon from "../../../icons/Gerator/support_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg";
import { generateRandomStringForImage } from '../../../helper/UserHelper';




export const AccountUpdateProfile = () => {


  const [backendValidation, setBackendValidation] = useState([])
  const formikRef = useRef(null);
  const countryTax = useGetCountryTaxQuery();
  const [currentUsermeta, setCurrentUsermeta] = useState(null);
  const [triggerGetUser, { data, isLoading, isSuccess }] = useLazyGetUserQuery();
  const [triggerGetGoogleProfileImageLink, { data: profileLinkData, isSuccess: isProfileLinkSuccess }] = useLazyGetGoogleProfileImageLinkQuery();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [googleProfileImageLink, setGoogleProfileImageLink] = useState("");

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  const [allTaxonomy, setAllTaxonomy] = useState({ country: countryTax })

  useEffect(() => {

    const allSuccess = [countryTax.isSuccess,].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        country: countryTax
      });
    }
  }, [
    countryTax.isSuccess, countryTax.data,
  ]);



  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {

      if (user) {
        // const result = await triggerGetUser().unwrap();
        // // Get user to check wizardStep

        console.log(user);



        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const usermeta = userDocSnapshot.data(); // Default to 1 if not set
          setCurrentUsermeta(usermeta);
          setCurrentUser(user);
          if (user.photoURL)
            triggerGetGoogleProfileImageLink();
          console.log("Wizard Step:", usermeta.wizardStep);
        } else {
          console.log("User document does not exist in Firestore");
          navigate("/sign-in", { state: { message: "Internal server error" } });
        }
      }
      else {
        navigate("/sign-in", { state: { message: "User not authorized" } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  useEffect(() => {
    if (profileLinkData) {
      console.log(profileLinkData);

      setGoogleProfileImageLink(profileLinkData.url);
    }
  }, [isProfileLinkSuccess, profileLinkData]);


  useEffect(() => {
    if (currentUsermeta) {
      switch (currentUsermeta.wizardStep) {
        case 0:
          navigate('/dashboard');
          break;
        case 1:
          navigate('/account-setup-check-email');
          break;
        // case 2:
        //   navigate('/account-setup-update-profile');
        case 3:
          navigate('/account-setup-subscription-plan');
          break;

      }
      triggerGetUser();
    }
  }, [currentUsermeta])

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       console.log(user);
  //       setCurrentUser(user)
  //       // sendVerificationEmail(user);
  //     } else {
  //       navigate("/sign-in", { state: { message: "User not authorized" } });
  //     }
  //   });

  //   return () => unsubscribe(); // Clean up subscription on unmount
  // }, [navigate]);
  if (currentUsermeta && isSuccess) {
    return (
      <>
        <Helmet>
          <title>Step 2 - Update Profile</title>
        </Helmet>
        <div className="account-update-profile-account-update-profile">

          <div className="account-update-profile-section2">
            <div className="account-update-profile-progress-container-wrapper">
              <div className="account-update-profile-progress-container">
                <div className="account-update-profile-logo1">
                  <img
                    className="account-update-profile-gerator-04-1-icon1"
                    alt=""
                    src={gerator_logo}
                  />
                </div>
                <div className="account-update-profile-progress-steps-progress-icon1">
                  <div className="account-update-profile-step-base5">
                    <div className="account-update-profile-step-elements">
                      <img
                        className="account-update-profile-featured-icon8"
                        loading="lazy"
                        alt=""
                        src={feature_icon}
                      />

                      <div className="account-update-profile-step-labels-parent">
                        <div className="account-update-profile-step-labels">Verify your email address </div>
                        <div className="account-update-profile-supporting-text9">
                        Quick tap to confirm you're you!
                        </div>
                      </div>
                    </div>
                    <div className="account-update-profile-progress-connectors">
                      <div className="account-update-profile-connector4"></div>
                    </div>
                  </div>
                  <div className="account-update-profile-step-base4">
                    <div className="account-update-profile-step-elements">
                      <img
                        className="account-update-profile-featured-icon8"
                        loading="lazy"
                        alt=""
                        src={feature_icon1}
                      />

                      <div className="account-update-profile-step-labels-parent">
                        <div className="account-update-profile-step-labels">Update Profile</div>
                        <div className="account-update-profile-supporting-text9">
                          Tell us a bit about yourself
                        </div>
                      </div>
                    </div>
                    <div className="account-update-profile-progress-connectors">
                      <div className="account-update-profile-connector4"></div>
                    </div>
                  </div>
                  <div className="account-update-profile-step-base6">
                    <div className="account-update-profile-connector-wrap2">
                      <img
                        className="account-update-profile-featured-icon10"
                        loading="lazy"
                        alt=""
                        src={feature_icon2}
                      />
                    </div>
                    <div className="account-update-profile-text-and-supporting-text2">
                      <div className="account-update-profile-step-labels">Choose a Subscription plan</div>
                      <div className="account-update-profile-supporting-text11">
                        Choose how you want to roll
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="account-update-profile-text-container">

            </div>
          </div>

          <section className="account-update-profile-content-wrapper-wrapper">
            <div className="account-update-profile-content-wrapper">
              <div className="account-update-profile-header-section">
                <div className="account-update-profile-container3">
                  <div className="account-update-profile-page-header">

                    <div className="account-update-profile-content7">
                      <div className="account-update-profile-text-and-supporting-text4">
                        <a className="account-update-profile-page-title">Profile</a>
                      </div>
                    </div>
                    <img className="account-update-profile-divider-icon" alt="" src={divider} />
                  </div>
                </div>
              </div>
              <div className="account-update-profile-content-elements-wrapper account-update-profile-content-elements-wrapper-custom-padding">
                <Provider value={{ googleProfileImageLink, setGoogleProfileImageLink, allTaxonomy, backendValidation, setBackendValidation, cropedImageFile, setCropedImageFile, selectedImageFile, setSelectedImageFile }}>
                  <AccountUpdateProfileForm user={currentUser} formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} />
                </Provider>
              </div>
              {showSuccessMessage.message && (
                <div
                  style={{
                    fontSize: "14px",
                    color: showSuccessMessage.color,
                    padding: "15px 10px",
                    border: "1px solid " + showSuccessMessage.color,
                    borderRadius: "10px",
                    margin: "0 auto",
                  }}
                >
                  {showSuccessMessage.message}
                </div>
              )}



              <div className="account-update-profile-mobile-container" >
                <div className="account-update-profile-mobile-PaginationDotGroup" >
                  <div className="account-update-profile-mobile-PaginationDotIndicator" />
                  <div className="account-update-profile-mobile-PaginationDotIndicator1" />
                  <div className="account-update-profile-mobile-PaginationDotIndicator" />
                  {/* <div className="account-email-check-mobile-PaginationDotIndicator"  /> */}
                </div>

                <div className="account-update-profile-content-wrapper-inner">
                  <div className="account-update-profile-help-content-wrapper">
                    <div className="account-update-profile-help-content">
                      <div className="account-update-profile-icon-and-text1">
                        <img
                          className="account-update-profile-chevron-right-icon"
                          loading="lazy"
                          alt=""
                          src={support_icon}
                        />

                        <div className="account-update-profile-helpcentergeratorcom"><a href=" https://helpcenter.gerator.com/" className='verified-email-hyperlink'>
                          Need Help?</a>
                        </div>

                      </div>
                      <div className="account-update-profile-text72">
                        © Gerator Asia LLP. All rights reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
  else {
    return <Loader />
  }
} 
