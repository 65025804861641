import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Business/VerificationBusiness/VerificationBusinessContext";
import { Link, useNavigate } from "react-router-dom";
import { clearForm, updateField } from "../AddDeviceFormComponent/AddDeviceSlice";
import { updateAppDataDevice, clearAppData } from "../AddDeviceFormComponent/AppDataDeviceSlice";
import { useGetBusinessGenreTaxQuery, useGetBusinessSizeTaxQuery, useGetCountryTaxQuery, useGetOwnershipTaxQuery } from '../../api/TaxonomyFormAPI';
// import { getAllTaxonomyData } from "../../helper/AddDeviceHelper";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useAddDeviceMutation } from "../../api/DeviceAPI";
import { objectToFormData } from "../../helper/DeviceHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { AddBusinessForm, AddBusinessVerificationForm } from "./AddBusinessVerificationForm";
import { documentObjectToBusinessFormData, objectToBusinessFormData } from "../../helper/BusinessHelper";
import { useAddBusinessDocumentMutation, useAddBusinessMutation } from "../../api/BusinessAPI";

const AddBusinessVerificationParent = ({ setShowSuccessMessage, businessId }) => {

  const navigate = useNavigate();


  const initialValues =
  {
    businessEntity: '',
    businessProofOfIdentity: '',
    businessProofOfAddress: '',
  }

  const [selectedDocumentFile1, setSelectedDocumentFile1] = useState("");
  const [documentCheckboxError1, setDocumentCheckboxError1] = useState("");
  const [selectedDocumentFile2, setSelectedDocumentFile2] = useState("");
  const [documentCheckboxError2, setDocumentCheckboxError2] = useState("");
  const [selectedDocumentFile3, setSelectedDocumentFile3] = useState("");
  const [documentCheckboxError3, setDocumentCheckboxError3] = useState("");
  const [backendValidation, setBackendValidation] = useState([]);


  const [addBusinessDocuments, { isLoading, isError, error }] = useAddBusinessDocumentMutation();


  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await addBusinessDocuments(multiPartData).unwrap();
      // console.log("Device added successfully!", response);
      //If form was submitted successfully then isCreated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Verification documents added successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        // formikRef.current.resetForm();
        // window.location.reload();
        navigate('/dashboard/settings/business');
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        // matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      // innerRef={formikRef}
      initialValues={{
        ...initialValues
      }}

      validationSchema={Yup.object({

        businessEntity: Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only pdf)',
            value => {
              if (!value) return true
              return ['application/pdf'].includes(value.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => !value || (value && value.size <= 1024 * 1024)
          )
          .required('A file is required'),

        businessProofOfAddress: Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only pdf)',
            value => {
              if (!value) return true
              return ['application/pdf'].includes(value.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => !value || (value && value.size <= 1024 * 1024)
          )
          .required('A file is required'),

        businessProofOfIdentity: Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only pdf)',
            value => {
              if (!value) return true
              return ['application/pdf'].includes(value.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => !value || (value && value.size <= 1024 * 1024)
          )
          .required('A file is required'),
      })}


      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        multiPartData = documentObjectToBusinessFormData(values, businessId)

        finalSubmit(multiPartData, setSubmitting);

        // console.log(multiPartData);
        // for (const [key, value] of multiPartData.entries()) {
        //   console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
        // resetForm();
      }}
    >
      {() => (
        <Form className="add-device-step-section-header-parent">

          <Provider value={{ selectedDocumentFile1, setSelectedDocumentFile1, documentCheckboxError1, setDocumentCheckboxError1, selectedDocumentFile2, setSelectedDocumentFile2, documentCheckboxError2, setDocumentCheckboxError2, selectedDocumentFile3, setSelectedDocumentFile3, documentCheckboxError3, setDocumentCheckboxError3, backendValidation, setBackendValidation }}>
            <AddBusinessVerificationForm />
          </Provider>

        </Form>
      )}
    </Formik>
  );
};

export default AddBusinessVerificationParent;