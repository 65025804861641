// import { useEffect, useState } from "react";
// import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../api/TaxonomyFormAPI";

import { encryptData } from "./AESHelper";


export function objectToFormDataForAddSpare(obj, featureImage, imageMultiUploadStatus, documentMultiUploadStatus) {
  const formData = new FormData();

  // Destructure to remove file fields from obj
  const { document: _, gallery: __, featureImage: ___, imageMultiCheckbox: ____, imageDocCheckbox: _____, ...values } = obj;

  values.price = values.price.replace(/,/g, '');
  values.availableQuantity = Number(values.availableQuantity.replace(/,/g, ''));

  values.gallery = imageMultiUploadStatus.map(item => item.postId);

  values.document = documentMultiUploadStatus.map(item => item.postId);

  console.log(values);


  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  // Append featureImage if it's a valid File
  if (featureImage instanceof File) {
    formData.append("featureImageObject", featureImage);
  }



  return formData;
}


export function objectToFormDataForEditSpare(obj, documentFileRM, galleryImageRM, featureImage, imageMultiUploadStatus, documentMultiUploadStatus) {
  const formData = new FormData();

  // Destructure to remove file fields from obj
  const { document: _, gallery, featureImage: __, galleryImages, spares_files, documentFile, document_file, feature_image, tax_device_category, tax_oem, imageCheckbox, imageDocCheckbox, imageMultiCheckbox, ...values } = obj;


  values.price = values.price.replace(/,/g, '');
  values.availableQuantity = Number(values.availableQuantity.replace(/,/g, ''));

  values.gallery = imageMultiUploadStatus
    .map(item => Number(item.postId)) // Convert each postId to a number
    .filter(postId => !isNaN(postId)); // Filter out non-numeric values

  values.document = documentMultiUploadStatus
    .map(item => Number(item.postId)) // Convert each postId to a number
    .filter(postId => !isNaN(postId)); // Filter out non-numeric values

  if (galleryImageRM.length) {
    values.galleryImageRM = [...galleryImageRM]; // Add galleryImageRM to values
  }

  if (documentFileRM !== "" && documentFileRM !== undefined) {
    values.documentFileRM = documentFileRM; // Add documentFileRM to values
  }

  console.log(values);


  const encryptedValue = encryptData(values); // Custom encryptData function
  console.log('Pure object');

  formData.append("encryptedData", encryptedValue);


  // Append featureImage if it's a valid File
  if (featureImage instanceof File) {
    formData.append("featureImageObject", featureImage);
  }
  // Append the encrypted text data


  return formData;
}



export function getCustomizedDate(sqlDate) {
  // const date = ; 
  const formattedDate = new Date(sqlDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
}

export function getAllTaxonomyData() {


  return allTaxonomy;
}


export function isValidURL(urlString) {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
}

export function isAllURLsValid(urlArray) {
  for (const url of urlArray) {
    if (!isValidURL(url)) {
      return false; // If even one URL is invalid, return false
    }
  }
  return true; // All URLs were valid
}