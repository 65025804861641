import React, { useEffect, useRef, useState } from 'react';
import "./SalesIQ.css";
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Footer } from '../../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { firestore, doc, getDoc, auth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../screens/Login/FirebaseClient";
import divider3 from '../../icons/Gerator/sales_iq/divider3.svg';
import divider1 from '../../icons/Gerator/sales_iq/divider1.svg';
import plus from '../../icons/Gerator/sales_iq/plus.svg';
import homeline1 from '../../icons/Gerator/sales_iq/homeline1.svg';
import chevronright from '../../icons/Gerator/sales_iq/chevronright.svg';
import chart_data2x from '../../icons/Gerator/sales_iq/-chart-data@2x.png';
import arrowup from '../../icons/Gerator/sales_iq/arrowup.svg';
import arrowdown from '../../icons/Gerator/sales_iq/arrowdown.svg';
import chart_mini2x from '../../icons/Gerator/sales_iq/-chart-mini@2x.png';
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import chart_mini1_2x from '../../icons/Gerator/sales_iq/-chart-mini1@2x.png';
import _1_2x from '../../icons/Gerator/sales_iq/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';

import { Helmet } from 'react-helmet-async';
import ButtonDropdown from '../../components/AddButtonDropdown/AddButtonDropdown';
import PlanExpiryStatus from '../../components/PlanExpiryStatusComponent/PlanExpiryStatus';
import { useLazyGetCurrentPlanOfUserQuery } from '../../api/PlanAPI';
import { useLazyGetProfileImageLinkQuery } from '../../api/Login&RegisterAPI';
import { SalesIQSpareComponent } from '../../components/SalesIQComponents/SalesIQSpareComponent/SalesIQSpareComponent';
import { SideBarSubSalesIQ } from '../../components/SideBars-Sub/SideBarSubSalesIQ/SideBarSubSalesIQ';
import { SalesIQServiceComponent } from '../../components/SalesIQComponents/SalesIQServiceComponent/SalesIQServiceComponent';
import { SalesIQSoftwareComponent } from '../../components/SalesIQComponents/SalesIQSoftwareComponent/SalesIQSoftwareComponent';
import { SalesIQJobComponent } from '../../components/SalesIQComponents/SalesIQJobComponent/SalesIQJobComponent';
import { SalesIQDeviceComponent } from '../../components/SalesIQComponents/SalesIQDeviceComponent/SalesIQDeviceComponent';


// const auth = getAuth(app);


export const SalesIQ = () => {

  const itemsA = [

    {
      title: 'Add Device',

      link: 'add-device'
    },
    {
      title: 'Add Spare',

      link: 'add-spare'
    },
    {
      title: 'Add Service',

      link: 'add-service'
    },
    {
      title: 'Add Software',

      link: 'add-software'
    },
    {
      title: 'Add Job',

      link: 'add-job'
    },
  ];

  const [isNewDropdownVisible, setNewDropdownVisible] = useState(false);
  const newdropdownRef = useRef(null);

  const toggleNewDropdown = () => {
    setNewDropdownVisible(!isNewDropdownVisible);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (

  //       (newdropdownRef.current && !newdropdownRef.current.contains(event.target))
  //     ) {

  //       setNewDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is on a Link inside the dropdown
      const isLink = event.target.closest('a');
  
      if (isLink) {
        return; // Allow navigation to happen
      }
  
      if (newdropdownRef.current && !newdropdownRef.current.contains(event.target)) {
        setNewDropdownVisible(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [activeTab, setActiveTab] = useState('device');
  const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  const renderContent = () => {
    switch (activeTab) {
      case 'device':
        return <SalesIQDeviceComponent />;
      case 'spare':
        return <SalesIQSpareComponent />;
      case 'service':
        return <SalesIQServiceComponent />;
      case 'software':
        return <SalesIQSoftwareComponent />;
      case 'job':
        return <SalesIQJobComponent />;
      default:
        return null;
    }
  };

  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);

  // useEffect(() => {
  //   navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
  // }, [isCurrentPlanError])



  useEffect(() => {
    if (isCurrentPlanSuccess && currentPlanData?.data?.order_plan?.id) {
      setCurrentPlanName(currentPlanData.data.order_plan.planName);
      setCurrentPlanExpiry(currentPlanData.data.orderExpiry);
    } else {
      console.log("No current plan data or ID not available.");
    }
  }, [isCurrentPlanSuccess, currentPlanData]);


  if (currentUser && isCurrentPlanSuccess && profileImageLink) {
    return (
      <div className="sales-iq-sales-iq-frame-layout">
        <Helmet>
          <title>Sales IQ</title>
        </Helmet>
        <div className="sales-iq-sales-iq-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

        <div className="sales-iq-all">
          <img className="sales-iq-divider-icon" alt="" src={divider3} />

          <div className="sales-iq-all-content">

            <Sidebar>
              <SideBarSubSalesIQ userData={currentUser} />
            </Sidebar>

            <div className="sales-iq-frame-parent">
              <div className="sales-iq-main-wrapper">
                <div className="sales-iq-main sales-iq-main-custom">
                  <div className="sales-iq-header-section">
                    <div className="sales-iq-container">
                      <div className="sales-iq-page-header">
                        <div className="sales-iq-breadcrumbs">
                          <div className="sales-iq-tabs">
                            <div className="sales-iq-breadcrumb-button-base">
                              <img
                                className="sales-iq-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="sales-iq-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-breadcrumb-button-base1">
                              <div className="sales-iq-location">Sales IQ</div>
                            </div>
                            <img
                              className="sales-iq-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-breadcrumb-button-base2">
                              <div className="sales-iq-text20">...</div>
                            </div>
                            <img
                              className="sales-iq-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-breadcrumb-button-base3">
                              <div className="sales-iq-text21">Another link</div>
                            </div>
                            <img
                              className="sales-iq-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-breadcrumb-button-base4">
                              <div className="sales-iq-text22">Overview</div>
                            </div>
                          </div>
                        </div>
                        <div className="sales-iq-content13">
                          <div className="sales-iq-text-and-supporting-text">
                            <div className="sales-iq-heading">Welcome back, {currentUser.displayName}</div>

                            <div className="sales-iq-supporting-text1">
                              {/* You are on Krypton Plan, expiring in 23 days. */}
                              <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} />
                            </div>
                          </div>
                        </div>
                        <img
                          className="sales-iq-divider-icon1"
                          alt=""
                          src={divider1}
                        />


                      </div>
                      {/* <div className="sales-iq-tabs-and-filters">
                        <div className="sales-iq-button-group">
                          <div className="sales-iq-button-group-base">
                            <div className="sales-iq-text31">12 months</div>
                          </div>
                          <div className="sales-iq-button-group-base1">
                            <div className="sales-iq-text32">30 days</div>
                          </div>
                          <div className="sales-iq-button-group-base2">
                            <div className="sales-iq-text29">7 days</div>
                          </div>
                          <div className="sales-iq-button-group-base3">
                            <div className="sales-iq-text34">Text</div>
                          </div>
                          <div className="sales-iq-button-group-base4">
                            <div className="sales-iq-text34">Text</div>
                          </div>
                          <div className="sales-iq-button-group-base5">
                            <div className="sales-iq-text34">Text</div>
                          </div>
                          <div className="sales-iq-button-group-base6">
                            <div className="sales-iq-text34">Text</div>
                          </div>
                          <div className="sales-iq-button-group-base7">
                            <div className="sales-iq-text34">Text</div>
                          </div>
                          <div className="sales-iq-button-group-base8">
                            <div className="sales-iq-text39">24 hours</div>
                          </div>
                        </div>
                        <div className="sales-iq-actions2">
                          <div className="sales-iq-date-picker-dropdown">
                            <div className="sales-iq-buttonsbutton1">
                              <img
                                className="sales-iq-upload-04-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="sales-iq-text-padding6">
                                <div className="sales-iq-filter-date">Select dates</div>
                              </div>
                              <img
                                className="sales-iq-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div>
                          </div>
                          <div className="sales-iq-buttonsbutton2">
                            <img
                              className="sales-iq-upload-04-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="sales-iq-text-padding6">
                              <div className="sales-iq-filter-options">Filters</div>
                            </div>
                            <img
                              className="sales-iq-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="sales-iq-line-and-bar-chart-wrapper">
                    <div className="sales-iq-line-and-bar-chart">
                      <div className="sales-iq-line-and-bar-chart">
                        <div className="sales-iq-content14">
                          <div className="sales-iq-legend">
                            <div className="sales-iq-legend-series">
                              <div className="sales-iq-legend-color">
                                <div className="sales-iq-color"></div>
                              </div>
                              <div className="sales-iq-series-1">2022</div>
                            </div>
                            <div className="sales-iq-legend-series">
                              <div className="sales-iq-legend-color">
                                <div className="sales-iq-color1"></div>
                              </div>
                              <div className="sales-iq-series-2">2021</div>
                            </div>
                            <div className="sales-iq-legend-series">
                              <div className="sales-iq-legend-color">
                                <div className="sales-iq-color2"></div>
                              </div>
                              <div className="sales-iq-series-1">2020</div>
                            </div>
                            <div className="sales-iq-legend-series3">
                              <div className="sales-iq-color3"></div>
                              <div className="sales-iq-series-4">Series 4</div>
                            </div>
                            <div className="sales-iq-legend-series4">
                              <div className="sales-iq-color4"></div>
                              <div className="sales-iq-series-5">Series 5</div>
                            </div>
                            <div className="sales-iq-legend-series3">
                              <div className="sales-iq-color5"></div>
                              <div className="sales-iq-series-4">Series 6</div>
                            </div>
                          </div>
                          <div className="sales-iq-y-axis">
                            <div className="sales-iq-y-axis-line">
                              <div className="sales-iq-divider"></div>
                            </div>
                            <div className="sales-iq-y-axis-line1">
                              <div className="sales-iq-divider"></div>
                            </div>
                            <div className="sales-iq-y-axis-line1">
                              <div className="sales-iq-divider"></div>
                            </div>
                            <div className="sales-iq-y-axis-line1">
                              <div className="sales-iq-divider"></div>
                            </div>
                            <div className="sales-iq-y-axis-line1">
                              <div className="sales-iq-divider"></div>
                            </div>
                            <div className="sales-iq-y-axis-line1">
                              <div className="sales-iq-divider"></div>
                            </div>
                            <div className="sales-iq-y-axis-line6">
                              <div className="sales-iq-divider6"></div>
                            </div>
                            <div className="sales-iq-y-axis-line6">
                              <div className="sales-iq-divider6"></div>
                            </div>
                            <div className="sales-iq-y-axis-line6">
                              <div className="sales-iq-divider6"></div>
                            </div>
                            <div className="sales-iq-y-axis-line6">
                              <div className="sales-iq-divider6"></div>
                            </div>
                            <div className="sales-iq-y-axis-line6">
                              <div className="sales-iq-divider6"></div>
                            </div>
                            <div className="sales-iq-y-axis-line6">
                              <div className="sales-iq-divider6"></div>
                            </div>
                          </div>
                          <div className="sales-iq-x-axis">
                            <div className="sales-iq-jan">Jan</div>
                            <div className="sales-iq-jan">Feb</div>
                            <div className="sales-iq-mar">Mar</div>
                            <div className="sales-iq-jan">Apr</div>
                            <div className="sales-iq-may">May</div>
                            <div className="sales-iq-jun">Jun</div>
                            <div className="sales-iq-jul">Jul</div>
                            <div className="sales-iq-mar">Aug</div>
                            <div className="sales-iq-mar">Sep</div>
                            <div className="sales-iq-jan">Oct</div>
                            <div className="sales-iq-mar">Nov</div>
                            <div className="sales-iq-mar">Dec</div>
                          </div>
                          <img
                            className="sales-iq-chart-data-icon"
                            alt=""
                            src={chart_data2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sales-iq-section">
                    <div className="sales-iq-container1">
                      <div className="sales-iq-metric-group">
                        <div className="sales-iq-metric-item">
                          <div className="sales-iq-heading1">Today’s revenue</div>
                          <div className="sales-iq-number-and-chart">
                            <div className="sales-iq-number-and-badge">
                              <div className="sales-iq-number">$1,280</div>
                              <div className="sales-iq-change-and-text">
                                <div className="sales-iq-change">
                                  <img
                                    className="sales-iq-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="sales-iq-change1">15%</div>
                                </div>
                                <div className="sales-iq-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="sales-iq-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="sales-iq-dropdown">
                            <img
                              className="sales-iq-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="sales-iq-metric-item">
                          <div className="sales-iq-heading1">Today’s orders</div>
                          <div className="sales-iq-number-and-chart">
                            <div className="sales-iq-number-and-badge">
                              <div className="sales-iq-heading">14</div>
                              <div className="sales-iq-change-and-text1">
                                <div className="sales-iq-change2">
                                  <img
                                    className="sales-iq-upload-04-icon"
                                    alt=""
                                    src={arrowdown}
                                  />

                                  <div className="sales-iq-change1">10%</div>
                                </div>
                                <div className="sales-iq-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="sales-iq-chart-mini-icon"
                              alt=""
                              src={chart_mini1_2x}
                            />
                          </div>
                          <div className="sales-iq-dropdown">
                            <img
                              className="sales-iq-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="sales-iq-metric-item">
                          <div className="sales-iq-heading1">Avg. order value</div>
                          <div className="sales-iq-number-and-chart">
                            <div className="sales-iq-number-and-badge">
                              <div className="sales-iq-number">$91.42</div>
                              <div className="sales-iq-change-and-text">
                                <div className="sales-iq-change">
                                  <img
                                    className="sales-iq-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="sales-iq-change-amount">20%</div>
                                </div>
                                <div className="sales-iq-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="sales-iq-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="sales-iq-dropdown">
                            <img
                              className="sales-iq-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* <div className="sales-iq-separator"></div>
              <div className="sales-iq-separator1"></div> */}
              <div className="sales-iq-main-wrapper">
                <div className="sales-iq-page-header-wrapper">
                  <div className="sales-iq-page-header1">
                    <div className="sales-iq-breadcrumbs1">
                      <div className="sales-iq-tabs1">
                        <div className="sales-iq-breadcrumb-button-base">
                          <img
                            className="sales-iq-search-lg-icon"
                            alt=""
                            src={homeline1}
                          />
                        </div>
                        <img
                          className="sales-iq-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="sales-iq-breadcrumb-button-base6">
                          <div className="sales-iq-text42">Settings</div>
                        </div>
                        <img
                          className="sales-iq-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="sales-iq-breadcrumb-button-base7">
                          <div className="sales-iq-text20">...</div>
                        </div>
                        <img
                          className="sales-iq-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="sales-iq-breadcrumb-button-base3">
                          <div className="sales-iq-text21">Another link</div>
                        </div>
                        <img
                          className="sales-iq-chevron-right-icon2"
                          alt=""
                          src={chevronright}
                        />

                        <div className="sales-iq-breadcrumb-button-base4">
                          <div className="sales-iq-text34">Team</div>
                        </div>
                      </div>
                    </div>
                    <div className="sales-iq-content15">
                      <div className="sales-iq-text-and-supporting-text1">
                        <div className="sales-iq-text46">Your Listings</div>
                        <div className="sales-iq-supporting-text2">
                          Manage your team members and their account permissions
                          here.
                        </div>
                      </div>
                      {/* <div className="sales-iq-input-dropdown1">
                        <div className="sales-iq-input-with-label1">
                          <div className="sales-iq-label">Search</div>
                          <div className="sales-iq-input1">
                            <div className="sales-iq-content16">
                              <img
                                className="sales-iq-search-lg-icon"
                                alt=""
                                src={searchlg}
                              />

                              <div className="sales-iq-text47">Search</div>
                            </div>
                          </div>
                        </div>
                        <div className="sales-iq-hint-text1">
                          This is a hint text to help user.
                        </div>
                      </div> */}
                    </div>
                    <img className="sales-iq-divider-icon1" alt="" src={divider1} />
                  </div>


                </div>
                <div className="sales-iq-supporting-text-wrapper">
                  <div className="sales-iq-supporting-text3">
                    Here's to new beginnings! 💰
                  </div>

                  <div className="sales-iq-buttonsbutton5a" ref={newdropdownRef} onClick={toggleNewDropdown} style={{ cursor: 'pointer' }}>
                    <img className="sales-iq-search-lg-icon1" alt="" src={plus} />

                    <div className="sales-iq-text-padding15">
                      <div className="sales-iq-text74">Add Listings</div>
                    </div>
                    {/* <img
                        className="sales-iq-placeholder-icon27"
                        alt=""
                        src={placeholder1}
                      /> */}
                  </div>

                  {isNewDropdownVisible && (
                    <div className="sales-iq-dropdown-wrapper" >
                      <ButtonDropdown items={itemsA} />
                    </div>
                  )}
                </div>
                <div className="sales-iq-horizontal-tabs-wrapper">
                  <div className="sales-iq-horizontal-tabs">
                    <div className="sales-iq-tabs2">
                      <div className={activeTab === 'device' ? "sales-iq-tab-button-base" : "sales-iq-tab-button-base1"}>
                        <div className="sales-iq-tab-name" style={{ cursor: "pointer" }} onClick={() => setActiveTab('device')}>Devices</div>
                      </div>
                      <div className={activeTab === 'spare' ? "sales-iq-tab-button-base" : "sales-iq-tab-button-base1"}>
                        <div className="sales-iq-text48" style={{ cursor: "pointer" }} onClick={() => setActiveTab('spare')}>Spares</div>
                      </div>
                      <div className={activeTab === 'service' ? "sales-iq-tab-button-base" : "sales-iq-tab-button-base1"}>
                        <div className="sales-iq-text49" style={{ cursor: "pointer" }} onClick={() => setActiveTab('service')}>Service</div>
                      </div>
                      <div className={activeTab === 'software' ? "sales-iq-tab-button-base" : "sales-iq-tab-button-base1"}>
                        <div className="sales-iq-text50" style={{ cursor: "pointer" }} onClick={() => setActiveTab('software')}>Software</div>
                      </div>
                      <div className={activeTab === 'job' ? "sales-iq-tab-button-base" : "sales-iq-tab-button-base1"}>
                        <div className="sales-iq-text51" style={{ cursor: "pointer" }} onClick={() => setActiveTab('job')}>Jobs</div>
                      </div>
                      <div className="sales-iq-tab-button-base5">
                        <div className="sales-iq-text52">Billing</div>
                      </div>
                      <div className="sales-iq-tab-button-base5">
                        <div className="sales-iq-text52">Email</div>
                      </div>
                      <div className="sales-iq-tab-button-base7">
                        <div className="sales-iq-text52">Notifications</div>
                        <div className="sales-iq-badge10">
                          <div className="sales-iq-text55">2</div>
                        </div>
                      </div>
                      <div className="sales-iq-tab-button-base5">
                        <div className="sales-iq-text52">Integrations</div>
                      </div>
                      <div className="sales-iq-tab-button-base9">
                        <div className="sales-iq-text52">API</div>
                      </div>
                    </div>
                  </div>
                </div>



                {renderContent()}



              </div>





            </div>
          </div>
        </div>
        <img className="sales-iq-divider-icon6" alt="" src={divider3} />

        <Footer />


      </div>
    )
  }
  else {
    return <Loader />
  }


}

