import React, { useEffect, useRef, useState } from 'react';
import "./FunnelSoftwareComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/SoftwareHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetUserDashboardWantedSoftwaresQuery } from '../../../api/WantedSoftwareAPI';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import FunnelSoftwareCardTabs from '../../FunnelCardTabs/FunnelSoftwareCardTabs';
import { useFindGetAllCountFunnelDashboardQuery } from '../../../api/Funnel/FunnelWantedDeviceAPI';

export const FunnelSoftwareComponent = () => {



  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedDevice, settotalCountWantedDevice] = useState("");
  const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useFindGetAllCountFunnelDashboardQuery();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.wanted_softwares.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountWantedSpare(data.totalCountWantedSpare);
      settotalCountWantedService(data.totalCountWantedService);
      settotalCountWantedDevice(data.totalCountWantedDevice);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-wanted-software"} pathName={"Wanted Software"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    // if (data.wanted_softwares.length > 0) {
    //   // dataLength = data.wanted_softwares.length;
    //   content = data.wanted_softwares.map((wantedSoftware, index) =>
    //   (
    //     <div className="funnel-software-component-funnel-content-items" key={index}>
    //       <div className="funnel-software-component-funnel-divider-container1">
    //         <div className="funnel-software-component-funnel-divider-container-child"></div>
    //         <div className="funnel-software-component-funnel-rectangle-parent5">
    //           <div className="funnel-software-component-funnel-frame-parent1">
    //             <div className="funnel-software-component-funnel-action-fields">
    //               <div className="funnel-software-component-funnel-input-elements">
    //                 <Link to={`/wanted-software/${wantedSoftware.id}/${wantedSoftware.title}`} className="funnel-software-component-funnel-text78">
    //                   {wantedSoftware.title}
    //                 </Link>
    //                 <div className="funnel-software-component-funnel-frame-parent21">
    //                   <div className="funnel-software-component-funnel-badge-item-parent">
    //                     <div className="funnel-software-component-funnel-badge-parent">

    //                       {/* <PostStatusLIstingComponent postStatus={'Expired'} postSlug={"expired"} postId={'1'} /> */}

    //                     </div>
    //                     <div className="funnel-software-component-funnel-frame-parent4">
    //                       <div className="funnel-software-component-funnel-end-label">
    //                         <div className="funnel-software-component-funnel-search-icon-input">4.9/5</div>
    //                       </div>
    //                       <div className="funnel-software-component-funnel-type-support">
    //                         <div className="funnel-software-component-funnel-supporting-text9">
    //                           99,999 reviews
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className="funnel-software-component-funnel-badge33">
    //                       <div className="funnel-software-component-funnel-card-badge-number">
    //                         {wantedSoftware.tax_software_category.name}
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="funnel-software-component-funnel-frame-parent24">
    //                     {/* <div className="funnel-software-component-funnel-location-pin-parent">
    //                         <div className="funnel-software-component-funnel-search-input">
    //                             <img
    //                             className="funnel-software-component-funnel-marker-pin-02-icon2"
    //                             alt=""
    //                             src={markerpin02}
    //                             />
    //                         </div>
    //                         <div className="funnel-software-component-funnel-location3">India, New Delhi</div>
    //                         </div> */}

    //                     <div className="funnel-software-component-funnel-frame-wrapper19">
    //                       <div className="funnel-software-component-funnel-legend-series">
    //                         <div className="funnel-software-component-funnel-search-input">
    //                           <img
    //                             className="funnel-software-component-funnel-marker-pin-02-icon2"
    //                             alt=""
    //                             src={calendar1}
    //                           />
    //                         </div>
    //                         <div className="funnel-software-component-funnel-type-of-work2">{getCustomizedDate(wantedSoftware.createdAt)}</div>
    //                       </div>
    //                     </div>

    //                     <div className="funnel-software-component-funnel-frame-wrapper18">
    //                       <div className="funnel-software-component-funnel-badge-elements-copy">
    //                         <div className="funnel-software-component-funnel-badge36">
    //                           <div className="funnel-software-component-funnel-badge-number-element">
    //                             {wantedSoftware.tax_software_type.name}
    //                           </div>
    //                         </div>
    //                         <div className="funnel-software-component-funnel-badge37">
    //                           <div className="funnel-software-component-funnel-second-badge-count">{wantedSoftware.tax_clinical_application.name}</div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="funnel-software-component-funnel-frame-parent26">

    //                     <div className="funnel-software-component-funnel-badge38">
    //                       <div className="funnel-software-component-funnel-third-badge-number">
    //                         {wantedSoftware.tax_purpose_use.name}
    //                       </div>
    //                     </div>
    //                   </div>
    //                   {/* <div className="funnel-software-component-funnel-badge39">
    //                         <div className="funnel-software-component-funnel-location-badge-elements">
    //                         Accounting
    //                         </div>
    //                     </div> */}
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="funnel-software-component-funnel-filter-elements">
    //               <div className="funnel-software-component-funnel-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
    //                 <img
    //                   className="funnel-software-component-funnel-dots-vertical-icon"
    //                   alt=""
    //                   src={dotsvertical}
    //                 />
    //                 {activeThreeDotId === index && <ThreeDotsListingComponent url={`wanted-software/${wantedSoftware.id}`} />}
    //               </div>

    //             </div>
    //           </div>
    //         </div>
    //         <img className="funnel-software-component-funnel-divider-icon8" alt="" src={divider2} />

    //         <div className="funnel-software-component-funnel-frame-parent71">
    //           <div className="funnel-software-component-funnel-buttonsbutton-parent1">
    //             <div className="funnel-legend-series">
    //               <img
    //                 className="funnel-location-column-child"
    //                 alt=""
    //                 src={wantedSoftware.profileImage}
    //               />

    //               <div className="funnel-location-column-inner">
    //                 <div className="funnel-date-picker-dropdown">
    //                   <div className="funnel-name">{wantedSoftware.wanted_software_user.displayName}</div>
    //                   {/* <div className="funnel-role">Business Na...</div> */}
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='funnel-software-component-main-buttons-group'>
    //               <div className="funnel-software-component-funnel-buttonsbutton13">
    //                 <img
    //                   className="funnel-software-component-funnel-arrow-narrow-left-icon"
    //                   alt=""
    //                   src={eye}
    //                 />

    //                 <div className="funnel-software-component-funnel-search-input">
    //                   <div className="funnel-software-component-funnel-text67">View</div>
    //                 </div>
    //               </div>
    //               <div className="funnel-software-component-funnel-buttonsbutton25">
    //                 <div className="funnel-software-component-funnel-icon-wrapper">
    //                   <img className="funnel-software-component-funnel-icon1" alt="" src={icon1} />
    //                 </div>
    //                 <div className="funnel-software-component-funnel-search-input">
    //                   <div className="funnel-software-component-funnel-text68">Sales IQ</div>
    //                 </div>
    //                 <img
    //                   className="funnel-software-component-funnel-arrow-narrow-left-icon"
    //                   alt=""
    //                   src={lock04}
    //                 />
    //               </div>
    //               <div className="funnel-software-component-funnel-buttonsbutton26">
    //                 <div className="funnel-software-component-funnel-icon-wrapper">
    //                   <img className="funnel-software-component-funnel-icon1" alt="" src={icon1} />
    //                 </div>
    //                 <div className="funnel-software-component-funnel-search-input">
    //                   <div className="funnel-software-component-funnel-text68">Sales IQ</div>
    //                 </div>
    //                 <img
    //                   className="funnel-software-component-funnel-arrow-narrow-left-icon"
    //                   alt=""
    //                   src={lockunlocked04}
    //                 />
    //               </div>
    //               <div className="funnel-software-component-funnel-buttonsbutton27">
    //                 <img
    //                   className="funnel-software-component-funnel-upload-04-icon"
    //                   alt=""
    //                   src={messagechatsquare}
    //                 />

    //                 <div className="funnel-software-component-funnel-text145">Messages</div>
    //                 <div className="funnel-software-component-funnel-ellipse-parent">
    //                   <div className="funnel-software-component-funnel-ellipse-div"></div>
    //                   <div className="funnel-software-component-funnel-div">2</div>
    //                 </div>
    //               </div>
    //             </div>

    //           </div>
    //           <div className="funnel-software-component-funnel-arrow-square-up-right-wrapper1">
    //             <img
    //               className="funnel-software-component-funnel-arrow-square-up-right-icon1"
    //               alt=""
    //               src={arrowsquareupright}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ))
    // }
    // else {
    //   content = <NoListingFound path={"/add-wanted-software"} pathName={"Wanted Software"} />

    // }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  if (isSuccess) {
    return (
      <>
        <div className="funnel-frame-wrapper">
          <div className="funnel-frame-group">
            <div className="funnel-rectangle-group">
              <div className="funnel-frame-item"></div>
              <div className="funnel-metric-card-row">
                <div className="funnel-metric-card">
                  <div className="funnel-metric-card-title">
                    <div className="funnel-heading4">Devices</div>
                  </div>
                  <div className="funnel-dropdown3">
                    <img
                      className="funnel-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                  </div>
                </div>
                <div className="funnel-number3">{data.totalCountFunnelDevice || 0}</div>
              </div>
              {/* <div className="funnel-badge11">
              <div className="funnel-options-icon">
                <img
                  className="funnel-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="funnel-text58">20 slots available</div>
            </div> */}
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-parent">
                  <div className="funnel-heading5">Spares</div>
                  <div className="funnel-number4">{data.totalCountFunnelSpare || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-options-icon">
                <div className="funnel-heading-group">
                  <div className="funnel-heading5">Services</div>
                  <div className="funnel-number4">{data.totalCountFunnelService || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-container">
                  <div className="funnel-heading5">Software</div>
                  <div className="funnel-number4">{data.totalCountFunnelSoftware || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-container">
                  <div className="funnel-heading5">Jobs</div>
                  <div className="funnel-number4">{data.totalCountFunnelJob || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>

          </div>
        </div>


        <div className="funnel-dropdowns">

          <FunnelSoftwareCardTabs />

        </div>

        {/* Load more button  */}
        <div className="funnel-frame-wrapper44">
          <div className="funnel-frame-parent72">
            <div className="funnel-buttonsbutton-wrapper1">
              {showLoadMore && (
                <button className='funnel-buttonsbutton-wrapper1-child'
                  onClick={handleLoadMore}
                  disabled={isFetching}
                >
                  <div className="funnel-buttonsbutton28">
                    <img
                      className="funnel-arrow-narrow-left-icon"
                      alt=""
                      src={arrowdown1}
                    />
                    <div className="funnel-search-input">
                      <div className="funnel-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  } else if (isError) {
    return <ErrorShowComponent message1={'Internal server error'} />

  }

}

