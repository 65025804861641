import { AddWantedDevice } from "../../screens/WantedDevice/AddWantedDevice/AddWantedDevice";
import { ArchiveWantedDevice } from "../../screens/WantedDevice/ArchiveWantedDevice/ArchiveWantedDevice";
import { ArchiveWantedDeviceFinal } from "../../screens/WantedDevice/ArchiveWantedDevice/ArchiveWantedDeviceFinal";
import { DetailedWantedDevice } from "../../screens/WantedDevice/DetailedWantedDevice/DetailedWantedDevice";
import { EditWantedDevice } from "../../screens/WantedDevice/EditWantedDevice/EditWantedDevice";

export const wantedDeviceRoutes = [
    {
        path: "/wanted-devices",
        element: <ArchiveWantedDeviceFinal />,
      },
      {
        path: "/add-wanted-device",
        element: <AddWantedDevice />,
      },
      {
        path: "/edit-wanted-device/:id/*",
        element: <EditWantedDevice />,
      },
      {
        path: "/wanted-device/:id/*",
        element: <DetailedWantedDevice />,
      },
]