import React from 'react';
import "./NoAccessBusiness.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoAccess from "../../icons/Gerator/business_page/NoAccess.png";
import { logout } from '../../helper/UserHelper';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const NoAccessBusiness = ({ path, pathName }) => {
  const navigate = useNavigate();

  const handleNewAccountButton = async () => {
    const auth = getAuth();
    try {
      //Signout from firebase
      await signOut(auth);

      // Handle successful logout, like redirecting to the login page
      console.log("User logged out successfully");
      navigate("/sign-up", { state: { message: "Create new account with business email." } });
    } catch (error) {
      console.log("Error during logout:", error);
      navigate("/sign-up", { state: { message: "Internal server error" } });
    }
  };

  return (
    <div className="no-access-business-no-access-business">
      <section className="no-access-business-empty-state">
        <div className="no-access-business-empty-state1">
          <div className="no-access-business-content">
            <img
              className="no-access-business-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoAccess}
            />

            <div className="no-access-business-text-and-supporting-text">
              <div className="no-access-business-title">Business profile needs business email</div>
              <div className="no-access-business-supporting-text">
                You signed up with {path || "[Email]"}. To add a business profile, create a new account with your work email (you@yourcompany.com).
              </div>
            </div>
          </div>
          <div className="no-access-business-actions">

            <button className="no-access-business-buttonsbutton" onClick={handleNewAccountButton}>
              <img
                className="no-access-business-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-access-business-text-padding">
                <div className="no-access-business-text">New Account</div>
              </div>
              <img
                className="no-access-business-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>


            {/* <button className="no-access-business-buttonsbutton1"  >
              <Link to={path} className='no-access-business-button-link'>
                <img className="no-access-business-plus-icon" alt="" src={plus} />

                <div className="no-access-business-text-padding">
                  <div className="no-access-business-text1">Add {pathName}</div>
                </div>
                <img
                  className="no-access-business-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button> */}
          </div>

          <div className="no-access-business-supporting-text">
            Why do you need a business domain and work email?
          </div>
        </div>
      </section>
    </div>
  )
}
