

export function imageToFormData(galleryImage) {
  const formData = new FormData();
  console.log(galleryImage);

  if (galleryImage instanceof File) {
    console.log('galleryimage is added ');

    formData.append("galleryImageObject", galleryImage);
  }
  console.log(formData);

  return formData;

}


export function documentToFormData(documentImage) {
  const formData = new FormData();
  console.log(documentImage);

  if (documentImage instanceof File) {
    console.log('document is added ');

    formData.append("documentFileObject", documentImage);
  }
  console.log(formData);

  return formData;

}
