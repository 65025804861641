import React, { useContext, useEffect, useState } from 'react'
import divider from '../../icons/Gerator/account_setup_profile_subscription/divider.svg';
import homeline from '../../icons/Gerator/account_setup_profile_subscription/homeline.svg';
import chevronright from '../../icons/Gerator/account_setup_profile_subscription/chevronright.svg';
import placeholder1 from '../../icons/Gerator/account_setup_profile_subscription/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/account_setup_profile_subscription/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/account_setup_profile_subscription/placeholder3.svg';
import denied from '../../icons/Gerator/denied.svg';
import loading from '../../icons/Gerator/loading.svg';
import placeholder4 from '../../icons/Gerator/account_setup_profile_subscription/placeholder4.svg';
import searchlg from '../../icons/Gerator/account_setup_profile_subscription/searchlg.svg';
import help_icon from '../../icons/Gerator/account_setup_profile_subscription/help-icon.svg';
import lock04 from '../../icons/Gerator/account_setup_profile_subscription/lock04.svg';
import mail01_11 from '../../icons/Gerator/account_setup_profile_subscription/mail01-11.svg';
import checkcircle from '../../icons/Gerator/account_setup_profile_subscription/checkcircle.svg';
import phone from '../../icons/Gerator/account_setup_profile_subscription/phone.svg';
import featured_icon_41 from '../../icons/Gerator/account_setup_profile_subscription/featured-icon-41.svg';
import page from '../../icons/Gerator/account_setup_profile_subscription/page.svg';
import cursor_2x from '../../icons/Gerator/account_setup_profile_subscription/cursor@2x.png';
import au from '../../icons/Gerator/account_setup_profile_subscription/au.svg';
import chevrondown from '../../icons/Gerator/account_setup_profile_subscription/chevrondown.svg';
import "./AccountUpdateProfileForm.css";
import { AccountSetupButton } from '../AccountSetupButton/AccountSetupButton';
import { Form, useNavigate } from 'react-router-dom';
import { Field, Formik, useField } from 'formik';
import * as Yup from "yup";
import { useAddUserProfileMutation } from '../../api/UsermetaAPI';
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
import { BackToLoginButton } from '../BackToLoginButton/BackToLoginButton';
import { Link } from 'react-router-dom';
import AddProfileImageCrop from '../ImageCropComponent/AddProfileImageCrop';
import AccountUpdateProfileContext from '../../screens/AccountSetup/Profile/AccountUpdateProfileContext';
import { objectToFormDataAddUsermeta } from '../../helper/UsermetaHelper';
import { isValidURL } from '../../helper/DeviceHelper';



const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  {
    fieldValidationData !== null ? (
      <div className="error">{fieldValidationData.msg}</div>
    ) : null
  }

  return (
    <>
      <div className="account-update-profile-input-with-label3">
        <div className="account-update-profile-input7">
          {/* <div className="account-update-profile-content23"> */}
          {/* <img
              className="account-update-profile-home-line-icon"
              loading="lazy"
              alt=""
              src="/account_setup_profile_subscription/au.svg"
            /> */}

          <select
            style={inputStyle}   //New code for border colour
            {...field}
            {...props}
          />
          {/* </div> */}
          {/* <img
            className="account-update-profile-home-line-icon"
            loading="lazy"
            alt=""
            src={chevrondown}
          /> */}
        </div>
      </div>





      {meta.touched && meta.error ? (
        <div className="account-update-profile-hint-text2 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="account-update-profile-hint-text2 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}
    </>
  );
};

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="account-update-profile-input-with-label3">
        <div className="account-update-profile-input3">

          <input
            // className="account-update-profile-content27"
            {...field}
            {...props}
          />
          <img
            className="account-update-profile-help-icon"
            alt=""
            src={help_icon}
          />
        </div>
      </div>
      {(meta.touched && meta.error) ? (
        <div className="account-update-profile-hint-text2 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="account-update-profile-hint-text2 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}

    </>
  );
};


const FileUpload = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "featureImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <label htmlFor="profileImage" className='update-profile'>
        <div className="account-update-profile-file-upload-base">
          <div className="account-update-profile-content17">

            <img
              className="account-update-profile-featured-icon12"
              loading="lazy"
              alt=""
              src={featured_icon_41}
            />

            <div className="account-update-profile-text-and-supporting-text5">
              <div className="account-update-profile-action">
                <div className="account-update-profile-buttonsbutton8">
                  <img
                    className="account-update-profile-placeholder-icon16"
                    alt=""
                    src={placeholder3}
                  />

                  <div className="account-update-profile-text56">Click to upload</div>
                  <input type="file" accept="image/*" {...field} {...props} className='hide-input-field' />
                  {/* <img
                className="account-update-profile-placeholder-icon16"
                alt=""
                src={placeholder3}
              /> */}
                </div>
                <div className="account-update-profile-text57">or drag and drop</div>
              </div>
              <div className="account-update-profile-supporting-text15">
                PNG or JPG (max. 400x400px)
              </div>
            </div>
            {/* <div className="account-update-profile-file">
          <div className="account-update-profile-file-type-icon">
            <img
              className="account-update-profile-page-icon"
              alt=""
              src={page}
            />

            <div className="account-update-profile-file-type-wrap">
              <b className="account-update-profile-file-type">JPG</b>
            </div>
          </div>
          <img
            className="account-update-profile-cursor-icon"
            loading="lazy"
            alt=""
            src={cursor_2x}
          />
        </div> */}
            {(meta.touched && meta.error) ? (
              <div className="error-for-all-field error-for-document">{meta.error}</div>
            ) : null}
            {/* <div className="error-for-all-field error-for-document">Error</div> */}
            {fieldValidationData !== "" ? (
              <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
            ) : null}
          </div>
        </div>
      </label>
    </>

  );
};

const AccountUpdateProfileForm = ({ user, formikRef, setShowSuccessMessage }) => {
  const [addUserProfile, { isLoading, isError, error }] = useAddUserProfileMutation();
  const [featureImageDemoFile, setFeatureImageDemoFile] = useState("");
  const [imageSingleCheckboxError, setImageSingleCheckboxError] = useState("");
  const [fileURL, setFileURL] = useState("");
  // const [imageSingleCheckboxError, setImageSingleCheckboxError] = useState("");
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    profileImage: '',
    profileImageLink: '',
    userWorkTitle: '',
    userBusinessName: '',
    userCountry: '',
    userBuilding: '',
    userStreet: '',
    userState: '',
    userDistrict: '',
    userTown: '',
    userPostcodeZip: '',
  });


  const { googleProfileImageLink, setGoogleProfileImageLink, allTaxonomy, backendValidation, setBackendValidation, cropedImageFile, setCropedImageFile, selectedImageFile, setSelectedImageFile } = useContext(AccountUpdateProfileContext);
  useEffect(() => {
    // console.log( user);
    if (user) {
      let name = user.displayName.split(" ");

      setInitialValues({
        firstName: name[0] || '',
        lastName: name[1] || 'Not Known',
        email: user.email || '',
        phone: '',
        profileImage: googleProfileImageLink || '',
        profileImageLink: googleProfileImageLink || '',
        userWorkTitle: '',
        userBusinessName: '',
        userCountry: '',
        userBuilding: '',
        userStreet: '',
        userState: '',
        userDistrict: '',
        userTown: '',
        userPostcodeZip: '',
      })
    }
  }, [user])



  // Add google profile photo to profileImage 
  useEffect(() => {

    const setProfileImage = async () => {


      if (googleProfileImageLink) {


        setFileURL(googleProfileImageLink);


        await formikRef.current.setFieldValue('profileImage', googleProfileImageLink);
        await formikRef.current.setFieldValue('profileImageLink', googleProfileImageLink);
        // await formikRef.current.setFieldTouched("profileImage", true);
      }


    }

    setProfileImage();

  }, [googleProfileImageLink]);


  // Add uploaded image photo to profileImage
  useEffect(() => {
    if (cropedImageFile) {
      const url = URL.createObjectURL(cropedImageFile);
      setFileURL(url);

      return () => URL.revokeObjectURL(url); // Cleanup when the component unmounts or the file changes
    }
  }, [cropedImageFile]);

  // At first before image added to profilImage this effect is called
  useEffect(() => {
    if (selectedImageFile) {
      handleSelectedImageFile();
    }
  }, [selectedImageFile]);



  const handleChange = async (event) => {
    if (!formikRef.current) return;

    const { name, value } = event.target;
    console.log("handle change called");

    if (name === 'profileImage') {
      setBackendValidation([]);
      const file = event.target.files[0];
      console.log('\x1b[36m%s\x1b[0m', "inside handle change called");
      formikRef.current.setFieldValue(name, file);
      formikRef.current.setFieldTouched("profileImage", true);
      // Clearing errors
      if (formikRef.current.errors.profileImage) {
        console.log(formikRef.current.errors.profileImage);
        const filteredErrors = Object.keys(formikRef.current.errors).reduce((acc, fieldName) => {
          if (fieldName !== 'profileImage') {
            acc[fieldName] = formikRef.current.errors[fieldName];
          }
          return acc;
        }, {});
        console.log(filteredErrors);
        formikRef.current.setErrors(filteredErrors)
      }
      await setSelectedImageFile(file);
      return;
    }

    console.log("handle change closed");
    formikRef.current.setFieldValue(name, value);
  };


  const handleSelectedImageFile = async () => {
    if (!formikRef.current) return;

    // console.log("HandleSelectedImageFile Called " + );

    formikRef.current.setFieldTouched("profileImage", true);

    console.log(formikRef.current.errors);

    if (formikRef.current.errors.profileImage) {
      console.log("Inside errors.gallery is true means error found ");
      if (cropedImageFile !== '') setCropedImageFile('')
      setSelectedImageFile('');
      setFeatureImageDemoFile(formikRef.current.values.profileImage);
      setImageSingleCheckboxError(formikRef.current.errors.profileImage);
      return;
    }

    console.log("Inside !errors.gallery is true means no error found");
    setFeatureImageDemoFile('');
    setImageSingleCheckboxError('');
    if (cropedImageFile === '') setSelectedImageFile(formikRef.current.values.profileImage);
  }

  const handleImageCheckbox = async (e) => {
    if (!formikRef.current) return;

    if (cropedImageFile !== '') setCropedImageFile('');

    if (formikRef.current.errors.profileImage) {
      console.log(formikRef.current.errors.profileImage);
      const filteredErrors = Object.keys(formikRef.current.errors).reduce((acc, fieldName) => {
        if (fieldName !== 'profileImage') {
          acc[fieldName] = formikRef.current.errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await formikRef.current.setFieldValue("profileImage", '');
      await formikRef.current.setErrors(filteredErrors)
      setFileURL("");
      setGoogleProfileImageLink('');
      setFeatureImageDemoFile('');
      setImageSingleCheckboxError('');
    } else {
      await formikRef.current.setFieldValue("profileImage", '');
      setFileURL("");
      setGoogleProfileImageLink('');
      setFeatureImageDemoFile('');
      setImageSingleCheckboxError('');
      // if (formikRef.current.errors.profileImage) {
      //   console.log(formikRef.current.errors.profileImage);
      //   const filteredErrors = Object.keys(formikRef.current.errors).reduce((acc, fieldName) => {
      //     if (fieldName !== 'profileImage') {
      //       acc[fieldName] = formikRef.current.errors[fieldName];
      //     }
      //     return acc;
      //   }, {});
      //   console.log(filteredErrors);
      //   formikRef.current.setErrors(filteredErrors);
      // }
    }
  }



  const finalSubmit = async (formData, setSubmitting) => {

    try {

      const response = await addUserProfile(formData).unwrap();
      // console.log("Device added successfully!", response);
      //If form was submitted successfully then status return with true, then reseting form
      // return;
      console.log(response);
      if (response.status) {
        // setShowSuccessMessage(response.message)

        setShowSuccessMessage({ message: "Profile updated successfully.", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        navigate('/account-setup-subscription-plan');
        formikRef.current.resetForm();
      }
      if (!response.status) {
        setShowSuccessMessage({ message: response.message, color: "red" })
      }
    } catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error.data);
        console.log(decryptedData.errors);
        setBackendValidation(decryptedData.errors)
        // setShowSuccessMessage({message: decryptedData.message, color: "red" });
      } else if (error.status === 400) {
        setShowSuccessMessage({ message: "Oh! found some issue with image. Please reselect image", color: "red" });
      }
      else {
        console.log(error);
        setShowSuccessMessage({ message: error.message, color: "red" });
      }
      setSubmitting(false);
    }

  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={Yup.object({

          phone: Yup.string().required("Field is required").matches(/^\+?[0-9]{1,4}[ \-]?[0-9]{5,15}$/, 'Invalid phone number format. Please enter a valid number'),

          profileImage: Yup.mixed()
            .test(
              'fileType',
              'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
              value => {
                if (isValidURL(value)) return true;
                if (!value) return true;
                return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
              }

            )
            .test(
              'fileSize',
              'File too large (max size 1MB)',
              value => {
                if (isValidURL(value)) return true;
                // console.log(value);
                return (value && value.size <= 1024 * 1024)
              }
            )
            .required('A file is required'),

          userWorkTitle: Yup.string()
            .required("Field is required")
            .matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots')
            .min(3, "Must be 3 characters or more")
            .max(25, "Must be 25 characters or less"),

          userBusinessName: Yup.string().required("Field is required").matches(/^[A-Za-z0-9][A-Za-z0-9\s\-\.]+$/, 'Must start with a letter or number and contain only letters, numbers, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(35, 'Must be 35 characters or less'),

          userCountry: Yup.string().required("Field is required").test(
            "OPTION", "Please select a valid option",
            (value) => value !== '0'
          ),
          userBuilding: Yup.string().required("Field is required").matches(/^[A-Za-z0-9][A-Za-z0-9\s\-\.]+$/, 'Must start with a letter or number and contain only letters, numbers, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(35, 'Must be 35 characters or less'),

          userStreet: Yup.string().required("Field is required").matches(/^[A-Za-z0-9][A-Za-z0-9\s\-\.]+$/, 'Must start with a letter or number and contain only letters, numbers, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(40, 'Must be 40 characters or less'),

          userState: Yup.string().matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(40, 'Must be 40 characters or less'),

          userTown: Yup.string()
            .required("Field is required")
            .matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots')
            .min(3, 'Must be 3 characters or more')
            .max(40, 'Must be 40 characters or less'),

          userDistrict: Yup.string().matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(25, 'Must be 25 characters or less'),

          userPostcodeZip: Yup.string()
            .required("Field is required")
            .matches(/^[a-zA-Z0-9]+$/, 'Must contain only letters and numbers')
            .min(3, 'Must be 3 characters or more')
            .max(10, 'Must be 10 characters or less')
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          multiPartData = objectToFormDataAddUsermeta(values, cropedImageFile);
          finalSubmit(multiPartData, setSubmitting);
          // resetForm();
          // setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="account-update-profile-content-elements">
            <div className="account-update-profile-content9">
              <div className="account-update-profile-section-label">
                <div className="account-update-profile-text50">Name</div>
              </div>
              <div className="account-update-profile-input-fields">
                <div className="account-update-profile-input-field">
                  <div className="account-update-profile-input-with-label1">
                    <div className="account-update-profile-input1 input-disabled ">
                      <input
                        className="account-update-profile-content10 input-disabled-color"
                        // placeholder="Oliva"
                        type="text"
                        value={initialValues.firstName}
                        disabled
                      />
                      {/* <MyTextInput
                        className="account-update-profile-content10 input-disabled-color"
                        // placeholder="Oliva"
                        name="firstName"
                        type="text"
                        disabled
                      /> */}
                      {/* <Field
                    className="account-update-profile-content10"
                    placeholder="Oliva"
                    type="text"
                    name="firstName"
                    disabled
                  /> */}
                      <img
                        className="account-update-profile-home-line-icon"
                        alt=""
                        src={lock04}
                      />
                    </div>
                  </div>
                  <div className="account-update-profile-hint-text2">
                    This field cannot be edited.
                  </div>
                </div>
                <div className="account-update-profile-input-field">

                  <div className="account-update-profile-input2 input-disabled">
                    <input
                      className="account-update-profile-content11 input-disabled-color"
                      // placeholder="Oliva"
                      type="text"
                      value={initialValues.lastName}
                      disabled
                    />
                    <img
                      className="account-update-profile-home-line-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="account-update-profile-hint-text2">
                    This field cannot be edited.
                  </div>
                </div>
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <a className="account-update-profile-text51">Email</a>
              </div>
              <div className="account-update-profile-input-field1">
                <div className="account-update-profile-input-with-label3">
                  <div className="account-update-profile-input3 input-disabled">
                    <div className="account-update-profile-content13">
                      <img
                        className="account-update-profile-home-line-icon"
                        alt=""
                        src={mail01_11}
                      />

                      <input
                        className="account-update-profile-text52 input-disabled-color"
                        // placeholder="Oliva"
                        type="text"
                        value={initialValues.email}
                        disabled
                      />

                    </div>
                    <img
                      className="account-update-profile-home-line-icon"
                      alt=""
                      src={lock04}
                    />

                    <img
                      className="account-update-profile-help-icon"
                      alt=""
                      src={help_icon}
                    />
                  </div>
                </div>
                <div className="account-update-profile-hint-text2">
                  This field cannot be edited.
                </div>
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Phone <span style={{ color: "red" }}>*</span></div>
                <div className="update-profile-view-supporting-text14">
                  Country Code - Number
                </div>
              </div>
              <div className="account-update-profile-input-field1">
                <MyTextInput
                  className="account-update-profile-text52"
                  placeholder="Enter phone with country code "
                  type="text"
                  name="phone"
                  validate={backendValidation}
                />
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content9">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-heading-and-help-icon">
                  <div className="account-update-profile-text55">Your photo <span style={{ color: "red" }}>*</span></div>
                  <div className="account-update-profile-help-icon-wrapper">
                    {/* <img
                      className="account-update-profile-help-icon3"
                      loading="lazy"
                      alt=""
                      src={help_icon}
                    /> */}
                  </div>
                </div>
                <div className="account-update-profile-supporting-text14">
                  This will be displayed on your profile.
                </div>
              </div>
              <div className="account-update-profile-avatar-and-file-upload">
                <div className="account-update-profile-avatar1" style={{
                  backgroundImage: fileURL
                    && `url(${fileURL})`
                }}>
                  <div className="account-update-profile-contrast-border"></div>
                </div>
                <div className="account-update-profile-file-upload account-update-profile-file-upload-custom">


                  <FileUpload key={selectedImageFile ? selectedImageFile.name : Date.now()} name="profileImage" id="profileImage" onChange={handleChange} validate={backendValidation} value={undefined} />
                  {(selectedImageFile instanceof File) && (
                    <AddProfileImageCrop />
                  )}
                  {/* <div className="account-update-profile-content17">
                      <img
                        className="account-update-profile-featured-icon12"
                        loading="lazy"
                        alt=""
                        src={featured_icon_41}
                      />

                      <div className="account-update-profile-text-and-supporting-text5">
                        <div className="account-update-profile-action">
                          <div className="account-update-profile-buttonsbutton8">
                            <img
                              className="account-update-profile-placeholder-icon16"
                              alt=""
                              src={placeholder3}
                            />

                            <div className="account-update-profile-text56">Click to upload</div>
                            <img
                              className="account-update-profile-placeholder-icon16"
                              alt=""
                              src={placeholder3}
                            />
                          </div>
                          <div className="account-update-profile-text57">or drag and drop</div>
                        </div>
                        <div className="account-update-profile-supporting-text15">
                          PNG or JPG (max. 400x400px)
                        </div>
                      </div>
                      <div className="account-update-profile-file">
                        <div className="account-update-profile-file-type-icon">
                          <img
                            className="account-update-profile-page-icon"
                            alt=""
                            src={page}
                          />

                          <div className="account-update-profile-file-type-wrap">
                            <b className="account-update-profile-file-type">JPG</b>
                          </div>
                        </div>
                        <img
                          className="account-update-profile-cursor-icon"
                          loading="lazy"
                          alt=""
                          src={cursor_2x}
                        />
                      </div>
                    </div> */}

                  {
                    (typeof cropedImageFile === 'object' && cropedImageFile !== "") &&

                    (
                      <div className="add-service-step-file-upload-item-base account-update-profile-custom-margin">
                        <div className="add-service-step-first-upload-item-content">
                          <div className="add-service-step-file-type-icon">
                            <img
                              className="add-service-step-page-icon"
                              loading="lazy"
                              alt=""
                              src={page}
                            />

                            <div className="add-service-step-file-type-wrap">
                              <b className="add-service-step-file-type">IMG</b>
                            </div>
                          </div>
                          <div className="add-service-step-first-upload-item-info">
                            <div className="add-service-step-first-upload-item-inner">
                              <div className="add-service-step-first-upload-item">
                                {cropedImageFile.name}
                              </div>
                              <div className="add-service-step3-checkbox3">
                                <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                              </div>
                            </div>
                            <div className="add-service-step3-supporting-text14">{(cropedImageFile.size / 1000).toFixed(1) + " KB"}</div>
                          </div>
                        </div>
                        <div className="add-service-step3-progress-bar8">
                          <div className="add-service-step3-progress-bar-wrapper">
                            <div className="add-service-step3-progress-bar9">
                              <div className="add-service-step3-background3"></div>
                              <div className="add-service-step3-progress3"></div>
                            </div>
                          </div>
                          <div className="add-service-step3-percentage3">100%</div>

                          {/* {(imageSingleCheckboxError !== "") ?
                            <div style={{ color: "red" }}>
                              {imageSingleCheckboxError}
                            </div> :
                            <div className="add-service-step3-percentage3">100%</div>
                          } */}
                        </div>

                      </div>
                    )
                  }
                  {
                    (featureImageDemoFile instanceof File) &&

                    (
                      <div className="add-service-step-file-upload-item-base account-update-profile-custom-margin">
                        <div className="add-service-step-first-upload-item-content">
                          <div className="add-service-step-file-type-icon">
                            <img
                              className="add-service-step-page-icon"
                              loading="lazy"
                              alt=""
                              src={page}
                            />

                            <div className="add-service-step-file-type-wrap">
                              <b className="add-service-step-file-type">IMG</b>
                            </div>
                          </div>
                          <div className="add-service-step-first-upload-item-info">
                            <div className="add-service-step-first-upload-item-inner">
                              <div className="add-service-step-first-upload-item">
                                {featureImageDemoFile.name}
                              </div>
                              <div className="add-service-step3-checkbox3">
                                <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                              </div>
                            </div>
                            <div className="add-service-step3-supporting-text14">{(featureImageDemoFile.size / 1000).toFixed(1) + " KB"}</div>
                          </div>
                        </div>
                        <div className="add-service-step3-progress-bar8">
                          <div className="add-service-step3-progress-bar-wrapper">
                            <div className="add-service-step3-progress-bar9">
                              <div className="add-service-step3-background3"></div>
                              <div className="add-service-step3-progress3"></div>
                            </div>
                          </div>
                          <div className="add-service-step3-percentage3">100%</div>
                          {/* 
                          {(imageSingleCheckboxError !== "") ?
                            <div style={{ color: "red" }}>
                              {imageSingleCheckboxError}
                            </div> :
                            <div className="add-service-step3-percentage3">100%</div>
                          } */}
                        </div>

                      </div>
                    )
                  }

                </div>
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Work Title <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="account-update-profile-input-field1">
                <MyTextInput
                  className="account-update-profile-content19 error"
                  placeholder="Enter Work Title"
                  type="text"
                  name="userWorkTitle"
                  validate={backendValidation}
                />
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Business Name <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="account-update-profile-input-field1">
                <MyTextInput
                  className="account-update-profile-content19"
                  placeholder="Enter Business Name"
                  type="text"
                  name="userBusinessName"
                  validate={backendValidation}
                />

              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Country <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="account-update-profile-input-dropdown1">
                {/* <div className="account-update-profile-input-with-label3">

                  <div className="account-update-profile-input7">
                    
                  </div>
                </div> */}
                <MySelect className='account-update-profile-content-custom-23 down-icon-hide account-update-profile-text63 ' label="Country" name="userCountry" id="country" validate={backendValidation}>
                  <option value="0">Select From Dropdown List </option>
                  {(allTaxonomy.country.isLoading || allTaxonomy.country.isError) && <option className="account-update-profile-text61" value="0">Loading...</option>}
                  {
                    (allTaxonomy.country.isSuccess) && (allTaxonomy.country.data.map(tax => (
                      <option key={tax.id} value={tax.id}>{tax.name}</option>
                    )))
                  }

                </MySelect>

              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Building <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="account-update-profile-input-dropdown1">
                {/* <div className="account-update-profile-input-with-label3">
                  <div className="account-update-profile-input8">
                    
                  </div>

                </div> */}
                <MyTextInput
                  className="account-update-profile-content27"
                  placeholder="Enter Building"
                  type="text"
                  name="userBuilding"
                  validate={backendValidation}
                />
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Street <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="account-update-profile-input-dropdown1">
                <MyTextInput
                  className="account-update-profile-content27"
                  placeholder="Enter Street"
                  type="text"
                  name="userStreet"
                  validate={backendValidation}
                />

              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">State/Region</div>
              </div>
              <div className="account-update-profile-input-dropdown1">
                <MyTextInput
                  className="account-update-profile-content27"
                  placeholder="Enter State/Region"
                  type="text"
                  name="userState"
                  validate={backendValidation}
                />

              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">Town/City <span style={{ color: "red" }}>*</span></div>
              </div>
              <div className="account-update-profile-input-dropdown1">
                <MyTextInput
                  className="account-update-profile-content27"
                  placeholder="Enter Town/City"
                  type="text"
                  name="userTown"
                  validate={backendValidation}
                />
              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content12">
              <div className="account-update-profile-section-label1">
                <div className="account-update-profile-text53">District</div>
              </div>
              <div className="account-update-profile-input-dropdown1">
                <MyTextInput
                  className="account-update-profile-content27"
                  placeholder="Enter District"
                  type="text"
                  name="userDistrict"
                  validate={backendValidation}
                />

              </div>
            </div>
            <div className="account-update-profile-divider"></div>
            <div className="account-update-profile-content-container">
              <div className="account-update-profile-content32">
                <div className="account-update-profile-section-label1">
                  <div className="account-update-profile-text53">ZIP <span style={{ color: "red" }}>*</span></div>
                </div>
                <div className="account-update-profile-input-dropdown1">
                  <MyTextInput
                    className="account-update-profile-content27"
                    placeholder="Enter Post-code-Zip"
                    type="text"
                    name="userPostcodeZip"
                    validate={backendValidation}
                  />

                </div>
              </div>
            </div>
            <div className="account-update-profile-section-footer">
              <img
                className="account-update-profile-divider-icon1"
                loading="lazy"
                alt=""
                src={divider}
              />

              <div className="account-update-profile-content34">
                <div className="account-update-profile-buttonsbutton9">
                  <img
                    className="account-update-profile-placeholder-icon16"
                    alt=""
                    src={placeholder2}
                  />

                  <div className="account-update-profile-text68">Learn more</div>
                  <img
                    className="account-update-profile-placeholder-icon16"
                    alt=""
                    src={placeholder2}
                  />
                </div>
                <div className="account-update-profile-actions5">
                  {/* <div className="account-update-profile-button4">
                    <img
                      className="account-update-profile-placeholder-icon16"
                      alt=""
                      src={placeholder2}
                    />

                    <div className="account-update-profile-text-padding8">
                      <div className="account-update-profile-text69">Tertiary</div>
                    </div>
                    <img
                      className="account-update-profile-placeholder-icon16"
                      alt=""
                      src={placeholder2}
                    />
                  </div> */}

                  <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                    <BackToLoginButton />
                  </Link>

                  <button className="account-update-profile-button5">
                    <img
                      className="account-update-profile-placeholder-icon16"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="account-update-profile-text-padding13">
                      <div className="account-update-profile-text70">Cancel</div>
                    </div>
                    <img
                      className="account-update-profile-placeholder-icon16"
                      alt=""
                      src={placeholder1}
                    />
                  </button>
                  <button className="account-update-profile-button6" type='submit' onClick={handleSubmit} disabled={isSubmitting}>
                    {/* <img
                      className="account-update-profile-placeholder-icon16"
                      alt=""
                      src={placeholder4}
                    /> */}

                    <div className="account-update-profile-text-padding14">
                      <div className="account-update-profile-text71 account-update-profile-text71-custom ">{isSubmitting ? 'Updating...' : 'Update'}</div>
                    </div>
                    {/* <img
                      className="account-update-profile-placeholder-icon16"
                      alt=""
                      src={placeholder4}
                    /> */}
                  </button>
                </div>
              </div>
              {/* <AccountSetupButton text="Continue" next="/account-setup-subscription-plan" /> */}
            </div>
          </Form>
        )}
      </Formik >

    </>
  )
};

export default AccountUpdateProfileForm;


// let profileFormValues = {
//   firstName: '',
//   lastName: '',
//   phone: '',
//   userWorkTitle: '',
//   userBusinessName: '',
//   userCountry: '',
//   userBuilding: '',
//   userStreet: '',
//   userState: '',
//   userTown: '',
//   userPostcodeZip: '',
// }
// Alternative approach with a more specific dependency:
// const profileImageValue = formikRef.current?.values?.profileImage;
// const profileImageError = formikRef.current?.errors?.profileImage;

// useEffect(() => {
//   if (!formikRef.current) return;

//   console.log("Values useEffect Open");

//   if (formikRef.current.touched.profileImage) {
//     if (profileImageError && profileImageValue !== "") {
//       console.log("Values Error UseEffect");
//       handleSelectedImageFile("error");
//       return;
//     }

//     if (formikRef.current.touched.profileImage && profileImageValue !== "") {
//       console.log("Values Touch UseEffect");
//       handleSelectedImageFile("no-error");
//     }
//   }

//   console.log("Values useEffect Closed ");
// }, [profileImageValue, profileImageError]);

// useEffect(() => {
//   if (!formikRef.current) return;

//   console.log("Values useEffect Open");

//   // Access values directly from formikRef.current each time
//   if (formikRef.current.touched.profileImage) {
//     if (formikRef.current.errors.profileImage && formikRef.current.values.profileImage !== "") {
//       console.log("Values Error UseEffect");
//       handleSelectedImageFile("error");
//       return;
//     }

//     if (formikRef.current.touched.profileImage && formikRef.current.values.profileImage !== "") {
//       console.log("Values Touch UseEffect");
//       handleSelectedImageFile("no-error");
//     }
//   }

//   console.log("Values useEffect Closed ");
// }, [formikRef.current]);
// let profileFormValues = {
//   firstName: '',
//   lastName: '',
//   phone: '',
//   userWorkTitle: '',
//   userBusinessName: '',
//   userCountry: '',
//   userBuilding: '',
//   userStreet: '',
//   userState: '',
//   userTown: '',
//   userPostcodeZip: '',
// }
// Alternative approach with a more specific dependency:
// const profileImageValue = formikRef.current?.values?.profileImage;
// const profileImageError = formikRef.current?.errors?.profileImage;

// useEffect(() => {
//   if (!formikRef.current) return;

//   console.log("Values useEffect Open");

//   if (formikRef.current.touched.profileImage) {
//     if (profileImageError && profileImageValue !== "") {
//       console.log("Values Error UseEffect");
//       handleSelectedImageFile("error");
//       return;
//     }

//     if (formikRef.current.touched.profileImage && profileImageValue !== "") {
//       console.log("Values Touch UseEffect");
//       handleSelectedImageFile("no-error");
//     }
//   }

//   console.log("Values useEffect Closed ");
// }, [profileImageValue, profileImageError]);

// useEffect(() => {
//   if (!formikRef.current) return;

//   console.log("Values useEffect Open");

//   // Access values directly from formikRef.current each time
//   if (formikRef.current.touched.profileImage) {
//     if (formikRef.current.errors.profileImage && formikRef.current.values.profileImage !== "") {
//       console.log("Values Error UseEffect");
//       handleSelectedImageFile("error");
//       return;
//     }

//     if (formikRef.current.touched.profileImage && formikRef.current.values.profileImage !== "") {
//       console.log("Values Touch UseEffect");
//       handleSelectedImageFile("no-error");
//     }
//   }

//   console.log("Values useEffect Closed ");
// }, [formikRef.current]);
