import React, { useState } from 'react';
import './FunnelCardTabs.css'; // Import your CSS file


import { FunnelOfferedJobCard } from '../Funnel/FunnelOfferedJobCard/FunnelOfferedJobCard';

export const FunnelJobCardTabs = () => {
    
   


    return (
        <div className='funnel-card-tab-main-container'>
           
            <div className="component-display-area">
             <FunnelOfferedJobCard/>
            </div>

        </div>
);
};


export default FunnelJobCardTabs;
