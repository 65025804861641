// import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import addDeviceReducer from '../components/AddDeviceFormComponent/AddDeviceSlice';
// import editDeviceReducer from '../components/EditDeviceFormComponent/EditDeviceSlice'
// import appDataAddDeviceReducer from '../components/AddDeviceFormComponent/AppDataDeviceSlice';
// import appDataEditDeviceReducer from '../components/EditDeviceFormComponent/AppDataEditDeviceSlice';
// import addSpareReducer from '../components/AddSpareFormComponent/AddSpareSlice';
// import editSpareReducer from '../components/EditSpareFormComponent/EditSpareSlice'
// import appDataAddSpareReducer from '../components/AddSpareFormComponent/AppDataSpareSlice';
// import appDataEditSpareReducer from '../components/EditSpareFormComponent/AppDataEditSpareSlice';
// import addServiceReducer from '../components/AddServiceFormComponent/AddServiceSlice';
// import appDataAddServiceReducer from '../components/AddServiceFormComponent/AppDataServiceSlice';
// import editServiceReducer from '../components/EditServiceFormComponent/EditServiceSlice'
// import appDataEditServiceReducer from '../components/EditServiceFormComponent/AppDataEditServiceSlice';
// import addWantedDeviceReducer from '../components/AddWantedDeviceFormComponent/AddWantedDeviceSlice';
// import editWantedDeviceReducer from '../components/EditWantedDeviceFormComponent/EditWantedDeviceSlice'
// import addWantedSpareReducer from '../components/AddWantedSpareFormComponent/AddWantedSpareSlice';
// import editWantedSpareReducer from '../components/EditWantedSpareFormComponent/EditWantedSpareSlice'
// import addWantedServiceReducer from '../components/AddWantedServiceFormComponent/AddWantedServiceSlice';
// import editWantedServiceReducer from '../components/EditWantedServiceFormComponent/EditWantedServiceSlice'
// import addWantedSoftwareReducer from '../components/AddWantedSoftwareFormComponent/AddWantedSoftwareSlice';
// import editWantedSoftwareReducer from '../components/EditWantedSoftwareFormComponent/EditWantedSoftwareSlice'

// import { DeviceAPI } from '../api/DeviceAPI';
// import { SpareAPI } from '../api/SpareAPI ';
// import { ServiceAPI } from '../api/ServiceAPI';
// import { WantedDeviceAPI } from '../api/WantedDeviceAPI';
// import { WantedSpareAPI } from '../api/WantedSpareAPI';
// import { WantedServiceAPI } from '../api/WantedServiceAPI';
// import { WantedSoftwareAPI } from '../api/WantedSoftwareAPI';
// import { TaxonomyFormAPI } from '../api/TaxonomyFormAPI';

// // const persistConfig = {
// //   key: "root",
// //   version: 1,
// //   storage,
// //   blacklist:['devices']
// // }

// const reducer = combineReducers({
//   addDevice: addDeviceReducer,
//   appDataAddDevice: appDataAddDeviceReducer,
//   editDevice: editDeviceReducer,
//   appDataEditDevice: appDataEditDeviceReducer,
//   addSpare: addSpareReducer,
//   appDataAddSpare: appDataAddSpareReducer,
//   editSpare: editSpareReducer,
//   appDataEditSpare: appDataEditSpareReducer,
//   addService: addServiceReducer,
//   appDataAddService: appDataAddServiceReducer,
//   editService: editServiceReducer,
//   appDataEditService: appDataEditServiceReducer,
//   addWantedDevice: addWantedDeviceReducer,
//   editWantedDevice: editWantedDeviceReducer,
//   addWantedSpare: addWantedSpareReducer,
//   editWantedSpare: editWantedSpareReducer,
//   addWantedService: addWantedServiceReducer,
//   editWantedService: editWantedServiceReducer,
//   addWantedSoftware: addWantedSoftwareReducer,
//   editWantedSoftware: editWantedSoftwareReducer,
//   [SpareAPI.reducerPath]: SpareAPI.reducer,
//   [DeviceAPI.reducerPath]: DeviceAPI.reducer,
//   [ServiceAPI.reducerPath]: ServiceAPI.reducer,
//   [WantedDeviceAPI.reducerPath]: WantedDeviceAPI.reducer,
//   [WantedSpareAPI.reducerPath]: WantedSpareAPI.reducer,
//   [WantedServiceAPI.reducerPath]: WantedServiceAPI.reducer,
//   [WantedSoftwareAPI.reducerPath]: WantedSoftwareAPI.reducer,
//   [TaxonomyFormAPI.reducerPath]: TaxonomyFormAPI.reducer,
// })

// // const persistedReducer = persistReducer(persistConfig, reducer);

// // export const store = configureStore({
// //   reducer: persistedReducer,
// //   middleware: getDefaultMiddleware =>
// //     getDefaultMiddleware({
// //       serializableCheck: {
// //         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
// //       },
// //     }).concat(DeviceAPI.middleware,TaxonomyFormAPI.middleware),
// // });


// export const store = configureStore({
//   reducer: reducer,
//   middleware: getDefaultMiddleware =>
//         getDefaultMiddleware().concat(DeviceAPI.middleware,SpareAPI.middleware,ServiceAPI.middleware,WantedDeviceAPI.middleware,WantedSpareAPI.middleware,WantedServiceAPI.middleware,WantedSoftwareAPI.middleware,TaxonomyFormAPI.middleware),
// });

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from "redux-persist";
import storage from "redux-persist/lib/storage";
import addDeviceReducer from '../components/AddDeviceFormComponent/AddDeviceSlice';
import appDataAddDeviceReducer from '../components/AddDeviceFormComponent/AppDataDeviceSlice';
import editDeviceReducer from '../components/EditDeviceFormComponent/EditDeviceSlice'
import appDataEditDeviceReducer from '../components/EditDeviceFormComponent/AppDataEditDeviceSlice';
import addSpareReducer from '../components/AddSpareFormComponent/AddSpareSlice';
import appDataAddSpareReducer from '../components/AddSpareFormComponent/AppDataSpareSlice';
import editSpareReducer from '../components/EditSpareFormComponent/EditSpareSlice'
import appDataEditSpareReducer from '../components/EditSpareFormComponent/AppDataEditSpareSlice';
import addServiceReducer from '../components/AddServiceFormComponent/AddServiceSlice';
import appDataAddServiceReducer from '../components/AddServiceFormComponent/AppDataServiceSlice';
import editServiceReducer from '../components/EditServiceFormComponent/EditServiceSlice'
import appDataEditServiceReducer from '../components/EditServiceFormComponent/AppDataEditServiceSlice';
import addSoftwareReducer from '../components/AddSoftwareFormComponent/AddSoftwareSlice';
import appDataAddSoftwareReducer from '../components/AddSoftwareFormComponent/AppDataSoftwareSlice';
import editSoftwareReducer from '../components/EditSoftwareFormComponent/EditSoftwareSlice'
import appDataEditSoftwareReducer from '../components/EditSoftwareFormComponent/AppDataEditSoftwareSlice';
import addJobReducer from '../components/AddJobFormComponent/AddJobSlice';
import appDataAddJobReducer from '../components/AddJobFormComponent/AppDataJobSlice';
import editJobReducer from '../components/EditJobFormComponent/EditJobSlice'
import appDataEditJobReducer from '../components/EditJobFormComponent/AppDataEditJobSlice';


import addAddressReducer from '../components/AddAddressFormComponent/AddAddressSlice';
import editAddressReducer from '../components/EditAddressFormComponent/EditAddressSlice'


import addWantedDeviceReducer from '../components/AddWantedDeviceFormComponent/AddWantedDeviceSlice';
import editWantedDeviceReducer from '../components/EditWantedDeviceFormComponent/EditWantedDeviceSlice'
import addWantedSpareReducer from '../components/AddWantedSpareFormComponent/AddWantedSpareSlice';
import editWantedSpareReducer from '../components/EditWantedSpareFormComponent/EditWantedSpareSlice'
import addWantedServiceReducer from '../components/AddWantedServiceFormComponent/AddWantedServiceSlice';
import editWantedServiceReducer from '../components/EditWantedServiceFormComponent/EditWantedServiceSlice'
import addWantedSoftwareReducer from '../components/AddWantedSoftwareFormComponent/AddWantedSoftwareSlice';
import editWantedSoftwareReducer from '../components/EditWantedSoftwareFormComponent/EditWantedSoftwareSlice'



import { LoginRegisterAPI } from '../api/Login&RegisterAPI';
import { UsermetaAPI } from '../api/UsermetaAPI';
import { BusinessAPI } from '../api/BusinessAPI';
import { AddressAPI } from '../api/AddressAPI';
import { ContactAPI } from '../api/ContactAPI';
import { OrderAPI } from '../api/OrderAPI';
import { PlanAPI } from '../api/PlanAPI';


import { DeviceAPI } from '../api/DeviceAPI';
import { SpareAPI } from '../api/SpareAPI';
import { ServiceAPI } from '../api/ServiceAPI';
import { SoftwareAPI } from '../api/SoftwareAPI';
import { JobAPI } from '../api/JobAPI';

import { WantedDeviceAPI } from '../api/WantedDeviceAPI';
import { WantedSpareAPI } from '../api/WantedSpareAPI';
import { WantedServiceAPI } from '../api/WantedServiceAPI';
import { WantedSoftwareAPI } from '../api/WantedSoftwareAPI';

import { RadarDeviceAPI } from '../api/Radar/RadarDeviceAPI';
import { RadarSpareAPI } from '../api/Radar/RadarSpareAPI';
import { RadarServiceAPI } from '../api/Radar/RadarServiceAPI';
import { RadarSoftwareAPI } from '../api/Radar/RadarSoftwareAPI';
import { RadarBusinessAPI } from '../api/Radar/RadarBusinessAPI';
import { RadarJobAPI } from '../api/Radar/RadarJobAPI';

import { ViewDeviceAPI } from '../api/View/ViewDeviceAPI';
import { ViewSpareAPI } from '../api/View/ViewSpareAPI';
import { ViewServiceAPI } from '../api/View/ViewServiceAPI';
import { ViewSoftwareAPI } from '../api/View/ViewSoftwareAPI';
import { ViewBusinessAPI } from '../api/View/ViewBusinessAPI';
import { ViewJobAPI } from '../api/View/ViewJobAPI';

import { FunnelDeviceAPI } from '../api/Funnel/FunnelDeviceAPI';
import { FunnelSpareAPI } from '../api/Funnel/FunnelSpareAPI';
import { FunnelServiceAPI } from '../api/Funnel/FunnelServiceAPI';
import { FunnelSoftwareAPI } from '../api/Funnel/FunnelSoftwareAPI';
import { FunnelJobAPI } from '../api/Funnel/FunnelJobAPI';


import { FunnelWantedDeviceAPI } from '../api/Funnel/FunnelWantedDeviceAPI';
import { FunnelWantedSpareAPI } from '../api/Funnel/FunnelWantedSpareAPI';
import { FunnelWantedServiceAPI } from '../api/Funnel/FunnelWantedServiceAPI';
import { FunnelWantedSoftwareAPI } from '../api/Funnel/FunnelWantedSoftwareAPI';

import { TaxonomyFormAPI } from '../api/TaxonomyFormAPI';

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
//   blacklist:['devices']
// }

const reducer = combineReducers({
  addDevice: addDeviceReducer,
  appDataAddDevice: appDataAddDeviceReducer,
  editDevice: editDeviceReducer,
  appDataEditDevice: appDataEditDeviceReducer,
  addSpare: addSpareReducer,
  appDataAddSpare: appDataAddSpareReducer,
  editSpare: editSpareReducer,
  appDataEditSpare: appDataEditSpareReducer,
  addService: addServiceReducer,
  appDataAddService: appDataAddServiceReducer,
  editService: editServiceReducer,
  appDataEditService: appDataEditServiceReducer,
  addSoftware: addSoftwareReducer,
  appDataAddSoftware: appDataAddSoftwareReducer,
  editSoftware: editSoftwareReducer,
  appDataEditSoftware: appDataEditSoftwareReducer,
  addJob: addJobReducer,
  appDataAddJob: appDataAddJobReducer,
  editJob: editJobReducer,
  appDataEditJob: appDataEditJobReducer,

  addAddress: addAddressReducer,
  editAddress: editAddressReducer,

  addWantedDevice: addWantedDeviceReducer,
  editWantedDevice: editWantedDeviceReducer,
  addWantedSpare: addWantedSpareReducer,
  editWantedSpare: editWantedSpareReducer,
  addWantedService: addWantedServiceReducer,
  editWantedService: editWantedServiceReducer,
  addWantedSoftware: addWantedSoftwareReducer,
  editWantedSoftware: editWantedSoftwareReducer,

  [LoginRegisterAPI.reducerPath]: LoginRegisterAPI.reducer,
  [UsermetaAPI.reducerPath]: UsermetaAPI.reducer,
  [BusinessAPI.reducerPath]: BusinessAPI.reducer,
  [AddressAPI.reducerPath]: AddressAPI.reducer,
  [SpareAPI.reducerPath]: SpareAPI.reducer,
  [DeviceAPI.reducerPath]: DeviceAPI.reducer,
  [ServiceAPI.reducerPath]: ServiceAPI.reducer,
  [SoftwareAPI.reducerPath]: SoftwareAPI.reducer,
  [JobAPI.reducerPath]: JobAPI.reducer,

  [ContactAPI.reducerPath]: ContactAPI.reducer,
  [OrderAPI.reducerPath]: OrderAPI.reducer,
  [PlanAPI.reducerPath]: PlanAPI.reducer,

  [WantedDeviceAPI.reducerPath]: WantedDeviceAPI.reducer,
  [WantedSpareAPI.reducerPath]: WantedSpareAPI.reducer,
  [WantedServiceAPI.reducerPath]: WantedServiceAPI.reducer,
  [WantedSoftwareAPI.reducerPath]: WantedSoftwareAPI.reducer,

  [RadarDeviceAPI.reducerPath]: RadarDeviceAPI.reducer,
  [RadarSpareAPI.reducerPath]: RadarSpareAPI.reducer,
  [RadarServiceAPI.reducerPath]: RadarServiceAPI.reducer,
  [RadarSoftwareAPI.reducerPath]: RadarSoftwareAPI.reducer,
  [RadarJobAPI.reducerPath]: RadarJobAPI.reducer,
  [RadarBusinessAPI.reducerPath]: RadarBusinessAPI.reducer,

  [ViewDeviceAPI.reducerPath]: ViewDeviceAPI.reducer,
  [ViewSpareAPI.reducerPath]: ViewSpareAPI.reducer,
  [ViewServiceAPI.reducerPath]: ViewServiceAPI.reducer,
  [ViewSoftwareAPI.reducerPath]: ViewSoftwareAPI.reducer,
  [ViewJobAPI.reducerPath]: ViewJobAPI.reducer,
  [ViewBusinessAPI.reducerPath]: ViewBusinessAPI.reducer,

  [FunnelDeviceAPI.reducerPath]: FunnelDeviceAPI.reducer,
  [FunnelSpareAPI.reducerPath]: FunnelSpareAPI.reducer,
  [FunnelServiceAPI.reducerPath]: FunnelServiceAPI.reducer,
  [FunnelSoftwareAPI.reducerPath]: FunnelSoftwareAPI.reducer,
  [FunnelJobAPI.reducerPath]: FunnelJobAPI.reducer,

  [FunnelWantedDeviceAPI.reducerPath]: FunnelWantedDeviceAPI.reducer,
  [FunnelWantedSpareAPI.reducerPath]: FunnelWantedSpareAPI.reducer,
  [FunnelWantedServiceAPI.reducerPath]: FunnelWantedServiceAPI.reducer,
  [FunnelWantedSoftwareAPI.reducerPath]: FunnelWantedSoftwareAPI.reducer,

  [TaxonomyFormAPI.reducerPath]: TaxonomyFormAPI.reducer,
})

// const persistedReducer = persistReducer(persistConfig, reducer);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }).concat(DeviceAPI.middleware,TaxonomyFormAPI.middleware),
// });


export const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(DeviceAPI.middleware, SpareAPI.middleware, ServiceAPI.middleware, SoftwareAPI.middleware, JobAPI.middleware, WantedDeviceAPI.middleware, WantedSpareAPI.middleware, WantedServiceAPI.middleware, WantedSoftwareAPI.middleware, TaxonomyFormAPI.middleware, LoginRegisterAPI.middleware, UsermetaAPI.middleware, BusinessAPI.middleware, AddressAPI.middleware, RadarDeviceAPI.middleware, RadarSpareAPI.middleware, RadarServiceAPI.middleware, RadarSoftwareAPI.middleware, RadarBusinessAPI.middleware, RadarJobAPI.middleware, ContactAPI.middleware, OrderAPI.middleware, PlanAPI.middleware, ViewDeviceAPI.middleware, ViewSpareAPI.middleware, ViewServiceAPI.middleware, ViewSoftwareAPI.middleware, ViewBusinessAPI.middleware, ViewJobAPI.middleware, FunnelWantedDeviceAPI.middleware, FunnelWantedSpareAPI.middleware, FunnelWantedServiceAPI.middleware, FunnelWantedSoftwareAPI.middleware, FunnelDeviceAPI.middleware, FunnelSpareAPI.middleware, FunnelServiceAPI.middleware, FunnelSoftwareAPI.middleware, FunnelJobAPI.middleware),
});