import React from 'react';
import "./InvitedTeamMemberTableComponent.css";
import divider3 from '../../icons/Gerator/sales_iq/divider3.svg';
import arrowdown from '../../icons/Gerator/dashboard/arrowdown.svg';
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';



export const InvitedTeamMemberTableComponent = () => {
  return (
    <>

               
    <div className="team-member-table-display-form-content-wrapper-Invited">
                    <div className="team-member-table-display-form-content-Invited">
                    <div className="team-member-table-display-toggle-header-parent-Invited">
                        <div className="team-member-table-display-toggle-header-Invited">
                        <div className="team-member-table-display-toggle-title-Invited">Pending</div>
                        {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
                        </div>

                    </div>
                    <div className="team-member-table-display-divider-container-Invited">
                        <img
                        className="team-member-table-display-divider-icon3-Invited"
                        alt=""
                        src={divider3}
                        />
                    </div>

                    <form className="team-member-table-table-Invited">

                        <div className="team-member-table-table-content-Invited">

                        <div className="team-member-table-table-row-Invited">

                            {/* Invoice */}



                            <div className="team-member-table-table-header-cell1-Invited">
                            {/* <input className="team-member-table-checkbox-wrapper" type="checkbox" /> */}
                            <div className="team-member-table-table-header-Invited">
                                <a className="team-member-table-column-title-Invited">Name</a>
                                {/* <img
                                className="team-member-table-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                /> */}
                            </div>
                            </div>
                                

                            {/* Location */}

                            <div className="team-member-table-table-header-cell2-Invited">
                            <div className="team-member-table-table-header-Invited">
                                <a className="team-member-table-column-title-Invited">Email</a>

                            </div>
                            </div>


                            {/* Role  */}

                            <div className="team-member-table-table-header-cell3-Invited">
                            <div className="team-member-table-table-header-Invited">
                                <a className="team-member-table-column-title-Invited">Status</a>


                            </div>
                            </div>

                            {/* Last seen  */}

                            <div className="team-member-table-table-header-cell3-Invited">
                            <div className="team-member-table-table-header-Invited">
                                <a className="team-member-table-column-title-Invited">Date & Time</a>


                            </div>
                            </div>

                            {/* Price  */}

                            {/* <div className="team-member-table-table-header-cell4">
                            <div className="team-member-table-table-header">
                                <a className="team-member-table-column-title">Price</a>


                            </div>
                            </div> */}

                            {/* Action  */}

                            <div className="team-member-table-table-header-cell4-Invited">
                            <div className="team-member-table-table-header2-Invited">
                                <div className="team-member-table-action-column-title-Invited">Action</div>
                                <img
                                className="team-member-table-arrow-down-icon2-Invited"
                                alt=""
                                src={arrowdown}
                                />
                            </div>
                            </div>

                        </div>

                       

                        </div>

                        <div className='team-member-table-table-data-custom-main-container'>

                        <div className="team-member-table-table-header-cell-parent" >

                              <div className="team-member-table-table-cell10-Invited">

                                {/* <div className="team-member-table-cell-content-Invited">
                                  <input className="team-member-table-checkbox-wrapper" type="checkbox" />
                                </div> */}

                                <div className="team-member-table-avatar-Invited">
                                    {/* <div className="team-member-table-contrast-border">
                                        <div className="team-member-table-avatar-online-indicator"></div>
                                    </div> */}
                                    </div>

                                <div className="team-member-table-dropdown1-Invited">
                                  <div className="team-member-table-cell-title-Invited">Olivia Rhye</div>
                                  <div className="team-member-table-supporting-text4-Invited">Intern</div>

                                </div>
                              </div>

                              <div className="team-member-table-table-cell20-Invited">
                                <div className="team-member-table-location-content">


                                  <div className="team-member-table-text36">oliviarhye@medico.commmmmmmm</div>
                                </div>
                              </div>

                              <div className="team-member-table-table-cell30-Invited">
                                <div className="team-member-table-location-content">
                                <div className="team-member-table-text36">Access Sought</div>

                                
                                </div>
                              </div>

                              <div className="team-member-table-table-cell30-Invited">
                                <div className="team-member-table-location-content">


                                  <div className="team-member-table-text36">5-Nov-2024, 22:00</div>
                                </div>
                              </div>



                              <div className="team-member-table-table-cell40-Invited">
                              <div className="team-member-table-location-content">

                              <img
                                    className="team-member-table-dots-vertical-icon"
                                    alt=""
                                    src={dotsvertical}
                                  />
                              {/* <div className="team-member-table-text36">
                                 
                                </div> */}
                                </div>
                                
                              </div>

                        </div>
                        </div>

                         {/* Load More  */}

                         <div className="team-member-table-frame-wrapper44">
                                        <div className="team-member-table-frame-parent72">
                                          <div className="team-member-table-buttonsbutton-wrapper1">

                                            <button className='team-member-table-buttonsbutton-wrapper1-child'
                                            >
                                              <div className="team-member-table-buttonsbutton28">
                                                <img
                                                  className="team-member-table-arrow-narrow-left-icon"
                                                  alt=""
                                                  src={arrowdown1}
                                                />
                                                <div className="team-member-table-search-input">
                                                  <div className="team-member-table-text146">Load More</div>
                                                </div>
                                              </div>
                                            </button>

                                          </div>
                                        </div>
                            </div>



                    </form>


                    </div>
                </div >
    </>
  )
}

