import React from 'react';
import "./InactiveTeamMemberTableComponent.css";
import divider3 from '../../icons/Gerator/sales_iq/divider3.svg';
import arrowdown from '../../icons/Gerator/dashboard/arrowdown.svg';
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';



export const InactiveTeamMemberTableComponent = () => {
  return (
    <>

               
    <div className="team-member-table-display-form-content-wrapper-Inactive">
                    <div className="team-member-table-display-form-content-Inactive">
                    <div className="team-member-table-display-toggle-header-parent-Inactive">
                        <div className="team-member-table-display-toggle-header-Inactive">
                        <div className="team-member-table-display-toggle-title-Inactive">Inactive</div>
                        {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
                        </div>

                    </div>
                    <div className="team-member-table-display-divider-container-Inactive">
                        <img
                        className="team-member-table-display-divider-icon3-Inactive"
                        alt=""
                        src={divider3}
                        />
                    </div>

                    <form className="team-member-table-table-Inactive">

                        <div className="team-member-table-table-content-Inactive">

                        <div className="team-member-table-table-row-Inactive">

                            {/* Invoice */}



                            <div className="team-member-table-table-header-cell1-Inactive">
                            {/* <input className="team-member-table-checkbox-wrapper" type="checkbox" /> */}
                            <div className="team-member-table-table-header-Inactive">
                                <a className="team-member-table-column-title-Inactive">Name</a>
                                {/* <img
                                className="team-member-table-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                /> */}
                            </div>
                            </div>
                                

                            {/* Location */}

                            <div className="team-member-table-table-header-cell2-Inactive">
                            <div className="team-member-table-table-header-Inactive">
                                <a className="team-member-table-column-title-Inactive">Email</a>

                            </div>
                            </div>


                            {/* Role  */}

                            {/* <div className="team-member-table-table-header-cell3-Inactive">
                            <div className="team-member-table-table-header-Inactive">
                                <a className="team-member-table-column-title-Inactive">Status</a>


                            </div>
                            </div> */}

                            {/* Last seen  */}
{/* 
                            <div className="team-member-table-table-header-cell3-Inactive">
                            <div className="team-member-table-table-header-Inactive">
                                <a className="team-member-table-column-title-Inactive">Date & Time</a>


                            </div>
                            </div> */}

                            {/* Price  */}

                            {/* <div className="team-member-table-table-header-cell4">
                            <div className="team-member-table-table-header">
                                <a className="team-member-table-column-title">Price</a>


                            </div>
                            </div> */}

                            {/* Action  */}

                            <div className="team-member-table-table-header-cell4-Inactive">
                            <div className="team-member-table-table-header2-Inactive">
                                <div className="team-member-table-action-column-title-Inactive">Action</div>
                                <img
                                className="team-member-table-arrow-down-icon2-Inactive"
                                alt=""
                                src={arrowdown}
                                />
                            </div>
                            </div>

                        </div>

                       

                        </div>

                        <div className='team-member-table-table-data-custom-main-container'>

                        <div className="team-member-table-table-header-cell-parent" >

                              <div className="team-member-table-table-cell10-Inactive">

                                {/* <div className="team-member-table-cell-content-Inactive">
                                  <input className="team-member-table-checkbox-wrapper" type="checkbox" />
                                </div> */}

                                <div className="team-member-table-avatar-Inactive">
                                    {/* <div className="team-member-table-contrast-border">
                                        <div className="team-member-table-avatar-online-indicator"></div>
                                    </div> */}
                                    </div>

                                <div className="team-member-table-dropdown1-Inactive">
                                  <div className="team-member-table-cell-title-Inactive">Olivia Rhye</div>
                                  <div className="team-member-table-supporting-text4-Inactive">Intern</div>

                                </div>
                              </div>

                              <div className="team-member-table-table-cell20-Inactive">
                                <div className="team-member-table-location-content">


                                  <div className="team-member-table-text36">oliviyarhye@medico.com</div>
                                </div>
                              </div>

                              {/* <div className="team-member-table-table-cell30-Inactive">
                                <div className="team-member-table-location-content">
                                <div className="team-member-table-text36">Access Sort</div>
                                </div>
                              </div>

                              <div className="team-member-table-table-cell30-Inactive">
                                <div className="team-member-table-location-content">


                                  <div className="team-member-table-text36">5-Nov-2024, 22:00</div>
                                </div>
                              </div> */}



                              <div className="team-member-table-table-cell40-Inactive">
                              <div className="team-member-table-location-content">

                              <img
                                    className="team-member-table-dots-vertical-icon"
                                    alt=""
                                    src={dotsvertical}
                                  />
                              {/* <div className="team-member-table-text36">
                                 
                                </div> */}
                                </div>
                                
                              </div>

                        </div>
                        </div>

                         {/* Load More  */}

                         <div className="team-member-table-frame-wrapper44">
                                        <div className="team-member-table-frame-parent72">
                                          <div className="team-member-table-buttonsbutton-wrapper1">

                                            <button className='team-member-table-buttonsbutton-wrapper1-child'
                                            >
                                              <div className="team-member-table-buttonsbutton28">
                                                <img
                                                  className="team-member-table-arrow-narrow-left-icon"
                                                  alt=""
                                                  src={arrowdown1}
                                                />
                                                <div className="team-member-table-search-input">
                                                  <div className="team-member-table-text146">Load More</div>
                                                </div>
                                              </div>
                                            </button>

                                          </div>
                                        </div>
                            </div>



                    </form>


                    </div>
                </div >
    </>
  )
}

