import React from 'react'
import "./SkeletonTableListingLoaderComponent.css"
const SkeletonTableListingLoaderComponent = () => {
    return (
        <div className="skeleton-table-loader">
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
            <div className="skeleton-table-item"></div>
        </div>
    )
}

export default SkeletonTableListingLoaderComponent