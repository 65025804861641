import React, { useEffect, useRef, useState } from 'react';
import "./RadarDeviceComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar_1_1_2x from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';

import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/DeviceHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useDeleteRadarDeviceMutation, useGetUserDashboardRadarDevicesQuery } from '../../../api/Radar/RadarDeviceAPI';



export const RadarDeviceComponent = () => {


  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCountRadarDevice, settotalCountRadarDevice] = useState("");
  const [totalCountRadarSpare, settotalCountRadarSpare] = useState("");
  const [totalCountRadarService, settotalCountRadarService] = useState("");
  const [totalCountRadarSoftware, settotalCountRadarSoftware] = useState("");
  const [totalCountRadarJob, settotalCountRadarJob] = useState("");
  const [totalCountRadarBusiness, settotalCountRadarBusiness] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardRadarDevicesQuery(
    { offset, sortBy, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  // Query to delete from RadarDevice
  const [deleteRadarDevice] = useDeleteRadarDeviceMutation();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  // Method reponsible to hit rtk delete radar query
  const handleRemoveRadar = async (deviceId) => {
    await deleteRadarDevice(deviceId);
    window.location.reload();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    // if (activePostStatus === filterLabel) {
    //   setSortBy('date_desc')
    //   setSelectedSortOption('')
    //   setActivePostStatusFilter(null);
    // } else {
    //   setSortBy('date_desc')
    //   setSelectedSortOption('')
    //   setActivePostStatusFilter(filterLabel);
    // }
    // setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.radar_device.length < data.totalCountRadarDevice &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      settotalCountRadarDevice(data.totalCountRadarDevice);
      settotalCountRadarSpare(data.totalCountRadarSpare);
      settotalCountRadarSoftware(data.totalCountRadarSoftware);
      settotalCountRadarService(data.totalCountRadarService);
      settotalCountRadarJob(data.totalCountRadarJob);
      settotalCountRadarBusiness(data.totalCountRadarBusiness);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-device"} pathName={"Device"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.radar_device.length > 0) {
      // dataLength = data.radar_device.length;
      content = data.radar_device.map((device, index) =>
      (
        <div className="radar-device-component-radar-content-items" key={index}>
          <div className="radar-device-component-radar-divider-container1">
            <div className="radar-device-component-radar-rectangle-parent5">
              <img
                className="radar-device-component-radar-frame-child7"
                alt=""
                src={device.featureImage}
              />

              <div className="radar-device-component-radar-frame-parent1">
                <div className="radar-device-component-radar-action-fields">
                  <div className="radar-device-component-radar-input-elements">
                    <Link to={`/device/${device.id}/${device.title}`} className="radar-device-component-radar-text78">
                      {device.title}
                    </Link>
                    <div className="radar-device-component-radar-frame-parent21">
                      <div className="radar-device-component-radar-badge-item-parent">
                        <div className="radar-device-component-radar-badge-parent">

                          <PostStatusLIstingComponent postStatus={device.tax_post_status.name} postSlug={device.tax_post_status.slug} postId={device.id} />

                        </div>
                        <div className="radar-device-component-radar-frame-parent4">
                          <div className="radar-device-component-radar-end-label">
                            <div className="radar-device-component-radar-search-icon-input">4.9/5</div>
                          </div>
                          <div className="radar-device-component-radar-type-support">
                            <div className="radar-device-component-radar-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                        <div className="radar-device-component-radar-badge33">
                          <div className="radar-device-component-radar-card-badge-number">
                            {device.tax_device_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="radar-device-component-radar-frame-parent24">
                        <div className="radar-device-component-radar-location-pin-parent">
                          <div className="radar-device-component-radar-search-input">
                            <img
                              className="radar-device-component-radar-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="radar-device-component-radar-location3">{device.device_address.addressTownCity}, {device.device_address.tax_country.name}</div>
                        </div>
                        <div className="radar-device-component-radar-frame-wrapper18">
                          <div className="radar-device-component-radar-badge-elements-copy">
                            <div className="radar-device-component-radar-badge36">
                              <div className="radar-device-component-radar-badge-number-element">
                                11 Health
                              </div>
                            </div>
                            <div className="radar-device-component-radar-badge37">
                              <div className="radar-device-component-radar-second-badge-count">{device.tax_oem.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="radar-device-component-radar-frame-parent26">
                        <div className="radar-device-component-radar-frame-wrapper19">
                          <div className="radar-device-component-radar-legend-series">
                            <div className="radar-device-component-radar-search-input">
                              <img
                                className="radar-device-component-radar-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="radar-device-component-radar-type-of-work2">{getCustomizedDate(device.createdAt)}</div>
                          </div>
                        </div>
                        <div className="radar-device-component-radar-badge38">
                          <div className="radar-device-component-radar-third-badge-number">
                            {device.tax_clinical_application.name}
                          </div>
                        </div>
                      </div>
                      <div className="radar-device-component-radar-badge39">
                        <div className="radar-device-component-radar-location-badge-elements">
                          Accounting
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="radar-device-component-radar-filter-elements">
                  <div className="radar-device-component-radar-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <img
                      className="radar-device-component-radar-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotId === index && <ThreeDotsListingComponent url={"#"} />}
                  </div>
                  <div className="radar-device-component-radar-filter-elements-inner">
                    <div className="radar-device-component-radar-price-parent">
                      <TransactionTypeComponent transactionType={device.tax_transaction_type.name} slug={device.tax_transaction_type.slug} />
                      <div className="radar-device-component-radar-price2">${device.price}</div>
                      <div className="radar-device-component-radar-dropdown-elements">
                        <div className="radar-device-component-radar-dropdown-options-parent">
                          <div className="radar-device-component-radar-text83">{device.tax_unit_measure_device_spare.name}</div>
                          <div className="radar-device-component-radar-frame-wrapper3">
                            <div className="radar-device-component-radar-radar-1-1-container">
                              <img
                                className="radar-device-component-radar-magnifier-1-icon"
                                alt=""
                                src={target_04}
                                // onClick={handleRadar}
                                onClick={() => handleRemoveRadar(device.id)}
                                style={{ cursor: "pointer" }}
                              />

                              <img
                                className="radar-device-component-radar-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="radar-device-component-radar-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="radar-device-component-radar-divider-icon8" alt="" src={divider2} />

            <div className="radar-device-component-radar-frame-parent71">
              <div className="radar-device-component-radar-buttonsbutton-parent1">
                <div className="radar-legend-series">
                  <img
                    className="radar-location-column-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="radar-location-column-inner">
                    <div className="radar-date-picker-dropdown">
                      <div className="radar-name">{device.device_business.business_user.displayName}</div>
                      <Link className="radar-role" to={`/business/${device.device_business.id}/${device.device_business.businessName}`}>
                        {device.device_business.businessName}
                      </Link>
                      {/* <div className="radar-role">{device.device_business.businessName}</div> */}
                    </div>
                  </div>
                </div>
                <div className='radar-device-component-main-buttons-group'>
                  <div className="radar-device-component-radar-buttonsbutton13">
                    <img
                      className="radar-device-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="radar-device-component-radar-search-input">
                      <div className="radar-device-component-radar-text67">View</div>
                    </div>
                  </div>
                  <div className="radar-device-component-radar-buttonsbutton25">
                    <div className="radar-device-component-radar-icon-wrapper">
                      <img className="radar-device-component-radar-icon1" alt="" src={icon1} />
                    </div>
                    <div className="radar-device-component-radar-search-input">
                      <div className="radar-device-component-radar-text68">Sales IQ</div>
                    </div>
                    <img
                      className="radar-device-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="radar-device-component-radar-buttonsbutton26">
                    <div className="radar-device-component-radar-icon-wrapper">
                      <img className="radar-device-component-radar-icon1" alt="" src={icon1} />
                    </div>
                    <div className="radar-device-component-radar-search-input">
                      <div className="radar-device-component-radar-text68">Sales IQ</div>
                    </div>
                    <img
                      className="radar-device-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                  <div className="radar-device-component-radar-buttonsbutton27">
                    <img
                      className="radar-device-component-radar-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="radar-device-component-radar-text145">Messages</div>
                    <div className="radar-device-component-radar-ellipse-parent">
                      <div className="radar-device-component-radar-ellipse-div"></div>
                      <div className="radar-device-component-radar-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="radar-device-component-radar-arrow-square-up-right-wrapper1">
                <img
                  className="radar-device-component-radar-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path={"/add-device"} pathName={"Device"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="radar-frame-wrapper">
        <div className="radar-frame-group">
          <div className="radar-rectangle-group">
            <div className="radar-frame-item"></div>
            <div className="radar-metric-card-row">
              <div className="radar-metric-card">
                <div className="radar-metric-card-title">
                  <div className="radar-heading4">Devices</div>
                </div>
                <div className="radar-dropdown3">
                  <img
                    className="radar-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                </div>
              </div>
              <div className="radar-number3">{totalCountRadarDevice || 0}</div>
            </div>
            {/* <div className="radar-badge11">
              <div className="radar-options-icon">
                <img
                  className="radar-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="radar-text58">20 slots available</div>
            </div> */}
          </div>
          <div className="radar-rectangle-container">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-parent">
                <div className="radar-heading5">Spares</div>
                <div className="radar-number4">{totalCountRadarSpare || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown3">
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <div className="radar-rectangle-container">
            <div className="radar-frame-inner"></div>
            <div className="radar-options-icon">
              <div className="radar-heading-group">
                <div className="radar-heading5">Services</div>
                <div className="radar-number4">{totalCountRadarService || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown3">
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <div className="radar-rectangle-container">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-container">
                <div className="radar-heading5">Software</div>
                <div className="radar-number4">{totalCountRadarSoftware || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown3">
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <div className="radar-rectangle-parent2">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-parent1">
                <div className="radar-heading5">Jobs</div>
                <div className="radar-number4">{totalCountRadarJob || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown7">
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>

          <div className="radar-rectangle-parent2">
            <div className="radar-frame-inner"></div>
            <div className="radar-frame-container">
              <div className="radar-heading-parent1">
                <div className="radar-heading5">Businesses</div>
                <div className="radar-number4">{totalCountRadarBusiness || 0}</div>
                {/* <div className="radar-badge12">
                  <div className="radar-options-icon">
                    <img
                      className="radar-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="radar-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="radar-dropdown7">
              <img
                className="radar-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="radar-section-wrapper">
        <div className="radar-section1">
          <div className="radar-container1">
            <div className="radar-filters-bar">
              <div className="radar-content17">
                <div className="radar-dropdowns">

                  <RadarFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="radar-input-dropdown2">
                  {/* <div className="radar-input-with-label2">
                    <div className="radar-label">Search</div>
                    <div className="radar-input2">
                      <div className="radar-content3">
                        <img
                          className="radar-search-lg-icon radar-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="radar-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="radar-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="radar-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="radar-text-padding6" ref={dropdownRef}>
                      <div className="radar-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="radar-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Listing Container */}
      <div className="radar-device-component-listing-container">

        {/* Listing layout start    */}
        {content}

        {/* <div className="radar-device-component-radar-content-items">
          <div className="radar-device-component-radar-divider-container1">
            <div className="radar-device-component-radar-divider-container-child"></div>
            <div className="radar-device-component-radar-rectangle-parent5">
              <img
                className="radar-device-component-radar-frame-child7"
                alt=""
                src={content_elements_2x}
              />

              <div className="radar-device-component-radar-frame-parent1">
                <div className="radar-device-component-radar-action-fields">
                  <div className="radar-device-component-radar-input-elements">
                    <Link to={"#"} className="radar-device-component-radar-text78">
                      New / Surplus 2021 Philips Healthcare HD11 XE
                      (P/N:23A10) for abalationment
                    </Link>
                    <div className="radar-device-component-radar-frame-parent21">
                      <div className="radar-device-component-radar-badge-item-parent">
                        <div className="radar-device-component-radar-badge-parent">

                          <PostStatusLIstingComponent postStatus={'Job'} postSlug={"expired"} postId={'1'} />

                        </div>
                        <div className="radar-device-component-radar-frame-parent4">
                          <div className="radar-device-component-radar-end-label">
                            <div className="radar-device-component-radar-search-icon-input">4.9/5</div>
                          </div>
                          <div className="radar-device-component-radar-type-support">
                            <div className="radar-device-component-radar-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                        <div className="radar-device-component-radar-badge33">
                          <div className="radar-device-component-radar-card-badge-number">
                            3D Image Workstations
                          </div>
                        </div>
                      </div>
                      <div className="radar-device-component-radar-frame-parent24">
                        <div className="radar-device-component-radar-location-pin-parent">
                          <div className="radar-device-component-radar-search-input">
                            <img
                              className="radar-device-component-radar-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="radar-device-component-radar-location3">India, New Delhi</div>
                        </div>
                        <div className="radar-device-component-radar-frame-wrapper18">
                          <div className="radar-device-component-radar-badge-elements-copy">
                            <div className="radar-device-component-radar-badge36">
                              <div className="radar-device-component-radar-badge-number-element">
                                11 Health
                              </div>
                            </div>
                            <div className="radar-device-component-radar-badge37">
                              <div className="radar-device-component-radar-second-badge-count">Anatomy</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="radar-device-component-radar-frame-parent26">
                        <div className="radar-device-component-radar-frame-wrapper19">
                          <div className="radar-device-component-radar-legend-series">
                            <div className="radar-device-component-radar-search-input">
                              <img
                                className="radar-device-component-radar-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="radar-device-component-radar-type-of-work2">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="radar-device-component-radar-badge38">
                          <div className="radar-device-component-radar-third-badge-number">
                            Patient / Rehabititation
                          </div>
                        </div>
                      </div>
                      <div className="radar-device-component-radar-badge39">
                        <div className="radar-device-component-radar-location-badge-elements">
                          Accounting
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="radar-device-component-radar-filter-elements">
                  <div className="radar-device-component-radar-dropdown10" onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                    <img
                      className="radar-device-component-radar-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {isThreeDotsVisible && <ThreeDotsListingComponent url={'device/1'} />}
                  </div>
                  <div className="radar-device-component-radar-filter-elements-inner">
                    <div className="radar-device-component-radar-price-parent">
                      <TransactionTypeComponent transactionType={"Rent"} slug={"rent-lease"} />
                      <div className="radar-device-component-radar-price2">$9,999,999.99</div>
                      <div className="radar-device-component-radar-dropdown-elements">
                        <div className="radar-device-component-radar-dropdown-options-parent">
                          <div className="radar-device-component-radar-text83">USD - Per Unit</div>
                          <div className="radar-device-component-radar-frame-wrapper3">
                            <div className="radar-device-component-radar-radar-1-1-container">
                              <img
                                className="radar-device-component-radar-magnifier-1-icon"
                                alt=""
                                src={imageSrc}
                                onClick={handleRemoveRadar}
                                style={{ cursor: "pointer" }}
                              />

                              <img
                                className="radar-device-component-radar-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="radar-device-component-radar-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="radar-device-component-radar-divider-icon8" alt="" src={divider2} />

            <div className="radar-device-component-radar-frame-parent71">
              <div className="radar-device-component-radar-buttonsbutton-parent1">
                <div className="radar-legend-series">
                  <img
                    className="radar-location-column-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="radar-location-column-inner">
                    <div className="radar-date-picker-dropdown">
                      <div className="radar-name">Olivia Rhye</div>
                      <div className="radar-role">Business Na...</div>
                    </div>
                  </div>
                </div>
                <div className='radar-device-component-main-buttons-group'>
                  <div className="radar-device-component-radar-buttonsbutton13">
                    <img
                      className="radar-device-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="radar-device-component-radar-search-input">
                      <div className="radar-device-component-radar-text67">View</div>
                    </div>
                  </div>
                  <div className="radar-device-component-radar-buttonsbutton25">
                    <div className="radar-device-component-radar-icon-wrapper">
                      <img className="radar-device-component-radar-icon1" alt="" src={icon1} />
                    </div>
                    <div className="radar-device-component-radar-search-input">
                      <div className="radar-device-component-radar-text68">Sales IQ</div>
                    </div>
                    <img
                      className="radar-device-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="radar-device-component-radar-buttonsbutton26">
                    <div className="radar-device-component-radar-icon-wrapper">
                      <img className="radar-device-component-radar-icon1" alt="" src={icon1} />
                    </div>
                    <div className="radar-device-component-radar-search-input">
                      <div className="radar-device-component-radar-text68">Sales IQ</div>
                    </div>
                    <img
                      className="radar-device-component-radar-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                  <div className="radar-device-component-radar-buttonsbutton27">
                    <img
                      className="radar-device-component-radar-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="radar-device-component-radar-text145">Messages</div>
                    <div className="radar-device-component-radar-ellipse-parent">
                      <div className="radar-device-component-radar-ellipse-div"></div>
                      <div className="radar-device-component-radar-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="radar-device-component-radar-arrow-square-up-right-wrapper1">
                <img
                  className="radar-device-component-radar-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div> */}

      </div>

      {/* Load more button  */}
      <div className="radar-frame-wrapper44">
        <div className="radar-frame-parent72">
          <div className="radar-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='radar-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="radar-buttonsbutton28">
                  <img
                    className="radar-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="radar-search-input">
                    <div className="radar-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

