import React, { useEffect, useState } from "react";
import "./DetailedWantedSpare.css"
import { useLazyGetWantedSparesQuery, useDeleteWantedSpareMutation } from "../../../api/WantedSpareAPI";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';
import divider5 from '../../../icons/Gerator/detailed_listing/divider5.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"


import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';


import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetWantedSpareQuery, useLazyGetWantedSparesQuery } from "../../../api/WantedSpareAPI";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/SpareHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useAddContactMutation, useDeleteContactMutation } from "../../../api/ContactAPI";
import { useAddFunnelWantedSpareMutation, useDeleteFunnelWantedSpareMutation } from "../../../api/Funnel/FunnelWantedSpareAPI";
import { ErrorShowComponent } from "../../../components/ErrorShowComponent/ErrorShowComponent";

export const DetailedWantedSpare = () => {

  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");

  const [triggerGetWantedSpare, { data: wantedSpareData,
    isLoading: isWantedSpareLoading,
    isSuccess: isWantedSpareSuccess,
    isError: isWantedSpareError,
    error: wantedSpareError }] = useLazyFindGetWantedSpareQuery(id);

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  // Funnel query delete
  const [deleteFromFunnel] = useDeleteFunnelWantedSpareMutation();

  // Funnel query delete
  const [addToFunnel] = useAddFunnelWantedSpareMutation();

  // Add/Remove Contact Query
  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetWantedSpare(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  //Funnel Code
  const handleRemoveFunnel = async (wantedSpareId) => {
    await deleteFromFunnel(wantedSpareId);
    window.location.reload();
  };

  const handleAddFunnel = async (wantedSpareId) => {
    await addToFunnel(wantedSpareId);
    window.location.reload();
  };

  //Contact Code
  const handleRemoveContact = async (wantedSpareId) => {
    await deleteFromContact(wantedSpareId);
    window.location.reload();
  };

  const handleAddContact = async (wantedSpareId) => {
    await addToContact(wantedSpareId);
    window.location.reload();
  };


  if (isWantedSpareError) {
    content = <ErrorShowComponent message1={"Internal server error"} />
  }

  if (isWantedSpareSuccess && profileImageLink) {
    return (
      <>
        <div className="detailed-wanted-spare-listings-detailed-page-devic">
          <div className="detailed-wanted-spare-rectangle-parent">
            <div className="detailed-wanted-spare-frame-child"></div>
            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            <img className="detailed-wanted-spare-divider-icon" alt="" src={divider} />

            <div className="detailed-wanted-spare-breadcrumb-button-base">
              <div className="detailed-wanted-spare-text6">...</div>
            </div>
            <img
              className="detailed-wanted-spare-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-spare-breadcrumb-button-base1">
              <div className="detailed-wanted-spare-text7">Another link</div>
            </div>
            <img
              className="detailed-wanted-spare-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-spare-frame-parent">
              <div className="detailed-wanted-spare-breadcrumb-container-parent">
                <div className="detailed-wanted-spare-breadcrumb">
                  <div className="detailed-wanted-spare-breadcrumb-items">
                    <div className="detailed-wanted-spare-breadcrumb-button-base2">
                      <div className="detailed-wanted-spare-text9">Dashboard</div>
                    </div>
                    <div className="detailed-wanted-spare-chevron-container">
                      <img
                        className="detailed-wanted-spare-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-wanted-spare-breadcrumb-button-base3">
                      <div className="detailed-wanted-spare-text10">Wanted Spare</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-spare-listing-content-wrapper">
                <div className="detailed-wanted-spare-listing-content">
                  <div className="detailed-wanted-spare-description-block-parent">
                    <div className="detailed-wanted-spare-description-block">
                      <div className="detailed-wanted-spare-description-heading">
                        {/* <span className="detailed-wanted-spare-pre-">Pre-</span>
                    <span className="detailed-wanted-spare-o">o</span>
                    <span className="detailed-wanted-spare-pre-">wned, </span>
                    <span className="detailed-wanted-spare-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                      WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                      DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                    > */}
                        {wantedSpareData.data.title}
                      </div>
                      <div className="detailed-wanted-spare-listing-details">
                        <div className="detailed-wanted-spare-detail-container">
                          <div className="detailed-wanted-spare-details-content">
                            <div className="detailed-wanted-spare-details-row">
                              <div className="detailed-wanted-spare-detail-items">
                                <img
                                  className="detailed-wanted-spare-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="detailed-wanted-spare-supporting-text">{wantedSpareData.data.wanted_spare_user.user_usermeta.tax_country.name}, {wantedSpareData.data.wanted_spare_user.user_usermeta.userTown}</div>
                              </div>
                              <div className="detailed-wanted-spare-detail-items">
                                <img
                                  className="detailed-wanted-spare-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-wanted-spare-supporting-text">{getCustomizedDate(wantedSpareData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-wanted-spare-contact-details">
                              <div className="detailed-wanted-spare-contact-name-wrapper">
                                <div className="detailed-wanted-spare-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-wanted-spare-contact-role">
                                <div className="detailed-wanted-spare-supporting-text2">99,999 reviews</div>
                              </div>
                              <div className="detailed-wanted-spare-social-links">
                                <div className="detailed-wanted-spare-rectangle-container">
                                  <div className="detailed-wanted-spare-frame-inner"></div>
                                  <div className="detailed-wanted-spare-icon-backgrounds-parent">
                                    <div className="detailed-wanted-spare-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-spare-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-spare-icon-backgrounds-parent">
                                    <div className="detailed-wanted-spare-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-spare-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-spare-icon-backgrounds-parent">
                                    <div className="detailed-wanted-spare-frame-child1"></div>
                                    <img
                                      className="detailed-wanted-spare-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-spare-icon-backgrounds-parent">
                                    <div className="detailed-wanted-spare-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-spare-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-spare-icon-backgrounds-parent">
                                    <div className="detailed-wanted-spare-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-spare-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-spare-button-row">
                            {!wantedSpareData.data.isAuthor && (
                              wantedSpareData.data.addedToFunnel ? (

                                <div className="detailed-wanted-spare-buttonsbutton1" onClick={() => handleRemoveFunnel(wantedSpareData.data.id)
                                } style={{ cursor: "pointer" }}  >

                                  <img
                                    className="detailed-wanted-spare-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />

                                  <div className="detailed-wanted-spare-text-padding">
                                    <div className="detailed-wanted-spare-text11">Added To Funnel</div>
                                  </div>
                                </div>
                              ) : (
                                <div className="detailed-wanted-spare-buttonsbutton1" onClick={() => handleAddFunnel(wantedSpareData.data.id)
                                } style={{ cursor: "pointer" }}  >

                                  <img
                                    className="detailed-wanted-spare-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                  <div className="detailed-wanted-spare-text-padding">
                                    <div className="detailed-wanted-spare-text11">Add To Funnel</div>
                                  </div>
                                </div>
                              )
                            )}
                            <div className="detailed-wanted-spare-buttonsbutton2">
                              <img
                                className="detailed-wanted-spare-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-spare-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-wanted-spare-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-spare-buttonsbutton2">
                              <img
                                className="detailed-wanted-spare-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-spare-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-wanted-spare-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-spare-buttonsbutton2">
                              <img
                                className="detailed-wanted-spare-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-spare-magnifier-1-icon"
                                alt=""
                                src={share_1_2x}
                              />

                              <img
                                className="detailed-wanted-spare-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <img
                              className="detailed-wanted-spare-button-row-child"
                              alt=""
                              src={group_1010}
                            />
                          </div>
                        </div>
                        <div className="detailed-wanted-spare-dropdown-container">
                          <div className="detailed-wanted-spare-dropdown">
                            <img
                              className="detailed-wanted-spare-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="detailed-wanted-spare-divider-icon" alt="" src={divider1} />

                    <div className="detailed-wanted-spare-heading-parent">
                      <div className="detailed-wanted-spare-heading">Highlights</div>
                      <div className="detailed-wanted-spare-description-content">
                        <div className="detailed-wanted-spare-description-columns-first">
                          <div className="detailed-wanted-spare-first-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">
                              {wantedSpareData.data.tax_device_category.name}
                            </div>
                            <div className="detailed-wanted-spare-paragraph1">Device Category</div>
                          </div>
                          <div className="detailed-wanted-spare-first-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">{wantedSpareData.data.tax_status_condition.name}</div>
                            <div className="detailed-wanted-spare-paragraph1">Status</div>
                          </div>
                          <div className="detailed-wanted-spare-first-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">{wantedSpareData.data.tax_clinical_application.name}</div>
                            <div className="detailed-wanted-spare-paragraph1">Clinical Applications</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-spare-description-columns-second">
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">
                              {wantedSpareData.data.tax_oem.name}
                            </div>
                            <div className="detailed-wanted-spare-paragraph1">OEM Brand</div>
                          </div>
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">{wantedSpareData.data.tax_age_of_device.name}</div>
                            <div className="detailed-wanted-spare-paragraph1">Age of the Device</div>
                          </div>
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">
                              {wantedSpareData.data.tax_purpose_use.name}
                            </div>
                            <div className="detailed-wanted-spare-paragraph1">Spare Use</div>
                          </div>
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">{wantedSpareData.data.partName}</div>
                            <div className="detailed-wanted-spare-paragraph1">Part Name</div>
                          </div>
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">
                              {wantedSpareData.data.tax_availability.name}
                            </div>
                            <div className="detailed-wanted-spare-paragraph1">Availability</div>
                          </div>
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">{wantedSpareData.data.tax_physical_location.name}</div>
                            <div className="detailed-wanted-spare-paragraph1">Physical Location</div>
                          </div>
                          <div className="detailed-wanted-spare-second-columns-paragraphs">
                            <div className="detailed-wanted-spare-paragraph">
                              {wantedSpareData.data.partNumber}
                            </div>
                            <div className="detailed-wanted-spare-paragraph1">Part Number</div>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                  <div className="detailed-wanted-spare-service-card">
                    <div className="detailed-wanted-spare-card-content">
                      <div className="detailed-wanted-spare-card-content-child"></div>
                      <div className="detailed-wanted-spare-card-header">
                        <div className="detailed-wanted-spare-price-tag">
                          <div className="detailed-wanted-spare-badge-wrapper">
                            <div className="detailed-wanted-spare-badge3">
                              <img
                                className="detailed-wanted-spare-dot-icon3"
                                alt=""
                                src={dot1}
                              />

                              <div className="detailed-wanted-spare-text29">Sale</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-spare-price-parent">
                            <div className="detailed-wanted-spare-price">$9,999,999.99</div>
                            <div className="detailed-wanted-spare-original-price">
                              <div className="detailed-wanted-spare-discount">USD - Per Unit</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-spare-service-title-container">
                            <div className="detailed-wanted-spare-service-title">Posted By Manufacturer</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-spare-card-footer">
                        <img
                          className="detailed-wanted-spare-card-footer-child"
                          alt=""
                          src={wantedSpareData.data.profileImage}
                        />

                        <div className="detailed-wanted-spare-footer-divider">
                          <img
                            className="detailed-wanted-spare-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="detailed-wanted-spare-professional-info">
                        <div className="detailed-wanted-spare-professional-details">
                          <div className="detailed-wanted-spare-professional-actions">
                            <div className="detailed-wanted-spare-benefits-container">
                              <div className="detailed-wanted-spare-amlie-laurent">{wantedSpareData.data.wanted_spare_user.displayName}</div>
                              {
                                wantedSpareData?.data?.wanted_spare_user?.businessName &&
                                <div className="detailed-wanted-spare-role">{wantedSpareData.data.wanted_spare_user.businessName}</div>
                              }
                            </div>
                            <div className="detailed-wanted-spare-action-buttons">
                              <div className="detailed-wanted-spare-contact-button">
                                <div className="detailed-wanted-spare-message-button">
                                  <div className="detailed-wanted-spare-navigation">
                                    <img
                                      className="detailed-wanted-spare-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-wanted-spare-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-wanted-spare-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div>
                            <div className="detailed-wanted-spare-favorite-button">
                              <div className="detailed-wanted-spare-button-container2">
                                <div className="detailed-wanted-spare-buttonsbutton5">
                                  <img
                                    className="detailed-wanted-spare-filter-lines-icon"
                                    alt=""
                                    src={messagechatcircle}
                                  />

                                  <div className="detailed-wanted-spare-text30">Private Message</div>
                                </div>
                                {/* Add To Contact */}
                                {!wantedSpareData.data.isAuthor && (
                                  (wantedSpareData.data.addedToContact) ?
                                    <div className="detailed-wanted-spare-buttonsbutton6" onClick={() => handleRemoveContact(wantedSpareData.data.wanted_spare_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-wanted-spare-search-lg-icon"
                                        alt=""
                                        src={users01}
                                      />
                                    </div>
                                    :
                                    <div className="detailed-wanted-spare-buttonsbutton6" onClick={() => handleAddContact(wantedSpareData.data.wanted_spare_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-wanted-spare-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-spare-dropdown-menu">
                            <div className="detailed-wanted-spare-dropdown1">
                              <img
                                className="detailed-wanted-spare-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <FooterDetailPage />
          </div>
        </div></>
    )
  } else {
    return <Loader />;
  }


}

