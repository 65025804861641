import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Service/EditService/EditServiceContext";
import { useNavigate, useParams } from "react-router-dom";
import { clearEditServiceForm, fillEditServiceForm, updateEditServiceField, updateField } from "../EditServiceFormComponent/EditServiceSlice";
import { updateAppDataEditService, clearAppDataEditService } from "../EditServiceFormComponent/AppDataEditServiceSlice";
import { useGetClinicalApplicationsTaxQuery, useGetServiceCategoryTaxQuery, useGetPurposeUseTaxQuery, useGetServiceCategoryTaxQuery, useGetUnitOfMeasureServiceTaxQuery, useGetServiceandSupportReachTaxQuery, useGetPhysicalLocationTaxQuery, useGetDeviceCategoryTaxQuery } from "../../api/TaxonomyFormAPI";
// import { getAllTaxonomyData } from "../../helper/EditServiceHelper";
import { EditServiceStep1 } from "./EditServiceStep1";
import { EditServiceStep2 } from "./EditServiceStep2";
import { EditServiceStep3 } from "./EditServiceStep3";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEditServiceMutation } from "../../api/ServiceAPI";
import { isValidURL, objectToFormDataEditService } from "../../helper/ServiceHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { useFindGetRelatedAddressRemoteQuery } from "../../api/AddressAPI";

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <EditServiceStep1 />;
    case 1:
      return <EditServiceStep2 />;
    case 2:
      return <EditServiceStep3 />;
    default:
      return null;
  }
};

const EditServiceParent = ({ setShowSuccessMessage, setIsTaxonomySet }) => {
  const { id } = useParams();

  const formikRef = useRef(null);

  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["serviceCategory",
    "serviceValueProposition",
    "rangeMin",
    "rangeMax",
    "unitOfMeasureService",
    "clinicalApplications",
    "purposeUse",
    "deviceCategory",
    "physicalLocation",];
  const step2FormKey = ["serviceFeatureHighlights",
    "serviceBenefitsHighlights",
    "parentDevices",
    "featureImage",
    "featureImageLink",
    "featureImageObject",
    "bannerImage",
    "bannerImageLink",
    "bannerImageObject",
    "videoType",
    "linkVideo",
    "location",
    "serviceAndSupportReach",]
  const step3FormKey = ["serviceFeatures",
    "serviceBenefits",
    "scopeOfWork",
    "serviceLevelAgreement",
    "additionalInformation",
    "document",
    "documentLink",
    "documentFileObject",]

  const [editService, { isLoading, isError, error }] = useEditServiceMutation();
  const navigate = useNavigate();
  const serviceCategoryTax = useGetServiceCategoryTaxQuery();
  const unitOfMeasureServiceTax = useGetUnitOfMeasureServiceTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery();
  const purposeUseTax = useGetPurposeUseTaxQuery();
  const physicalLocationTax = useGetPhysicalLocationTaxQuery();
  const serviceAndSupportReachTax = useGetServiceandSupportReachTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  // const [taxonomyLoaded, setTaxonomyLoaded] = useState(false);
  const relatedAddressTax = useFindGetRelatedAddressRemoteQuery();

  // const [taxonomyLoaded, setTaxonomyLoaded] = useState(false);




  const [allTaxonomy, setAllTaxonomy] = useState({ deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureService: unitOfMeasureServiceTax, serviceAndSupportReach: serviceAndSupportReachTax, serviceCategory: serviceCategoryTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, unitOfMeasureServiceTax.isSuccess, serviceAndSupportReachTax.isSuccess, serviceCategoryTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureService: unitOfMeasureServiceTax, serviceAndSupportReach: serviceAndSupportReachTax, serviceCategory: serviceCategoryTax, relatedAddress: relatedAddressTax
      });
      // setTaxonomyLoaded(true);
    }
  }, [
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    serviceCategoryTax.isSuccess, serviceCategoryTax.data,
    serviceAndSupportReachTax.isSuccess, serviceAndSupportReachTax.data,
    unitOfMeasureServiceTax.isSuccess, unitOfMeasureServiceTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,
  ]);

  // useEffect(() => {
  //   if(allTaxonomy.serviceCategory.status === "fulfilled")
  //     {
  //       // console.log(allTaxonomy);
  //       setTaxonomyLoaded(true)
  //     }
  // }, [allTaxonomy])

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.editService);
  const appData = useSelector((state) => state.appDataEditService);

  // const [featureImageMeta, setFeatureImageMeta] = useState("");
  const [documentFileToDelete, setDocumentFileToDelete] = useState('');
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState('');
  const [cropedBannerImageFile, setCropedBannerImageFile] = useState("");
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");
  const [documentCheckboxError, setDocumentCheckboxError] = useState("")
  const [backendValidation, setBackendValidation] = useState([])
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [editServiceFormInitialData, setEditServiceFormInitialData] = useState(null);



  //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedImageFile("")
  //   setSelectedBannerImageFile([])
  //   setSelectedDocumentFile("")
  //   setBackendValidation([])
  //   setShowSuccessMessage("")
  //   setDeviceCategorySearchField(editServiceFormInitialData.tax_device_category.name)
  //   // dispatch(clearEditServiceForm())
  //   dispatch(clearAppDataEditService());
  //   dispatch(fillEditServiceForm(editServiceFormInitialData))
  //   // formikRef.current.resetForm();
  //   // purgeStorage()
  // }

  const step1Schema = Yup.object({
    //   serviceCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'
    //   ),
    //   serviceValueProposition: Yup.string().required("This is a required field").max(30, 'Must be 70 characters or less'),
    //   // rangeMin: Yup.string().required("This is a required field").test(
    //   //   "OPTION", "Please select a valid option",
    //   //   (value) => value !== '0'

    //   // ),


    // rangeMin: Yup
    //   .number('Please enter a valid number')
    //   .required('This is a required field')
    //   .typeError('Please enter a valid number')
    //   .test('non-negative', 'Field data cannot be negative', value => value >= 0)
    //   .min(1,'Field data must be greater than or equal to 1')
    //   .test('decimal-places', 'No decimal places are allowed', value => {

    //     const [, decimalPart] = String(value).split('.');
    //     return decimalPart === undefined || decimalPart.length <= 0;
    //   }),

    // rangeMax: Yup
    //   .number('Please enter a valid number')
    //   .typeError('Please enter a valid number')
    //   .required('This is a required field')
    //   .test('non-negative', 'Field data cannot be negative', value => value >= 0)
    //   .test('decimal-places', 'No decimal places are allowed', value => {

    //     const [, decimalPart] = String(value).split('.');
    //     return decimalPart === undefined || decimalPart.length <= 0;
    //   })

    //   .moreThan(Yup.ref('rangeMin'), 'Maximum range must be greater than minimum range'),


    //   unitOfMeasureService: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
    //   clinicalApplications: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
    //   purposeUse: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
    //   deviceCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
    //   physicalLocation: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

  })

  const step2Schema = Yup.object().shape({
    // serviceFeatureHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // serviceBenefitsHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // parentDevices: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // featureImage: Yup.mixed()
    // .test(
    //   'fileType',
    //   'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
    //   value => {
    //     if(isValidURL(value)) return true;
    //     if (!value) return true
    //     return ['image/jpeg', 'image/png','image/webp'].includes(value.type)
    //   }
    // )
    // .test(
    //   'fileSize',
    //   'File too large (max size 1MB)',
    //   value => {
    //     if(isValidURL(value)) return true;
    //     !value || (value && value.size <= 1024 * 1024)}
    // )
    // .required('A file is required'),

    // bannerImage: Yup.mixed()
    //   .test(
    //     'fileType',
    //     'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
    //     value => {
    //       if(isValidURL(value)) return true;
    //       if (!value) return true
    //       return ['image/jpeg', 'image/png','image/webp'].includes(value.type)
    //     }
    //   )
    //   .test(
    //     'fileSize',
    //     'File too large (max size 1MB)',
    //     value => {
    //       if(isValidURL(value)) return true;
    //       !value || (value && value.size <= 1024 * 1024)}
    //   )
    //   .required('A file is required'),






    // location: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'
    // ),
    // serviceAndSupportReach: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'
    // ),
  }
  )

  const step3Schema = Yup.object().shape({

    // serviceFeatures: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // serviceBenefits: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // scopeOfWork: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // serviceLevelAgreement: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // additionalInformation: Yup.string().max(2000, 'Must be 2000 characters or less'),

    // document: Yup.mixed()
    //   .test(
    //     'fileType',
    //     'Unsupported File Format (only pdf)',
    //     value => {
    //       if (isValidURL(value)) return true;
    //       if (!value) return true
    //       return ['application/pdf'].includes(value.type)
    //     }
    //   )
    //   .test(
    //     'fileSize',
    //     'File too large (max size 1MB)',
    //     value => {
    //       if (isValidURL(value)) return true;
    //       return !value || (value && value.size <= 1024 * 1024)
    //     }
    //   )
  })


  const matchErrorsAgainstBackendValidationList = async (backendValidationError) => {
    if (backendValidationError?.length > 0) {



      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataEditService({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataEditService({ case: "CURRENTSTEP", value: 1 }));
          break;
        } else if (step3FormKey.includes(field.path)) {
          console.log("step3 was true");
          dispatch(updateAppDataEditService({ case: "CURRENTSTEP", value: 2 }));
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await editService({ body: multiPartData }).unwrap();
      //If form was submitted successfully then isUpdated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Service edited successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        navigate('/dashboard');
        formikRef.current.resetForm();
        // window.location.reload();
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : appData.currentStep === 2 ? step3Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {
        // Getting FormData object to send multipart request
        if (appData.isYoutubeChecked) { dispatch(updateEditServiceField({ field: "videoType", value: "Youtube" })) }
        if (appData.isVimeoChecked) dispatch(updateEditServiceField({ field: "videoType", value: "Vimeo" }));
        multiPartData = objectToFormDataEditService(values, documentFileToDelete, cropedImageFile, cropedBannerImageFile, selectedDocumentFile)
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);

        // for (const [key, value] of multiPartData.entries()) {
        //   console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form className="add-service-step-section-header-parent">


          <Provider value={{ deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, editServiceFormInitialData, setEditServiceFormInitialData, documentFileToDelete, setDocumentFileToDelete, isRequestFirst, setIsRequestFirst, backendValidation, setBackendValidation, documentCheckboxError, setDocumentCheckboxError, isImageSelected, setIsImageSelected, allTaxonomy, cropedImageFile, cropedBannerImageFile, setCropedBannerImageFile, setCropedImageFile, selectedBannerImageFile, setSelectedBannerImageFile, selectedDocumentFile, setSelectedDocumentFile, selectedImageFile, setSelectedImageFile }}>

            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default EditServiceParent;