

import { Field, Formik, useField, useFormikContext } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, updateField } from "./AddSoftwareSlice";
import { clearAppData, updateAppDataSoftware } from "./AppDataSoftwareSlice";
import AddSoftwareFormContext from "../../screens/Software/AddSoftware/AddSoftwareContext";
import AddSoftwareFormContext from "../../screens/Software/AddSoftware/AddSoftwareContext";
import "./AddSoftwareStep3.css";
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevronleft from '../../icons/Gerator/add_form/chevronleft.svg';
import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
import placeholder1 from '../../icons/Gerator/add_form/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/add_form/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import checkbox_base from '../../icons/Gerator/add_form/-checkbox-base.svg';
import eye from '../../icons/Gerator/add_form/eye.svg';
import login04 from '../../icons/Gerator/add_form/login04.svg';
import { useAddDocumentSoftwareMutation } from "../../api/SoftwareAPI";

const DocumentMultiFileUpload = ({ validate, maxfilewarning, isUploadedDocumentHasErrorShow, isDocumentUploading, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "documentFileObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step3-label58 ">
        Documents
      </div>
      {/* <div className="add-service-step3-label58"></div> */}
      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="document">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13" htmlFor="document">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  {(!isDocumentUploading) &&
                    <input type="file" multiple accept=".pdf" {...field} {...props} className='hide-input-field' />
                  }
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PDF</span>
                <span> only (Maximum file size.10 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {/* {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null} */}

        {isDocumentUploading && (
          <div>
            <div style={{ color: "orange" }}>
              Please wait while image is uploading.
            </div>
          </div>
        )}
        {isUploadedDocumentHasErrorShow && (
          <div>
            <div style={{ color: "red" }}>
              Image upload failed, please remove image.
            </div>
          </div>
        )}
        {maxfilewarning.isMaxDocument && (
          <div>
            <div style={{ color: "red" }}>
              {maxfilewarning.message}
            </div>
          </div>
        )}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
        ) : null}
      </div>


    </div>
  );
};

const MyTextAreaNotMandatory = ({ validate, label, remainingChars, onChange, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step3-input-with-label42">
        <div>
          <label className="add-service-step3-label47" htmlFor={props.id || props.name}>
            {label}
            {/* <span className="add-service-step-span">*</span> */}
          </label>

        </div>
        <div className="add-service-step3-content62">
          <div className="add-service-step3-textarea-input-field7">
            <div className="add-service-step3-input-with-label43">
              {/* <div className="add-service-step3-label46">Description</div> */}
              <div className="add-service-step3-input31">
                <textarea
                  className="add-service-step3-text82"
                  // style={inputStyle}  //New code for border colour
                  {...field}
                  {...props}
                  onChange={(e) => {
                    onChange(e); // Call parent onChange to track remaining characters
                  }}

                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <span>{remainingChars} characters remaining</span>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

export const AddSoftwareStep3 = () => {

  const [softwareFeatureCharCount, setsoftwareFeatureCharCount] = useState(1000);
  const [softwareBenefitCharCount, setsoftwareBenefitCharCount] = useState(1000);
  const [systemRequirementCharCount, setsystemRequirementCharCount] = useState(1000);
  const [additionalInformationCharCount, setadditionalInformationCharCount] = useState(2000);

  //Sets min and max document allowed basis on plan
  const [documentMinMaxAllowed, setDocumentMinMaxAllowed] = useState('');

  //------------- Query for uploading documents ------------
  const [addDocument, { isLoading, isError, error, data }] = useAddDocumentSoftwareMutation();

  //This hook will store boolean value if uploaded document has documentMinMaxAllowed.max . document validation exceeding
  const [maxFileValidation, setMaxFileValidation] = useState({ message: "", isMaxDocument: false });


  const dispatch = useDispatch();
  const formData = useSelector((state) => state.addSoftware);
  const appData = useSelector((state) => state.appDataAddSoftware);
  const { isUploadedDocumentHasErrorShow, setisUploadedDocumentHasErrorShow, documentMultiUploadStatus, setDocumentMultiUploadStatus, isDocumentUploading, setIsDocumentUploading, currentPlanData, backendValidation, documentMultiCheckboxError, setDocumentMultiCheckboxError, selectedMultiDocumentFile, setSelectedMultiDocumentFile } = useContext(AddSoftwareFormContext);
  // const [fileDoceLinkRemover, setFileDocLinkRemover] = useState(false);
  // ------------ Formik Methods to manage form flow ---------------------
  const { values, setFieldValue, setErrors, setFieldTouched, errors, touched, handleSubmit, isSubmitting } = useFormikContext();



  useEffect(() => {
    if (currentPlanData) {
      setDocumentMinMaxAllowed({ max: currentPlanData.planAllowedMaxDocument });
    }
  }, [currentPlanData]);

  /* 
       This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
   */
  useEffect(() => {
    console.log("Values useEffect Open");

    //BELOWE CODE IS FOR DOCUMENTS
    if (touched.document) {
      if (errors.document && values.document.length > 0) {
        console.log("Values Error UseEffect");
        handleSelectedMultiDocumentFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.document && values.document[0] != null) {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedMultiDocumentFile("no-error");
      }
    }
    // 1. Check for Errors FIRST:

    console.log("Values useEffect Closed ");


  }, [values, errors.document]);


  // This will upload document on backend
  useEffect(() => {
    console.log("Values useEffect Open");

    const uploadDocument = async () => {
      setIsDocumentUploading(true);
      for (const [index, document] of values.document.entries()) {
        let skip = false;

        // Check if status for this index already exists and is not pending
        const statusExists = documentMultiUploadStatus.some(status => status.index === index && !status.pending);
        if (statusExists) {
          console.log(`Skipping index ${index}: already uploaded.`);
          skip = true;
        }

        if (!skip) {
          setDocumentMultiUploadStatus((prevStatus) => {
            if (!prevStatus || !prevStatus.length) {
              console.log('First record added on : ' + index);
              return [{
                index: index,
                pending: true,
                success: false,
                isError: false
              }];
            } else {
              // Check if status for this index already exists and is pending
              const pendingStatusExists = prevStatus.some(status => status.index === index && status.pending);
              if (!pendingStatusExists) {
                console.log('Adding New Index : ' + index);
                return [...prevStatus, {
                  index: index,
                  pending: true,
                  success: false,
                  isError: false
                }];
              }
            }
            return prevStatus;
          });

          try {

            console.log(document.file);
            const result = await addDocument(document.file).unwrap();
            // console.log();
            // If successful, update the status
            setDocumentMultiUploadStatus((prevStatus) =>
              prevStatus.map((status) =>
                status.index === index
                  ? { ...status, pending: false, success: true, isError: false, postId: result.data[0] }
                  : status
              )
            );
            // if (index === 2) {
            //   throw Error();
            // } else {
            //   const result = await addDocument(document.file).unwrap();
            //   // console.log();
            //   // If successful, update the status
            //   setDocumentMultiUploadStatus((prevStatus) =>
            //     prevStatus.map((status) =>
            //       status.index === index
            //         ? { ...status, pending: false, success: true, isError: false, postId: result.data[0] }
            //         : status
            //     )
            //   );
            // }
          } catch (uploadError) {
            console.log(uploadError);
            setDocumentMultiUploadStatus((prevStatus) =>
              prevStatus.map((status) =>
                status.index === index
                  ? { ...status, pending: false, success: false, isError: true }
                  : status
              )
            );
          }
        }
      }
      setIsDocumentUploading(false);
    };

    if (!maxFileValidation.isMaxDocument && !errors.document) {
      uploadDocument();
    }

    // if (!minFileValidation.isMinDocument && maxFileValidation.isMaxDocument) {
    //   // Handle the case where min is true and max validation is true
    // }

    console.log("Values useEffect Closed");
  }, [selectedMultiDocumentFile, addDocument]);



  const prev = () => dispatch(updateAppDataSoftware({ case: "CURRENTSTEP", value: (appData.currentStep - 1) }));





  const handleSelectedMultiDocumentFile = async (type) => {
    console.log("HandleSelectedMultiDocumentFile Called " + type);

    setFieldTouched("document", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.document) {
      console.log("Inside errors.document is true means error found 435");
      setSelectedMultiDocumentFile(values.document);
      setDocumentMultiCheckboxError(errors.document);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.document is true means no error found 435");
    setSelectedMultiDocumentFile(values.document);
    console.log("File added into select multi image hook");
  };


  //onClick handle method For gallery image remove one by one




  const handleDocumentMultiCheckbox = async (indexToRemove) => {
    //Removing upload failed message 
    await setSelectedMultiDocumentFile(selectedMultiDocumentFile => selectedMultiDocumentFile.filter((_, index) => index !== indexToRemove));
    await setDocumentMultiUploadStatus((documentMultiUploadStatus) =>
      documentMultiUploadStatus
        .filter((_, index) => index !== indexToRemove) // Remove the selected object
        .map((item, newIndex) => ({ ...item, index: newIndex })) // Recalculate indexes
    );

    setisUploadedDocumentHasErrorShow(false);


    const document = values.document;
    document.splice(indexToRemove, 1)

    if (documentMultiCheckboxError.length !== 0) {
      const errorMessages = documentMultiCheckboxError;
      errorMessages.splice(indexToRemove, 1);
      setDocumentMultiCheckboxError(errorMessages);
    }

    console.log("handleDocumentMultiCheckbox index removed :" + indexToRemove);
    console.log(document);

    if (document.length !== 0) {
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'document') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      setErrors(filteredErrors)
      await setFieldValue('document', document);

      //Custom Max Min file check validation.
      customValidation();
      console.log("handleDocumentMultiCheckbox working.....");
      return;
    } else {
      setFieldValue('document', []);
    }

    console.log("handleDocumentMultiCheckbox closed.....");

  }

  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log("called");
    if (name === 'document') {

      console.log("Document new File uploading.....................");
      console.log(event.target.files);
      const filesObject = event.target.files;
      const fileObjects = Array.from(filesObject).map(file => ({
        file: file, // Store the original file object
      }));
      let fileLength = values.document.length + fileObjects.length;


      if (fileLength <= documentMinMaxAllowed.max) {

        maxFileValidation.isMaxDocument && setMaxFileValidation({ message: `Maximum of ${documentMinMaxAllowed.max} files are allowed to upload`, isMaxDocument: false });
        setFieldTouched("document", true);
        await setFieldValue(name, [...values.document, ...fileObjects]);
      } else {
        setMaxFileValidation({ message: `Maximum of ${documentMinMaxAllowed.max} files are allowed to upload`, isMaxDocument: true });
      };
      console.log("Values updated........");
      return;
    }

    if (name === "softwareFeatures") {
      const maxLength = 1000;
      const remainingChars = Math.max(maxLength - value.length, 0);
      setsoftwareFeatureCharCount(remainingChars);
    }

    if (name === "softwareBenefits") {
      const maxLength = 1000;
      const remainingChars = Math.max(maxLength - value.length, 0);
      setsoftwareBenefitCharCount(remainingChars);
    }

    if (name === "systemRequirements") {
      const maxLength = 1000;
      const remainingChars = Math.max(maxLength - value.length, 0);
      setsystemRequirementCharCount(remainingChars);
    }





    if (name === "additionalInformation") {
      const maxLength = 2000;
      const remainingChars = Math.max(maxLength - value.length, 0);
      setadditionalInformationCharCount(remainingChars);
    }

    setFieldValue(name, value);
    dispatch(updateField({ field: name, value }));
  };

  const customValidation = () => {
    //gallery length is less than 3 then save true
    //If isMaxImage is true then save value to false
    maxFileValidation.isMaxDocument && setMaxFileValidation({ message: `Maximum of ${documentMinMaxAllowed.max} files are allowed to upload`, isMaxDocument: false });

  }

  return (
    <>
      <div className="add-software-step-section-header-parent">
        <div className="add-software-step3-add-software-step-3">
          <div className="add-software-step3-hint-text46">This is a hint text to help user.</div>
          <main className="add-software-step3-section-header-group">
            <div className="add-software-step3-section-header7">
              <div className="add-software-step3-content61">
                Description
              </div>


              <img className="add-software-step3-divider-icon14" alt="" src={divider1} />
            </div>

            <section className="add-software-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Software Features"
                name="softwareFeatures"                                                          //New Code
                type="text"
                placeholder="1000 characters"
                id="softwareFeatures"
                onChange={handleChange}
                validate={backendValidation} value={formData.softwareFeatures}
                remainingChars={softwareFeatureCharCount}
              />
            </section>

            <section className="add-software-step3-frame-section">
              <div className="add-software-step3-input-field34">
                <MyTextAreaNotMandatory
                  label="Software Benefits"
                  name="softwareBenefits"                                                          //New Code
                  type="text"
                  placeholder="1000 characters"
                  id="softwareBenefits"
                  onChange={handleChange}
                  validate={backendValidation} value={formData.softwareBenefits}
                  remainingChars={softwareBenefitCharCount}
                />

              </div>
            </section>
            <section className="add-software-step3-input-field35">
              <MyTextAreaNotMandatory
                label="System Requirements"
                name="systemRequirements"                                                          //New Code
                type="text"
                placeholder="1000 characters"
                id="systemRequirements"
                onChange={handleChange}
                validate={backendValidation} value={formData.systemRequirements}
                remainingChars={systemRequirementCharCount}
              />
            </section>


            <section className="add-software-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Additional Information"
                name="additionalInformation"                                                          //New Code
                type="text"
                placeholder="2000 characters"
                id="additionalInformation"
                onChange={handleChange}
                validate={backendValidation} value={formData.additionalInformation}
                remainingChars={additionalInformationCharCount}
              /></section>
            <div className="add-service-step3-file-upload">
              <DocumentMultiFileUpload id="document" name="document" validate={backendValidation} onChange={handleChange} maxfilewarning={maxFileValidation} isUploadedDocumentHasErrorShow={isUploadedDocumentHasErrorShow} isDocumentUploading={isDocumentUploading} value=
                {undefined} />


              {
                (typeof selectedMultiDocumentFile === 'object' && selectedMultiDocumentFile.length !== 0) && (
                  selectedMultiDocumentFile?.map((documentObject, index) => (
                    <div className="add-service-step3-file-upload-item-base3" key={index}>
                      <div className="add-service-step3-file-type-icon-parent">
                        <div className="add-service-step3-file-type-icon3">
                          <img
                            className="add-service-step3-page-icon3"
                            loading="lazy"
                            alt=""
                            src={page}
                          />

                          <div className="add-service-step3-file-type-wrap3">
                            <b className="add-service-step3-file-type3">PDF</b>
                          </div>
                        </div>
                        <div className="add-service-step3-frame-parent7">
                          <div className="add-service-step3-text-parent1">
                            <div className="add-service-step3-text90">{documentObject.file.name}</div>
                            <div className="add-service-step3-checkbox3">
                              {documentMultiCheckboxError ?
                                <Field type="checkbox" onClick={() => handleDocumentMultiCheckbox(index)} name="imageDocCheckbox" checked className="checkbox-instance custom-checkbox"></Field> :
                                <Field type="checkbox" onClick={() => handleDocumentMultiCheckbox(index)} name="imageDocCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                              }
                            </div>
                          </div>
                          <div className="add-service-step3-supporting-text29">{(documentObject.file.size / 1000).toFixed(1) + " KB"}</div>
                        </div>
                      </div>
                      <div className="add-service-step3-progress-bar8">

                        {/* <div className="add-service-step3-percentage3">100%</div> */}
                        {(documentMultiCheckboxError[index]) &&
                          <div key={index} style={{ color: "red" }}>
                            {documentMultiCheckboxError[index]}
                          </div>
                        }
                        {documentMultiUploadStatus.length &&
                          (documentMultiUploadStatus[index]?.pending || documentMultiUploadStatus[index]?.success || documentMultiUploadStatus[index]?.isError) ? (
                          <>
                            {documentMultiUploadStatus[index].pending && <div className="add-service-step3-percentage3">Uploading...</div>}
                            {documentMultiUploadStatus[index].success &&
                              <>
                                <div className="add-service-step3-progress-bar-wrapper">
                                  <div className="add-service-step3-progress-bar9">
                                    <div className="add-service-step3-background3"></div>
                                    <div className="add-service-step3-progress3"></div>
                                  </div>
                                </div>
                                <div className="add-service-step3-percentage3">100%</div>
                              </>
                            }
                            {documentMultiUploadStatus[index].isError && <div key={index} style={{ color: "red" }}>Not able to upload. Try again</div>}
                          </>
                        ) : (
                          <div className="add-service-step3-percentage3">Pending</div>
                        )
                        }
                      </div>
                    </div>
                  ))

                )
              }
            </div>
            <div className="add-software-step3-section-footer7">
              <div className="add-software-step3-section-footer8">
                <img
                  className="add-software-step3-divider-icon14"
                  loading="lazy"
                  alt=""
                  src={divider1}
                />

                <div className="add-software-step3-content70 add-job-step-content31-custom">

                  <div className="add-software-step3-actions6 add-service-step3-actions6-custom">



                    <button className="add-software-step3-button21" type="button" onClick={prev}>
                      <img
                        className="add-software-step3-placeholder-icon66"
                        alt=""
                        src={placeholder2}
                      />

                      <img
                        className="add-software-step3-chevron-left-icon3"
                        alt=""
                        src={chevronleft}
                      />

                      <div className="add-software-step3-text-padding25">
                        <div className="add-software-step3-text93">Back</div>
                      </div>
                      <img
                        className="add-software-step3-placeholder-icon66"
                        alt=""
                        src={placeholder2}
                      />
                    </button>
                    <button className="add-software-step3-button22" type="button">
                      <img
                        className="add-software-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />

                      <img
                        className="add-software-step3-chevron-left-icon3"
                        alt=""
                        src={eye}
                      />

                      <div className="add-software-step3-text-padding25" >
                        <a className="add-software-step3-text94">Preview</a>
                      </div>
                      <img
                        className="add-software-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />
                    </button>
                    <button className="add-software-step3-button23" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                      <img
                        className="add-software-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />

                      <img
                        className="add-software-step3-chevron-left-icon3"
                        alt=""
                        src={login04}
                      />

                      <div className="add-software-step3-text-padding25">
                        <div className="add-software-step3-text95">{isSubmitting ? 'Submitting...' : 'Save & Submit'}</div>
                      </div>
                      <img
                        className="add-software-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>

  )
}


