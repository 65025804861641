import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Device/EditDevice/EditDeviceContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clearEditDeviceForm, fillEditDeviceForm, updateEditDeviceField, updateField } from "../EditDeviceFormComponent/EditDeviceSlice";
import { updateAppDataEditDevice, clearAppDataEditDevice } from "../EditDeviceFormComponent/AppDataEditDeviceSlice";
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../../api/TaxonomyFormAPI";
// import { getAllTaxonomyData } from "../../helper/EditDeviceHelper";
import { EditDeviceStep1 } from "./EditDeviceStep1";
import { EditDeviceStep2 } from "./EditDeviceStep2";
import { EditDeviceStep3 } from "./EditDeviceStep3";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import { useEditDeviceMutation } from "../../api/DeviceAPI";
import { isValidURL, objectToFormData, objectToFormDataForEditDevice } from "../../helper/DeviceHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { useFindGetRelatedAddressNoRemoteQuery } from "../../api/AddressAPI";


const renderStep = (step) => {
  switch (step) {
    case 0:
      return <EditDeviceStep1 />;
    case 1:
      return <EditDeviceStep2 />;
    case 2:
      return <EditDeviceStep3 />;
    default:
      return null;
  }
};

const EditDeviceParent = ({ setShowSuccessMessage, setIsTaxonomySet }) => {
  const formikRef = useRef(null);
  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //Oem helper select field
  const [newOemLength, setNewOemLength] = useState(50);
  const [oemSearchField, setOemSearchField] = useState("");

  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["transactionType", "yourRole", "deviceCategory", "oem", "modelName", "statusCondition", "yearOfManufacture", "availability", "modelNumber", "serialNumber", "price", "unitOfMeasure", "availableQuantity", "warranty", "shipping", "clinicalApplications", "purposeUse", "physicalLocation",];
  const step2FormKey = ["hardwareHighlights", "softwareUpgradesOsApplicationsworklistHighlights", "accessoriesHighlights", "featureImageObject", "galleryImageObject", "linkVideo", "location",]
  const step3FormKey = ["documentFileObject", "documentLink", "hardware", "softwareUpgraadesOsApplicationsWorklist", "accessories", "warrantyInput", "serviceHistory", "packingList", "additionalInformation",]

  const [editDevice, { isLoading, isError, error }] = useEditDeviceMutation();
  const navigate = useNavigate();
  const transactionTypeTax = useGetTransactionTypeTaxQuery();
  const yourRoleTax = useGetYourRoleTaxQuery();
  const yearofManufactureTax = useGetYearOfManufactureTaxQuery()
  const availabilityTax = useGetAvailabilityTaxQuery()
  const unitOfMeasureTax = useGetUnitOfMeasureDeviceSpareTaxQuery();
  const warrantyTax = useGetWarrantyTaxQuery();
  const shippingTax = useGetShippingTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery()
  const purposeUseTax = useGetPurposeUseTaxQuery()
  const physicalLocationTax = useGetPhysicalLocationTaxQuery()
  const statusConditionTax = useGetStatusConditionTaxQuery();
  const oemTax = useGetOemTaxQuery({ id: newOemLength, query: oemSearchField });
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  // This Query is not taxonomy but treating as tax because it has address in select form
  const relatedAddressTax = useFindGetRelatedAddressNoRemoteQuery();


  const [allTaxonomy, setAllTaxonomy] = useState({ oem: oemTax, deviceCategory: deviceCategoryTax, statusCondition: statusConditionTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, shipping: shippingTax, warranty: warrantyTax, unitOfMeasure: unitOfMeasureTax, availability: availabilityTax, yearOfManufacture: yearofManufactureTax, yourRole: yourRoleTax, transactionType: transactionTypeTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [oemTax.isSuccess, deviceCategoryTax.isSuccess, statusConditionTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, shippingTax.isSuccess, warrantyTax.isSuccess, unitOfMeasureTax.isSuccess, availabilityTax.isSuccess, yearofManufactureTax.isSuccess, yourRoleTax.isSuccess, transactionTypeTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        oem: oemTax, deviceCategory: deviceCategoryTax, statusCondition: statusConditionTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, shipping: shippingTax, warranty: warrantyTax, unitOfMeasure: unitOfMeasureTax, availability: availabilityTax, yearOfManufacture: yearofManufactureTax, yourRole: yourRoleTax, transactionType: transactionTypeTax, relatedAddress: relatedAddressTax
      });
    }
  }, [
    oemTax.isSuccess, oemTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    transactionTypeTax.isSuccess, transactionTypeTax.data,
    yourRoleTax.isSuccess, yourRoleTax.data,
    yearofManufactureTax.isSuccess, yearofManufactureTax.data,
    availabilityTax.isSuccess, availabilityTax.data,
    unitOfMeasureTax.isSuccess, unitOfMeasureTax.data,
    warrantyTax.isSuccess, warrantyTax.data,
    shippingTax.isSuccess, shippingTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    statusConditionTax.isSuccess, statusConditionTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,
  ]);


  const dispatch = useDispatch();
  const formData = useSelector((state) => state.editDevice);
  const appData = useSelector((state) => state.appDataEditDevice);
  // const [featureImageMeta, setFeatureImageMeta] = useState("");
  const [galleryImageToDelete, setGalleryImageToDelete] = useState([]);
  const [documentFileToDelete, setDocumentFileToDelete] = useState('');
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [selectedMultiImageFile, setSelectedMultiImageFile] = useState([]);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [documentCheckboxError, setDocumentCheckboxError] = useState("")
  const [backendValidation, setBackendValidation] = useState([])
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const [editDeviceFormInitialData, setEditDeviceFormInitialData] = useState(null);



  //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedImageFile("")
  //   setSelectedMultiImageFile([])
  //   setSelectedDocumentFile("")
  //   setBackendValidation([])
  //   setShowSuccessMessage("")
  //   setDeviceCategorySearchField(editDeviceFormInitialData.tax_device_category.name)
  //   setOemSearchField(editDeviceFormInitialData.tax_oem.name)
  //   // dispatch(clearEditDeviceForm())
  //   dispatch(clearAppDataEditDevice());
  //   dispatch(fillEditDeviceForm(editDeviceFormInitialData));
  //   // formikRef.current.resetForm();
  //   // purgeStorage()
  // }

  const step1Schema = Yup.object({
    // transactionType: Yup.string().required("This is a required fieldssssssss").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'
    // ),
    // yourRole: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // deviceCategory: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // oem: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'
    // ),

    // modelName: Yup.string().required("This is a required field").max(30, 'Must be 30 characters or less'),

    // statusCondition: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // yearOfManufacture: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // availability: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // modelNumber: Yup.string()
    //   .max(30, 'Must be 30 characters or less'),

    // serialNumber: Yup.string()
    //   .max(30, 'Must be 30 characters or less'),


    // price: Yup.number("Please enter a valid number").required("This is a required field")
    // .test('non-negative', 'Field data cannot be negative', value => value >= 0)
    //   .test('decimal-places', 'Only two decimal places are allowed', value => {

    //     const [, decimalPart] = String(value).split('.');
    //     return decimalPart === undefined || decimalPart.length <= 2;
    //   })
    //   .min(0.01,'Field data must be greater than or equal to 0.01')
    //   .max(9999999.99, 'Field data must be less than or equal to 9,999,999.99'),

    // unitOfMeasure: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // availableQuantity: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // )
    // .test("range", "Value must be between 1 and 99,999", value => {
    //   const numberValue = Number(value);
    //   return numberValue > 0 && numberValue <= 99999;
    // })
    // .test('decimal-places', 'No decimal places are allowed', value => {

    //   const [, decimalPart] = String(value).split('.');
    //   return decimalPart === undefined || decimalPart.length <= 0;
    // }),

    // warranty: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // shipping: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // clinicalApplications: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // purposeUse: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // physicalLocation: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
  })

  const step2Schema = Yup.object().shape({
    // hardwareHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // softwareUpgradesOsApplicationsworklistHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // accessoriesHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // location: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'
    // ),
    // featureImage: Yup.mixed()
    //   .test(
    //     'fileType',
    //     'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
    //     value => {
    //       if(isValidURL(value))return true;
    //       if (!value) return true;
    //       return ['image/jpeg', 'image/png','image/webp'].includes(value.type)
    //     }

    //   )
    //   .test(
    //     'fileSize',
    //     'File too large (max size 1MB)',
    //     value => {
    //       if(isValidURL(value)) return true;
    //       !value || (value && value.size <= 1024 * 1024)}
    //   )
    //   .required('A file is required'),

    // gallery: Yup.array()
    //   .of(
    //     Yup.mixed()
    //       .test(
    //         'fileType',
    //          'Unsupported File Format (only JPEG, PNG allowed)',
    //         value => {
    //           // if (!value)return true
    //           if(isValidURL(value)) return true;

    //           return ['image/jpeg', 'image/png'].includes(value.file.type)
    //         }
    //       )
    //       .test(
    //         'fileSize',
    //         'File too large (max size 1MB)',
    //         value =>{
    //           if(isValidURL(value)) return true;
    //          return !value.file || (value.file && value.file.size <= 1024 * 1024)
    //         }
    //       )
    //   )
  }
  )

  const step3Schema = Yup.object().shape({

    // hardware: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // softwareUpgraadesOsApplicationsWorklist: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // accessories: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // warrantyInput: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // serviceHistory: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // packingList: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // additionalInformation: Yup.string().max(2000, 'Must be 2000 characters or less'),

    // document: Yup.mixed()
    //   .test(
    //     'fileType',
    //     'Unsupported File Format (only Pdf)',
    //     value => {
    //       if(isValidURL(value)) return true;
    //       if (!value) return true
    //       return ['application/pdf'].includes(value.type)
    //     }
    //   )
    //   .test(
    //     'fileSize',
    //     'File too large (max size 1MB)',
    //     value => {
    //       if(isValidURL(value)) return true;
    //      return !value || (value && value.size <= 1024 * 1024)}
    //   )
  })

  const matchErrorsAgainstBackendValidationList = async (backendValidationError) => {
    if (backendValidationError?.length > 0) {



      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataEditDevice({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataEditDevice({ case: "CURRENTSTEP", value: 1 }));
          break;
        } else if (step3FormKey.includes(field.path)) {
          console.log("step3 was true");
          dispatch(updateAppDataEditDevice({ case: "CURRENTSTEP", value: 2 }));
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await editDevice({ body: multiPartData }).unwrap();
      //If form was submitted successfully then isUpdated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Device edited successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        // window.location.reload();
        navigate('/dashboard');
        formikRef.current.resetForm();
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : appData.currentStep === 2 ? step3Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {
        // Getting FormData object to send multipart request
        if (appData.isYoutubeChecked) { dispatch(updateEditDeviceField({ field: "videoType", value: "Youtube" })) }
        if (appData.isVimeoChecked) dispatch(updateEditDeviceField({ field: "videoType", value: "Vimeo" }));
        multiPartData = objectToFormDataForEditDevice(values, documentFileToDelete, galleryImageToDelete, cropedImageFile, selectedMultiImageFile, selectedDocumentFile)
        // console.log(multiPartData);
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);
        // finalSubmit(multiPartData);
        // console.log(values);
        // for (const [key, value] of multiPartData.entries()) {
        //   console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
        // Final submission of form

      }}
    >
      {(formik) => (
        <Form className="add-device-step-section-header-parent">


          <Provider value={{ oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, editDeviceFormInitialData, setEditDeviceFormInitialData, documentFileToDelete, setDocumentFileToDelete, isRequestFirst, setIsRequestFirst, galleryImageToDelete, setGalleryImageToDelete, backendValidation, setBackendValidation, documentCheckboxError, setDocumentCheckboxError, isImageSelected, setIsImageSelected, allTaxonomy, cropedImageFile, setCropedImageFile, selectedMultiImageFile, setSelectedMultiImageFile, selectedDocumentFile, setSelectedDocumentFile, selectedImageFile, setSelectedImageFile }}>
            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default EditDeviceParent;