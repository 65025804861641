import React, { useEffect, useState } from 'react';
import "./DetailedJob.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';

import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';

import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetJobQuery, useLazyGetJobsQuery } from "../../../api/JobAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/JobHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarJobMutation, useDeleteRadarJobMutation } from '../../../api/Radar/RadarJobAPI';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import VideoSection from '../../../components/VideoSection/VideoSection';
import { formatNumber } from '../../../helper/DeviceHelper';
import { useAddViewJobMutation } from '../../../api/View/ViewJobAPI';


export const DetailedJob = () => {


  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetJob, { data: jobData,
    isLoading: isJobLoading,
    isSuccess: isJobSuccess,
    isError: isJobError,
    error: jobError }] = useLazyFindGetJobQuery(id);

  const [addViewJob, { data: jobViewData,
    isLoading: isViewJobLoading,
    isSuccess: isViewJobSuccess,
    isError: isViewJobError,
    error: jobViewError }] = useAddViewJobMutation();


  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarJobMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarJobMutation();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetJob(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  // // Trigger the get wanted job query only after user data is successfully fetched
  useEffect(() => {
    if (isJobSuccess && jobData) {
      addViewJob(id);
      console.log('added view data');
    }
  }, [isJobSuccess, jobData]);

  useEffect(() => {
    if (jobViewData) {
      console.log(jobViewData);
    }
  }, [jobViewData]);

  const handleRemoveRadar = async (jobId) => {
    await deleteFromRadar(jobId);
    window.location.reload();
  };

  const handleAddRadar = async (jobId) => {
    await addToRadar(jobId);
    window.location.reload();
  };

  const handleRemoveContact = async (jobId) => {
    const result = await deleteFromContact(jobId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (jobId) => {
    const result = await addToContact(jobId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])



  if (isJobSuccess && profileImageLink) {
    return (
      <div className="detailed-job-listings-detailed-page-devic">
        <div className="detailed-job-rectangle-parent">
          <div className="detailed-job-frame-child"></div>

          <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

          <img className="detailed-job-divider-icon" alt="" src={divider} />

          <div className="detailed-job-breadcrumb-button-base">
            <div className="detailed-job-text6">...</div>
          </div>
          <img
            className="detailed-job-chevron-right-icon"
            alt=""
            src={chevronright}
          />

          <div className="detailed-job-breadcrumb-button-base1">
            <div className="detailed-job-text7">Another link</div>
          </div>
          <img
            className="detailed-job-chevron-right-icon1"
            alt=""
            src={chevronright}
          />

          <div className="detailed-job-frame-parent">
            <div className="detailed-job-breadcrumb-container-parent">

              {/* <div className="detailed-job-breadcrumb-container">
                <div className="detailed-job-breadcrumb-controls">
                  <img
                    className="detailed-job-breadcrumb-controls-child"
                    alt=""
                    src="/detailed_listing/rectangle-5@2x.png"
                  />
  
                  <div className="detailed-job-testiomonial-carousel-arrow">
                    <img
                      className="detailed-job-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <img
                  className="detailed-job-breadcrumb-controls-icon"
                  alt=""
                  src="/detailed_listing/breadcrumb-controls@2x.png"
                />
  
                <img
                  className="detailed-job-breadcrumb-controls-icon"
                  alt=""
                  src="/detailed_listing/breadcrumb-controls1@2x.png"
                />
  
                <img
                  className="detailed-job-breadcrumb-controls-icon"
                  alt=""
                  src="/detailed_listing/breadcrumb-controls2@2x.png"
                />
  
                <div className="detailed-job-breadcrumb-controls1">
                  <img
                    className="detailed-job-breadcrumb-controls-item"
                    alt=""
                    src="/detailed_listing/rectangle-9@2x.png"
                  />
  
                  <div className="detailed-job-buttonsbutton">
                    <img
                      className="detailed-job-chevron-down-icon"
                      alt=""
                      src="/detailed_listing/placeholder.svg"
                    />
  
                    <div className="detailed-job-navigation">
                      <img
                        className="detailed-job-maximize-01-icon"
                        alt=""
                        src="/detailed_listing/maximize01.svg"
                      />
                    </div>
                    <div className="detailed-job-text8">See all Photos</div>
                    <img
                      className="detailed-job-chevron-down-icon"
                      alt=""
                      src="/detailed_listing/placeholder.svg"
                    />
                  </div>
                  <div className="detailed-job-testiomonial-carousel-arrow1">
                    <img
                      className="detailed-job-arrow-left-icon"
                      alt=""
                      src={arrowright}
                    />
                  </div>
                </div>
              </div> */}
              <div className="detailed-job-breadcrumb">
                <div className="detailed-job-breadcrumb-items">
                  <div className="detailed-job-breadcrumb-button-base2">
                    <div className="detailed-job-text9">Dashboard</div>
                  </div>
                  <div className="detailed-job-chevron-container">
                    <img
                      className="detailed-job-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />
                  </div>
                  <div className="detailed-job-breadcrumb-button-base3">
                    <div className="detailed-job-text10">Job</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-job-listing-content-wrapper">
              <div className="detailed-job-listing-content">
                <div className="detailed-job-description-block-parent">
                  <div className="detailed-job-description-block">
                    <div className="detailed-job-description-heading">
                      {jobData.data.title}
                    </div>
                    <div className="detailed-job-listing-details">
                      <div className="detailed-job-detail-container">
                        <div className="detailed-job-details-content">
                          <div className="detailed-job-details-row">
                            <div className="detailed-job-detail-items">
                              <img
                                className="detailed-job-marker-pin-02-icon"
                                alt=""
                                src={markerpin02}
                              />
                              {jobData.data.job_address.tax_address_type
                                .slug === "address" ?
                                <div className="detailed-job-supporting-text">{jobData.data.job_address.addressTownCity},  {jobData.data.job_address.tax_country.name}</div>
                                :
                                <div className="detailed-job-supporting-text">{jobData.data.job_address.tax_address_type
                                  .name}</div>
                              }
                            </div>
                            <div className="detailed-job-detail-items">
                              <img
                                className="detailed-job-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="detailed-job-supporting-text">{getCustomizedDate(jobData.data.createdAt)}</div>
                            </div>
                          </div>
                          <div className="detailed-job-contact-details">
                            <div className="detailed-job-contact-name-wrapper">
                              <div className="detailed-job-contact-name">4.9/5</div>
                            </div>
                            <div className="detailed-job-contact-role">
                              <div className="detailed-job-supporting-text2">99,999 reviews</div>
                            </div>
                            <div className="detailed-job-social-links">
                              <div className="detailed-job-rectangle-container">
                                <div className="detailed-job-frame-inner"></div>
                                <div className="detailed-job-icon-backgrounds-parent">
                                  <div className="detailed-job-icon-backgrounds"></div>
                                  <img
                                    className="detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={facebook_1_1_2x}
                                  />
                                </div>
                                <div className="detailed-job-icon-backgrounds-parent">
                                  <div className="detailed-job-ellipse-div"></div>
                                  <img
                                    className="detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={instagram_1_1_2x}
                                  />
                                </div>
                                <div className="detailed-job-icon-backgrounds-parent">
                                  <div className="detailed-job-frame-child1"></div>
                                  <img
                                    className="detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={whatsapp_1_2x}
                                  />
                                </div>
                                <div className="detailed-job-icon-backgrounds-parent">
                                  <div className="detailed-job-icon-backgrounds"></div>
                                  <img
                                    className="detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={twitter_1_2x}
                                  />
                                </div>
                                <div className="detailed-job-icon-backgrounds-parent">
                                  <div className="detailed-job-ellipse-div"></div>
                                  <img
                                    className="detailed-job-whatsapp-1-icon"
                                    alt=""
                                    src={linkedinlogo_1_2x}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-button-row">

                          {!jobData.data.isAuthor && (
                            jobData.data.addedToRadar ? (

                              <div className="detailed-job-buttonsbutton1" onClick={() => handleRemoveRadar(jobData.data.id)} style={{ cursor: "pointer" }}>
                                <img
                                  className="detailed-job-magnifier-1-icon"
                                  alt=""
                                  src={target_04}
                                />
                                <div className="detailed-job-text-padding">
                                  <div className="detailed-job-text11">Added To Radar</div>
                                </div>

                              </div>
                            ) : (
                              <div className="detailed-job-buttonsbutton1" onClick={() => handleAddRadar(jobData.data.id)} style={{ cursor: "pointer" }}>
                                <img
                                  className="detailed-job-magnifier-1-icon"
                                  alt=""
                                  src={radar_1_1_2x}
                                />

                                <div className="detailed-job-text-padding">
                                  <div className="detailed-job-text11">Add To Radar</div>
                                </div>
                              </div>
                            )
                          )}
                          <div className="detailed-job-buttonsbutton2">
                            <img
                              className="detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="detailed-job-magnifier-1-icon"
                              alt=""
                              src={deal_2_1_2x}
                            />

                            <img
                              className="detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <div className="detailed-job-buttonsbutton2">
                            <img
                              className="detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="detailed-job-magnifier-1-icon"
                              alt=""
                              src={magnifier_1_2x}
                            />

                            <img
                              className="detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <div className="detailed-job-buttonsbutton2">
                            <img
                              className="detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="detailed-job-magnifier-1-icon"
                              alt=""
                              src={share_1_2x}
                            />

                            <img
                              className="detailed-job-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <img
                            className="detailed-job-button-row-child"
                            alt=""
                            src={group_1010}
                          />
                        </div>
                      </div>
                      <div className="detailed-job-dropdown-container">
                        <div className="detailed-job-dropdown">
                          <img
                            className="detailed-job-maximize-01-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="detailed-job-divider-icon" alt="" src={divider1} />

                  <div className="detailed-job-heading-parent">
                    <div className="detailed-job-heading">Highlights</div>
                    <div className="detailed-job-description-content">
                      <div className="detailed-job-description-columns-first">
                        <div className="detailed-job-first-columns-paragraphs">
                          <div className="detailed-job-paragraph">
                            {jobData.data.job_business.businessName}
                          </div>
                          <div className="detailed-job-paragraph1">Hiring Entity</div>
                        </div>
                        <div className="detailed-job-first-columns-paragraphs">
                          <div className="detailed-job-paragraph">{jobData.data.tax_experience_level.name}</div>
                          <div className="detailed-job-paragraph1">Experience Level</div>
                        </div>
                        <div className="detailed-job-first-columns-paragraphs">
                          <div className="detailed-job-paragraph">{jobData.data.tax_engagement_type.name}</div>
                          <div className="detailed-job-paragraph1">Engagement Type</div>
                        </div>
                      </div>
                      <div className="detailed-job-description-columns-second">
                        <div className="detailed-job-second-columns-paragraphs">
                          <div className="detailed-job-paragraph">
                            {jobData.data.tax_service_category.name}
                          </div>
                          <div className="detailed-job-paragraph1">Service Category</div>
                        </div>
                        <div className="detailed-job-second-columns-paragraphs">
                          <div className="detailed-job-paragraph">{jobData.data.tax_occupation_category.name}</div>
                          <div className="detailed-job-paragraph1">Occupation Category</div>
                        </div>

                        {jobData.data.tax_physical_location?.name &&
                          <div className="detailed-job-second-columns-paragraphs">
                            <div className="detailed-job-paragraph">
                              {jobData.data.tax_physical_location.name}
                            </div>
                            <div className="detailed-job-paragraph1">Physical Location / Department</div>
                          </div>
                        }

                        {jobData.data.tax_purpose_use?.name &&
                          <div className="detailed-job-second-columns-paragraphs">
                            <div className="detailed-job-paragraph">{jobData.data.tax_purpose_use.name}</div>
                            <div className="detailed-job-paragraph1">Device Use</div>
                          </div>
                        }
                        <div className="detailed-job-second-columns-paragraphs">

                          {/* <div className="detailed-job-paragraph">{jobData.data.location}</div> */}
                          {jobData.data.job_address.tax_address_type
                            .slug === "address" ?
                            <div className="detailed-job-paragraph">{jobData.data.job_address.addressTownCity},  {jobData.data.job_address.tax_country.name}</div>
                            :
                            <div className="detailed-job-paragraph">{jobData.data.job_address.tax_address_type
                              .name}</div>
                          }
                          <div className="detailed-job-paragraph1">Job Location</div>
                        </div>

                        {jobData.data.tax_clinical_application?.name &&
                          <div className="detailed-job-second-columns-paragraphs">
                            <div className="detailed-job-paragraph">{jobData.data.tax_clinical_application.name}</div>
                            <div className="detailed-job-paragraph1">Clinical Applications</div>
                          </div>
                        }

                        {/* <div className="detailed-job-second-columns-paragraphs">
                        <div className="detailed-job-paragraph">EchoStar</div>
                        <div className="detailed-job-paragraph1">Communication</div>
                      </div> */}

                      </div>
                    </div>
                  </div>

                  <div className="detailed-job-content-blocks">
                    <div className="detailed-job-heading1">Overview</div>
                    <div className="detailed-job-paragraph26">
                      {jobData.data.overview}
                    </div>
                  </div>
                  <div className="detailed-job-content-blocks">
                    <div className="detailed-job-heading1">
                      Deliverables
                    </div>
                    <div className="detailed-job-paragraph26">
                      {jobData.data.deliverables}
                    </div>
                  </div>

                  <div className="detailed-job-content-blocks">
                    <div className="detailed-job-heading1">Location</div>
                    {jobData.data.job_address.tax_address_type
                      .slug === "address" ?
                      <div className="detailed-job-paragraph26">
                        {jobData.data.job_address?.fullAddress}
                      </div>
                      :
                      <div className="detailed-job-paragraph26">
                        {jobData.data.job_address.tax_address_type
                          .name}
                      </div>
                    }

                    {jobData.data.job_address.tax_address_type
                      .slug === "address" && <MapLocationViewComponent
                        key={jobData.data.id}
                        width={"100%"}
                        height={"400px"}
                        initialCoordinates={jobData.data.job_address.addressLocation}
                      />}
                  </div>
                  {(jobData.data.videoType && jobData.data.linkVideo) &&
                    // (jobData.data.videoType === 'Youtube') ?
                    <>
                      <VideoSection videoUrl={jobData.data.linkVideo} />
                      <br />
                    </>
                    // :
                    // <>
                    //   <VideoSection videoUrl={serviceData.data.linkVideo} />
                    //   <br />
                    // </>
                  }

                  <img className="detailed-job-divider-icon" alt="" src={divider1} />

                  {jobData.data?.documentFiles?.length > 0 && (
                    <div className="detailed-device-heading-parent">
                      <div className="detailed-device-heading1">Documents</div>
                      <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                        {jobData.data.documentFiles.map((link, index) => (
                          <a
                            key={index}
                            href={link} // Use the appropriate property for the file URL
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="detailed-device-download-items">
                              <div className="detailed-device-file-upload-item-base">
                                <img
                                  className="detailed-device-progress-fill-icon"
                                  alt=""
                                  src={progress_fill}
                                />
                                <div className="detailed-device-file-type-icon">
                                  <img
                                    className="detailed-device-page-icon"
                                    alt=""
                                    src={page}
                                  />
                                  <b className="detailed-device-file-type">PDF</b>
                                </div>
                                <div className="detailed-device-download-info">
                                  <div className="detailed-device-text12">
                                    {jobData.data.jobs_files[index].file_name} {/* Access file name from the current file object */}
                                  </div>
                                  <div className="detailed-device-supporting-text3">
                                    {(jobData.data.jobs_files[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="detailed-job-heading15">Reviews (2)</div>
                  <div className="detailed-job-filters-bar">
                    <div className="detailed-job-content4">
                      <div className="detailed-job-button-group">
                        <div className="detailed-job-button-group-base">
                          <div className="detailed-job-text18">Newest</div>
                        </div>
                        <div className="detailed-job-button-group-base1">
                          <div className="detailed-job-text19">Highest</div>
                        </div>
                        <div className="detailed-job-button-group-base2">
                          <div className="detailed-job-text20">Text</div>
                        </div>
                        <div className="detailed-job-button-group-base3">
                          <div className="detailed-job-text20">Text</div>
                        </div>
                        <div className="detailed-job-button-group-base4">
                          <div className="detailed-job-text20">Text</div>
                        </div>
                        <div className="detailed-job-button-group-base5">
                          <div className="detailed-job-text20">Text</div>
                        </div>
                        <div className="detailed-job-button-group-base6">
                          <div className="detailed-job-text20">Text</div>
                        </div>
                        <div className="detailed-job-button-group-base7">
                          <div className="detailed-job-text20">Text</div>
                        </div>
                        <div className="detailed-job-button-group-base8">
                          <div className="detailed-job-text26">Lowest</div>
                        </div>
                      </div>
                      <div className="detailed-job-actions">
                        <div className="detailed-job-input-dropdown">
                          <div className="detailed-job-input-with-label1">
                            <div className="detailed-job-label">Search</div>
                            <div className="detailed-job-input1">
                              <div className="detailed-job-content5">
                                <img
                                  className="detailed-job-search-lg-icon"
                                  alt=""
                                  src={searchlg}
                                />

                                <div className="detailed-job-text27">Thomson Medical</div>
                                <div className="detailed-job-supporting-text9">@olivia</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="detailed-job-button">
                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={filterlines}
                          />

                          <div className="detailed-job-text-padding">
                            <div className="detailed-job-text28">Filters</div>
                          </div>
                          <img
                            className="detailed-job-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-review-container">
                    <div className="detailed-job-review-content">
                      <div className="detailed-job-first-review">
                        <b className="detailed-job-empty-review">5.0</b>
                        <div className="detailed-job-reviewer">
                          <div className="detailed-job-heading16">/5</div>
                        </div>
                      </div>
                      <div className="detailed-job-review-stars-background">
                        <img
                          className="detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-job-star-background-icon"
                          alt=""
                          src={star_background}
                        />
                      </div>
                    </div>
                    <div className="detailed-job-review-stars">
                      <div className="detailed-job-rectangle-parent1">
                        <div className="detailed-job-rectangle-div"></div>
                        <div className="detailed-job-stars">
                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-job-first-review-fifth-star">
                          <div className="detailed-job-heading17">Accuracy (5.0)</div>
                        </div>
                      </div>
                      <div className="detailed-job-rectangle-parent1">
                        <div className="detailed-job-rectangle-div"></div>
                        <div className="detailed-job-stars">
                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-job-heading-wrapper">
                          <div className="detailed-job-heading18">Relevance (5.0)</div>
                        </div>
                      </div>
                      <div className="detailed-job-rectangle-parent3">
                        <div className="detailed-job-rectangle-div"></div>
                        <div className="detailed-job-stars-wrapper">
                          <div className="detailed-job-stars2">
                            <img
                              className="detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-job-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="detailed-job-heading19">Value for Money (5.0)</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-divider-wrapper">
                    <img
                      className="detailed-job-divider-icon3"
                      alt=""
                      src={divider1}
                    />
                  </div>
                  <div className="detailed-job-client-review">
                    <img
                      className="detailed-job-client-review-child"
                      alt=""
                      src={group_78_2x}
                    />

                    <div className="detailed-job-client-review-header">
                      <div className="detailed-job-heading20">Chloe Tammy</div>
                      <div className="detailed-job-heading21">
                        <span>Marketing Communications at</span>
                        <span className="detailed-job-alvo-medical"> Alvo Medical</span>
                      </div>
                    </div>
                    <div className="detailed-job-client-review-content">
                      <div className="detailed-job-client-rating">
                        <div className="detailed-job-stars3">
                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-job-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-job-rating-value">
                          <div className="detailed-job-heading22">1 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-service-card">
                  <div className="detailed-job-card-content">
                    <div className="detailed-job-card-content-child"></div>
                    <div className="detailed-job-card-header">
                      <div className="detailed-job-price-tag">
                        <div className="detailed-job-badge-wrapper">
                          {/* <div className="detailed-job-badge3">
                            <img
                              className="detailed-job-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="detailed-job-text29">Sale</div>
                          </div> */}
                        </div>
                        <div className="detailed-job-price-parent">
                          <div className="detailed-job-price">${formatNumber(jobData.data.budget)}</div>
                          <div className="detailed-job-original-price">
                            <div className="detailed-job-discount">{jobData.data.tax_unit_measure_service.name}</div>
                          </div>
                        </div>
                        {/* <div className="detailed-job-service-title-container">
                          <div className="detailed-job-service-title">Posted By {jobData.data.tax_your_role.name}</div>
                        </div> */}
                      </div>
                    </div>
                    <div className="detailed-job-card-footer">
                      {jobData.data.logoImage ?
                        <img
                          className="detailed-job-card-footer-child"
                          alt=""
                          src={jobData.data.logoImage}
                        />
                        :
                        <img
                          className="detailed-job-card-footer-child"
                          alt=""
                          src={group_781_2x}
                        />
                      }

                      <div className="detailed-job-footer-divider">
                        <img
                          className="detailed-job-divider-icon4"
                          alt=""
                          src={divider2}
                        />
                      </div>
                    </div>
                    <div className="detailed-job-professional-info">
                      <div className="detailed-job-professional-details">
                        <div className="detailed-job-professional-actions">
                          <div className="detailed-job-benefits-container">
                            <div className="detailed-job-amlie-laurent">{jobData.data.job_business.business_user.displayName}</div>
                            <Link className="detailed-job-role" to={`/business/${jobData.data.job_business.id}/${jobData.data.job_business.businessName}`}>
                              {jobData.data.job_business.businessName}
                            </Link>
                            {/* <div className="detailed-job-role">{jobData.data.job_business.businessName}</div> */}
                          </div>
                          <div className="detailed-job-action-buttons">
                            <div className="detailed-job-contact-button">
                              <div className="detailed-job-message-button">
                                <div className="detailed-job-navigation">
                                  <img
                                    className="detailed-job-bookmark-check-icon"
                                    alt=""
                                    src={bookmarkcheck}
                                  />
                                </div>
                                <div className="detailed-job-supporting-text10">
                                  Verified by Gerätor
                                </div>
                              </div>
                            </div>
                            <div className="detailed-job-supporting-text11">
                              Adani Health conforms to Gerätor's requirements for
                              verification
                            </div>
                          </div>
                          <div className="detailed-job-favorite-button">
                            <div className="detailed-job-button-container2">
                              <div className="detailed-job-buttonsbutton5">
                                <img
                                  className="detailed-job-filter-lines-icon"
                                  alt=""
                                  src={messagedotscircle}
                                />

                                <div className="detailed-job-text30">Private Message</div>
                              </div>
                              {/* Add To Contact */}
                              {!jobData.data.isAuthor && (
                                (jobData.data.addedToContact) ?
                                  <div className="detailed-job-buttonsbutton6" onClick={() => handleRemoveContact(jobData.data.job_business.business_user.id)} style={{ cursor: 'pointer' }}>
                                    <img
                                      className="detailed-job-search-lg-icon"
                                      alt=""
                                      src={users01}
                                    />
                                  </div>
                                  :
                                  <div className="detailed-job-buttonsbutton6" onClick={() => handleAddContact(jobData.data.job_business.business_user.id)} style={{ cursor: 'pointer' }}>
                                    <img
                                      className="detailed-job-search-lg-icon"
                                      alt=""
                                      src={usercheck02}
                                    />
                                  </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-dropdown-menu">
                          <div className="detailed-job-dropdown1">
                            <img
                              className="detailed-job-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-rectangle-parent4">
                    <div className="detailed-job-frame-child6"></div>
                    <div className="detailed-job-testimonial-header">
                      <div className="detailed-job-header-content1">
                        <div className="detailed-job-heading23">Jobs</div>
                        <div className="detailed-job-testimonial-description">
                          <div className="detailed-job-description">View All</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-job-testimonial-list">
                      <img
                        className="detailed-job-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-job-testimonial-divider">
                        <div className="detailed-job-testimonial-item">
                          <div className="detailed-job-rectangle-parent5">
                            <div className="detailed-job-frame-child7"></div>
                            <img
                              className="detailed-job-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-job-testimonial-details">
                            <div className="detailed-job-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-job-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-job-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-job-testimonial-divider">
                        <div className="detailed-job-testimonial-item">
                          <div className="detailed-job-rectangle-parent5">
                            <div className="detailed-job-frame-child7"></div>
                            <img
                              className="detailed-job-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-job-testimonial-details">
                            <div className="detailed-job-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-job-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-job-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-job-testimonial-divider">
                        <div className="detailed-job-testimonial-item">
                          <div className="detailed-job-rectangle-parent5">
                            <div className="detailed-job-frame-child7"></div>
                            <img
                              className="detailed-job-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-job-testimonial-details">
                            <div className="detailed-job-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-job-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-job-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-job-testimonial-divider">
                        <div className="detailed-job-testimonial-item">
                          <div className="detailed-job-rectangle-parent5">
                            <div className="detailed-job-frame-child7"></div>
                            <img
                              className="detailed-job-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-job-testimonial-details">
                            <div className="detailed-job-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-job-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-job-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-job-testimonial-divider">
                        <div className="detailed-job-testimonial-item">
                          <div className="detailed-job-rectangle-parent5">
                            <div className="detailed-job-frame-child7"></div>
                            <img
                              className="detailed-job-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-job-testimonial-details">
                            <div className="detailed-job-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-job-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid">
            <div className="detailed-job-paragraph37">
              One of the best offers I found in the market
            </div>
          </div>
          <div className="detailed-job-listing-image-grid1">
            <div className="detailed-job-paragraph35">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.
            </div>
          </div>
          <div className="detailed-job-client-feedback">
            <div className="detailed-job-feedback-content">
              <img
                className="detailed-job-feedback-content-child"
                alt=""
                src={group_78_2x}
              />

              <div className="detailed-job-feedback-header">
                <div className="detailed-job-header-info">
                  <div className="detailed-job-heading20">Chloe Tammy</div>
                  <div className="detailed-job-heading21">
                    <span>Marketing Communications at</span>
                    <span className="detailed-job-alvo-medical"> Alvo Medical</span>
                  </div>
                </div>
              </div>
              <div className="detailed-job-feedback-rating">
                <div className="detailed-job-buttonsbutton7">
                  <img
                    className="detailed-job-filter-lines-icon"
                    alt=""
                    src={messagedotscircle}
                  />

                  <div className="detailed-job-text35">Reply</div>
                </div>
                <div className="detailed-job-feedback-score">
                  <div className="detailed-job-stars-parent">
                    <div className="detailed-job-stars">
                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                    <div className="detailed-job-rating-value">
                      <div className="detailed-job-heading22">1 month ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-job-client-message">
            <div className="detailed-job-message-content">
              <div className="detailed-job-paragraph39">
                One of the best offers I found in the market
              </div>
              <div className="detailed-job-paragraph26">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
          <div className="detailed-job-view-replies">
            <div className="detailed-job-buttonsbutton8">
              <img
                className="detailed-job-filter-lines-icon"
                alt=""
                src={messagedotscircle}
              />

              <div className="detailed-job-text35">Reply</div>
            </div>
          </div>
          <div className="detailed-job-pagination-wrapper">
            <div className="detailed-job-pagination">
              <div className="detailed-job-button-wrap">
                <div className="detailed-job-buttonsbutton9">
                  <img
                    className="detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="detailed-job-text-padding">
                    <div className="detailed-job-page-number">Previous</div>
                  </div>
                  <img
                    className="detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />
                </div>
              </div>
              <div className="detailed-job-details">1 of 10</div>
              <div className="detailed-job-button-wrap1">
                <div className="detailed-job-buttonsbutton10">
                  <img
                    className="detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="detailed-job-text-padding">
                    <div className="detailed-job-text37">Next</div>
                  </div>
                  <img
                    className="detailed-job-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid1">
            <img className="detailed-job-divider-icon3" alt="" src={divider1} />
          </div>
          <div className="detailed-job-review-form-wrapper">
            <div className="detailed-job-review-form">
              <div className="detailed-job-review-form-child"></div>
              <div className="detailed-job-breadcrumb-container-parent">
                <div className="detailed-job-heading32">Write Review</div>
                <div className="detailed-job-frame-parent3">
                  <div className="detailed-job-heading-container">
                    <div className="detailed-job-heading33">Accuracy</div>
                    <div className="detailed-job-listing-detail-item-date-parent">
                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                  <div className="detailed-job-heading-container">
                    <div className="detailed-job-heading33">Relevance</div>
                    <div className="detailed-job-listing-detail-item-date-parent">
                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                  <div className="detailed-job-heading-container">
                    <div className="detailed-job-heading35">Value for Money</div>
                    <div className="detailed-job-listing-detail-item-date-parent">
                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-job-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                </div>
                <div className="detailed-job-input-field">
                  <div className="detailed-job-input-with-label1">
                    <div className="detailed-job-label2">Title</div>
                    <div className="detailed-job-content6">
                      <div className="detailed-job-textarea-input-field">
                        <div className="detailed-job-input-with-label3">
                          <div className="detailed-job-label3">Description</div>
                          <div className="detailed-job-input2">
                            <div className="detailed-job-text38">70 characters</div>
                          </div>
                        </div>
                        <div className="detailed-job-hint-text1">10 characters left</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-hint-text">This is a hint text to help user.</div>
                </div>
                <div className="detailed-job-input-field1">
                  <div className="detailed-job-input-with-label1">
                    <div className="detailed-job-label4">Review</div>
                    <div className="detailed-job-content6">
                      <div className="detailed-job-textarea-input-field1">
                        <div className="detailed-job-input-with-label3">
                          <div className="detailed-job-label3">Description</div>
                          <div className="detailed-job-input3">
                            <div className="detailed-job-text39">1000 characters</div>
                          </div>
                        </div>
                        <div className="detailed-job-hint-text3">40 characters left</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-hint-text">This is a hint text to help user.</div>
                </div>
              </div>
              <div className="detailed-job-submit-button-wrapper">
                <div className="detailed-job-button1">
                  <img
                    className="detailed-job-chevron-down-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <img className="detailed-job-log-in-04-icon" alt="" src={login04} />

                  <div className="detailed-job-navigation">
                    <div className="detailed-job-text40">Submit</div>
                  </div>
                  <img
                    className="detailed-job-chevron-down-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid3">
            <img className="detailed-job-divider-icon11" alt="" src="/detailed_listing/divider3.svg" />
          </div>
          <div className="detailed-job-listing-image-grid4">
            <div className="detailed-job-heading-parent3">
              <div className="detailed-job-heading36">Related Devices</div>
              <div className="detailed-job-text-wrapper">
                <div className="detailed-job-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid5">
            <div className="detailed-job-frame-parent4">
              <div className="detailed-job-frame-parent5">
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay3">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-badge-group">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton14">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-work-four-wrapper">
                        <div className="detailed-job-heading-container">
                          <div className="detailed-job-listing-detail-item-location">
                            <div className="detailed-job-listing-detail-item-date-parent">
                              <div className="detailed-job-listing-detail-item-date">
                                <img
                                  className="detailed-job-chevron-right-icon2"
                                  alt=""
                                  src={markerpin021}
                                />
                              </div>
                              <div className="detailed-job-location">India, New Delhi</div>
                            </div>
                            <div className="detailed-job-listing-detail-item-date-parent">
                              <div className="detailed-job-listing-detail-item-date">
                                <img
                                  className="detailed-job-chevron-right-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="detailed-job-type-of-work">April 09, 2023</div>
                            </div>
                          </div>
                          <div className="detailed-job-listing-team">
                            <div className="detailed-job-listing-team-member-wrapper">
                              <div className="detailed-job-listing-team-member">4.9/5</div>
                            </div>
                            <div className="detailed-job-contact-role">
                              <div className="detailed-job-supporting-text12">99,999 reviews</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-job-agent-details-icon">
                        <img className="detailed-job-icon4" alt="" src={icon1} />

                        <div className="detailed-job-agent-extra-info">
                          <div className="detailed-job-listing-detail-item-pin-wrapper">
                            <div className="detailed-job-breadcrumb-items">
                              <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-job-supporting-text-wrapper">
                                <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-job-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-job-another-agent-info">
                            <div className="detailed-job-detail-items">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-testiomonial-carousel-arrow2">
                  <img
                    className="detailed-job-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-job-testiomonial-carousel-arrow3">
                <img
                  className="detailed-job-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid4">
            <div className="detailed-job-heading-parent3">
              <div className="detailed-job-heading36">Related Spares</div>
              <div className="detailed-job-testimonial-description">
                <div className="detailed-job-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid5">
            <div className="detailed-job-frame-parent4">
              <div className="detailed-job-frame-parent5">
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay3">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-badge-group">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton14">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-work-four-wrapper">
                        <div className="detailed-job-heading-container">
                          <div className="detailed-job-listing-detail-item-location">
                            <div className="detailed-job-listing-detail-item-date-parent">
                              <div className="detailed-job-listing-detail-item-date">
                                <img
                                  className="detailed-job-chevron-right-icon2"
                                  alt=""
                                  src={markerpin021}
                                />
                              </div>
                              <div className="detailed-job-location">India, New Delhi</div>
                            </div>
                            <div className="detailed-job-listing-detail-item-date-parent">
                              <div className="detailed-job-listing-detail-item-date">
                                <img
                                  className="detailed-job-chevron-right-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="detailed-job-type-of-work">April 09, 2023</div>
                            </div>
                          </div>
                          <div className="detailed-job-listing-team">
                            <div className="detailed-job-listing-team-member-wrapper">
                              <div className="detailed-job-listing-team-member">4.9/5</div>
                            </div>
                            <div className="detailed-job-contact-role">
                              <div className="detailed-job-supporting-text12">99,999 reviews</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-job-agent-details-icon">
                        <img className="detailed-job-icon4" alt="" src={icon1} />

                        <div className="detailed-job-agent-extra-info">
                          <div className="detailed-job-listing-detail-item-pin-wrapper">
                            <div className="detailed-job-breadcrumb-items">
                              <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-job-supporting-text-wrapper">
                                <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-job-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-job-another-agent-info">
                            <div className="detailed-job-detail-items">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-testiomonial-carousel-arrow4">
                  <img
                    className="detailed-job-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-job-testiomonial-carousel-arrow5">
                <img
                  className="detailed-job-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid4">
            <div className="detailed-job-heading-parent3">
              <div className="detailed-job-heading38">Related Services</div>
              <div className="detailed-job-testimonial-description">
                <div className="detailed-job-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid5">
            <div className="detailed-job-frame-parent4">
              <div className="detailed-job-frame-parent5">
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-listing-card-badge-overlay">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-rectangle-parent30">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay3">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-badge-group">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton14">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-icon-group">
                        <img className="detailed-job-icon4" alt="" src={icon1} />

                        <div className="detailed-job-message-content">
                          <div className="detailed-job-listing-detail-item-pin-wrapper">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-supporting-work-five">
                            <div className="detailed-job-breadcrumb-items">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-supporting-six-wrapper">
                            <div className="detailed-job-breadcrumb-items">
                              <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-job-supporting-text-wrapper">
                                <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-divider-container">
                            <img
                              className="detailed-job-divider-icon3"
                              alt=""
                              src={divider4}
                            />
                          </div>
                          <div className="detailed-job-team-info-four">
                            <div className="detailed-job-detail-items">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-testiomonial-carousel-arrow6">
                  <img
                    className="detailed-job-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-job-testiomonial-carousel-arrow7">
                <img
                  className="detailed-job-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>

          <img className="detailed-job-divider-icon24" alt="" src="/detailed_listing/divider5.svg" />

          {/* <div className="detailed-job-actions1">
          <div className="detailed-job-button2">
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
  
            <div className="detailed-job-text-padding4">
              <div className="detailed-job-text98">Tertiary</div>
            </div>
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
          </div>
          <div className="detailed-job-button3">
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
  
            <div className="detailed-job-text-padding5">
              <div className="detailed-job-text98">Secondary</div>
            </div>
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
          </div>
          <div className="detailed-job-button4">
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
  
            <div className="detailed-job-text-padding5">
              <div className="detailed-job-text98">Secondary</div>
            </div>
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="detailed-job-button5">
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
  
            <div className="detailed-job-text-padding7">
              <div className="detailed-job-text98">Primary</div>
            </div>
            <img
              className="detailed-job-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
          </div>
        </div> */}
          <div className="detailed-job-listing-image-grid4">
            <div className="detailed-job-heading-parent3">
              <div className="detailed-job-heading36">Related Software</div>
              <div className="detailed-job-testimonial-description">
                <div className="detailed-job-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-job-listing-image-grid11">
            <div className="detailed-job-frame-parent4">
              <div className="detailed-job-frame-parent5">
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-rectangle-parent30">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-rectangle-parent30">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-rectangle-parent30">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-short-listing-team">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton11">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-listing-detail-item-value">
                        <div className="detailed-job-listing-detail-item-pin-wrapper">
                          <div className="detailed-job-heading-container">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-team">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-job-listing">
                          <div className="detailed-job-breadcrumb-items">
                            <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-job-supporting-text-wrapper">
                              <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-job-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-job-listing-detail-item-value-inner">
                          <div className="detailed-job-footer-social-parent">
                            <div className="detailed-job-footer-social">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-job-listing-short-detail-item-date">
                              <img
                                className="detailed-job-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-rectangle-parent10">
                  <div className="detailed-job-frame-child12"></div>
                  <div className="detailed-job-rectangle-parent30">
                    <img
                      className="detailed-job-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-job-image-overlay3">
                      <div className="detailed-job-badge4">
                        <div className="detailed-job-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-job-badge-group">
                        <div className="detailed-job-badge5">
                          <div className="detailed-job-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-job-buttonsbutton14">
                          <img
                            className="detailed-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-job-listing-detail-item">
                    <div className="detailed-job-message-content">
                      <div className="detailed-job-text-frame">
                        <div className="detailed-job-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-job-icon-group">
                        <img className="detailed-job-icon4" alt="" src={icon1} />

                        <div className="detailed-job-message-content">
                          <div className="detailed-job-listing-detail-item-pin-wrapper">
                            <div className="detailed-job-listing-detail-item-location">
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-job-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-job-listing-detail-item-date-parent">
                                <div className="detailed-job-listing-detail-item-date">
                                  <img
                                    className="detailed-job-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-job-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-supporting-work-five">
                            <div className="detailed-job-breadcrumb-items">
                              <div className="detailed-job-listing-team-member-wrapper">
                                <div className="detailed-job-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-job-contact-role">
                                <div className="detailed-job-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-supporting-six-wrapper">
                            <div className="detailed-job-breadcrumb-items">
                              <div className="detailed-job-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-job-supporting-text-wrapper">
                                <div className="detailed-job-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-job-divider-container">
                            <img
                              className="detailed-job-divider-icon3"
                              alt=""
                              src={divider4}
                            />
                          </div>
                          <div className="detailed-job-team-info-four">
                            <div className="detailed-job-detail-items">
                              <img
                                className="detailed-job-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-job-listing-short-detail-item-pin-parent">
                                <div className="detailed-job-listing-short-detail-item-pin">
                                  <div className="detailed-job-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-job-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-job-testiomonial-carousel-arrow8">
                  <img
                    className="detailed-job-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-job-testiomonial-carousel-arrow9">
                <img
                  className="detailed-job-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          <FooterDetailPage />
        </div>
      </div>
    )
  }
  else {
    return <Loader />;
  }

}

