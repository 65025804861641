import React from 'react';
// import "./AddBusinessForm.css"
import React, { useContext, useRef, useState, useEffect } from "react";
import { Formik, Form, useField, useFormikContext, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
import * as Yup from "yup";
import { useEffect } from 'react';
import { useAddBusinessMutation } from '../../api/BusinessAPI';
import AddBusinessFormContext from '../../screens/Business/AddBusiness/AddBusinessContext';
import { useAddBusinessMutation } from '../../api/BusinessAPI';
import { objectToBusinessFormData } from '../../helper/BusinessHelper';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';

import wysiwyg_editor_icon from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon.svg';
import wysiwyg_editor_icon_1 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-1.svg';
import wysiwyg_editor_icon1 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon1.svg';

import wysiwyg_editor_icon2 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon2.svg';
import wysiwyg_editor_icon_2 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-2.svg';

import wysiwyg_editor_icon_3 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-3.svg';
import wysiwyg_editor_icon_4 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-4.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import featured_icon from '../../icons/Gerator/profile_settings/featured-icon.svg';
import divider from '../../icons/Gerator/profile_settings/divider.svg';
import divider from '../../icons/Gerator/profile_settings/divider.svg';
import placeholder1 from '../../icons/Gerator/profile_settings/placeholder1.svg';
import placeholder3 from '../../icons/Gerator/profile_settings/placeholder3.svg';

import chevrondown from '../../icons/Gerator/profile_settings/chevrondown.svg';
import AddBusinessImageCrop from '../ImageCropComponent/AddBusinessImageCrop';
import AddBusinessBannerImageCrop from '../ImageCropComponent/AddBusinessBannerImageCrop';

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="business-form-section-label1">
        <label className="business-form-title" htmlFor={props.name}>
          {label} <span className="business-form-span">*</span>
        </label>
      </div>
      <div className="business-form-input-dropdown1">
        <div className="business-form-textarea-input-field">

          <div className="business-form-input8">
            <div className="business-form-content29">

              <select
                className="add-job-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>
        </div>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field">{meta.error}</div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="error-for-all-field">{fieldValidationData.msg}</div>
        ) : null}
      </div>








    </>
  );
};

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }



  return (
    <>
      {/* <label className="label">Model Number</label> */}
      {/* <div className="business-form-title">Business Genre <span className="business-form-span">*</span> </div> */}

      <div className="business-form-section-label1">
        <label className="business-form-title" htmlFor={props.id || props.name}>
          {label}<span className="business-form-span">*</span>
        </label>
      </div>
      <div className="business-form-input-field1">
        <div className="business-form-textarea-input-field">
          <div className="business-form-input4">
            <div className="business-form-content19">

              <input
                className="business-form-extra-input-info"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}

              />
            </div>

          </div>
        </div>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field">{meta.error}</div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="error-for-all-field">{fieldValidationData.msg}</div>
        ) : null}

      </div>
    </>
  );
};
const MyTextInputNotMandatory = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }



  return (
    <>
      {/* <label className="label">Model Number</label> */}
      {/* <div className="business-form-title">Business Genre <span className="business-form-span">*</span> </div> */}

      <div className="business-form-section-label1">
        <label className="business-form-title" htmlFor={props.id || props.name}>
          {label}
        </label>
      </div>
      <div className="business-form-input-field1">
        <div className="business-form-textarea-input-field">
          <div className="business-form-input4">
            <div className="business-form-content19">

              <input
                className="business-form-extra-input-info"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}

              />
            </div>

          </div>
        </div>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field">{meta.error}</div>
        ) : null}

        {fieldValidationData !== null ? (
          <div className="error-for-all-field">{fieldValidationData.msg}</div>
        ) : null}

      </div>
    </>
  );
};


const FileUpload = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "logoImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="business-form-content44">
        <div className="business-form-section-label1">
          <div className="business-form-title">Logo <span className="business-form-span">*</span></div>
          <div className="business-form-supporting-text4">
          </div>
        </div>
        <div className="business-form-file-upload">
          <label htmlFor="logoImage">
            <div className="business-form-file-upload-base1">
              <div className="business-form-content45">
                <img
                  className="business-form-logod-icon"
                  alt=""
                  src={featured_icon}
                />

                <div className="business-form-text-and-supporting-text1">
                  <div className="business-form-action business-form-action-custom">
                    <div className="business-form-buttonsbutton1">
                      <img
                        className="business-form-placeholder-icon"
                        alt=""
                        src={placeholder1}
                      />

                      <div className="business-form-text54 documentLabel" style={{ cursor: "pointer" }}>Click to upload</div>
                      <input type="file" accept="image/*" {...field} {...props} className='hide-input-field' />
                      <img
                        className="business-form-placeholder-icon"
                        alt=""
                        src={placeholder1}
                      />
                    </div>
                    <div className="action custom-action">

                      <div className="business-form-text55">or drag and drop</div>
                    </div>
                  </div>
                  <div className="business-form-supporting-text5">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </div>
                </div>
              </div>
            </div>
          </label>
          {(meta.touched && meta.error) ? (
            <div className="error-for-all-field error-for-document">{meta.error}</div>
          ) : null}

          {fieldValidationData !== "" ? (
            <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
          ) : null}
        </div>
      </div>


    </div>
  );
};

const FileUploadBanner = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "bannerImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="business-form-content44">
        <div className="business-form-section-label1">
          <div className="business-form-title">Banner <span className="business-form-span">*</span></div>
          <div className="business-form-supporting-text4">
          </div>
        </div>
        <div className="business-form-file-upload">
          <label htmlFor="bannerImage">
            <div className="business-form-file-upload-base1">
              <div className="business-form-content45">
                <img
                  className="business-form-logod-icon"
                  alt=""
                  src={featured_icon}
                />

                <div className="business-form-text-and-supporting-text1">
                  <div className="business-form-action business-form-action-custom">
                    <div className="business-form-buttonsbutton1">
                      <img
                        className="business-form-placeholder-icon"
                        alt=""
                        src={placeholder1}
                      />

                      <div className="business-form-text54 documentLabel" style={{ cursor: "pointer" }}>Click to upload</div>
                      <input type="file" accept="image/*" {...field} {...props} className='hide-input-field' />
                      <img
                        className="business-form-placeholder-icon"
                        alt=""
                        src={placeholder1}
                      />
                    </div>
                    <div className="action custom-action">

                      <div className="business-form-text55">or drag and drop</div>
                    </div>
                  </div>
                  <div className="business-form-supporting-text5">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </div>
                </div>
              </div>
            </div>
          </label>
          {(meta.touched && meta.error) ? (
            <div className="error-for-all-field error-for-document">{meta.error}</div>
          ) : null}

          {fieldValidationData !== "" ? (
            <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
          ) : null}
        </div>
      </div>


    </div>
  );
};

const MyTextArea = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="business-form-section-label1">
        <label className="business-form-title" htmlFor={props.id || props.name}>
          {label} <span className="business-form-span">*</span>
        </label>
      </div>
      {/* <label className="label">Model Number</label> */}
      <div className="business-form-toolbar-and-text-input">
        <div className="business-form-wysiwyg-toolbar">
          <div className="business-form-input-dropdown8">


          </div>

        </div>
        <div className="business-form-textarea-input-field">
          <div className="business-form-textarea-input-field">
            <div className="business-form-label16">Description</div>
            <div className="business-form-input16">
              {/* <div className="business-form-text68">

              </div> */}
              <textarea
                className="add-service-step3-text82"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}

              />
            </div>
          </div>
          <div className="business-form-supporting-text4">
            {(meta.touched && meta.error) ? (
              <div className="error-for-all-field">{meta.error}</div>
            ) : null}

            {fieldValidationData !== null ? (
              <div className="error-for-all-field">{fieldValidationData.msg}</div>
            ) : null}
          </div>
        </div>
      </div>





    </>
  );
};

const MyTextAreaWithMenu = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="business-form-section-label1">
        <label className="business-form-title" htmlFor={props.id || props.name}>
          {label} <span className="business-form-span">*</span>
        </label>
      </div>
      {/* <label className="label">Model Number</label> */}
      <div className="business-form-toolbar-and-text-input">
        <div className="business-form-wysiwyg-toolbar">
          <div className="business-form-input-dropdown8">
            {/* <div className="business-form-input-with-label1">
              <div className="business-form-input15">
                <div className="business-form-content43">
                  <div className="business-form-dropdown-placeholder">
                    Regular
                  </div>

                  <div className="">@olivia</div>
                </div>
                <img
                  className="business-form-search-lg-icon"
                  alt=""
                  src={chevrondown}
                  />
              </div>
            </div> */}

          </div>
          {/* <div className="business-form-icons">
            <img
              className="business-form-wysiwyg-editor-icon"
              loading="lazy"
              alt=""
              src={wysiwyg_editor_icon}
            />

            <img
              className="business-form-wysiwyg-editor-icon"
              alt=""
              src={wysiwyg_editor_icon_1}
            />

            <img
              className="business-form-wysiwyg-editor-icon2"
              alt=""
              src={wysiwyg_editor_icon1}
            />

            <img
              className="business-form-wysiwyg-editor-icon2"
              alt=""
              src={wysiwyg_editor_icon2}
            />

            <img
              className="business-form-wysiwyg-editor-icon2"
              alt=""
              src={wysiwyg_editor_icon_3}
            />

            <img
              className="business-form-wysiwyg-editor-icon"
              alt=""
              src={wysiwyg_editor_icon_2}
            />

            <img
              className="business-form-wysiwyg-editor-icon2"
              alt=""
              src={wysiwyg_editor_icon_4}
            />

            <img
              className="business-form-wysiwyg-editor-icon"
              alt=""
              src={wysiwyg_editor_icon_3}
            />

            <img
              className="business-form-wysiwyg-editor-icon"
              alt=""
              src={wysiwyg_editor_icon_4}
            />
          </div> */}
        </div>
        <div className="business-form-textarea-input-field">
          <div className="business-form-textarea-input-field">
            <div className="business-form-label16">Description</div>
            <div className="business-form-input16">
              {/* <div className="business-form-text68">

              </div> */}
              <textarea
                className="add-service-step3-text82"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}

              />
            </div>
          </div>
          <div className="business-form-supporting-text4">
            {(meta.touched && meta.error) ? (
              <div className="error-for-all-field">{meta.error}</div>
            ) : null}

            {fieldValidationData !== null ? (
              <div className="error-for-all-field">{fieldValidationData.msg}</div>
            ) : null}
          </div>
        </div>
      </div>





    </>
  );
};




export const AddBusinessForm = () => {

  //This hook will store error message of featureImage 
  const [imageSingleCheckboxError, setImageSingleCheckboxError] = useState("");
  //This hook will store error message of bannerImage 
  const [bannerSingleCheckboxError, setbannerSingleCheckboxError] = useState("");
  //This hook will store image file if uploaded image have some validation error on FeatureImage 
  const [logoImageDemoFile, setLogoImageDemoFile] = useState("");
  //This hook will store image file if uploaded image have some validation error on BannerImage 
  const [bannerImageDemoFile, setBannerImageDemoFile] = useState("");


  const { allTaxonomy, backendValidation, setBackendValidation, selectedBannerImageFile, setSelectedBannerImageFile, selectedImageFile, setSelectedImageFile, cropedImageFile, setCropedImageFile, cropedBannerImageFile, setCropedBannerImageFile } = useContext(AddBusinessFormContext)

  const { values, validateForm, setFieldValue, setFieldError, setErrors, setFieldTouched, validateField, errors, isValid, dirty, touched, handleSubmit, isSubmitting } = useFormikContext();



  /* 
    This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
*/
  useEffect(() => {
    console.log("Values useEffect Open");

    if (touched.logoImage) {
      if (errors.logoImage && values.logoImage !== "") {
        console.log("Values Error UseEffect");
        handleSelectedImageFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.logoImage && values.logoImage != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedImageFile("no-error");
      }
    }

    console.log("Values useEffect Closed ");
    // }

  }, [values.logoImage, errors.logoImage]);


  /* 
    This useEffect will provide updated values from formik and run some conditions Used on Gallery Image
*/
  useEffect(() => {
    console.log("Values useEffect Open");

    //BELOWE CODE IS FOR GALLERY IMAGES
    if (touched.bannerImage) {
      if (errors.bannerImage && values.bannerImage !== "") {
        console.log("Values Error UseEffect");
        handleSelectedBannerImageFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.bannerImage && values.bannerImage != "") {
        console.log("Values Touch UseEffect");
        handleSelectedBannerImageFile("no-error");
      }
    }

    console.log("Values useEffect Closed ");


  }, [values.bannerImage, errors.bannerImage]);

  //onChange handle method for logoImage where populating image
  const handleSelectedImageFile = async (type) => {

    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("logoImage", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.logoImage) {
      console.log("Inside errors.gallery is true means error found ");
      if (cropedImageFile !== '') setCropedImageFile('')
      setSelectedImageFile('');
      setLogoImageDemoFile(values.logoImage);
      setImageSingleCheckboxError(errors.logoImage);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    setLogoImageDemoFile('');
    setImageSingleCheckboxError('');
    if (cropedImageFile === '') setSelectedImageFile(values.logoImage);
    else {
      await setCropedImageFile('');
      await setSelectedImageFile(values.logoImage);
    }
    console.log("File added into select multi image hook");

  }



  //onClick handle method For logoImage remove
  const handleImageCheckbox = async (e) => {

    //If croped Image hook has some data then clean up 
    if (cropedImageFile !== '') setCropedImageFile('');
    //If errors.logoImage has some errors then filter then set logoImage to "", clear other field as well
    if (errors.logoImage) {
      console.log(errors.logoImage);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'logoImage') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("logoImage", '');
      setErrors(filteredErrors)
      setLogoImageDemoFile('');
      setImageSingleCheckboxError('');
    } else {

      await setFieldValue("logoImage", '');
      setLogoImageDemoFile('');
      setImageSingleCheckboxError('');
    }
  }

  //onChange handle method for logoImage where populating image
  const handleSelectedBannerImageFile = async (type) => {
    //If croped Image hook has some data then clean up 
    if (cropedBannerImageFile !== '') setCropedBannerImageFile('');
    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("bannerImage", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.bannerImage) {
      console.log("Inside errors.gallery is true means error found ");
      if (cropedBannerImageFile !== '') setCropedBannerImageFile('')
      setSelectedBannerImageFile('');
      setBannerImageDemoFile(values.bannerImage);
      setbannerSingleCheckboxError(errors.bannerImage);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    setBannerImageDemoFile('');
    setbannerSingleCheckboxError('');
    if (cropedBannerImageFile === '') setSelectedBannerImageFile(values.bannerImage);
    // console.log("File added into select multi image hook");

  }



  //onClick handle method For logoImage remove
  const handleBannerImageCheckbox = async (e) => {
    //If croped Image hook has some data then clean up 
    if (cropedBannerImageFile !== '') setCropedBannerImageFile('');
    //If errors.logoImage has some errors then filter then set logoImage to "", clear other field as well
    if (errors.bannerImage) {
      console.log(errors.bannerImage);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'bannerImage') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("bannerImage", '');
      setErrors(filteredErrors)
      setBannerImageDemoFile('');
      setbannerSingleCheckboxError('');
    } else {

      await setFieldValue("bannerImage", '');
      setBannerImageDemoFile('');
      setbannerSingleCheckboxError('');
    }
  }

  //This handle method used to capture input entered text and save them into redux state
  const handleChange = async (event) => {
    const { name, value } = event.target;
    // console.log("handle change called");
    // console.log(name);
    if (name === 'logoImage') {
      const file = event.target.files[0];
      // console.log('\x1b[36m%s\x1b[0m', "inside handle change called");
      setFieldValue(name, file);
      setFieldTouched("logoImage", true);
      return;
    }

    if (name === 'bannerImage') {
      const file = event.target.files[0];
      // console.log('\x1b[36m%s\x1b[0m', "inside handle change called");
      setFieldValue(name, file);
      setFieldTouched("bannerImage", true);
      return;
    }

    // console.log("handle change closed");

    setFieldValue(name, value);

  };

  return (
    // <form className="add-wanted-device-step-section-header-parent">

    <>
      <div className="business-form-form-content-wrapper">
        <div className="business-form-form-content">
          <div className="business-form-toggle-header-parent">
            <div className="business-form-toggle-header">
              <div className="business-form-toggle-title">Launch My Business</div>
              <div className="business-form-supporting-text2">
                Equipment management doesn't have to eat your lunch break! 🥪
              </div>

            </div>

          </div>
          <div className="business-form-divider-container">
            <img
              className="business-form-divider-icon3"
              alt=""
              src={divider2}
            />
          </div>


          <div className="business-form-content18">

            <MyTextInput
              label="Business Name "
              name="businessName"
              type="text"
              placeholder="Enter business name"
              id="businessName"
              onChange={handleChange} validate={backendValidation}
            />
            <div className="business-form-hint-text2">
              Cannot be edited later
            </div>
          </div>
          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MyTextInput
              label="Website"
              name="businessWebsite"
              type="text"
              placeholder="Enter business name"
              id="businessWebsite"
              onChange={handleChange} validate={backendValidation}
            />
          </div>
          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MySelect label="Business Genre" onChange={handleChange} validate={backendValidation} name="businessGenre" id="businessGenre" >
              <option value="0">Select From Dropdown list </option>
              {(allTaxonomy.businessGenre?.isLoading || allTaxonomy.businessGenre?.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.businessGenre?.isSuccess) && (allTaxonomy.businessGenre?.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }

            </MySelect>
          </div>

          <div className="business-form-divider"></div>

          <div className="business-form-content18">
            <MySelect label="Ownership" onChange={handleChange} validate={backendValidation} name="businessOwnership" id="businessOwnership" >
              <option value="0">Select From Dropdown list </option>
              {(allTaxonomy.ownership?.isLoading || allTaxonomy.ownership?.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.ownership?.isSuccess) && (allTaxonomy.ownership?.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }

            </MySelect>
          </div>

          <div className="business-form-divider"></div>

          <div className="business-form-content18">
            <MySelect label="Business Size" onChange={handleChange} validate={backendValidation} name="businessSize" id="businessSize" >
              <option value="0">Select From Dropdown list </option>
              {(allTaxonomy.businessSize?.isLoading || allTaxonomy.businessSize?.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.businessSize?.isSuccess) && (allTaxonomy.businessSize?.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }

            </MySelect>
          </div>

          <div className="business-form-divider"></div>

          <div className="business-form-content18">
            <MyTextArea
              label="Expert Highlights"
              name="businessExpertiseHighlights"
              type="text"
              placeholder="Enter expertise"
              id="businessExpertiseHighlights"
              onChange={handleChange} validate={backendValidation}
            />
            <div className="business-form-hint-text3">
              What are you best at?
            </div>
          </div>

          <div className="business-form-divider"></div>

          <div className="business-form-content18">
            <MyTextAreaWithMenu
              label="Expertise Description"
              name="businessExpertiseDescription"                                                          //New Code
              type="textarea"
              placeholder="500 characters. Eg: Siemens MAGNETOM Essenza 1.5 T MRI System. Separate entries with comma."
              id="businessExpertiseDescription"
              onChange={handleChange}
              validate={backendValidation}

            />
            <div className="business-form-hint-text4">
              Details of your achievements
            </div>

          </div>

          <div className="business-form-toggle-header-parent">
            <div className="business-form-toggle-header">
              <div className="business-form-toggle-title1"> Business Location</div>
            </div>
          </div>

          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MySelect label="Country" onChange={handleChange} validate={backendValidation} name="businessCountry" id="businessCountry" >
              <option value="0">Select From Dropdown list </option>
              {(allTaxonomy.country?.isLoading || allTaxonomy.country?.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.country?.isSuccess) && (allTaxonomy.country?.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }

            </MySelect>
          </div>


          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MyTextInput
              label="Building"
              name="businessBuilding"
              type="text"
              placeholder="Enter Building"
              id="businessBuilding"
              onChange={handleChange} validate={backendValidation}
            />
          </div>

          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MyTextInput
              label="Street"
              name="businessStreet"
              type="text"
              placeholder="Enter Street"
              id="businessStreet"
              onChange={handleChange} validate={backendValidation}
            />
          </div>

          <div className="business-form-divider"></div>

          <div className="business-form-content18">
            <MyTextInputNotMandatory
              label="State"
              name="businessState"
              type="text"
              placeholder="Enter State"
              id="businessState"
              onChange={handleChange} validate={backendValidation}
            />
          </div>

          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MyTextInput
              label="Town / City"
              name="businessTownCity"
              type="text"
              placeholder="Enter Town/City"
              id="businessTownCity"
              onChange={handleChange} validate={backendValidation}
            />
          </div>


          <div className="business-form-divider"></div>

          <div className="business-form-content18">
            <MyTextInputNotMandatory
              label="District"
              name="businessDistrict"
              type="text"
              placeholder="Enter District"
              id="businessDistrict"
              onChange={handleChange} validate={backendValidation}
            />
          </div>

          <div className="business-form-divider"></div>
          <div className="business-form-content18">
            <MyTextInput
              label="Postcode / ZIP"
              name="businessPostcodeZip"
              type="text"
              placeholder="Enter Postcode / Zip"
              id="businessPostcodeZip"
              onChange={handleChange} validate={backendValidation}
            />
          </div>


          <div className="business-form-toggle-header-parent">
            <div className="business-form-toggle-header">
              <div className="business-form-toggle-title1"> Business Profile </div>

            </div>

          </div>


          <div className="business-form-divider"></div>






        </div>
      </div>

      <div className="business-form-content-wrapper">

        <FileUpload key={selectedImageFile ? selectedImageFile.name : Date.now()} name="logoImage" id="logoImage" validate={backendValidation} onChange={handleChange} value={undefined} />
        {(selectedImageFile instanceof File) && (
          <AddBusinessImageCrop />
        )}
        {
          (typeof cropedImageFile === 'object' && cropedImageFile !== "") &&

          (
            <div className="add-service-step-file-upload-item-base">
              <div className="add-service-step-first-upload-item-content">
                <div className="add-service-step-file-type-icon">
                  <img
                    className="add-service-step-page-icon"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step-file-type-wrap">
                    <b className="add-service-step-file-type">IMG</b>
                  </div>
                </div>
                <div className="add-service-step-first-upload-item-info">
                  <div className="add-service-step-first-upload-item-inner">
                    <div className="add-service-step-first-upload-item">
                      {cropedImageFile.name}
                    </div>
                    <div className="add-service-step3-checkbox3">
                      <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text14">{(cropedImageFile.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                {(imageSingleCheckboxError !== "") ?
                  <div style={{ color: "red" }}>
                    {imageSingleCheckboxError}
                  </div> :
                  <div className="add-service-step3-percentage3">100%</div>
                }
              </div>

            </div>
          )
        }
        {
          (logoImageDemoFile instanceof File) &&

          (
            <div className="add-service-step-file-upload-item-base">
              <div className="add-service-step-first-upload-item-content">
                <div className="add-service-step-file-type-icon">
                  <img
                    className="add-service-step-page-icon"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step-file-type-wrap">
                    <b className="add-service-step-file-type">IMG</b>
                  </div>
                </div>
                <div className="add-service-step-first-upload-item-info">
                  <div className="add-service-step-first-upload-item-inner">
                    <div className="add-service-step-first-upload-item">
                      {logoImageDemoFile.name}
                    </div>
                    <div className="add-service-step3-checkbox3">
                      <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text14">{(logoImageDemoFile.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                {(imageSingleCheckboxError !== "") ?
                  <div style={{ color: "red" }}>
                    {imageSingleCheckboxError}
                  </div> :
                  <div className="add-service-step3-percentage3">100%</div>
                }
              </div>

            </div>
          )
        }
      </div>

      <div className="business-form-divider"></div>

      <div className="business-form-content-wrapper">
        <FileUploadBanner key={selectedBannerImageFile ? selectedBannerImageFile.name : Date.now()} name="bannerImage" id="bannerImage" validate={backendValidation} onChange={handleChange} value={undefined} />
        {(selectedBannerImageFile instanceof File) && (
          <AddBusinessBannerImageCrop />
        )}

        {
          (typeof cropedBannerImageFile === 'object' && cropedBannerImageFile !== "") &&

          (
            <div className="add-service-step-file-upload-item-base">
              <div className="add-service-step-first-upload-item-content">
                <div className="add-service-step-file-type-icon">
                  <img
                    className="add-service-step-page-icon"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step-file-type-wrap">
                    <b className="add-service-step-file-type">IMG</b>
                  </div>
                </div>
                <div className="add-service-step-first-upload-item-info">
                  <div className="add-service-step-first-upload-item-inner">
                    <div className="add-service-step-first-upload-item">
                      {cropedBannerImageFile.name}
                    </div>
                    <div className="add-service-step3-checkbox3">
                      <Field type="checkbox" onClick={handleBannerImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text14">{(cropedBannerImageFile.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                {(bannerSingleCheckboxError !== "") ?
                  <div style={{ color: "red" }}>
                    {bannerSingleCheckboxError}
                  </div> :
                  <div className="add-service-step3-percentage3">100%</div>
                }
              </div>

            </div>
          )
        }
        {
          (bannerImageDemoFile instanceof File) &&

          (
            <div className="add-service-step-file-upload-item-base">
              <div className="add-service-step-first-upload-item-content">
                <div className="add-service-step-file-type-icon">
                  <img
                    className="add-service-step-page-icon"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step-file-type-wrap">
                    <b className="add-service-step-file-type">IMG</b>
                  </div>
                </div>
                <div className="add-service-step-first-upload-item-info">
                  <div className="add-service-step-first-upload-item-inner">
                    <div className="add-service-step-first-upload-item">
                      {bannerImageDemoFile.name}
                    </div>
                    <div className="add-service-step3-checkbox3">
                      <Field type="checkbox" onClick={handleBannerImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text14">{(bannerImageDemoFile.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                {(bannerSingleCheckboxError !== "") ?
                  <div style={{ color: "red" }}>
                    {bannerSingleCheckboxError}
                  </div> :
                  <div className="add-service-step3-percentage3">100%</div>
                }
              </div>

            </div>
          )
        }
      </div>
      <div className="business-form-upload-footer">
        <div className="business-form-section-footer">
          <img
            className="business-form-divider-icon4"
            alt=""
            src={divider2}
          />

          <div className="business-form-content46">

            <div className="business-form-actions1">

              <button className="business-form-button11" type='submit' onClick={handleSubmit} disabled={isSubmitting}>
                <img
                  className="business-form-placeholder-icon"
                  alt=""
                  src={placeholder3}
                />

                <div className="business-form-text-padding8">
                  <div className="business-form-text76">{isSubmitting ? 'Submitting...' : 'Submit'}</div>
                </div>
                <img
                  className="business-form-placeholder-icon"
                  alt=""
                  src={placeholder3}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

    </>

    // </form>
  )
}
