import React, { useState } from 'react';
import './FunnelCardTabs.css'; // Import your CSS file


import { FunnelWantedSpareCard } from '../Funnel/FunnelWantedSpareCard/FunnelWantedSpareCard';
import { FunnelOfferedSpareCard } from '../Funnel/FunnelOfferedSpareCard/FunnelOfferedSpareCard';

export const FunnelSpareCardTabs = () => {
    const [activeButton, setActiveButton] = useState('Offered');

    const handleButtonClick = (switchButton) => {
        setActiveButton(switchButton);
    };

    const renderComponent = () => {
        switch (activeButton) {
            case 'Offered':
                return <FunnelOfferedSpareCard />;
            case 'Wanted':
                return <FunnelWantedSpareCard />;

            default:
                return null;
        }
    };


    return (
        <div className='funnel-card-tab-main-container'>
            <div className='funnel-card-tab-tab'>
                <div className="funnel-card-tab-button-group custom-cursor">
                    <div
                        className={`funnel-card-tab-button-group-base1 ${activeButton === 'Offered' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Offered')}
                    >
                        <div className="funnel-card-tab-text31">Offered</div>
                    </div>
                    <div
                        className={`funnel-card-tab-button-group-base1 ${activeButton === 'Wanted' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Wanted')}
                    >
                        <div className="funnel-card-tab-text32">Wanted</div>
                    </div>

                </div>

            </div>

            <div>
                {renderComponent()}
            </div>

        </div>
    );
};


export default FunnelSpareCardTabs;
