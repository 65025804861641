import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  transactionType: '',
  yourRole: '',
  deviceCategory: '',
  oem: '',
  partName: '',
  statusCondition: '',
  yearOfManufacture: '',
  availability: '',
  partNumber: '',
  serialNumber: '',
  price: '',
  unitOfMeasure: '',
  availableQuantity: '',
  warranty: '',
  shipping: '',
  clinicalApplications: '',
  purposeUse: '',
  physicalLocation: '',
  hardwareHighlights: '',
  softwareUpgradesOsApplicationsworklistHighlights: '',
  featureImage: '',
  featureImageLink: '',
  gallery: [],
  galleryImageLinks: '',
  videoType: '',
  linkVideo: '',
  location: '',
  document: [],
  documentLink: '',
  parentDeviceCompatible: '',
  hardware: '',
  softwareUpgraadesOsApplicationsWorklist: '',
  serviceHistory: '',
  warrantyInput: '',
  packingList: '',
  additionalInformation: '',
}





const addSpareSlice = createSlice({
  name: "addSpare",
  initialState,
  reducers: {
    updateField(state, action) {
      const { field, value } = action.payload
      if (value !== undefined) {
        state[field] = value;
      }
    },
    clearForm() {
      return initialState;
    }
  },
});

export const { updateField, clearForm } = addSpareSlice.actions

export default addSpareSlice.reducer

