import React, { useEffect, useState } from 'react';
import "./SignUp.css";
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
import social_logo_google from '../../icons/Gerator/social-icon.svg';
import star_icon from '../../icons/Gerator/star-icon.svg';
import chevron_left from '../../icons/Gerator/chevronleft.svg';
import chevron_right from '../../icons/Gerator/chevronright.svg';
import avatar_company_icon from '../../icons/Gerator/-avatar-company-icon@2x.png';
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import RegisterForm from '../../components/RegisterFormComponent/RegisterForm';
import { Helmet } from 'react-helmet-async';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../screens/Login/FirebaseClient';
import { Loader } from '../../components/Loader';
import { useLazyGetUserQuery } from '../../api/Login&RegisterAPI';
import support_icon from "../../icons/Gerator/support_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg"
import { auth, onAuthStateChanged } from '../../screens/Login/FirebaseClient';



export const SignUp = () => {
  const navigate = useNavigate();

  const [isNotLogin, setIsNotLogin] = useState(false);

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {


      if (user) {
        // Get user to check wizardStep
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const usermeta = userDocSnapshot.data(); // Default to 1 if not set

          switch (usermeta.wizardStep) {
            case 1:
              navigate('/account-setup-check-email');
              break;
            case 2:
              navigate('/account-setup-update-profile');
              break;
            case 3:
              navigate('/account-setup-subscription-plan');
              break;
            default:
              navigate('/dashboard');
              break;
          }

          // setCurrentUsermeta(usermeta);
          // setCurrentUser(user);
          console.log("Wizard Step:", usermeta.wizardStep);
        }
      }
      else {
        setIsNotLogin(true);
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  if (isNotLogin) {
    return (
      <div className="sign-up-sign-up-desktop">
        <div className="sign-up-section ">
          <div className="sign-up-logo-container-signup">
            <div className="sign-up-logo">
              <img
                className="sign-up-gerator-04-1-icon"
                alt=""
                src={gerator_logo}
              />
            </div>
            <div className="sign-up-content-wrapper">
              <div className="sign-up-content">
                {/* <div className="sign-up-testimonial">
                  <div className="sign-up-stars">
                    <img
                      className="sign-up-star-icon"
                      loading="lazy"
                      alt=""
                      src={star_icon}
                    />

                    <img
                      className="sign-up-star-icon"
                      loading="lazy"
                      alt=""
                      src={star_icon}
                    />

                    <img
                      className="sign-up-star-icon"
                      loading="lazy"
                      alt=""
                      src={star_icon}
                    />

                    <img
                      className="sign-up-star-icon"
                      loading="lazy"
                      alt=""
                      src={star_icon}
                    />

                    <img
                      className="sign-up-star-icon"
                      loading="lazy"
                      alt=""
                      src={star_icon}
                    />
                  </div>
                </div> */}
                <h1 className="sign-up-text ">
                  Pro tip: Your next killer equipment deal is hiding in plain sight. Let's find it! 🔍
                </h1>
                {/* <div className="sign-up-reviewer-info">
                  <div className="sign-up-testimonial">
                    <div className="sign-up-avatar">
                      <div className="sign-up-contrast-border"></div>
                      <img
                        className="sign-up-avatar-company-icon"
                        loading="lazy"
                        alt=""
                        src={avatar_company_icon}
                      />
                    </div>
                  </div>
                  <div className="sign-up-reviewer-details">
                    <div className="sign-up-reviewer-name">Lori Bryson</div>
                    <div className="sign-up-supporting-text">Product Designer, Sisyphus</div>
                  </div>
                </div> */}
                {/* <div className="sign-up-testimonial">
                <div className="sign-up-navigation-controls">
                  <div className="sign-up-buttonsbutton-signup ">
                    <img
                      className="sign-up-chevron-left-icon"
                      loading="lazy"
                      alt=""
                      src={chevron_left}
                    />
                  </div>
                  <div className="sign-up-pagination">
                    <div className="sign-up-pagination-dot-group">
                      <div className="sign-up-pagination-dot-indicator"></div>
                      <div className="sign-up-pagination-dot-indicator1"></div>
                      <div className="sign-up-pagination-dot-indicator1"></div>
                      <div className="sign-up-pagination-dot-indicator1"></div>
                      <div className="sign-up-pagination-dot-indicator4"></div>
                      <div className="sign-up-pagination-dot-indicator4"></div>
                      <div className="sign-up-pagination-dot-indicator4"></div>
                    </div>
                  </div>
                  <div className="sign-up-buttonsbutton-signup ">
                    <img
                      className="sign-up-chevron-left-icon"
                      loading="lazy"
                      alt=""
                      src={chevron_right}
                    />
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
          <div className="sign-up-footer">
            <div className="sign-up-text1">© Gerator Asia LLP. All rights reserved.</div>
            <div className="sign-up-footer-links">
              <div className="sign-up-email-link">
                <img
                  className="sign-up-mail-01-icon"
                  loading="lazy"
                  alt=""
                  src={support_icon}
                />
              </div>
              <div className="sign-in-helpcentergeratorcom1"><a href=" https://helpcenter.gerator.com/" className='sign-in-hyperlink'>Need Help?</a></div>
            </div>
          </div>
        </div>
        <div className="sign-up-section1">
          <div className="sign-up-container-signup">
            <div className="sign-up-content1">
              <div className="sign-up-text-and-supporting-text">
                <h1 className="sign-up-logo-title">Sign up</h1>
                <div className="sign-up-supporting-text1">Its time to work smarter! 💡</div>
              </div>
              <RegisterForm />
              <div className="sign-up-row">
                <div className="sign-up-helpcentergeratorcom">Already have an account?</div>
                <div className="sign-up-buttonsbutton3">
                  <img
                    className="sign-up-placeholder-icon2"
                    alt=""
                    src="/placeholder1.svg"
                  />

                  <Link to={"/sign-in"}>
                    <span className="sign-up-text7">
                      Log in
                    </span>
                  </Link>
                  <img
                    className="sign-up-placeholder-icon2"
                    alt=""
                    src="/placeholder1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  } else {
    return <Loader />
  }
}

