import React, { useEffect, useState } from "react";
import "./ArchiveWantedDevice.css"
import { useLazyGetWantedDevicesQuery, useDeleteWantedDeviceMutation } from "../../../api/WantedDeviceAPI";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';

import { decryptErrorData } from "../../../helper/AESHelper";
import { NoListingFound } from "../../../components/NoListing/NoListingFound";


export const ArchiveWantedDevice = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");


  const [deleteWantedDevice] = useDeleteWantedDeviceMutation();
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetWantedDevice, { data: wantedDeviceData,
    isLoading: isWantedDeviceLoading,
    isSuccess: isWantedDeviceSuccess,
    isError: isWantedDeviceError,
    error: wantedDeviceError }] = useLazyGetWantedDevicesQuery();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  //Delete
  const handleDelete = async (id) => {
    try {

      const response = await deleteWantedDevice(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete wanted device `, error);
    }
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  // Trigger the get wanted device query only after user data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // Once the user is authenticated and the getUser query is successful, trigger the getWantedDevice query
      triggerGetWantedDevice();
    }
  }, [isSuccess, triggerGetWantedDevice]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }



  // if (isLoading || isWantedDeviceLoading) {

  // } else 
  if (isWantedDeviceSuccess) {

    if (wantedDeviceData.data.length > 0) {
      dataLength = wantedDeviceData.data.length;
      content = wantedDeviceData.data.map(wantedDevice =>
      (

        <div className="archive-wanted-device-rectangle-group listing-item" key={wantedDevice.id}>

          <div className="archive-wanted-device-rectangle-container">
            {/* <img
              className="archive-wanted-device-frame-inner"
              alt=""
              src={rectangle_41_2x}
            /> */}

            <div className="archive-wanted-device-dots-vertical-container">
              {/* <img
                className="archive-wanted-device-dots-vertical-icon1"
                alt=""
                src={dotsvertical}
              /> */}

              <div className="archive-wanted-device-text-parent">

                <Link to={`/wanted-device/${wantedDevice.id}/${wantedDevice.title}`} className=" archive-wanted-device-text37"><p className="text-overflow-archive-device">{wantedDevice.title}</p></Link>

                <div className="archive-wanted-device-frame-group">
                  <div className="archive-wanted-device-frame-container">
                    <div className="archive-wanted-device-frame-div">
                      <div className="archive-wanted-device-frame-parent1">
                        <div className="archive-wanted-device-badge-parent">
                          <div className="archive-wanted-device-badge9">
                            <img
                              className="archive-wanted-device-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="archive-wanted-device-price-value">Pending</div>
                          </div>
                          <div className="archive-wanted-device-supporting-text4">ID# {wantedDevice.id}</div>
                        </div>
                        <div className="archive-wanted-device-deal-type-parent">
                          <div className="archive-wanted-device-completed">
                            <div className="archive-wanted-device-second-value">4.9/5</div>
                          </div>
                          <div className="archive-wanted-device-supporting-text-wrapper">
                            <div className="archive-wanted-device-supporting-text3">99,999 reviews</div>
                          </div>
                        </div>
                        <div className="archive-wanted-device-badge10">
                          <div className="archive-wanted-device-third-badge-count text-overflow-archive-device">
                            {wantedDevice.tax_device_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-device-deal-location-parent">
                        <div className="archive-wanted-device-dot-item-location-parent">
                          {/* <div className="archive-wanted-device-navigation">
                            <img
                              className="archive-wanted-device-marker-pin-02-icon"
                              alt=""
                              src={markerpin02}
                            />
                          </div> */}
                          {/* <div className="archive-wanted-device-location">India, New Delhi</div> */}
                        </div>
                        <div className="archive-wanted-device-frame-wrapper1">
                          <div className="archive-wanted-device-badge-group">
                            <div className="archive-wanted-device-badge10">
                              <div className="archive-wanted-device-sub-badge text-overflow-archive-device">{wantedDevice.tax_clinical_application.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-device-card-work">
                        <div className="archive-wanted-device-inner-work">
                          <div className="archive-wanted-device-dot-item-type-parent">
                            <div className="archive-wanted-device-navigation">
                              <img
                                className="archive-wanted-device-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="archive-wanted-device-type-of-work">{getCustomizedDate(wantedDevice.createdAt)}</div>
                          </div>
                        </div>
                        <div className="archive-wanted-device-badge13 text-overflow-archive-device">
                          <div className="archive-wanted-device-third-badge-count">
                            {wantedDevice.tax_purpose_use.name}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* <div className="archive-wanted-device-second-price">
                    <div className="archive-wanted-device-price">$9,999,999.99</div>
                    <div className="archive-wanted-device-price-value1">
                      <div className="archive-wanted-device-deal-title">USD - Per Unit</div>
                    </div>
                    <div className="archive-wanted-device-inner-icons-wrapper">
                      <div className="archive-wanted-device-inner-icons">
                        <img
                          className="archive-wanted-device-magnifier-1-icon"
                          alt=""
                          src={radar_1_1_2x}
                        />

                        <img
                          className="archive-wanted-device-magnifier-1-icon"
                          alt=""
                          src={deal_2_1_2x}
                        />

                        <img
                          className="archive-wanted-device-magnifier-1-icon"
                          alt=""
                          src={magnifier_1_2x}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-device-divider-parent">
            <img className="archive-wanted-device-divider-icon4" alt="" src={divider2} />

            <div className="archive-wanted-device-user-listings">
              <div className="archive-wanted-device-frame-parent3">
                <div className="archive-wanted-device-frame-parent4">
                  <img
                    className="archive-wanted-device-user-content-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="archive-wanted-device-inner-info-wrapper">
                    <div className="archive-wanted-device-inner-info">
                      <div className="archive-wanted-device-name">Olivia Rhye</div>
                      <div className="archive-wanted-device-role1">Business Name</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-device-buttonsbutton-wrapper">
                  <Link to={"/edit-wanted-device/" + wantedDevice.id}>
                    <button className="archive-wanted-device-buttonsbutton1">

                      <img className="archive-wanted-device-eye-icon" alt="" src={eye} />

                      <div className="archive-wanted-device-navigation">
                        <div className="archive-wanted-device-view-label">Edit</div>
                      </div>
                    </button>
                  </Link>
                </div>
                <div className="archive-wanted-device-logo-wrapper">
                  {/* <Link to={"/delete-wanted-device/" + wantedDevice.id}> */}
                  <div className="archive-wanted-device-buttonsbutton2 archive-wanted-device-view-label" onClick={() => handleDelete(wantedDevice.id)}>
                    <div className="archive-wanted-device-icon-wrapper">
                      <img className="archive-wanted-device-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-device-navigation">
                      <div className="archive-wanted-device-lock-label">Delete</div>
                    </div>
                    <img className="archive-wanted-device-eye-icon" alt="" src={lock04} />
                  </div>
                  {/* </Link> */}
                </div>
                <div className="archive-wanted-device-logo-wrapper">
                  <div className="archive-wanted-device-buttonsbutton3">
                    <div className="archive-wanted-device-icon-wrapper">
                      <img className="archive-wanted-device-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-device-navigation">
                      <div className="archive-wanted-device-lock-label">Sales IQ</div>
                    </div>
                    <img
                      className="archive-wanted-device-eye-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                </div>
              </div>
              <div className="archive-wanted-device-arrow-square-up-right-wrapper">
                <img
                  className="archive-wanted-device-frame-child1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>


      ))
    }
    else {
      content = (

        // <div className="content-44" >
        //   <img
        //     className="bifocal-optometry-2"
        //     alt="Bifocal optometry"
        //     src="/img/bifocal-optometry-measurement-device-2.png"
        //   />
        //   <div className="text-and-supporting-16" style={{ alignSelf: "unset !important" }}>
        //     <div className="text-157">No listings found</div>
        //     <p className="supporting-text-36">
        //       Your search “Landing page design” did not match any projects. Please try again.
        //     </p>
        //   </div>
        //   <div className="actions-10">
        //     <div className="buttons-button-37">
        //       <div className="text-padding-18">
        //         <div className="text-158">Clear search</div>
        //       </div>
        //     </div>
        //     <div className="buttons-button-38">
        //       <img className="img-26" alt="Plus" src="/img/plus.svg" />
        //       <div className="text-padding-18">
        //         <Link to={"/add-wanted-device"}>
        //           <div className="text-159">Add Listing</div>
        //         </Link>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
      )
    }
    // content = <Loader/>
  } else {
    return <Loader />
  }




  return (
    <div className="archive-wanted-device-listings-search-results-list">
      <div className="archive-wanted-device-listings-search-results-list-child"></div>


      <Navbar1 userData={currentUser} navigate={navigate} />


      <div className="archive-wanted-device-filters">
        <div className="archive-wanted-device-main">
          <div className="archive-wanted-device-header-section">
            <div className="archive-wanted-device-container">
              <div className="archive-wanted-device-page-header">
                <div className="archive-wanted-device-breadcrumbs">
                  <div className="archive-wanted-device-tabs">
                    <div className="archive-wanted-device-breadcrumb-button-base">
                      <img
                        className="archive-wanted-device-search-lg-icon"
                        alt=""
                        src={homeline}
                      />
                    </div>
                    <img
                      className="archive-wanted-device-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-device-breadcrumb-button-base1">
                      <a className="archive-wanted-device-text7">Dashboard</a>
                    </div>
                    <img
                      className="archive-wanted-device-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-device-breadcrumb-button-base2">
                      <div className="archive-wanted-device-text8">...</div>
                    </div>
                    <img
                      className="archive-wanted-device-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-device-breadcrumb-button-base3">
                      <div className="archive-wanted-device-text9">Another link</div>
                    </div>
                    <img
                      className="archive-wanted-device-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-device-breadcrumb-button-base4">
                      <a className="archive-wanted-device-text10">Category</a>
                    </div>
                  </div>
                </div>
                <img className="archive-wanted-device-divider-icon" alt="" src={divider} />
              </div>
            </div>
          </div>
          <div className="archive-wanted-device-main-child"></div>
          <div className="archive-wanted-device-main-item"></div>
        </div>
        <div className="archive-wanted-device-secondary-content">
          <div className="archive-wanted-device-page-header1">
            <div className="archive-wanted-device-breadcrumbs1">
              <div className="archive-wanted-device-tabs1">
                <div className="archive-wanted-device-breadcrumb-button-base">
                  <img
                    className="archive-wanted-device-search-lg-icon"
                    alt=""
                    src={homeline}
                  />
                </div>
                <img
                  className="archive-wanted-device-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-device-breadcrumb-button-base6">
                  <div className="archive-wanted-device-text11">Settings</div>
                </div>
                <img
                  className="archive-wanted-device-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-device-breadcrumb-button-base7">
                  <div className="archive-wanted-device-text8">...</div>
                </div>
                <img
                  className="archive-wanted-device-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-device-breadcrumb-button-base3">
                  <div className="archive-wanted-device-text9">Another link</div>
                </div>
                <img
                  className="archive-wanted-device-chevron-right-icon2"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-device-breadcrumb-button-base4">
                  <div className="archive-wanted-device-menu-label">Team</div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-device-content4">
              <div className="archive-wanted-device-text-and-supporting-text">
                <div className="archive-wanted-device-page-title">{dataLength} Listings Found</div>
                <div className="archive-wanted-device-supporting-text">
                  Manage your team members and their account permissions here.
                </div>
              </div>
              <div className="archive-wanted-device-input-dropdown">
                <div className="archive-wanted-device-input-with-label1">
                  <div className="archive-wanted-device-label1">Search</div>
                  <div className="archive-wanted-device-input1">
                    <div className="archive-wanted-device-content5">
                      <img
                        className="archive-wanted-device-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <div className="archive-wanted-device-text15">Search</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-device-hint-text">This is a hint text to help user.</div>
              </div>
            </div>
            <img className="archive-wanted-device-divider-icon1" alt="" src={divider1} />
          </div>
        </div>
        <div className="archive-wanted-device-button-group">
          <button className="archive-wanted-device-button-group-base">
            <a className="archive-wanted-device-text16">View all</a>
          </button>
          <div className="archive-wanted-device-button-group-base1">
            <div className="archive-wanted-device-text17">Monitored</div>
          </div>
          <div className="archive-wanted-device-button-group-base2">
            <div className="archive-wanted-device-menu-label">Text</div>
          </div>
          <div className="archive-wanted-device-button-group-base3">
            <div className="archive-wanted-device-menu-label">Text</div>
          </div>
          <div className="archive-wanted-device-button-group-base4">
            <div className="archive-wanted-device-menu-label">Text</div>
          </div>
          <div className="archive-wanted-device-button-group-base5">
            <div className="archive-wanted-device-menu-label">Text</div>
          </div>
          <div className="archive-wanted-device-button-group-base6">
            <div className="archive-wanted-device-menu-label">Text</div>
          </div>
          <div className="archive-wanted-device-button-group-base7">
            <div className="archive-wanted-device-menu-label">Text</div>
          </div>
          <div className="archive-wanted-device-button-group-base1">
            <div className="archive-wanted-device-text24">Unmonitored</div>
          </div>
          {/* <div className="archive-wanted-device-breadcrumbs2">
          <div className="archive-wanted-device-tabs2">
            <div className="archive-wanted-device-breadcrumb-button-base">
              <img
                className="archive-wanted-device-search-lg-icon"
                alt=""
                src={homeline}
              />
            </div>
            <div className="archive-wanted-device-logo-wrapper">
              <input className="archive-wanted-device-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-device-breadcrumb-button-base11">
              <div className="archive-wanted-device-text11">Settings</div>
            </div>
            <div className="archive-wanted-device-logo-wrapper">
              <input className="archive-wanted-device-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-device-breadcrumb-button-base12">
              <div className="archive-wanted-device-text8">...</div>
            </div>
            <div className="archive-wanted-device-logo-wrapper">
              <img
                className="archive-wanted-device-arrow-down-icon"
                alt=""
                src={chevronright}
              />
            </div>
            <div className="archive-wanted-device-breadcrumb-button-base3">
              <div className="archive-wanted-device-text9">Another link</div>
            </div>
            <img
              className="archive-wanted-device-chevron-right-icon2"
              alt=""
              src={chevronright}
            />

            <div className="archive-wanted-device-breadcrumb-button-base14">
              <div className="archive-wanted-device-text28">Team</div>
            </div>
          </div>
        </div> */}
        </div>
        <div className="archive-wanted-device-input-dropdown1">
          <div className="archive-wanted-device-hint-text1">This is a hint text to help user.</div>
          <div className="archive-wanted-device-buttonsbutton">
            <img className="archive-wanted-device-sliders-02-icon" alt="" src={sliders02} />

            <div className="archive-wanted-device-text-padding">
              <div className="archive-wanted-device-menu-label">Sort By : Relevance</div>
              <img
                className="archive-wanted-device-sliders-02-icon"
                alt=""
                src={chevrondown}
              />
            </div>
            <img
              className="archive-wanted-device-placeholder-icon"
              alt=""
              src={placeholder}
            />
          </div>
        </div>
        <img className="archive-wanted-device-divider-icon2" alt="" src={divider2} />

        <img className="archive-wanted-device-divider-icon3" alt="" src={divider2} />

        <div className="archive-wanted-device-supporting-text1">
          You searched based on the following criteria.
        </div>




        <div className="listings-container">
          {/* <div class="listing-item">
            <h3>Listing 1</h3>
            <p>Description for listing 1.</p>
          </div>
          <div class="listing-item">
            <h3>Listing 2</h3>
            <p>Description for listing 2.</p>
          </div>
          <div class="listing-item">
            <h3>Listing 3</h3>
            <p>Description for listing 3.</p>
          </div> */}
          {content}
        </div>















        <div className="archive-wanted-device-buttonsbutton7">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <a className="archive-wanted-device-text47">All time</a>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-device-buttonsbutton8">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text48">Clinical Applications</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </button>
        <div className="archive-wanted-device-buttonsbutton9">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text49">US, AU, +4</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </div>
        <div className="archive-wanted-device-buttonsbutton10">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text50">All/Employee</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-device-buttonsbutton11">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text48">Clinical Applications</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </button>
        <div className="archive-wanted-device-buttonsbutton12">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-menu-label">Year of Manufacture</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-device-buttonsbutton13">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text53">Warrenty</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-device-buttonsbutton14">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text54">Manufacturer</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-device-buttonsbutton15">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text55">All Applications</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-device-buttonsbutton16">
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-device-text56">Rent/Lease</div>
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-device-buttonsbutton17">
          <img className="archive-wanted-device-sliders-02-icon" alt="" src={filterlines} />

          <div className="archive-wanted-device-text57">More filters</div>
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />
        </button>
        <button className="archive-wanted-device-buttonsbutton18">
          <img
            className="archive-wanted-device-sliders-02-icon"
            alt=""
            src={filterfunnel01}
          />

          <div className="archive-wanted-device-text58">Clear Filter</div>
          <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} />
        </button>
        <button className="archive-wanted-device-actions">
          <div className="archive-wanted-device-button">
            <img
              className="archive-wanted-device-placeholder-icon"
              alt=""
              src={placeholder1}
            />

            <div className="archive-wanted-device-text-padding1">
              <div className="archive-wanted-device-text59">Tertiary</div>
            </div>
            <img
              className="archive-wanted-device-placeholder-icon"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="archive-wanted-device-button1">
            <img
              className="archive-wanted-device-placeholder-icon"
              alt=""
              src={placeholder2}
            />

            <div className="archive-wanted-device-text-padding1">
              <div className="archive-wanted-device-text60">Secondary</div>
            </div>
            <img
              className="archive-wanted-device-placeholder-icon"
              alt=""
              src={placeholder2}
            />
          </div>
          <div className="archive-wanted-device-button2">
            <img
              className="archive-wanted-device-sliders-02-icon"
              alt=""
              src={filterlines}
            />

            <div className="archive-wanted-device-text-padding">
              <div className="archive-wanted-device-filter-label">Save Filter</div>
            </div>
            <img
              className="archive-wanted-device-placeholder-icon"
              alt=""
              src={placeholder3}
            />
          </div>
        </button>
      </div>

      <img className="archive-wanted-device-divider-icon6" alt="" src={divider4} />

      <img className="archive-wanted-device-divider-icon7" alt="" src={divider4} />



      <FooterAddForms />




    </div>


  )
}





