import React from 'react';
import "./NoListingFoundForFilter.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";

export const NoListingFoundForFilter = ({ clearFilters }) => {
  return (
    <div className="no-listing-found-no-listing-found">
      <section className="no-listing-found-empty-state">
        <div className="no-listing-found-empty-state1">
          <div className="no-listing-found-content">
            <img
              className="no-listing-found-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={bifocallens}
            />

            <div className="no-listing-found-text-and-supporting-text">
              <div className="no-listing-found-title">No listings found</div>
              <div className="no-listing-found-supporting-text">
                Your search “Magnetom” did not match any listings. Please try
                again.
              </div>
            </div>
          </div>
          <div className="no-listing-found-actions" onClick={clearFilters}>
            <button className="no-listing-found-buttonsbutton" >
              <img
                className="no-listing-found-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-listing-found-text-padding">
                <div className="no-listing-found-text">Clear search</div>
              </div>
              <img
                className="no-listing-found-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>
            {/* <button className="no-listing-found-buttonsbutton1"  >
              <Link to={path}>
                <img className="no-listing-found-plus-icon" alt="" src={plus} />

                <div className="no-listing-found-text-padding">
                  <div className="no-listing-found-text1">Add Listing</div>
                </div>
                <img
                  className="no-listing-found-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button> */}
          </div>
        </div>
      </section>
    </div>
  )
}
