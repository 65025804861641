import React, { useEffect, useState } from 'react';
import "./DetailedSpare.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';
import divider5 from '../../../icons/Gerator/detailed_listing/divider5.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';


import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';

import target_04 from "../../../icons/Gerator/dashboard/target_04.png"
import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';


import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetSpareQuery, useLazyGetSparesQuery } from "../../../api/SpareAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/SpareHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarSpareMutation, useDeleteRadarSpareMutation } from '../../../api/Radar/RadarSpareAPI';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import VideoSection from '../../../components/VideoSection/VideoSection';
import { TransactionTypeComponent } from '../../../components/TransactionTypeComponent/TransactionTypeComponent';
import { formatNumber } from '../../../helper/DeviceHelper';
import { useAddViewSpareMutation } from '../../../api/View/ViewSpareAPI';

export const DetailedSpare = () => {
  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const [triggerGetSpare, { data: spareData,
    isLoading: isSpareLoading,
    isSuccess: isSpareSuccess,
    isError: isSpareError,
    error: spareError }] = useLazyFindGetSpareQuery(id);

  const [addViewSpare, { data: spareViewData,
    isLoading: isViewSpareLoading,
    isSuccess: isViewSpareSuccess,
    isError: isViewSpareError,
    error: spareViewError }] = useAddViewSpareMutation();

  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarSpareMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarSpareMutation();


  // const [showSuccessMessage, setShowSuccessMessage] = useState({
  //   message: "",
  //   color: "",
  // });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetSpare(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  // // Trigger the get wanted spare query only after user data is successfully fetched
  useEffect(() => {
    if (isSpareSuccess && spareData) {
      addViewSpare(id);
      console.log('added view data');
    }
  }, [isSpareSuccess, spareData]);

  useEffect(() => {
    if (spareViewData) {
      console.log(spareViewData);
    }
  }, [spareViewData]);


  const handleRemoveRadar = async (spareId) => {
    await deleteFromRadar(spareId);
    window.location.reload();
  };

  const handleAddRadar = async (spareId) => {
    await addToRadar(spareId);
    window.location.reload();
  };

  const handleRemoveContact = async (spareId) => {
    const result = await deleteFromContact(spareId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (spareId) => {
    const result = await addToContact(spareId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };
  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])



  if (currentUser && isSpareSuccess && profileImageLink) {
    return (
      <div className="detailed-spare-listings-detailed-page-devic">
        <div className="detailed-spare-rectangle-parent">
          <div className="detailed-spare-frame-child"></div>
          <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />
          <img className="detailed-spare-divider-icon" alt="" src={divider} />

          <div className="detailed-spare-breadcrumb-button-base">
            <div className="detailed-spare-text6">...</div>
          </div>
          <img
            className="detailed-spare-chevron-right-icon"
            alt=""
            src={chevronright}
          />

          <div className="detailed-spare-breadcrumb-button-base1">
            <div className="detailed-spare-text7">Another link</div>
          </div>
          <img
            className="detailed-spare-chevron-right-icon1"
            alt=""
            src={chevronright}
          />

          <div className="detailed-spare-frame-parent">
            <div className="detailed-spare-breadcrumb-container-parent">
              <SlideShowDetailPageComponent slides={spareData.data.galleryImages} />
              <div className="detailed-spare-breadcrumb">
                <div className="detailed-spare-breadcrumb-items">
                  <div className="detailed-spare-breadcrumb-button-base2">
                    <div className="detailed-spare-text9">Dashboard</div>
                  </div>
                  <div className="detailed-spare-chevron-container">
                    <img
                      className="detailed-spare-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />
                  </div>
                  <div className="detailed-spare-breadcrumb-button-base3">
                    <div className="detailed-spare-text10">Spare</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-spare-listing-content-wrapper">
              <div className="detailed-spare-listing-content">
                <div className="detailed-spare-description-block-parent">
                  <div className="detailed-spare-description-block">
                    <div className="detailed-spare-description-heading">
                      {spareData.data.title}
                    </div>
                    <div className="detailed-spare-listing-details">
                      <div className="detailed-spare-detail-container">
                        <div className="detailed-spare-details-content">
                          <div className="detailed-spare-details-row">
                            <div className="detailed-spare-detail-items">
                              <img
                                className="detailed-spare-marker-pin-02-icon"
                                alt=""
                                src={markerpin02}
                              />

                              <div className="detailed-spare-supporting-text">{spareData.data.spare_address.addressTownCity},  {spareData.data.spare_address.tax_country.name}</div>
                            </div>
                            <div className="detailed-spare-detail-items">
                              <img
                                className="detailed-spare-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="detailed-spare-supporting-text">{getCustomizedDate(spareData.data.createdAt)}</div>
                            </div>
                          </div>
                          <div className="detailed-spare-contact-details">
                            <div className="detailed-spare-contact-name-wrapper">
                              <div className="detailed-spare-contact-name">4.9/5</div>
                            </div>
                            <div className="detailed-spare-contact-role">
                              <div className="detailed-spare-supporting-text2">99,999 reviews</div>
                            </div>
                            <div className="detailed-spare-social-links">
                              <div className="detailed-spare-rectangle-container">
                                <div className="detailed-spare-frame-inner"></div>
                                <div className="detailed-spare-icon-backgrounds-parent">
                                  <div className="detailed-spare-icon-backgrounds"></div>
                                  <img
                                    className="detailed-spare-whatsapp-1-icon"
                                    alt=""
                                    src={facebook_1_1_2x}
                                  />
                                </div>
                                <div className="detailed-spare-icon-backgrounds-parent">
                                  <div className="detailed-spare-ellipse-div"></div>
                                  <img
                                    className="detailed-spare-whatsapp-1-icon"
                                    alt=""
                                    src={instagram_1_1_2x}
                                  />
                                </div>
                                <div className="detailed-spare-icon-backgrounds-parent">
                                  <div className="detailed-spare-frame-child1"></div>
                                  <img
                                    className="detailed-spare-whatsapp-1-icon"
                                    alt=""
                                    src={whatsapp_1_2x}
                                  />
                                </div>
                                <div className="detailed-spare-icon-backgrounds-parent">
                                  <div className="detailed-spare-icon-backgrounds"></div>
                                  <img
                                    className="detailed-spare-whatsapp-1-icon"
                                    alt=""
                                    src={twitter_1_2x}
                                  />
                                </div>
                                <div className="detailed-spare-icon-backgrounds-parent">
                                  <div className="detailed-spare-ellipse-div"></div>
                                  <img
                                    className="detailed-spare-whatsapp-1-icon"
                                    alt=""
                                    src={linkedinlogo_1_2x}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-button-row">
                          {/* <div className="detailed-spare-buttonsbutton1">
                            <img
                              className="detailed-spare-chevron-down-icon"
                              alt=""
                              src={placeholder1}
                            />
  
                            <img
                              className="detailed-spare-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
  
                            <div className="detailed-spare-text-padding">
                              <div className="detailed-spare-text11">Add To Radar</div>
                            </div>
                            <img
                              className="detailed-spare-chevron-down-icon"
                              alt=""
                              src={placeholder1}
                            />
                          </div> */}
                          {!spareData.data.isAuthor && (
                            spareData.data.addedToRadar ? (

                              <div className="detailed-spare-buttonsbutton1" onClick={() => handleRemoveRadar(spareData.data.id)} style={{ cursor: "pointer" }}>
                                <img
                                  className="detailed-spare-magnifier-1-icon"
                                  alt=""
                                  src={target_04}
                                />
                                <div className="detailed-spare-text-padding">
                                  <div className="detailed-spare-text11">Added To Radar</div>
                                </div>

                              </div>
                            ) : (
                              <div className="detailed-spare-buttonsbutton1" onClick={() => handleAddRadar(spareData.data.id)} style={{ cursor: "pointer" }}>
                                <img
                                  className="detailed-spare-magnifier-1-icon"
                                  alt=""
                                  src={radar_1_1_2x}
                                />

                                <div className="detailed-spare-text-padding">
                                  <div className="detailed-spare-text11">Add To Radar</div>
                                </div>
                              </div>
                            )
                          )}
                          <div className="detailed-spare-buttonsbutton2">
                            <img
                              className="detailed-spare-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="detailed-spare-magnifier-1-icon"
                              alt=""
                              src={deal_2_1_2x}
                            />

                            <img
                              className="detailed-spare-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <div className="detailed-spare-buttonsbutton2">
                            <img
                              className="detailed-spare-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="detailed-spare-magnifier-1-icon"
                              alt=""
                              src={magnifier_1_2x}
                            />

                            <img
                              className="detailed-spare-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <div className="detailed-spare-buttonsbutton2">
                            <img
                              className="detailed-spare-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />

                            <img
                              className="detailed-spare-magnifier-1-icon"
                              alt=""
                              src={share_1_2x}
                            />

                            <img
                              className="detailed-spare-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                          <img
                            className="detailed-spare-button-row-child"
                            alt=""
                            src={group_1010}
                          />
                        </div>
                      </div>
                      <div className="detailed-spare-dropdown-container">
                        <div className="detailed-spare-dropdown">
                          <img
                            className="detailed-spare-maximize-01-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="detailed-spare-divider-icon" alt="" src={divider1} />

                  <div className="detailed-spare-heading-parent">
                    <div className="detailed-spare-heading">Highlights</div>
                    <div className="detailed-spare-description-content">
                      <div className="detailed-spare-description-columns-first">
                        <div className="detailed-spare-first-columns-paragraphs">
                          <div className="detailed-spare-paragraph">
                            {spareData.data.tax_device_category.name}
                          </div>
                          <div className="detailed-spare-paragraph1">Parent Device Category</div>
                        </div>
                        <div className="detailed-spare-first-columns-paragraphs">
                          <div className="detailed-spare-paragraph">{spareData.data.tax_availability.name}</div>
                          <div className="detailed-spare-paragraph1">Available From</div>
                        </div>
                        <div className="detailed-spare-first-columns-paragraphs">
                          <div className="detailed-spare-paragraph">{spareData.data.tax_clinical_application.name}</div>
                          <div className="detailed-spare-paragraph1">Clinical Applications</div>
                        </div>
                      </div>
                      <div className="detailed-spare-description-columns-second">
                        <div className="detailed-spare-second-columns-paragraphs">
                          <div className="detailed-spare-paragraph">
                            {spareData.data.tax_oem.name}
                          </div>
                          <div className="detailed-spare-paragraph1">Part Manufacturer</div>
                        </div>
                        <div className="detailed-spare-second-columns-paragraphs">
                          <div className="detailed-spare-paragraph">{spareData.data.partNumber}</div>
                          <div className="detailed-spare-paragraph1">Part Number</div>
                        </div>
                        <div className="detailed-spare-second-columns-paragraphs">
                          <div className="detailed-spare-paragraph">
                            {spareData.data.tax_purpose_use.name}
                          </div>
                          <div className="detailed-spare-paragraph1">Device Use</div>
                        </div>
                        <div className="detailed-spare-second-columns-paragraphs">
                          <div className="detailed-spare-paragraph">{spareData.data.partName}</div>
                          <div className="detailed-spare-paragraph1">Part Name</div>
                        </div>
                        <div className="detailed-spare-second-columns-paragraphs">
                          <div className="detailed-spare-paragraph">{spareData.data.serialNumber}</div>
                          <div className="detailed-spare-paragraph1">Serial Number</div>
                        </div>
                        <div className="detailed-spare-second-columns-paragraphs">
                          <div className="detailed-spare-paragraph">
                            {spareData.data.tax_physical_location.name}
                          </div>
                          <div className="detailed-spare-paragraph1">Physical Location</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-third-columns-paragraphs-parent">
                    <div className="detailed-spare-first-columns-paragraphs">
                      <div className="detailed-spare-paragraph18">{spareData.data.tax_status_condition.name}</div>
                      <div className="detailed-spare-paragraph19">Status</div>
                    </div>
                    <div className="detailed-spare-first-columns-paragraphs">
                      <div className="detailed-spare-paragraph18">
                        {spareData.data.tax_warranty.name}
                      </div>
                      <div className="detailed-spare-paragraph19">Warranty</div>
                    </div>
                    <div className="detailed-spare-first-columns-paragraphs">
                      <div className="detailed-spare-paragraph18">{spareData.data.tax_year_of_manufacture.name}</div>
                      <div className="detailed-spare-paragraph19">Year of Manufacture</div>
                    </div>
                  </div>
                  <div className="detailed-spare-description-paragraphs">
                    <div className="detailed-spare-paragraph">{spareData.data.tax_shipping.name}</div>
                    <div className="detailed-spare-paragraph1">Shipping</div>
                  </div>
                  <div className="detailed-spare-content-blocks">
                    <div className="detailed-spare-heading1">Hardware</div>
                    <div className="detailed-spare-paragraph26">
                      {spareData.data.hardwareHighlights}
                    </div>
                  </div>
                  <div className="detailed-spare-content-blocks">
                    <div className="detailed-spare-heading1">
                      Software / Upgrade / OS / Applications / Worklist -
                      Highlights
                    </div>
                    <div className="detailed-spare-paragraph26">
                      {spareData.data.softwareUpgradesOsApplicationsworklistHighlights}
                    </div>
                  </div>
                  {/* <div className="detailed-spare-content-blocks">
                    <div className="detailed-spare-heading1">List of Parent Devices Compatible with this Spare / Part</div>
                    <div className="detailed-spare-paragraph26">
                    {spareData.data.softwareUpgradesOsApplicationsworklistHighlights}
                    </div>
                  </div> */}
                  <div className="detailed-spare-content-blocks">
                    <div className="detailed-spare-heading1">Location</div>
                    <div className="detailed-spare-paragraph26">
                      {spareData.data.spare_address?.fullAddress}
                    </div>
                    <MapLocationViewComponent
                      key={spareData.data.id}
                      width={"100%"}
                      height={"400px"}
                      initialCoordinates={spareData.data.spare_address.addressLocation}
                    />
                  </div>
                  {(spareData.data.videoType && spareData.data.linkVideo) &&
                    // (spareData.data.videoType === 'Youtube') ?
                    <>
                      <VideoSection videoUrl={spareData.data.linkVideo} />
                      <br />
                    </>
                    // :
                    // <>
                    //   <VideoSection videoUrl={spareData.data.linkVideo} />
                    //   <br />
                    // </>
                  }
                  <img className="detailed-spare-divider-icon" alt="" src={divider1} />

                  <div className="detailed-spare-section">
                    <div className="detailed-spare-benefits-container">
                      <div className="detailed-spare-benefits-content">
                        {(spareData.data?.softwareUpgraadesOsApplicationsWorklist?.trim() ||
                          spareData.data?.hardware?.trim() ||
                          spareData.data?.warrantyInput?.trim() ||
                          spareData.data?.packingList?.trim() ||
                          spareData.data?.warrantyInput?.trim() ||
                          spareData.data?.serviceHistory?.trim() ||
                          spareData.data?.packingList?.trim()) &&
                          <div className="detailed-spare-heading6">Description</div>}
                        {spareData.data?.hardware?.trim() !== "" && (
                          <div className="detailed-spare-benefits-subtitle">
                            <div className="detailed-spare-heading7">Hardware</div>
                            <div className="detailed-spare-paragraph30">
                              {spareData.data.hardware}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {spareData.data?.softwareUpgraadesOsApplicationsWorklist?.trim() !== "" && (
                    <div className="detailed-spare-content-blocks">
                      <div className="detailed-spare-heading1">
                        Software / Upgrade / OS / Applications / Worklist
                      </div>
                      <div className="detailed-spare-paragraph26">
                        {spareData.data.softwareUpgraadesOsApplicationsWorklist}
                      </div>
                    </div>
                  )}
                  {/* <div className="detailed-spare-content-blocks">
                  <div className="detailed-spare-heading1">Accessories</div>
                  <div className="detailed-spare-paragraph26">
                    The most recent advances in magnetic resonance imaging (MRI)
                    technology have been on the software side, enabling faster
                    contrast scans, greatly simplified cardiac imaging
                    workflows, and allowing MR scans of the lungs. In addition,
                    a few new MRI scanners have entered the market in the past
                    year. Watch the video “MRI Technology Report at RSNA 2015.”
                    Contributing Editor Greg Freiherr offers an overview of MRI
                    advances at the Radiological Society of North America (RSNA)
                    2015 annual meeting.
                  </div>
                </div> */}
                  {spareData.data?.warrantyInput?.trim() !== "" && (
                    <div className="detailed-spare-content-blocks">
                      <div className="detailed-spare-heading1">Warranty Inclusions & Exclusions</div>
                      <div className="detailed-spare-paragraph26">
                        {spareData.data.warrantyInput}
                      </div>
                    </div>
                  )}
                  {spareData.data?.serviceHistory?.trim() !== "" && (
                    <div className="detailed-spare-content-blocks">
                      <div className="detailed-spare-heading1">Service History</div>
                      <div className="detailed-spare-paragraph26">
                        {spareData.data.serviceHistory}
                      </div>
                    </div>
                  )}
                  <div className="detailed-spare-heading-parent">
                    {spareData.data?.packingList?.trim() !== "" && (
                      <div className="detailed-spare-content-blocks">
                        <div className="detailed-spare-heading1">Packing List</div>
                        <div className="detailed-spare-second-paragraph">
                          <div className="detailed-spare-paragraph35">
                            {spareData.data.packingList}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="detailed-spare-content-blocks">
                    <div className="detailed-spare-heading1">Additional Information</div>
                    <div className="detailed-spare-paragraph26">
                      Summary: The MGH-USC HCP team has acquired and shared
                      diffusion imaging data from 35 healthy adults, between the
                      ages of 20 and 59, scanned on the customized Siemens 3T
                      Connectom scanner. This scanner is a modified 3T Skyra
                      system (MAGNETOM Skyra Siemens Healthcare), housed at the
                      MGH/HST Athinoula A. Martinos Center for Biomedical
                      Imaging (see Setsompop et al., 2013 for details of the
                      scanner design and implementation). A 64-channel,
                      tight-fitting brain array coil (Keil et al., 2013) was
                      used for data acquisition.
                    </div>
                  </div> */}
                  </div>
                  {spareData.data?.documentFiles?.length > 0 && (
                    <div className="detailed-device-heading-parent">
                      <div className="detailed-device-heading1">Documents</div>
                      <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                        {spareData.data.documentFiles.map((link, index) => (
                          <a
                            key={index}
                            href={link} // Use the appropriate property for the file URL
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="detailed-device-download-items">
                              <div className="detailed-device-file-upload-item-base">
                                <img
                                  className="detailed-device-progress-fill-icon"
                                  alt=""
                                  src={progress_fill}
                                />
                                <div className="detailed-device-file-type-icon">
                                  <img
                                    className="detailed-device-page-icon"
                                    alt=""
                                    src={page}
                                  />
                                  <b className="detailed-device-file-type">PDF</b>
                                </div>
                                <div className="detailed-device-download-info">
                                  <div className="detailed-device-text12">
                                    {spareData.data.spares_files[index].file_name} {/* Access file name from the current file object */}
                                  </div>
                                  <div className="detailed-device-supporting-text3">
                                    {(spareData.data.spares_files[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="detailed-spare-heading15">Reviews (2)</div>
                  <div className="detailed-spare-filters-bar">
                    <div className="detailed-spare-content4">
                      <div className="detailed-spare-button-group">
                        <div className="detailed-spare-button-group-base">
                          <div className="detailed-spare-text18">Newest</div>
                        </div>
                        <div className="detailed-spare-button-group-base1">
                          <div className="detailed-spare-text19">Highest</div>
                        </div>
                        <div className="detailed-spare-button-group-base2">
                          <div className="detailed-spare-text20">Text</div>
                        </div>
                        <div className="detailed-spare-button-group-base3">
                          <div className="detailed-spare-text20">Text</div>
                        </div>
                        <div className="detailed-spare-button-group-base4">
                          <div className="detailed-spare-text20">Text</div>
                        </div>
                        <div className="detailed-spare-button-group-base5">
                          <div className="detailed-spare-text20">Text</div>
                        </div>
                        <div className="detailed-spare-button-group-base6">
                          <div className="detailed-spare-text20">Text</div>
                        </div>
                        <div className="detailed-spare-button-group-base7">
                          <div className="detailed-spare-text20">Text</div>
                        </div>
                        <div className="detailed-spare-button-group-base8">
                          <div className="detailed-spare-text26">Lowest</div>
                        </div>
                      </div>
                      <div className="detailed-spare-actions">
                        <div className="detailed-spare-input-dropdown">
                          <div className="detailed-spare-input-with-label1">
                            <div className="detailed-spare-label">Search</div>
                            <div className="detailed-spare-input1">
                              <div className="detailed-spare-content5">
                                <img
                                  className="detailed-spare-search-lg-icon"
                                  alt=""
                                  src={searchlg}
                                />

                                <div className="detailed-spare-text27">Thomson Medical</div>
                                <div className="detailed-spare-supporting-text9">@olivia</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="detailed-spare-button">
                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={filterlines}
                          />

                          <div className="detailed-spare-text-padding">
                            <div className="detailed-spare-text28">Filters</div>
                          </div>
                          <img
                            className="detailed-spare-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-review-container">
                    <div className="detailed-spare-review-content">
                      <div className="detailed-spare-first-review">
                        <b className="detailed-spare-empty-review">5.0</b>
                        <div className="detailed-spare-reviewer">
                          <div className="detailed-spare-heading16">/5</div>
                        </div>
                      </div>
                      <div className="detailed-spare-review-stars-background">
                        <img
                          className="detailed-spare-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-spare-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-spare-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-spare-star-background-icon"
                          alt=""
                          src={star_background}
                        />

                        <img
                          className="detailed-spare-star-background-icon"
                          alt=""
                          src={star_background}
                        />
                      </div>
                    </div>
                    <div className="detailed-spare-review-stars">
                      <div className="detailed-spare-rectangle-parent1">
                        <div className="detailed-spare-rectangle-div"></div>
                        <div className="detailed-spare-stars">
                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-spare-first-review-fifth-star">
                          <div className="detailed-spare-heading17">Accuracy (5.0)</div>
                        </div>
                      </div>
                      <div className="detailed-spare-rectangle-parent1">
                        <div className="detailed-spare-rectangle-div"></div>
                        <div className="detailed-spare-stars">
                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-spare-heading-wrapper">
                          <div className="detailed-spare-heading18">Relevance (5.0)</div>
                        </div>
                      </div>
                      <div className="detailed-spare-rectangle-parent3">
                        <div className="detailed-spare-rectangle-div"></div>
                        <div className="detailed-spare-stars-wrapper">
                          <div className="detailed-spare-stars2">
                            <img
                              className="detailed-spare-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-spare-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-spare-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-spare-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-spare-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="detailed-spare-heading19">Value for Money (5.0)</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-divider-wrapper">
                    <img
                      className="detailed-spare-divider-icon3"
                      alt=""
                      src={divider1}
                    />
                  </div>
                  <div className="detailed-spare-client-review">
                    <img
                      className="detailed-spare-client-review-child"
                      alt=""
                      src={group_78_2x}
                    />

                    <div className="detailed-spare-client-review-header">
                      <div className="detailed-spare-heading20">Chloe Tammy</div>
                      <div className="detailed-spare-heading21">
                        <span>Marketing Communications at</span>
                        <span className="detailed-spare-alvo-medical"> Alvo Medical</span>
                      </div>
                    </div>
                    <div className="detailed-spare-client-review-content">
                      <div className="detailed-spare-client-rating">
                        <div className="detailed-spare-stars3">
                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-spare-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-spare-rating-value">
                          <div className="detailed-spare-heading22">1 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-service-card">
                  <div className="detailed-spare-card-content">
                    <div className="detailed-spare-card-content-child"></div>
                    <div className="detailed-spare-card-header">
                      <div className="detailed-spare-price-tag">
                        <TransactionTypeComponent transactionType={spareData.data.tax_transaction_type.name} slug={spareData.data.tax_transaction_type.slug} />
                        <div className="detailed-spare-price-parent">
                          <div className="detailed-spare-price">${formatNumber(spareData.data.price)}</div>
                          <div className="detailed-spare-original-price">
                            <div className="detailed-spare-discount">{spareData.data.tax_unit_measure_device_spare.name}</div>
                          </div>
                        </div>
                        <div className="detailed-spare-service-title-container">
                          <div className="detailed-spare-service-title">Posted By {spareData.data.tax_your_role.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-spare-card-footer">
                      {spareData.data.profileImage ?
                        <img
                          className="detailed-spare-card-footer-child"
                          alt=""
                          src={spareData.data.profileImage}
                        />
                        :

                        <img
                          className="detailed-spare-card-footer-child"
                          alt=""
                          src={group_781_2x}
                        />
                      }
                      <div className="detailed-spare-footer-divider">
                        <img
                          className="detailed-spare-divider-icon4"
                          alt=""
                          src={divider2}
                        />
                      </div>
                    </div>
                    <div className="detailed-spare-professional-info">
                      <div className="detailed-spare-professional-details">
                        <div className="detailed-spare-professional-actions">
                          <div className="detailed-spare-benefits-container">
                            <div className="detailed-spare-amlie-laurent">{spareData.data.spare_business.business_user.displayName}</div>
                            <Link className="detailed-device-role" to={`/business/${spareData.data.spare_business.id}/${spareData.data.spare_business.businessName}`}>
                              {spareData.data.spare_business.businessName}
                            </Link>
                            {/* <div className="detailed-spare-role">{spareData.data.spare_business.businessName}</div> */}
                          </div>
                          <div className="detailed-spare-action-buttons">
                            <div className="detailed-spare-contact-button">
                              <div className="detailed-spare-message-button">
                                <div className="detailed-spare-navigation">
                                  <img
                                    className="detailed-spare-bookmark-check-icon"
                                    alt=""
                                    src={bookmarkcheck}
                                  />
                                </div>
                                <div className="detailed-spare-supporting-text10">
                                  Verified by Gerätor
                                </div>
                              </div>
                            </div>
                            <div className="detailed-spare-supporting-text11">
                              Adani Health conforms to Gerätor's requirements for
                              verification
                            </div>
                          </div>
                          <div className="detailed-spare-favorite-button">
                            <div className="detailed-spare-button-container2">
                              <div className="detailed-spare-buttonsbutton5">
                                <img
                                  className="detailed-spare-filter-lines-icon"
                                  alt=""
                                  src={messagechatcircle}
                                />

                                <div className="detailed-spare-text30">Private Message</div>
                              </div>
                              {/* Add To Contact */}
                              {!spareData.data.isAuthor && (
                                (spareData.data.addedToContact) ?
                                  <div className="detailed-spare-buttonsbutton6" onClick={() => handleRemoveContact(spareData.data.spare_business.business_user.id)} style={{ cursor: 'pointer' }}>
                                    <img
                                      className="detailed-spare-search-lg-icon"
                                      alt=""
                                      src={users01}
                                    />
                                  </div>
                                  :
                                  <div className="detailed-spare-buttonsbutton6" onClick={() => handleAddContact(spareData.data.spare_business.business_user.id)} style={{ cursor: 'pointer' }}>
                                    <img
                                      className="detailed-spare-search-lg-icon"
                                      alt=""
                                      src={usercheck02}
                                    />
                                  </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-dropdown-menu">
                          <div className="detailed-spare-dropdown1">
                            <img
                              className="detailed-spare-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-rectangle-parent4">
                    <div className="detailed-spare-frame-child6"></div>
                    <div className="detailed-spare-testimonial-header">
                      <div className="detailed-spare-header-content1">
                        <div className="detailed-spare-heading23">Jobs</div>
                        <div className="detailed-spare-testimonial-description">
                          <div className="detailed-spare-description">View All</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-spare-testimonial-list">
                      <img
                        className="detailed-spare-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-spare-testimonial-divider">
                        <div className="detailed-spare-testimonial-item">
                          <div className="detailed-spare-rectangle-parent5">
                            <div className="detailed-spare-frame-child7"></div>
                            <img
                              className="detailed-spare-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-spare-testimonial-details">
                            <div className="detailed-spare-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-spare-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-spare-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-spare-testimonial-divider">
                        <div className="detailed-spare-testimonial-item">
                          <div className="detailed-spare-rectangle-parent5">
                            <div className="detailed-spare-frame-child7"></div>
                            <img
                              className="detailed-spare-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-spare-testimonial-details">
                            <div className="detailed-spare-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-spare-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-spare-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-spare-testimonial-divider">
                        <div className="detailed-spare-testimonial-item">
                          <div className="detailed-spare-rectangle-parent5">
                            <div className="detailed-spare-frame-child7"></div>
                            <img
                              className="detailed-spare-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-spare-testimonial-details">
                            <div className="detailed-spare-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-spare-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-spare-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-spare-testimonial-divider">
                        <div className="detailed-spare-testimonial-item">
                          <div className="detailed-spare-rectangle-parent5">
                            <div className="detailed-spare-frame-child7"></div>
                            <img
                              className="detailed-spare-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-spare-testimonial-details">
                            <div className="detailed-spare-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-spare-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-spare-divider-icon"
                        alt=""
                        src={divider2}
                      />

                      <div className="detailed-spare-testimonial-divider">
                        <div className="detailed-spare-testimonial-item">
                          <div className="detailed-spare-rectangle-parent5">
                            <div className="detailed-spare-frame-child7"></div>
                            <img
                              className="detailed-spare-f4b711e503bc72fe396e5043e0c2-7-icon"
                              alt=""
                              src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                            />
                          </div>
                          <div className="detailed-spare-testimonial-details">
                            <div className="detailed-spare-client-name">
                              WHI Program assistant / Undergraduate researcher
                            </div>
                            <div className="detailed-spare-heading24">Marketing Fusion SARL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid">
            <div className="detailed-spare-paragraph37">
              One of the best offers I found in the market
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid1">
            <div className="detailed-spare-paragraph35">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.
            </div>
          </div>
          <div className="detailed-spare-client-feedback">
            <div className="detailed-spare-feedback-content">
              <img
                className="detailed-spare-feedback-content-child"
                alt=""
                src={group_78_2x}
              />

              <div className="detailed-spare-feedback-header">
                <div className="detailed-spare-header-info">
                  <div className="detailed-spare-heading20">Chloe Tammy</div>
                  <div className="detailed-spare-heading21">
                    <span>Marketing Communications at</span>
                    <span className="detailed-spare-alvo-medical"> Alvo Medical</span>
                  </div>
                </div>
              </div>
              <div className="detailed-spare-feedback-rating">
                <div className="detailed-spare-buttonsbutton7">
                  <img
                    className="detailed-spare-filter-lines-icon"
                    alt=""
                    src={messagedotscircle}
                  />

                  <div className="detailed-spare-text35">Reply</div>
                </div>
                <div className="detailed-spare-feedback-score">
                  <div className="detailed-spare-stars-parent">
                    <div className="detailed-spare-stars">
                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                    <div className="detailed-spare-rating-value">
                      <div className="detailed-spare-heading22">1 month ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-client-message">
            <div className="detailed-spare-message-content">
              <div className="detailed-spare-paragraph39">
                One of the best offers I found in the market
              </div>
              <div className="detailed-spare-paragraph26">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
          <div className="detailed-spare-view-replies">
            <div className="detailed-spare-buttonsbutton8">
              <img
                className="detailed-spare-filter-lines-icon"
                alt=""
                src={messagedotscircle}
              />

              <div className="detailed-spare-text35">Reply</div>
            </div>
          </div>
          <div className="detailed-spare-pagination-wrapper">
            <div className="detailed-spare-pagination">
              <div className="detailed-spare-button-wrap">
                <div className="detailed-spare-buttonsbutton9">
                  <img
                    className="detailed-spare-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="detailed-spare-text-padding">
                    <div className="detailed-spare-page-number">Previous</div>
                  </div>
                  <img
                    className="detailed-spare-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />
                </div>
              </div>
              <div className="detailed-spare-details">1 of 10</div>
              <div className="detailed-spare-button-wrap1">
                <div className="detailed-spare-buttonsbutton10">
                  <img
                    className="detailed-spare-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="detailed-spare-text-padding">
                    <div className="detailed-spare-text37">Next</div>
                  </div>
                  <img
                    className="detailed-spare-placeholder-icon4"
                    alt=""
                    src={placeholder1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid1">
            <img className="detailed-spare-divider-icon3" alt="" src={divider1} />
          </div>
          <div className="detailed-spare-review-form-wrapper">
            <div className="detailed-spare-review-form">
              <div className="detailed-spare-review-form-child"></div>
              <div className="detailed-spare-breadcrumb-container-parent">
                <div className="detailed-spare-heading32">Write Review</div>
                <div className="detailed-spare-frame-parent3">
                  <div className="detailed-spare-heading-container">
                    <div className="detailed-spare-heading33">Accuracy</div>
                    <div className="detailed-spare-listing-detail-item-date-parent">
                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                  <div className="detailed-spare-heading-container">
                    <div className="detailed-spare-heading33">Relevance</div>
                    <div className="detailed-spare-listing-detail-item-date-parent">
                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                  <div className="detailed-spare-heading-container">
                    <div className="detailed-spare-heading35">Value for Money</div>
                    <div className="detailed-spare-listing-detail-item-date-parent">
                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />

                      <img
                        className="detailed-spare-filter-lines-icon"
                        alt=""
                        src={star_icon}
                      />
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-input-field">
                  <div className="detailed-spare-input-with-label1">
                    <div className="detailed-spare-label2">Title</div>
                    <div className="detailed-spare-content6">
                      <div className="detailed-spare-textarea-input-field">
                        <div className="detailed-spare-input-with-label3">
                          <div className="detailed-spare-label3">Description</div>
                          <div className="detailed-spare-input2">
                            <div className="detailed-spare-text38">70 characters</div>
                          </div>
                        </div>
                        <div className="detailed-spare-hint-text1">10 characters left</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-hint-text">This is a hint text to help user.</div>
                </div>
                <div className="detailed-spare-input-field1">
                  <div className="detailed-spare-input-with-label1">
                    <div className="detailed-spare-label4">Review</div>
                    <div className="detailed-spare-content6">
                      <div className="detailed-spare-textarea-input-field1">
                        <div className="detailed-spare-input-with-label3">
                          <div className="detailed-spare-label3">Description</div>
                          <div className="detailed-spare-input3">
                            <div className="detailed-spare-text39">1000 characters</div>
                          </div>
                        </div>
                        <div className="detailed-spare-hint-text3">40 characters left</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-hint-text">This is a hint text to help user.</div>
                </div>
              </div>
              <div className="detailed-spare-submit-button-wrapper">
                <div className="detailed-spare-button1">
                  <img
                    className="detailed-spare-chevron-down-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <img className="detailed-spare-log-in-04-icon" alt="" src={login04} />

                  <div className="detailed-spare-navigation">
                    <div className="detailed-spare-text40">Submit</div>
                  </div>
                  <img
                    className="detailed-spare-chevron-down-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid3">
            <img className="detailed-spare-divider-icon11" alt="" src={divider3} />
          </div>
          <div className="detailed-spare-listing-image-grid4">
            <div className="detailed-spare-heading-parent3">
              <div className="detailed-spare-heading36">Related Devices</div>
              <div className="detailed-spare-text-wrapper">
                <div className="detailed-spare-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid5">
            <div className="detailed-spare-frame-parent4">
              <div className="detailed-spare-frame-parent5">
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay3">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-badge-group">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton14">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-work-four-wrapper">
                        <div className="detailed-spare-heading-container">
                          <div className="detailed-spare-listing-detail-item-location">
                            <div className="detailed-spare-listing-detail-item-date-parent">
                              <div className="detailed-spare-listing-detail-item-date">
                                <img
                                  className="detailed-spare-chevron-right-icon2"
                                  alt=""
                                  src={markerpin021}
                                />
                              </div>
                              <div className="detailed-spare-location">India, New Delhi</div>
                            </div>
                            <div className="detailed-spare-listing-detail-item-date-parent">
                              <div className="detailed-spare-listing-detail-item-date">
                                <img
                                  className="detailed-spare-chevron-right-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="detailed-spare-type-of-work">April 09, 2023</div>
                            </div>
                          </div>
                          <div className="detailed-spare-listing-team">
                            <div className="detailed-spare-listing-team-member-wrapper">
                              <div className="detailed-spare-listing-team-member">4.9/5</div>
                            </div>
                            <div className="detailed-spare-contact-role">
                              <div className="detailed-spare-supporting-text12">99,999 reviews</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-spare-agent-details-icon">
                        <img className="detailed-spare-icon4" alt="" src={icon1} />

                        <div className="detailed-spare-agent-extra-info">
                          <div className="detailed-spare-listing-detail-item-pin-wrapper">
                            <div className="detailed-spare-breadcrumb-items">
                              <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-spare-supporting-text-wrapper">
                                <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-spare-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-spare-another-agent-info">
                            <div className="detailed-spare-detail-items">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-testiomonial-carousel-arrow2">
                  <img
                    className="detailed-spare-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-spare-testiomonial-carousel-arrow3">
                <img
                  className="detailed-spare-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid4">
            <div className="detailed-spare-heading-parent3">
              <div className="detailed-spare-heading36">Related Spares</div>
              <div className="detailed-spare-testimonial-description">
                <div className="detailed-spare-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid5">
            <div className="detailed-spare-frame-parent4">
              <div className="detailed-spare-frame-parent5">
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay3">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-badge-group">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton14">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-work-four-wrapper">
                        <div className="detailed-spare-heading-container">
                          <div className="detailed-spare-listing-detail-item-location">
                            <div className="detailed-spare-listing-detail-item-date-parent">
                              <div className="detailed-spare-listing-detail-item-date">
                                <img
                                  className="detailed-spare-chevron-right-icon2"
                                  alt=""
                                  src={markerpin021}
                                />
                              </div>
                              <div className="detailed-spare-location">India, New Delhi</div>
                            </div>
                            <div className="detailed-spare-listing-detail-item-date-parent">
                              <div className="detailed-spare-listing-detail-item-date">
                                <img
                                  className="detailed-spare-chevron-right-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="detailed-spare-type-of-work">April 09, 2023</div>
                            </div>
                          </div>
                          <div className="detailed-spare-listing-team">
                            <div className="detailed-spare-listing-team-member-wrapper">
                              <div className="detailed-spare-listing-team-member">4.9/5</div>
                            </div>
                            <div className="detailed-spare-contact-role">
                              <div className="detailed-spare-supporting-text12">99,999 reviews</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-spare-agent-details-icon">
                        <img className="detailed-spare-icon4" alt="" src={icon1} />

                        <div className="detailed-spare-agent-extra-info">
                          <div className="detailed-spare-listing-detail-item-pin-wrapper">
                            <div className="detailed-spare-breadcrumb-items">
                              <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-spare-supporting-text-wrapper">
                                <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-spare-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-spare-another-agent-info">
                            <div className="detailed-spare-detail-items">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-testiomonial-carousel-arrow4">
                  <img
                    className="detailed-spare-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-spare-testiomonial-carousel-arrow5">
                <img
                  className="detailed-spare-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid4">
            <div className="detailed-spare-heading-parent3">
              <div className="detailed-spare-heading38">Related Services</div>
              <div className="detailed-spare-testimonial-description">
                <div className="detailed-spare-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid5">
            <div className="detailed-spare-frame-parent4">
              <div className="detailed-spare-frame-parent5">
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-listing-card-badge-overlay">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-rectangle-parent30">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay3">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-badge-group">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton14">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-icon-group">
                        <img className="detailed-spare-icon4" alt="" src={icon1} />

                        <div className="detailed-spare-message-content">
                          <div className="detailed-spare-listing-detail-item-pin-wrapper">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-supporting-work-five">
                            <div className="detailed-spare-breadcrumb-items">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-supporting-six-wrapper">
                            <div className="detailed-spare-breadcrumb-items">
                              <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-spare-supporting-text-wrapper">
                                <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-divider-container">
                            <img
                              className="detailed-spare-divider-icon3"
                              alt=""
                              src={divider4}
                            />
                          </div>
                          <div className="detailed-spare-team-info-four">
                            <div className="detailed-spare-detail-items">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-testiomonial-carousel-arrow6">
                  <img
                    className="detailed-spare-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-spare-testiomonial-carousel-arrow7">
                <img
                  className="detailed-spare-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          {/* <div className="detailed-spare-breadcrumb-button-base4">
            <div className="detailed-spare-text6">...</div>
          </div> */}
          {/* <img
            className="detailed-spare-chevron-right-icon3"
            alt=""
            src={chevronright}
          />
  
          <div className="detailed-spare-breadcrumb-button-base5">
            <div className="detailed-spare-text97">Another link</div>
          </div>
          <img
            className="detailed-spare-chevron-right-icon4"
            alt=""
            src={chevronright}
          /> */}

          <img className="detailed-spare-divider-icon24" alt="" src="/detailed_listing/divider5.svg" />

          {/* <div className="detailed-spare-actions1">
          <div className="detailed-spare-button2">
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
  
            <div className="detailed-spare-text-padding4">
              <div className="detailed-spare-text98">Tertiary</div>
            </div>
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
          </div>
          <div className="detailed-spare-button3">
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
  
            <div className="detailed-spare-text-padding5">
              <div className="detailed-spare-text98">Secondary</div>
            </div>
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
          </div>
          <div className="detailed-spare-button4">
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
  
            <div className="detailed-spare-text-padding5">
              <div className="detailed-spare-text98">Secondary</div>
            </div>
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="detailed-spare-button5">
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
  
            <div className="detailed-spare-text-padding7">
              <div className="detailed-spare-text98">Primary</div>
            </div>
            <img
              className="detailed-spare-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
          </div>
        </div> */}
          <div className="detailed-spare-listing-image-grid4">
            <div className="detailed-spare-heading-parent3">
              <div className="detailed-spare-heading36">Related Software</div>
              <div className="detailed-spare-testimonial-description">
                <div className="detailed-spare-description">View All</div>
              </div>
            </div>
          </div>
          <div className="detailed-spare-listing-image-grid11">
            <div className="detailed-spare-frame-parent4">
              <div className="detailed-spare-frame-parent5">
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-rectangle-parent30">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-rectangle-parent30">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-rectangle-parent30">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-short-listing-team">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton11">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-listing-detail-item-value">
                        <div className="detailed-spare-listing-detail-item-pin-wrapper">
                          <div className="detailed-spare-heading-container">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-team">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-spare-listing">
                          <div className="detailed-spare-breadcrumb-items">
                            <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                            <div className="detailed-spare-supporting-text-wrapper">
                              <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-spare-divider-icon"
                          alt=""
                          src={divider4}
                        />

                        <div className="detailed-spare-listing-detail-item-value-inner">
                          <div className="detailed-spare-footer-social-parent">
                            <div className="detailed-spare-footer-social">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                            <div className="detailed-spare-listing-short-detail-item-date">
                              <img
                                className="detailed-spare-icon1"
                                alt=""
                                src={icon1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-rectangle-parent10">
                  <div className="detailed-spare-frame-child12"></div>
                  <div className="detailed-spare-rectangle-parent30">
                    <img
                      className="detailed-spare-listing-card-badge-overlay-child"
                      alt=""
                      src={rectangle_95_2x}
                    />

                    <div className="detailed-spare-image-overlay3">
                      <div className="detailed-spare-badge4">
                        <div className="detailed-spare-listing-card-price">Assured</div>
                      </div>
                      <div className="detailed-spare-badge-group">
                        <div className="detailed-spare-badge5">
                          <div className="detailed-spare-listing-card-radar">ID# AXJYN</div>
                        </div>
                        <div className="detailed-spare-buttonsbutton14">
                          <img
                            className="detailed-spare-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-spare-listing-detail-item">
                    <div className="detailed-spare-message-content">
                      <div className="detailed-spare-text-frame">
                        <div className="detailed-spare-text42">
                          New / Surplus 2021 Philips Health-care HD11 XE
                          (P/N:23A...
                        </div>
                      </div>
                      <div className="detailed-spare-icon-group">
                        <img className="detailed-spare-icon4" alt="" src={icon1} />

                        <div className="detailed-spare-message-content">
                          <div className="detailed-spare-listing-detail-item-pin-wrapper">
                            <div className="detailed-spare-listing-detail-item-location">
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-spare-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-spare-listing-detail-item-date-parent">
                                <div className="detailed-spare-listing-detail-item-date">
                                  <img
                                    className="detailed-spare-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-spare-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-supporting-work-five">
                            <div className="detailed-spare-breadcrumb-items">
                              <div className="detailed-spare-listing-team-member-wrapper">
                                <div className="detailed-spare-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-spare-contact-role">
                                <div className="detailed-spare-supporting-text12">
                                  99,999 reviews
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-supporting-six-wrapper">
                            <div className="detailed-spare-breadcrumb-items">
                              <div className="detailed-spare-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-spare-supporting-text-wrapper">
                                <div className="detailed-spare-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-spare-divider-container">
                            <img
                              className="detailed-spare-divider-icon3"
                              alt=""
                              src={divider4}
                            />
                          </div>
                          <div className="detailed-spare-team-info-four">
                            <div className="detailed-spare-detail-items">
                              <img
                                className="detailed-spare-footer-social-child"
                                alt=""
                                src={group_58_2x}
                              />

                              <div className="detailed-spare-listing-short-detail-item-pin-parent">
                                <div className="detailed-spare-listing-short-detail-item-pin">
                                  <div className="detailed-spare-name">Olivia Rhye</div>
                                </div>
                                <div className="detailed-spare-role1">Business Name</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-spare-testiomonial-carousel-arrow8">
                  <img
                    className="detailed-spare-arrow-left-icon"
                    alt=""
                    src={arrowleft}
                  />
                </div>
              </div>
              <div className="detailed-spare-testiomonial-carousel-arrow9">
                <img
                  className="detailed-spare-arrow-left-icon"
                  alt=""
                  src={arrowright}
                />
              </div>
            </div>
          </div>
          <FooterDetailPage />
        </div>
      </div>
    )
  } else {
    return <Loader />;
  }

}

