import React, { useEffect, useRef, useState } from 'react';
import "./ContactComponent.css";
import plus from "../../icons/Gerator/contacts/plus.svg";
import uploadcloud02 from "../../icons/Gerator/contacts/uploadcloud02.svg";
import dotsvertical from "../../icons/Gerator/contacts/dotsvertical.svg";
import divider from "../../icons/Gerator/contacts/divider.svg";
import searchlg from "../../icons/Gerator/contacts/searchlg.svg";
import filterlines from "../../icons/Gerator/contacts/filterlines.svg";
import arrowdown from "../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../icons/Gerator/contacts/help_icon.svg";
import arrowright from "../../icons/Gerator/contacts/arrowright.svg"
import arrowleft from "../../icons/Gerator/contacts/arrowleft.svg"
import ContactFilterButtonGroup from '../ContactFilterButtonGroup/ContactFilterButtonGroup';

import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';
import { auth, onAuthStateChanged } from '../../screens/Login/FirebaseClient';

import { SlideOutMenu } from '../SlideOutMenu/SlideOutMenu';
import { useDeleteContactMutation, useGetUserDashboardContactsQuery } from '../../api/ContactAPI';
import { useNavigate } from 'react-router-dom';
import { NoListingFound } from '../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { ThreeDotsListingComponent } from '../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { ThreeDotsContactListingComponent } from '../ThreeDotsListingComponent/ThreeDotsContactListingComponent';

export const ContactComponent = () => {

  // const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [isMenuVisible, setMenuVisible] = useState(false);

  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCountContact, settotalCountContact] = useState("");


  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardContactsQuery(
    { offset, sortBy, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  const [deleteRadarDevice] = useDeleteContactMutation();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);


  const handleImageClick = async (deviceId) => {
    // setToggledImages(prev => ({
    //   ...prev,
    //   [deviceId]: !prev[deviceId]
    // }));
    await deleteRadarDevice(deviceId);
    window.location.reload();
  };


  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     // setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  // const handleThreeDotsClick = (id) => {
  //   console.log("Three dots clicked for id:", id);
  //   // setActiveThreeDotId(activeThreeDotId === index ? null : index);
  //   // if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     // setIsClickBlocked(true);
  //     // setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    // if (activePostStatus === filterLabel) {
    //   setSortBy('date_desc')
    //   setSelectedSortOption('')
    //   setActivePostStatusFilter(null);
    // } else {
    //   setSortBy('date_desc')
    //   setSelectedSortOption('')
    //   setActivePostStatusFilter(filterLabel);
    // }
    // setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.contact.length < data.totalCountContact &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleThreeDotsClick = (id) => {
    console.log("Three dots clicked for id:", id);
    if (activeThreeDotId === id) {
      setActiveThreeDotId(null);
    } else {
      setActiveThreeDotId(id);
    }
  };

  // Modified click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both the three dots icon and the dropdown
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        (!dropdownRef.current || !dropdownRef.current.contains(event.target))
      ) {
        setActiveThreeDotId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  // useEffect(() => {
  //   const handleThreeDotClickOutside = (event) => {
  //     if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
  //       setActiveThreeDotId(null); // Close any open dropdown when clicking outside
  //     }
  //   };

  //   document.addEventListener("mousedown", handleThreeDotClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleThreeDotClickOutside);
  //   };
  // }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      settotalCountContact(data.totalCountContact);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-device"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.contact.length > 0) {
      // dataLength = data.contact.length;
      content = data.contact.map((contact, index) =>
      (
        <div className="contact-component-table-header-cell-parent" key={index}>


          <div className="contact-component-table-cell10">
            {/* <div className="contact-component-cell-content">
              <input className="contact-component-checkbox-wrapper" type="checkbox" />
            </div> */}
            <div className="contact-component-avatar" style={{
              backgroundImage: contact.profileImage
                && `url(${contact.profileImage})`
            }}>
              <div className="contact-component-contrast-border">
                {/* <div className="contact-component-avatar-online-indicator"></div> */}
              </div>
            </div>
            <div className="contact-component-dropdown1">
              <div className="contact-component-cell-title">{`${contact.user_usermeta.firstName} ${contact.user_usermeta.lastName}`}</div>
              <div className="contact-component-supporting-text4">{contact.user_usermeta.userWorkTitle}</div>
            </div>
          </div>

          <div className="contact-component-table-cell20">
            <div className="contact-component-location-content">
              <img
                className="contact-component-upload-cloud-02-icon"
                alt=""
                src={markerpin02}
              />
              <div className="contact-component-text36">India, {contact.user_usermeta.userState}</div>
            </div>
          </div>
          <div className="contact-component-table-cell30">
            <div className="contact-component-text47">2 hours back</div>
          </div>


          <div className="contact-component-table-cell40">
            {/* <div className="contact-component-dropdown" onClick={() => {
              toggleThreeDotsDropdown(index)
            }
            } ref={threeDotsRef}>
              <img
                className="contact-component-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div> */}
            {/* <div className="contact-component-dropdown" onClick={() => handleThreeDotsClick(index)} ref={threeDotsRef}> */}
            <div
              className="contact-component-dropdown"
              onClick={(e) => {
                e.stopPropagation(); // Prevent event bubbling
                handleThreeDotsClick(index);
              }}
              ref={threeDotsRef}
            >
              <img src={dotsvertical} alt="options" />
            </div>
            {/* {activeThreeDotId === index && (
              <ThreeDotsContactListingComponent id={contact.contact_pk_id} />)} */}
            {activeThreeDotId === index && (
              <div ref={dropdownRef}>
                <ThreeDotsContactListingComponent
                  id={contact.contact_pk_id}
                  onClose={() => setActiveThreeDotId(null)}
                />
              </div>
            )}
          </div>

        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path="#" />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  return (
    <div className="contact-component-prsopective-buyers">
      <header className="contact-component-main">
        <div className="contact-component-card-header">
          <div className="contact-component-content1">
            <div className="contact-component-text-and-supporting-text1">
              <div className="contact-component-text-and-badge">
                <a className="contact-component-text2">Contact</a>
                <div className="contact-component-badge">
                  <div className="contact-component-text3">240 have shown interest</div>
                </div>
              </div>
              <div className="contact-component-supporting-text1">
                These users have signaled an interest in your listing
              </div>
            </div>
            <div className="contact-component-actions1" style={{ position: "relative" }}>
              <div className="contact-component-button">
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="../../../placeholder2.svg"
                />

                <div className="contact-component-text-padding2">
                  <div className="contact-component-text4">Tertiary</div>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </div>
              <div className="contact-component-button1">
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="contact-component-text-padding3">
                  <div className="contact-component-text4">Secondary</div>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </div>
              <button className="contact-component-button2">
                <img
                  className="contact-component-upload-cloud-02-icon"
                  alt=""
                  src={uploadcloud02}
                />

                <div className="contact-component-text-padding4">
                  <a className="contact-component-text6">Import</a>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </button>
              <button className="contact-component-button3">
                <img
                  className="contact-component-upload-cloud-02-icon"
                  alt=""
                  src={plus}
                />

                <div className="contact-component-text-padding4" >
                  <a className="contact-component-text7">Add Contact</a>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />
              </button>

              {/* <SlideOutMenu isVisible={isMenuVisible} onClose={handleCloseMenu} /> */}

            </div>
            <div className="contact-component-dropdown">
              <img
                className="contact-component-dots-vertical-icon"
                loading="lazy"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <img
            className="contact-component-divider-icon"
            loading="lazy"
            alt=""
            src={divider}
          />
        </div>
        <div className="contact-component-filters-bar">
          <div className="contact-component-content2">

            <ContactFilterButtonGroup
              handleFilterClick={handleFilterClick}
              activePostStatus={activePostStatus}
            />

            <div className="contact-component-actions2">
              <div className="contact-component-input-dropdown">
                <div className="contact-component-input-with-label">
                  <div className="contact-component-label">Search</div>
                  <div className="contact-component-input">
                    <div className="contact-component-content3">
                      <img
                        className="contact-component-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <input
                        className="contact-component-text17"
                        placeholder="Magnetom"
                        type="text"
                      />

                      <div className="contact-component-supporting-text2">@olivia</div>
                    </div>
                  </div>
                </div>
                <div className="contact-component-hint-text">This is a hint text to help user.</div>
              </div>
              <button className="contact-component-button4">
                <img
                  className="contact-component-upload-cloud-02-icon"
                  alt=""
                  src={filterlines}
                />

                <div className="contact-component-text-padding4">
                  <a className="contact-component-text18">Filters</a>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </button>
            </div>
          </div>
        </div>
      </header>

      
      <form className="contact-component-table">
        <div className="contact-component-card-header1">
          <div className="contact-component-content4">
            <div className="contact-component-text-and-supporting-text2">
              <div className="contact-component-text-and-badge1">
                <div className="contact-component-table-header-title">Viewed listing</div>
                <div className="contact-component-badge1">
                  <div className="contact-component-text19">100 users</div>
                </div>
              </div>
              <div className="contact-component-supporting-text3">
                Manage your team members and their account permissions here.
              </div>
            </div>
            <div className="contact-component-actions3">
              <div className="contact-component-button5">
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="contact-component-text-padding7">
                  <div className="contact-component-text20">Tertiary</div>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </div>
              <div className="contact-component-button6">
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="contact-component-text-padding7">
                  <div className="contact-component-text21">Secondary</div>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </div>
              <div className="contact-component-button7">
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />

                <div className="contact-component-text-padding7">
                  <div className="contact-component-text22">Secondary</div>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </div>
              <div className="contact-component-button8">
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />

                <div className="contact-component-text-padding7">
                  <div className="contact-component-text23">Primary</div>
                </div>
                <img
                  className="contact-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder1.svg"
                />
              </div>
            </div>
            <div className="contact-component-dropdown">
              <img
                className="contact-component-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <img className="contact-component-divider-icon" alt="" src="./public/divider.svg" />
        </div>

        {/* Table */}
        <div className="contact-component-table-content">

          <div className="contact-component-table-row">

            {/* Name */}



            <div className="contact-component-table-header-cell1">
              {/* <input className="contact-component-checkbox-wrapper" type="checkbox" /> */}
              <div className="contact-component-table-header">
                <a className="contact-component-column-title">Name</a>
                {/* <img
                  className="contact-component-arrow-down-icon1"
                  alt=""
                  src={arrowdown}
                /> */}
              </div>
            </div>

            {/* <div className="contact-component-header-title">
<a className="contact-component-text24">Name</a>
</div>
<div className="contact-component-header-icon">
<img
  className="contact-component-arrow-down-icon"
  alt=""
  src={arrowdown}
/>
</div> */}

            {/* Location  */}

            <div className="contact-component-table-header-cell2">
              <div className="contact-component-table-header">
                <a className="contact-component-column-title">Location</a>
                <img
                  className="contact-component-arrow-down-icon1"
                  alt=""
                  src={arrowdown}
                />
              </div>
            </div>


            {/* Visisted  */}

            <div className="contact-component-table-header-cell2">
              <div className="contact-component-table-header">
                <a className="contact-component-column-title">Visited</a>

                <img className="contact-component-help-icon" alt="" src={help_icon} />
              </div>
            </div>

            {/* Action  */}

            <div className="contact-component-table-header-cell3">
              <div className="contact-component-table-header2">
                <div className="contact-component-action-column-title">Action</div>
                <img
                  className="contact-component-arrow-down-icon2"
                  alt=""
                  src={arrowdown}
                />
              </div>
            </div>

          </div>


          {/*
           */}
          {content}

        </div>

        {/* Load More Button */}

        <div className="contact-frame-wrapper44">
          <div className="contact-frame-parent72">
            <div className="contact-buttonsbutton-wrapper1">

              <button className='contact-buttonsbutton-wrapper1-child'
              >
                <div className="contact-buttonsbutton28">
                  <img
                    className="contact-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="contact-search-input">
                    <div className="contact-text146">Load More</div>
                  </div>
                </div>
              </button>

            </div>
          </div>
        </div>


        {/* <div className="contact-component-pagination">
        <div className="contact-component-pagination-wrapper">
          <div className="contact-component-button-wrap">
            <button className="contact-component-buttonsbutton2">
              <img
                className="contact-component-upload-cloud-02-icon"
                alt=""
                src={arrowleft}
              />

              <div className="contact-component-text-padding4">
                <div className="contact-component-text56">Previous</div>
              </div>
              <img
                className="contact-component-placeholder-icon3"
                alt=""
                src="./public/placeholder.svg"
              />
            </button>
          </div>
        </div>
        <div className="contact-component-pagination-numbers">
          <div className="contact-component-pagination-number-base">
            <div className="contact-component-content5">
              <div className="contact-component-number">1</div>
            </div>
          </div>
          <div className="contact-component-pagination-number-base1">
            <div className="contact-component-content6">
              <div className="contact-component-number1">2</div>
            </div>
          </div>
          <div className="contact-component-pagination-number-base2">
            <div className="contact-component-content7">
              <div className="contact-component-number2">3</div>
            </div>
          </div>
          <div className="contact-component-pagination-number-base1">
            <div className="contact-component-content8">
              <div className="contact-component-number3">...</div>
            </div>
          </div>
          <div className="contact-component-pagination-number-base1">
            <div className="contact-component-content6">
              <div className="contact-component-number1">8</div>
            </div>
          </div>
          <div className="contact-component-pagination-number-base1">
            <div className="contact-component-content6">
              <div className="contact-component-number1">9</div>
            </div>
          </div>
          <div className="contact-component-pagination-number-base2">
            <div className="contact-component-content11">
              <div className="contact-component-number6">10</div>
            </div>
          </div>
        </div>
        <div className="contact-component-pagination-wrapper">
          <div className="contact-component-button-wrap1">
            <button className="contact-component-buttonsbutton3">
              <img
                className="contact-component-placeholder-icon3"
                alt=""
                src="./public/placeholder.svg"
              />

              <div className="contact-component-text-padding4">
                <div className="contact-component-text57">Next</div>
              </div>
              <img
                className="contact-component-upload-cloud-02-icon"
                alt=""
                src={arrowright}
              />
            </button>
          </div>
        </div>
      </div> */}
      </form>
    </div>
  )
}
