import React from 'react';
import "./ErrorShowComponent.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoAccess from "../../icons/Gerator/business_page/NoAccess.png";

export const ErrorShowComponent = ({ message1, message2 }) => {
  return (
    <div className="no-access-business-no-access-business">
      <section className="no-access-business-empty-state">
        <div className="no-access-business-empty-state1">
          <div className="no-access-business-content">
            <img
              className="no-access-business-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoAccess}
            />

            <div className="no-access-business-text-and-supporting-text">
              <div className="no-access-business-title">{message1 || "Internal server error"}</div>
              {/* <div className="no-access-business-supporting-text">
                You signed up with [email]. To add a business profile, create a new account with your work email (you@yourcompany.com).
              </div> */}
            </div>
          </div>
          {/* <div className="no-access-business-actions">

            <button className="no-access-business-buttonsbutton">
              <img
                className="no-access-business-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-access-business-text-padding">
                <div className="no-access-business-text">New Account</div>
              </div>
              <img
                className="no-access-business-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>


            <button className="no-access-business-buttonsbutton1"  >
              <Link to={message1} className='no-access-business-button-link'>
                <img className="no-access-business-plus-icon" alt="" src={plus} />

                <div className="no-access-business-text-padding">
                  <div className="no-access-business-text1">Add {message2}</div>
                </div>
                <img
                  className="no-access-business-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button>
          </div> */}

          <div className="no-access-business-supporting-text">
            {message2 || "Please wait... while we fix it."}
          </div>
        </div>
      </section>
    </div>
  )
}
