import { SalesIQ } from "../screens/SalesIQ/SalesIQ";
import { Funnel } from "../screens/Funnel/Funnel";
import { DemandCenter } from "../screens/DemandCenter/DemandCenter";
import { SalesIQDeviceDetailPage } from "../screens/SalesIQDetailsPage/SalesIQDeviceDetailPage/SalesIQDeviceDetailPage";
import { SalesIQSpareDetailPage } from "../screens/SalesIQDetailsPage/SalesIQSpareDetailPage/SalesIQSpareDetailPage";
import { SalesIQServiceDetailPage } from "../screens/SalesIQDetailsPage/SalesIQServiceDetailPage/SalesIQServiceDetailPage";
import { SalesIQSoftwareDetailPage } from "../screens/SalesIQDetailsPage/SalesIQSoftwareDetailPage/SalesIQSoftwareDetailPage";
import { SalesIQJobDetailPage } from "../screens/SalesIQDetailsPage/SalesIQJobDetailPage/SalesIQJobDetailPage";



export const salesIqRoutes = [

  {
    path: "/sales-iq",
    element: <SalesIQ />
  },
  {
    path: "/sales-iq/device/:id/*",
    element: <SalesIQDeviceDetailPage />,
  },
  {
    path: "/sales-iq/spare/:id/*",
    element: <SalesIQSpareDetailPage />,
  },
  {
    path: "/sales-iq/service/:id/*",
    element: <SalesIQServiceDetailPage />,
  },
  {
    path: "/sales-iq/software/:id/*",
    element: <SalesIQSoftwareDetailPage />,
  },
  {
    path: "/sales-iq/job/:id/*",
    element: <SalesIQJobDetailPage />,
  },
  {
    path: "/funnel",
    element: <Funnel />,
  },
  {
    path: "/demand-center",
    element: <DemandCenter />,
  }

];
