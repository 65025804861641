import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import "../FunnelSpareComponent/FunnelSpareComponent.css";
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import playstation from '../../../icons/Gerator/sales_iq/playstation.jpeg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png";
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import arrowdown1 from '../../../icons/Gerator/sales_iq/arrowdown1.svg';


import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../NoListing/NoListingFound';
import { formatNumber, getCustomizedDate } from '../../../helper/DeviceHelper';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { NoListingFoundForFilter } from '../../NoListingFoundForFilter/NoListingFoundForFilter';
import { useGetSalesIqDashboardFunnelSparesQuery } from '../../../api/Funnel/FunnelSpareAPI';

export const FunnelOfferedSpareCard = () => {

  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [activePostStatus, setActivePostStatusFilter] = useState(null);

  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetSalesIqDashboardFunnelSparesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.funnel_spare.length < data.totalCount &&
    !isFetching;

  const clearFilters = () => {
    setOffset(0);
    setSortBy('date_desc');
    setSearchTerm('');
    setSelectedSortOption('');
    setDropdownVisible(false);
  }



  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // // Set totalcount in listing counter layout
      // setTotalCount(data.totalCount);
      // settotalCountWantedSpare(data.totalCountWantedSpare);
      // settotalCountWantedService(data.totalCountWantedService);
      // settotalCountWantedDevice(data.totalCountWantedDevice);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <ErrorShowComponent message1={"Internal server error"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.funnel_spare.length > 0) {
      // dataLength = data.funnel_spare.length;
      content = data.funnel_spare.map((spare, index) =>
      (

        <div className="sales-iq-spare-component-sales-iq-content-items" key={index}>
          <div className="sales-iq-spare-component-sales-iq-divider-container1">
            <div className="sales-iq-spare-component-sales-iq-rectangle-parent5">
              <img
                className="sales-iq-spare-component-sales-iq-frame-child7"
                alt=""
                src={spare.featureImage}
              />

              <div className="sales-iq-spare-component-sales-iq-frame-parent1">
                <div className="sales-iq-spare-component-sales-iq-action-fields">
                  <div className="sales-iq-spare-component-sales-iq-input-elements">
                    <Link to={`/spare/${spare.id}/${spare.title}`} className="sales-iq-spare-component-sales-iq-text78">
                      {spare.title}
                    </Link>
                    <div className="sales-iq-spare-component-sales-iq-frame-parent21">
                      <div className="sales-iq-spare-component-sales-iq-badge-item-parent">
                        <div className="sales-iq-spare-component-sales-iq-badge-parent">

                          <PostStatusLIstingComponent postStatus={spare.tax_post_status.name} postSlug={spare.tax_post_status.slug} postId={spare.id} />

                        </div>
                        <div className="sales-iq-spare-component-sales-iq-frame-parent4">
                          <div className="sales-iq-spare-component-sales-iq-end-label">
                            <div className="sales-iq-spare-component-sales-iq-search-icon-input">4.9/5</div>
                          </div>
                          <div className="sales-iq-spare-component-sales-iq-type-support">
                            <div className="sales-iq-spare-component-sales-iq-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>

                      </div>




                      <div className="sales-iq-spare-component-sales-iq-frame-parent24">
                        <div className="sales-iq-spare-component-sales-iq-location-pin-parent">
                          <div className="sales-iq-spare-component-sales-iq-search-input">
                            <img
                              className="sales-iq-spare-component-sales-iq-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="sales-iq-spare-component-sales-iq-location3">{spare.spare_address.addressTownCity}, {spare.spare_address.tax_country.name}</div>
                        </div>

                      </div>
                      <div className="sales-iq-spare-component-sales-iq-frame-parent26">
                        <div className="sales-iq-spare-component-sales-iq-frame-wrapper19">
                          <div className="sales-iq-spare-component-sales-iq-legend-series">
                            <div className="sales-iq-spare-component-sales-iq-search-input">
                              <img
                                className="sales-iq-spare-component-sales-iq-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="sales-iq-spare-component-sales-iq-type-of-work2">{getCustomizedDate(spare.createdAt)}</div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>

                  {/* Detail small cards */}

                  <div className="sales-iq-spare-component-sales-iq-small-card">


                    <div className="sales-iq-spare-component-sales-iq-badge33">
                      <div className="sales-iq-spare-component-sales-iq-third-badge-number">
                        {spare.tax_device_category.name}
                      </div>
                    </div>

                    <div className="sales-iq-spare-component-sales-iq-badge36">
                      <div className="sales-iq-spare-component-sales-iq-third-badge-number">
                        11 Health
                      </div>
                    </div>


                    <div className="sales-iq-spare-component-sales-iq-badge37">
                      <div className="sales-iq-spare-component-sales-iq-third-badge-number">{spare.tax_oem.name}

                      </div>

                    </div>



                    <div className="sales-iq-spare-component-sales-iq-badge38">
                      <div className="sales-iq-spare-component-sales-iq-third-badge-number">
                        {spare.tax_clinical_application.name}
                      </div>
                    </div>


                    <div className="sales-iq-spare-component-sales-iq-badge39">
                      <div className="sales-iq-spare-component-sales-iq-third-badge-number">
                        Accounting
                      </div>
                    </div>



                  </div>

                </div>




                <div className="sales-iq-spare-component-sales-iq-filter-elements">
                  <div className="sales-iq-spare-component-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <img
                      className="sales-iq-spare-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotId === index && <ThreeDotsListingComponent url={`spare/${spare.id}`} />}
                  </div>
                  <div className="sales-iq-spare-component-sales-iq-filter-elements-inner">
                    <div className="sales-iq-spare-component-sales-iq-price-parent">
                      <TransactionTypeComponent transactionType={spare.tax_transaction_type.name} slug={spare.tax_transaction_type.slug} />
                      <div className="sales-iq-spare-component-sales-iq-price2">${formatNumber(spare.price)}</div>
                      <div className="sales-iq-spare-component-sales-iq-dropdown-elements">
                        <div className="sales-iq-spare-component-sales-iq-dropdown-options-parent">
                          <div className="sales-iq-spare-component-sales-iq-text83">{spare.tax_unit_measure_device_spare.name}</div>
                          <div className="sales-iq-spare-component-sales-iq-frame-wrapper3">
                            <div className="sales-iq-spare-component-sales-iq-radar-1-1-container">
                              <img
                                className="sales-iq-spare-component-sales-iq-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              <img
                                className="sales-iq-spare-component-sales-iq-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="sales-iq-spare-component-sales-iq-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img className="sales-iq-spare-component-sales-iq-divider-icon8" alt="" src={divider2} />

            <div className="sales-iq-spare-component-sales-iq-metric-item-parent">

              <div className="sales-iq-spare-component-sales-iq-metric-item3">
                <div className="sales-iq-spare-component-sales-iq-heading9">Active engagement</div>
                <div className="sales-iq-spare-component-sales-iq-number-and-chart">
                  <div className="sales-iq-spare-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-spare-component-sales-iq-heading">{spare.totalActiveEngagement || 0}</div>
                    <div className="sales-iq-spare-component-sales-iq-change-and-text3">
                      <div className="sales-iq-spare-component-sales-iq-change2">
                        <img
                          className="sales-iq-spare-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-spare-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-spare-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-spare-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}

                  />
                </div>
                <div className="sales-iq-spare-component-sales-iq-dropdown19">
                  <img
                    className="sales-iq-spare-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                </div>
              </div>

              <div className="sales-iq-spare-component-sales-iq-metric-item3">
                <div className="sales-iq-spare-component-sales-iq-heading9">Views</div>
                <div className="sales-iq-spare-component-sales-iq-number-and-chart">
                  <div className="sales-iq-spare-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-spare-component-sales-iq-heading">{spare.totalCountView}</div>
                    <div className="sales-iq-spare-component-sales-iq-change-and-text3">
                      <div className="sales-iq-spare-component-sales-iq-change2">
                        <img
                          className="sales-iq-spare-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-spare-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-spare-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-spare-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}

                  />
                </div>
                <div className="sales-iq-spare-component-sales-iq-dropdown19">
                  <img
                    className="sales-iq-spare-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                </div>
              </div>
              <div className="sales-iq-spare-component-sales-iq-metric-item3">
                <div className="sales-iq-spare-component-sales-iq-heading9">Added to Radar</div>
                <div className="sales-iq-spare-component-sales-iq-number-and-chart">
                  <div className="sales-iq-spare-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-spare-component-sales-iq-heading">{spare.totalCountRadar}</div>
                    <div className="sales-iq-spare-component-sales-iq-change-and-text3">
                      <div className="sales-iq-spare-component-sales-iq-change2">
                        <img
                          className="sales-iq-spare-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-spare-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-spare-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-spare-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}
                  />
                </div>
                <div className="sales-iq-spare-component-sales-iq-dropdown19">
                  <img
                    className="sales-iq-spare-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                </div>
              </div>
              <div className="sales-iq-spare-component-sales-iq-metric-item3">
                <div className="sales-iq-spare-component-sales-iq-heading9">Searches</div>
                <div className="sales-iq-spare-component-sales-iq-number-and-chart">
                  <div className="sales-iq-spare-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-spare-component-sales-iq-heading">{spare.totalSearch || 0}</div>
                    <div className="sales-iq-spare-component-sales-iq-change-and-text3">
                      <div className="sales-iq-spare-component-sales-iq-change2">
                        <img
                          className="sales-iq-spare-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-spare-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-spare-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-spare-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}
                  />
                </div>
                <div className="sales-iq-spare-component-sales-iq-dropdown19">
                  <img
                    className="sales-iq-spare-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                </div>
              </div>
            </div>

            <img className="sales-iq-spare-component-sales-iq-divider-icon8" alt="" src={divider2} />

            <div className="sales-iq-spare-component-sales-iq-frame-parent71">
              <div className="sales-iq-spare-component-sales-iq-buttonsbutton-parent1">
                <div className="sales-iq-legend-series">
                  <img
                    className="sales-iq-location-column-child"
                    alt=""
                    src={spare.profileImage}
                  />

                  <div className="sales-iq-location-column-inner">
                    <div className="sales-iq-date-picker-dropdown">
                      <div className="sales-iq-name">{spare.spare_business.business_user.displayName}</div>
                      <Link className="sales-iq-role" to={`/business/${spare.spare_business.id}/${spare.spare_business.businessName}`}>
                        {spare.spare_business.businessName}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='sales-iq-spare-component-main-buttons-group'>
                  <div className="sales-iq-spare-component-sales-iq-buttonsbutton13">
                    <img
                      className="sales-iq-spare-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="sales-iq-spare-component-sales-iq-search-input">
                      <div className="sales-iq-spare-component-sales-iq-text67">View</div>
                    </div>
                  </div>
                  {/* <div className="sales-iq-spare-component-sales-iq-buttonsbutton25">
                    <div className="sales-iq-spare-component-sales-iq-icon-wrapper">
                      <img className="sales-iq-spare-component-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="sales-iq-spare-component-sales-iq-search-input">
                      <div className="sales-iq-spare-component-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="sales-iq-spare-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}

                  <Link to={`/sales-iq/spare/${spare.id}/${spare.title}`}>
                    <div className="sales-iq-spare-component-sales-iq-buttonsbutton26" >
                      <div className="sales-iq-spare-component-sales-iq-icon-wrapper">
                        <img className="sales-iq-spare-component-sales-iq-icon1" alt="" src={icon1} />
                      </div>
                      <div className="sales-iq-spare-component-sales-iq-search-input">
                        <div className="sales-iq-spare-component-sales-iq-text68">Sales IQ</div>
                      </div>
                      <img
                        className="sales-iq-spare-component-sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={lockunlocked04}
                      />
                    </div>
                  </Link>

                  <div className="sales-iq-spare-component-sales-iq-buttonsbutton27">
                    <img
                      className="sales-iq-spare-component-sales-iq-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="sales-iq-spare-component-sales-iq-text145">Messages</div>
                    <div className="sales-iq-spare-component-sales-iq-ellipse-parent">
                      <div className="sales-iq-spare-component-sales-iq-ellipse-div"></div>
                      <div className="sales-iq-spare-component-sales-iq-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="sales-iq-spare-component-sales-iq-arrow-square-up-right-wrapper1">
                <img
                  className="sales-iq-spare-component-sales-iq-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    }
    else {
      // content = <NoListingFound path={"/add-wanted-service"} pathName={"Wanted Service"} />
      content = <NoListingFoundForFilter clearFilters={clearFilters} />

    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }

  return (
    <>

      <div className="funnel-section-wrapper">
        <div className="funnel-section1">
          <div className="funnel-container1">
            <div className="funnel-filters-bar">
              <div className="funnel-content17">

                <div className="funnel-input-dropdown2">

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="funnel-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="funnel-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="funnel-text-padding6" ref={dropdownRef}>
                      <div className="funnel-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="funnel-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
                {/* <div className="funnel-input-dropdown2">


                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="funnel-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="funnel-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="funnel-text-padding6" ref={dropdownRef}>
                      <div className="funnel-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="funnel-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Container */}
      <div className="funnel-service-component-listing-container">

        {/* Listing layout start    */}
        {content}

      </div>


      {/* Load more button  */}
      {showLoadMore && (
        <div className="funnel-frame-wrapper44">
          <div className="funnel-frame-parent72">
            <div className="funnel-buttonsbutton-wrapper1">
              <button className='funnel-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="funnel-buttonsbutton28">
                  <img
                    className="funnel-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="funnel-search-input">
                    <div className="funnel-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}


    </>
  )
}

