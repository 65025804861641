import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form, useField, useFormikContext } from "formik";
import "./EditWantedSpareForm.css"
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevrondown_2 from '../../icons/Gerator/add_form/chevrondown-2.svg';
import help_icon from '../../icons/Gerator/add_form/help-icon.svg';
import dotsvertical_1 from '../../icons/Gerator/add_form/dotsvertical-1.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import save02 from '../../icons/Gerator/add_form/save02.svg';
import { fillEditWantedSpareForm, updateEditWantedSpareField, clearEditWantedSpareForm } from "./EditWantedSpareSlice";
import { useDispatch, useSelector } from "react-redux";
import EditWantedSpareFormContext from "../../screens/WantedSpare/EditWantedSpare/EditWantedSpareContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEditWantedSpareMutation, useFindGetWantedSpareWithoutTaxQuery } from "../../api/WantedSpareAPI";
import * as Yup from "yup";
import { decryptErrorData } from "../../helper/AESHelper";

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  {
    fieldValidationData !== null ? (
      <div className="error">{fieldValidationData.msg}</div>
    ) : null
  }

  return (
    <>
      <div className="add-wanted-spare-step-first-upload-inner">
        <div className="add-wanted-spare-step-label2">
          <label className="add-wanted-spare-step-your-role-in" htmlFor={props.id || props.name}>
            {label} <span className="add-wanted-spare-step-span">*</span>
          </label>
        </div>
        <div className="add-wanted-spare-step-input1">
          <div className="add-wanted-spare-step-content8">
            <div className="add-wanted-spare-step-text-input">

              <select
                className="add-wanted-spare-step-first-nested-input account-update-profile-content-custom-23 down-icon-hide"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>
          {/* <div className="add-wanted-spare-step-dropdown">
            <img
              className="add-wanted-spare-step-search-lg-icon"
              alt=""
              src={chevrondown_2}
            />
          </div> */}
        </div>
      </div>

      {meta.touched && meta.error ? (
        <div className="add-wanted-spare-step-hint-text1 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-spare-step-hint-text1 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}
    </>
  );
};

const MyTextInput = ({ validate, label, remainingChars,onChange, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="add-wanted-spare-step-first-upload-inner">
        <div className="add-wanted-spare-step-label3">
          <label className="add-wanted-spare-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-wanted-spare-step-span">*</span>
          </label>
        </div>
        <div className="add-wanted-spare-step-input1">
          <div className="add-wanted-spare-step-content8 add-wanted-device-step-content8-custom">
            <div className="add-wanted-spare-step-text-input">
              <input
                className="add-wanted-spare-step-content12 myinputfield"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
                onChange={(e) => {
                  onChange(e); // Call parent onChange to track remaining characters
                }}

              />
            </div>
          </div>
          {/* <div className="add-wanted-spare-step-dropdown">
            <img
              className="add-wanted-spare-step-search-lg-icon"
              alt=""
              src={chevrondown_2}
            />
          </div> */}
        </div>
      </div>
      {/* <div className="add-wanted-spare-step-hint-text1">
  This is a hint text to help user.
</div> */}

      
     <span>{remainingChars} characters remaining</span> 

      {(meta.touched && meta.error) ? (
        <div className="add-wanted-spare-step-hint-text1 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-spare-step-hint-text1 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}

    </>
  );
};

const MyTextInput_not_mandatory = ({ validate, label,remainingChars,onChange, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="add-wanted-spare-step-first-upload-inner">
        <div className="add-wanted-spare-step-label3">
          <label className="add-wanted-spare-step-transaction-type" htmlFor={props.id || props.name}>
            {label}
          </label>
        </div>
        <div className="add-wanted-spare-step-input1">
          <div className="add-wanted-spare-step-content8 add-wanted-device-step-content8-custom">
            <div className="add-wanted-spare-step-text-input">
              <input
                className="add-wanted-spare-step-content12 myinputfield"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
                onChange={(e) => {
                  onChange(e); // Call parent onChange to track remaining characters
                }}

              />
            </div>
          </div>
          {/* <div className="add-wanted-spare-step-dropdown">
            <img
              className="add-wanted-spare-step-search-lg-icon"
              alt=""
              src={chevrondown_2}
            />
          </div> */}
        </div>
      </div>
      {/* <div className="add-wanted-spare-step-hint-text1">
  This is a hint text to help user.
</div> */}


      <span>{remainingChars} characters remaining</span> 

      {(meta.touched && meta.error) ? (
        <div className="add-wanted-spare-step-hint-text1 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-spare-step-hint-text1 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}

    </>
  );
};

const MyTextInputForCustomSelect = ({ disableBlurValidation, validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      {/* <div className="add-wanted-spare-step-input-field"> */}
      <div className="add-wanted-spare-step-first-upload-inner">
        <div className="add-wanted-spare-step-label1">
          {/* <span className="add-wanted-spare-step-transaction-type"
            >Device Category
            </span> */}
          <label className="add-wanted-spare-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-wanted-spare-step-span">*</span>
          </label>
        </div>
        <div className="add-wanted-spare-step-input1">
          <div className="add-wanted-spare-step-content8 add-wanted-device-step-content8-custom">
            <div className="add-wanted-spare-step-text-input">
              {/* <input
                          className="add-wanted-spare-step-content12"
                          placeholder="Enter Model Name without OEM Brand - max. 30 characters"
                          type="text"
                        /> */}
              <input
                className="add-wanted-spare-step-content12 myinputfield"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
                onBlur={disableBlurValidation ? (e) => e.preventDefault() : field.onBlur}
              />
              {/* <div className="add-wanted-spare-step-first-nested-input">
                  Select From Dropdown list
                </div> */}
            </div>
          </div>
          <div className="add-wanted-spare-step-dropdown">
            <img
              className="add-wanted-spare-step-search-lg-icon"
              alt=""
              src={chevrondown_2}
            />
          </div>
        </div>
      </div>
      {(meta.touched && meta.error) ? (
        <div className="add-wanted-spare-step-hint-text1 error">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="add-wanted-spare-step-hint-text1 error">
          {fieldValidationData.msg}
        </div>
      ) : null}

      {/* </div> */}




    </>
  );
};


export const EditWantedSpareForm = ({ formikRef, setShowSuccessMessage }) => {


  const maxLength = 30;

  const [partNameCharCount, setpartNameCharCount] = useState(maxLength);
  const [partNumberCharCount, setpartNumberCharCount] = useState(maxLength);


  const { id } = useParams();
  const navigate = useNavigate();
  const ulDeviceCategoryRef = useRef(null);
  const ulOemRef = useRef(null);
  const [visibleDeviceCategorySelectField, setVisibleDeviceCategorySelectField] = useState(false)
  const [visibleOemSelectField, setVisibleOemSelectField] = useState(false)
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.editWantedSpare);
  const {oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, setEditWantedSpareSavedData, isRequestFirst, setIsRequestFirst, backendValidation, setBackendValidation, allTaxonomy } = useContext(EditWantedSpareFormContext);

  //useEditWantedSpareMutation responsible to send request backend with edited data
  const [editWantedSpare, { isLoading, isError, error }] = useEditWantedSpareMutation();
  const editDataFromDb = useFindGetWantedSpareWithoutTaxQuery(id);

 //To autofill data in form when loaded with first request only
 useEffect(() => {
  if (editDataFromDb.isSuccess && isRequestFirst) {
    dispatch(fillEditWantedSpareForm(editDataFromDb.data));
    // setValues(editDataFromDb.data);
    setEditWantedSpareSavedData(editDataFromDb.data);
    setIsRequestFirst(false);
    setDeviceCategorySearchField(editDataFromDb.data.tax_device_category.name)
    setOemSearchField(editDataFromDb.data.tax_oem.name)
  }
}, [editDataFromDb.isSuccess]); // Dependencies to trigger the effect

  useEffect(() => {
    formikRef.current?.setValues(formData);
    // console.log(formikRef.current);
  }, [formData]);

  //This method responsible only for clear form after successfull submit
  // const resetForm = () => {
  //   dispatch(clearEditWantedSpareForm())
  //   setBackendValidation([])
  //   setEditWantedSpareSavedData(null)
  //   setDeviceCategorySearchField("");
  //   setOemSearchField("");
  // }

  const handleChange = (event) => {
    const { name, value } = event.target;
    //Saving details into form
    // setFieldValue(name, value);
    if (name === 'deviceCategory') {

    //when deviceCategory field's data edited/removed then this block will run
    if (value.length < deviceCategorySearchField.length || value.length > deviceCategorySearchField.length) {

      //deviceCategory field value is set 0 in form object
      dispatch(updateEditWantedSpareField({ field: name, value: "0" }));
      //deviceCategory field input value is set
      setDeviceCategorySearchField(value);
      //form field deviceCategory is set to touch. then validation error will show 
      formikRef.current?.setFieldTouched(name, true);
        return;
      }
    }

    if (name === 'oem') {

      //Dropdown will be visible
      // setVisibleDeviceCategorySelectField(true)

      //Field value length less than useState varaible then remove data from useState as well
      if (value.length < oemSearchField.length || value.length > oemSearchField.length) {

         //oem field value is set 0 in form object
         dispatch(updateEditWantedSpareField({ field: name, value: "0" }));
         //oem field input value is set
         setOemSearchField(value);
         //form field oem is set to touch. then validation error will show 
         formikRef.current?.setFieldTouched(name, true);
        // console.log(value.length);
        return;
      }
    }
    dispatch(updateEditWantedSpareField({ field: name, value }));
  };

  const handleSelect = (tax, field) => {
    const { id, name } = tax;
    //Saving details into form
    if (field === 'deviceCategory' && id != 0) {
      dispatch(updateEditWantedSpareField({ field, value: id }));
      setDeviceCategorySearchField(name)
      setVisibleDeviceCategorySelectField(false);
      setNewDeviceCategoryLength(50)
      return;
    } else {
      dispatch(updateEditWantedSpareField({ field, value: id }));
      setVisibleDeviceCategorySelectField(true);
      formikRef.current?.setFieldTouched(field, true);
    }

    if (field === 'oem' && id != 0) {
      dispatch(updateEditWantedSpareField({ field, value: id }));
      setOemSearchField(name)
      setVisibleOemSelectField(false);
      setNewOemLength(50)
      return;
    } else {
      dispatch(updateEditWantedSpareField({ field, value: id }));
      setVisibleOemSelectField(false);
      formikRef.current?.setFieldTouched(field, true);
    }
  };

  const handleClick = (event) => {
    const name = event.target.name;
    if (name === "deviceCategory") {
      setVisibleDeviceCategorySelectField(true);
      return;
    }
    if (name === "oem") {
      setVisibleOemSelectField(true);
      // return;
    }

    // setDeviceCategorySearchField();
  }


  const finalSubmit = async (formData, setSubmitting) => {


    try {

      const response = await editWantedSpare({ body: formData }).unwrap();
      //If form was submitted successfully then status return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Wanted Spare added successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        formikRef.current.resetForm();
        window.location.reload();
        // navigate('/user-profile');
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }

  };


    //useEffect to unselect dropdown when outside of deviceCategory and oem clicked
    useEffect(() => {
      const handleClick = (event) => { 
        const deviceCategoryElement = document.getElementById("deviceCategory");
        const oemElement = document.getElementById("oem");
  
       
        // if (ulElement.contains(event.target) || deviceCategoryElement.contains(event.target)) {
        if (!deviceCategoryElement.contains(event.target) ) {
        setVisibleDeviceCategorySelectField(false)
        }
  
        if (!oemElement.contains(event.target) ) {
        setVisibleOemSelectField(false)
        }
        // const dropdown = document.getElementById('custom-dropdown');
       }
  
       document.addEventListener('click', handleClick);
       return () => {
         document.removeEventListener('click', handleClick);
       };
    }, [])
  
  
  
  //useEffect to trigger next 50 tax when scrolling to bottom
    useEffect(() => {
      const handleScroll = (event) => {
  
        const ulChildElement = event.target;
        const scrollTop = ulChildElement.scrollTop;
        const scrollHeight = ulChildElement.scrollHeight;
        const clientHeight = ulChildElement.clientHeight;
  
        // console.log(ulChildElement);
        console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
  
  
        // // Check if the scroll is near the end
        if (scrollTop + clientHeight >= scrollHeight - 10 * (ulChildElement.children[0].offsetHeight)) {
          // const secondLastValue = ulChildElement.children[ulChildElement.children.length - 10].textContent;
          setNewDeviceCategoryLength(newDeviceCategoryLength + ulChildElement.children.length);
          console.log("length of device category " + (newDeviceCategoryLength + ulChildElement.children.length));
        }
        // console.log('ul is being scrolled', event);
      };
  
    
  
      const ulElement = ulDeviceCategoryRef.current;
      // console.log(ulElement);
      if (ulElement) {
        ulElement.addEventListener('scroll', handleScroll);
      }
  
      // Cleanup function to remove the event listener
      return () => {
        if (ulElement) {
          ulElement.removeEventListener('scroll', handleScroll);
        }
      };
  
      
    }, [visibleDeviceCategorySelectField]);
  
  
    
    
  
    //useEffect to trigger next 50 tax when scrolling to bottom
    useEffect(() => {
      const handleScroll = (event) => {
  
        const ulChildElement = event.target;
        const scrollTop = ulChildElement.scrollTop;
        const scrollHeight = ulChildElement.scrollHeight;
        const clientHeight = ulChildElement.clientHeight;
  
        // console.log(ulChildElement);
        console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
  
  
        // // Check if the scroll is near the end
        if (scrollTop + clientHeight >= scrollHeight - 10 * (ulChildElement.children[0].offsetHeight)) {
          // const secondLastValue = ulChildElement.children[ulChildElement.children.length - 10].textContent;
          setNewOemLength(newOemLength + ulChildElement.children.length);
          console.log("length of oem " + (newOemLength + ulChildElement.children.length));
        }
        // console.log('ul is being scrolled', event);
      };
  
  
      const ulElement = ulOemRef.current;
      // console.log(ulElement);
      if (ulElement) {
        ulElement.addEventListener('scroll', handleScroll);
      }
  
      // Cleanup function to remove the event listener
      return () => {
        if (ulElement) {
          ulElement.removeEventListener('scroll', handleScroll);
        }
      };
    }, [visibleOemSelectField]);

    
  useEffect(() => {
    setpartNameCharCount(Math.max(maxLength - (formData.partName?.length || 0), 0));
    setpartNumberCharCount(Math.max(maxLength - (formData.partNumber?.length || 0), 0));
   
  }, [formData]);


  return (
    
    <>
    <Formik
        innerRef={formikRef}
        initialValues={{
          formData
        }}

        validationSchema={Yup.object({

          // deviceCategory: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'
          // ),
          // oem: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'
          // ),

          // partName: Yup.string().required("This is a required field").max(30, 'Must be 30 characters or less'),

          // partNumber: Yup.string().max(30, 'Must be 30 characters or less'),

          // statusCondition: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'

          // ),
          // ageOfDevice: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'

          // ),
          // availability: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'

          // ),

          // clinicalApplications: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'

          // ),
          // purposeUse: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'

          // ),
          // physicalLocation: Yup.string().required("This is a required field").test(
          //   "OPTION", "Please select a valid option",
          //   (value) => value !== '0'

          // ),


        })}

        onSubmit={(values, { setSubmitting }) => {
          // multiPartData = objectToWantedDeviceFormData(values)
          console.log(values);

          finalSubmit(values, setSubmitting);
          // resetForm();
        }}

      >
        {({ handleSubmit, isSubmitting }) => {

          return (
            <>

              <Form className="add-wanted-spare-step-section-header-parent">
                <div className="add-wanted-spare-step-section-header">

                  <div className="add-wanted-spare-step-content7">
                    Basic Information
                  </div>


                  <img
                    className="add-wanted-spare-step-divider-icon2"
                    alt=""
                    src={divider1}
                  />
                </div>
                <div className="add-wanted-spare-step-input-field-parent">
                  <div className="add-wanted-spare-step-input-field">
                    <MyTextInputForCustomSelect
                      label="Device Category"
                      name="deviceCategory"
                      type="text"
                      placeholder="Enter Device Category without OEM Brand - max. 30 characters"
                      id="deviceCategory"
                      disableBlurValidation={true}
                      autoComplete="off"
                      onChange={handleChange} onClick={handleClick} value={deviceCategorySearchField} validate={backendValidation}
                    />
                    <div id="deviceCategoryCustomSelect">
                      {visibleDeviceCategorySelectField &&
                        <ul ref={ulDeviceCategoryRef}>
                          {(allTaxonomy.deviceCategory.isLoading || allTaxonomy.deviceCategory.isError) && <li value="0">Loading...</li>}
                          {(allTaxonomy.deviceCategory.isSuccess && allTaxonomy.deviceCategory.length === 0) && <li value="0">Result not found</li>}
                          {
                            (allTaxonomy.deviceCategory.isSuccess) && (allTaxonomy.deviceCategory.data.map(tax => (
                              <li key={tax.id} onClick={() => handleSelect(tax, 'deviceCategory')}>
                                {tax.name}
                              </li>
                            )))
                          }
                        </ul>
                      }

                    </div>
                  </div>
                  <div className="add-wanted-spare-step-input-field">
                    <MyTextInputForCustomSelect
                      placeholder="Enter OEM Brand - max. 30 characters"
                      type="text"
                      autoComplete="off"
                      disableBlurValidation={true}
                      label="Part Manufacturer" name="oem" id="oem" onChange={handleChange} onClick={handleClick} value={oemSearchField} validate={backendValidation}
                    />

                    <div id="oemCustomSelect">
                      {visibleOemSelectField &&
                        <ul ref={ulOemRef}>
                          {(allTaxonomy.oem.isLoading || allTaxonomy.oem.isError) && <li value="0">Loading...</li>}
                          {
                            (allTaxonomy.oem.isSuccess) && (allTaxonomy.oem.data.map(tax => (
                              <li key={tax.id} onClick={() => handleSelect(tax, 'oem')}>
                                {tax.name}
                              </li>
                            )))
                          }
                        </ul>
                      }

                    </div>
                  </div>
                </div>
                <div className="add-wanted-spare-step-input-field-parent">
                  <div className="add-wanted-spare-step-input-field">
                    <MyTextInput
                      label="Part Name (Without Manufacturer Brand)"
                      name="partName"                                                          //New Code
                      type="text"
                      placeholder="Enter Part name without manufacturer brand - max. 30 characters"
                      id="partName"
                      onChange={handleChange} validate={backendValidation} value={formData.partName}
                      remainingChars={partNameCharCount}
                    />

                  </div>
                  <div className="add-wanted-spare-step-input-field">
                    <MySelect label="Status" name="statusCondition" id="statusCondition" onChange={handleChange} validate={backendValidation} value={formData.statusCondition}>
                      <option value="0">Select From Dropdown List </option>
                      {(allTaxonomy.statusCondition.isLoading || allTaxonomy.statusCondition.isError) && <option value="0">Loading...</option>}
                      {
                        (allTaxonomy.statusCondition.isSuccess) && (allTaxonomy.statusCondition.data.map(tax => (
                          <option key={tax.id} value={tax.id}>{tax.name}</option>
                        )))
                      }

                    </MySelect>

                  </div>
                </div>

                <div className="add-wanted-spare-step-input-field-parent">
                  <div className="add-wanted-spare-step-input-field">
                    <MySelect label="Age of the spare" name="ageOfDevice" id="ageOfDevice" onChange={handleChange} validate={backendValidation} value={formData.ageOfDevice}>
                      <option value="0">Select From Dropdown List </option>
                      {(allTaxonomy.ageOfDevice.isLoading || allTaxonomy.ageOfDevice.isError) && <option value="0">Loading...</option>}
                      {
                        (allTaxonomy.ageOfDevice.isSuccess) && (allTaxonomy.ageOfDevice.data.map(tax => (
                          <option key={tax.id} value={tax.id}>{tax.name}</option>
                        )))
                      }

                    </MySelect>
                  </div>
                  <div className="add-wanted-spare-step-input-field">
                    <MySelect label="Availability" name="availability" id="availability" onChange={handleChange} validate={backendValidation} value={formData.availability}>
                      <option value="0">Select From Dropdown List </option>
                      {(allTaxonomy.availability.isLoading || allTaxonomy.availability.isError) && <option value="0">Loading...</option>}
                      {
                        (allTaxonomy.availability.isSuccess) && (allTaxonomy.availability.data.map(tax => (
                          <option key={tax.id} value={tax.id}>{tax.name}</option>
                        )))
                      }

                    </MySelect>
                  </div>
                </div>

                <div className="add-wanted-spare-step-input-field-parent">
                  <div className="add-wanted-spare-step-input-field">
                    <MyTextInput_not_mandatory
                      label="Part Number"
                      name="partNumber"                                                          //New Code
                      type="text"
                      placeholder="Enter Part number - max. 30 characters"
                      id="partNumber" validate={backendValidation} onChange={handleChange} value={formData.partNumber}
                      remainingChars={partNumberCharCount}
                    />
                  </div>

                </div>

                <div className="add-wanted-spare-step-input-tags">
                  <div className="add-wanted-spare-step-divider"></div>
                  <div className="add-wanted-spare-step-section-header1">
                    <div className="add-wanted-spare-step-content-parent">
                      <div className="add-wanted-spare-step-text-and-supporting-text5">
                        <div className="add-wanted-spare-step-uploads-title">
                          Select Tags Based on Device Features / Use
                        </div>
                      </div>
                      <div className="add-wanted-spare-step-dropdown9">
                        <img
                          className="add-wanted-spare-step-dots-vertical-icon"
                          alt=""
                          src={dotsvertical_1}
                        />
                      </div>
                    </div>
                    <img
                      className="add-wanted-spare-step-divider-icon2"
                      alt=""
                      src={divider1}
                    />
                  </div>
                  <div className="add-wanted-spare-step-tagged-inputs">
                    <div className="add-wanted-spare-step-input-field12">
                      <MySelect label="Clinical Applications" name="clinicalApplications" id="clinicalApplications" validate={backendValidation} onChange={handleChange} value={formData.clinicalApplications}>
                        <option value="0">Select From Dropdown List </option>
                        {(allTaxonomy.clinicalApplications.isLoading || allTaxonomy.clinicalApplications.isError) && <option value="0">Loading...</option>}
                        {
                          (allTaxonomy.clinicalApplications.isSuccess) && (allTaxonomy.clinicalApplications.data.map(tax => (
                            <option key={tax.id} value={tax.id}>{tax.name}</option>
                          )))
                        }

                      </MySelect>
                    </div>
                  </div>
                  <div className="add-wanted-spare-step-input-field13">
                    <MySelect label="Device Use" name="purposeUse" id="purposeUse" onChange={handleChange} validate={backendValidation} value={formData.purposeUse}>
                      <option value="0">Select From Dropdown List </option>
                      {(allTaxonomy.purposeUse.isLoading || allTaxonomy.purposeUse.isError) && <option value="0">Loading...</option>}
                      {
                        (allTaxonomy.purposeUse.isSuccess) && (allTaxonomy.purposeUse.data.map(tax => (
                          <option key={tax.id} value={tax.id}>{tax.name}</option>
                        )))
                      }

                    </MySelect>
                  </div>
                  <div className="add-wanted-spare-step-input-field13">
                    <MySelect label="Physical Location" name="physicalLocation" id="physicalLocation" validate={backendValidation} onChange={handleChange} value={formData.physicalLocation}>
                      <option value="0">Select From Dropdown List </option>
                      {(allTaxonomy.physicalLocation.isLoading || allTaxonomy.physicalLocation.isError) && <option value="0">Loading...</option>}
                      {
                        (allTaxonomy.physicalLocation.isSuccess) && (allTaxonomy.physicalLocation.data.map(tax => (
                          <option key={tax.id} value={tax.id}>{tax.name}</option>
                        )))
                      }

                    </MySelect>
                  </div>
                </div>
                <div className="add-wanted-spare-step-section-footer">
                  <img
                    className="add-wanted-spare-step-divider-icon2"
                    loading="lazy"
                    alt=""
                    src={divider1}
                  />

                  <div className="add-wanted-spare-step-content31 add-wanted-spare-step-content31-custom-margin">

                    <div className="add-wanted-spare-step-actions2">

                      <button className="add-wanted-spare-step-button9 " type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                        <img
                          className="add-wanted-spare-step-chevron-down-icon"
                          alt=""
                          src={placeholder3}
                        />

                        <img
                          className="add-wanted-spare-step-home-line-icon"
                          alt=""
                          src={save02}
                        />

                        <div className="add-wanted-spare-step-text-padding6">
                          <div className="add-wanted-spare-step-text44">{isSubmitting ? 'Submitting...' : 'Submit'}</div>
                        </div>
                        <img
                          className="add-wanted-spare-step-chevron-down-icon"
                          alt=""
                          src={placeholder3}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>

            </>
          )
        }}
      </Formik>



    </>

  )
}
