import React, { useEffect, useRef, useState } from 'react';
import "./SalesIQSoftwareDetailPage.css";
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Footer } from '../../../components/Footer/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import { firestore, doc, getDoc, auth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../../screens/Login/FirebaseClient";
import divider3 from '../../../icons/Gerator/sales_iq/divider3.svg';
import divider1 from '../../../icons/Gerator/sales_iq/divider1.svg';
import plus from '../../../icons/Gerator/sales_iq/plus.svg';
import homeline1 from '../../../icons/Gerator/sales_iq/homeline1.svg';
import chevronright from '../../../icons/Gerator/sales_iq/chevronright.svg';
import chart_data2x from '../../../icons/Gerator/sales_iq/-chart-data@2x.png';
import arrowup from '../../../icons/Gerator/sales_iq/arrowup.svg';
import arrowdown from '../../../icons/Gerator/sales_iq/arrowdown.svg';
import chart_mini2x from '../../../icons/Gerator/sales_iq/-chart-mini@2x.png';
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import group_58_2x from '../../../icons/Gerator/sales_iq/group-58@2x.png';

import _1_2x from '../../../icons/Gerator/sales_iq/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import divider2 from "../../../icons/Gerator/sales_iq/divider2.svg";
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import markerpin02 from '../../../icons/Gerator/sales_iq/markerpin02.svg';
import calendar1 from '../../../icons/Gerator/sales_iq/calendar1.svg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/sales_iq/content-elements@2x.png';
import eye from '../../../icons/Gerator/sales_iq/eye.svg';
import icon1 from '../../../icons/Gerator/sales_iq/icon1.svg';
import lock04 from '../../../icons/Gerator/sales_iq/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/sales_iq/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/sales_iq/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/sales_iq/messagechatsquare.svg';
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import { Helmet } from 'react-helmet-async';
import ButtonDropdown from '../../../components/AddButtonDropdown/AddButtonDropdown';
import PlanExpiryStatus from '../../../components/PlanExpiryStatusComponent/PlanExpiryStatus';
import { useLazyGetCurrentPlanOfUserQuery } from '../../../api/PlanAPI';
import { useLazyGetProfileImageLinkQuery } from '../../../api/Login&RegisterAPI';
import { SideBarSubSalesIQ } from '../../../components/SideBars-Sub/SideBarSubSalesIQ/SideBarSubSalesIQ';
import { SalesIQSoftwareDetailPageComponent } from '../../../components/SalesIQDetailPageComponents/SalesIQSoftwareDetailPageComponent/SalesIQSoftwareDetailPageComponent';
import { useLazyFindGetSoftwareWithoutTaxSalesIQQuery } from '../../../api/SoftwareAPI';
import { useLazyFindGetDeviceWithoutTaxSalesIQQuery } from '../../../api/DeviceAPI';
import { PostStatusLIstingComponent } from '../../../components/PostStatusLIstingComponent/PostStatusLIstingComponent';
import { formatNumber, getCustomizedDate } from '../../../helper/DeviceHelper';
import { ThreeDotsListingComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsListingComponent';
import SalesIqActiveEngagementTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqActiveEngagementTable';
import SalesIqViewTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqViewTable';
import SalesIqRadarTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqRadarTable';
import SalesIqSalesTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqSalesTable';
import { useLazyGetUsersForSalesIQDashboardViewSoftwareQuery } from '../../../api/View/ViewSoftwareAPI';
import { useLazyGetUsersForSalesIQDashboardRadarSoftwareQuery } from '../../../api/Radar/RadarSoftwareAPI';


// const auth = getAuth(app);


export const SalesIQSoftwareDetailPage = () => {





  const { id } = useParams();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [offSetView, setOffSetView] = useState(0);
  const [offSetRadar, setOffSetRadar] = useState(0);
  const [limit, setLimit] = useState(5);
  const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  const [currentPlanName, setCurrentPlanName] = useState("");

  //For Threedots in listing dropdown
  const [activeThreeDot, setActiveThreeDot] = useState(false);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const [triggerGetListingData, { data: currentListingData, isError: isListingsDataError, error: currentListingError, isLoading: isListingsDataLoading, isSuccess: isListingsDataSuccess }] = useLazyFindGetSoftwareWithoutTaxSalesIQQuery();

  const [triggerGetViewData, { data: currentViewData, isError: isViewsDataError, error: currentViewError, isLoading: isViewsDataLoading, isSuccess: isViewsDataSuccess, isFetching: isViewDataFetching }] = useLazyGetUsersForSalesIQDashboardViewSoftwareQuery();

  const [triggerGetRadarData, { data: currentRadarData, isError: isRadarsDataError, error: currentRadarError, isLoading: isRadarsDataLoading, isSuccess: isRadarsDataSuccess, isFetching: isRadarDataFetching }] = useLazyGetUsersForSalesIQDashboardRadarSoftwareQuery();

  const toggleThreeDotsDropdown = () => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDot) {
      setActiveThreeDot(false); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDot(true); // Open the clicked one
    }
  };

  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);

  useEffect(() => {
    if (currentUser) {
      triggerGetListingData(id || 0);
      triggerGetViewData({ offSetView, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
      triggerGetRadarData({ offSetRadar, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentUser]);


  useEffect(() => {
    if (currentUser) {
      triggerGetViewData({ offSetView, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [offSetView]);

  useEffect(() => {
    if (currentUser) {
      triggerGetRadarData({ offSetRadar, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [offSetRadar]);

  useEffect(() => {
    if (currentViewData) {
      console.log(currentViewData);
    }
  }, [currentViewData])

  useEffect(() => {
    if (isCurrentPlanSuccess && currentPlanData?.data?.order_plan?.id) {
      setCurrentPlanName(currentPlanData.data.order_plan.planName);
      setCurrentPlanExpiry(currentPlanData.data.orderExpiry);
    } else {
      console.log("No current plan data or ID not available.");
    }
  }, [isCurrentPlanSuccess, currentPlanData]);

  //Onclick listener for threedots dropdown
  useEffect(() => {

    const handleThreeDotClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDot(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  const handleViewDataLoadMore = () => {
    if (currentViewData?.hasMore && !isViewDataFetching) {
      setOffSetView(currentOffset => currentOffset + 5);
    }
  };

  const showViewDataLoadMore = currentViewData?.hasMore &&
    currentViewData.view_software.length < currentViewData.totalCount &&
    !isViewDataFetching;


  const handleRadarDataLoadMore = () => {
    if (currentRadarData?.hasMore && !isRadarDataFetching) {
      setOffSetRadar(currentOffset => currentOffset + 5);
    }
  };

  const showRadarDataLoadMore = currentRadarData?.hasMore &&
    currentRadarData.radar_software.length < currentRadarData.totalCount &&
    !isRadarDataFetching;


  if (currentUser && isCurrentPlanSuccess && profileImageLink && currentListingData) {
    return (
      <div className="sales-iq-detail-software-sales-iq-detail-software-frame-layout">
        <Helmet>
          <title>Sales IQ</title>
        </Helmet>
        <div className="sales-iq-detail-software-sales-iq-detail-software-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

        <div className="sales-iq-detail-software-all">
          <img className="sales-iq-detail-software-divider-icon" alt="" src={divider3} />

          <div className="sales-iq-detail-software-all-content">

            <Sidebar>
              <SideBarSubSalesIQ userData={currentUser} />
            </Sidebar>

            <div className="sales-iq-detail-software-frame-parent">
              <div className="sales-iq-detail-software-main-wrapper">
                <div className="sales-iq-detail-software-main sales-iq-detail-software-main-custom">
                  <div className="sales-iq-detail-software-header-section">
                    <div className="sales-iq-detail-software-container">
                      <div className="sales-iq-detail-software-page-header">
                        <div className="sales-iq-detail-software-breadcrumbs">
                          <div className="sales-iq-detail-software-tabs">
                            <div className="sales-iq-detail-software-breadcrumb-button-base">
                              <img
                                className="sales-iq-detail-software-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="sales-iq-detail-software-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-detail-software-breadcrumb-button-base1">
                              <div className="sales-iq-detail-software-location">Sales IQ</div>
                            </div>
                            <img
                              className="sales-iq-detail-software-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-detail-software-breadcrumb-button-base2">
                              <div className="sales-iq-detail-software-text20">...</div>
                            </div>
                            <img
                              className="sales-iq-detail-software-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-detail-software-breadcrumb-button-base3">
                              <div className="sales-iq-detail-software-text21">Another link</div>
                            </div>
                            <img
                              className="sales-iq-detail-software-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="sales-iq-detail-software-breadcrumb-button-base4">
                              <div className="sales-iq-detail-software-text22">Overview</div>
                            </div>
                          </div>
                        </div>



                      </div>

                    </div>
                  </div>


                </div>
              </div>
              {/* <div className="sales-iq-detail-software-separator"></div>
              <div className="sales-iq-detail-software-separator1"></div> */}

              <div className="sales-iq-detail-software-component-listing-container">

                <div className="sales-iq-detail-software-component-sales-iq-content-items">
                  <div className="sales-iq-detail-software-component-sales-iq-divider-container1">
                    <div className="sales-iq-detail-software-component-sales-iq-rectangle-parent5">
                      <img
                        className="sales-iq-detail-software-component-sales-iq-frame-child7"
                        alt=""
                        src={currentListingData.featureImage}
                      />

                      <div className="sales-iq-detail-software-component-sales-iq-frame-parent1">
                        <div className="sales-iq-detail-software-component-sales-iq-action-fields">
                          <div className="sales-iq-detail-software-component-sales-iq-input-elements">
                            <Link to={`/software/${currentListingData.id}/${currentListingData.title}`} className="sales-iq-detail-software-component-sales-iq-text78">
                              {currentListingData.title}
                            </Link>
                            <div className="sales-iq-detail-software-component-sales-iq-frame-parent21">
                              <div className="sales-iq-detail-software-component-sales-iq-badge-item-parent">
                                <div className="sales-iq-detail-software-component-sales-iq-badge-parent">

                                  <PostStatusLIstingComponent postStatus={currentListingData.tax_post_status.name} postSlug={currentListingData.tax_post_status.slug} postId={currentListingData.id} />

                                </div>
                                <div className="sales-iq-detail-software-component-sales-iq-frame-parent4">
                                  <div className="sales-iq-detail-software-component-sales-iq-end-label">
                                    <div className="sales-iq-detail-software-component-sales-iq-search-icon-input">4.9/5</div>
                                  </div>
                                  <div className="sales-iq-detail-software-component-sales-iq-type-support">
                                    <div className="sales-iq-detail-software-component-sales-iq-supporting-text9">
                                      99,999 reviews
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-frame-parent24">
                                <div className="sales-iq-detail-software-component-sales-iq-location-pin-parent">
                                  <div className="sales-iq-detail-software-component-sales-iq-search-input">
                                    <img
                                      className="sales-iq-detail-software-component-sales-iq-marker-pin-02-icon2"
                                      alt=""
                                      src={markerpin02}
                                    />
                                  </div>
                                  <div className="sales-iq-detail-software-component-sales-iq-location3">
                                    {
                                      (currentListingData.software_address.tax_address_type.slug === 'remote') && currentListingData.software_address.tax_address_type.name
                                    }

                                    {
                                      (currentListingData.software_address.tax_address_type.slug === 'address' && `${currentListingData.software_address.addressTownCity}, ${currentListingData.software_address.tax_country.name}`)
                                    }
                                    {/* {currentListingData.software_address.addressTownCity}, {currentListingData.software_address.tax_country.name} */}
                                  </div>
                                </div>
                                {/* <div className="sales-iq-detail-software-component-sales-iq-frame-wrapper18">
                          <div className="sales-iq-detail-software-component-sales-iq-badge-elements-copy">
                           
                          </div>
                        </div> */}
                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-frame-parent26">
                                <div className="sales-iq-detail-software-component-sales-iq-frame-wrapper19">
                                  <div className="sales-iq-detail-software-component-sales-iq-legend-series">
                                    <div className="sales-iq-detail-software-component-sales-iq-search-input">
                                      <img
                                        className="sales-iq-detail-software-component-sales-iq-marker-pin-02-icon2"
                                        alt=""
                                        src={calendar1}
                                      />
                                    </div>
                                    <div className="sales-iq-detail-software-component-sales-iq-type-of-work2">{getCustomizedDate(currentListingData.createdAt)}</div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>

                          <div className="sales-iq-detail-software-component-sales-iq-small-card">

                            <div className="sales-iq-detail-software-component-sales-iq-badge33">
                              <div className="sales-iq-detail-software-component-sales-iq-third-badge-number">
                                {currentListingData.tax_software_category.name}
                              </div>
                            </div>

                            <div className="sales-iq-detail-software-component-sales-iq-badge38">
                              <div className="sales-iq-detail-software-component-sales-iq-third-badge-number">
                                {currentListingData.tax_clinical_application.name}
                              </div>
                            </div>

                            <div className="sales-iq-detail-software-component-sales-iq-badge39">
                              <div className="sales-iq-detail-software-component-sales-iq-third-badge-number">
                                Accounting
                              </div>
                            </div>

                            <div className="sales-iq-detail-software-component-sales-iq-badge36">
                              <div className="sales-iq-detail-software-component-sales-iq-third-badge-number">
                                11 Health
                              </div>
                            </div>

                            <div className="sales-iq-detail-software-component-sales-iq-badge37">
                              <div className="sales-iq-detail-software-component-sales-iq-third-badge-number">{currentListingData.tax_software_type.name}</div>
                            </div>

                          </div>
                        </div>






                      </div>

                      <div className="sales-iq-detail-software-component-sales-iq-filter-elements">
                        {/* {(currentListingData.tax_post_status.slug !== 'pending') ? */}
                        <div className="sales-iq-detail-software-component-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown()} ref={threeDotsRef}>
                          <img
                            className="sales-iq-detail-software-component-sales-iq-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />


                          {activeThreeDot && <ThreeDotsListingComponent url={`software/${currentListingData.id}`} />}
                        </div>
                        {/* : */}
                        <div className="sales-iq-detail-software-component-sales-iq-dropdown10" >
                          {/* <img
                        className="sales-iq-detail-software-component-sales-iq-dots-vertical-icon"
                        alt=""
                        src={dotsvertical}
                      /> */}


                          {/* {activeThreeDotId ===  && <ThreeDotsListingComponent url={`software/${currentListingData.id}`} />} */}
                        </div>

                        {/* } */}
                        <div className="sales-iq-detail-software-component-sales-iq-filter-elements-inner">
                          <div className="sales-iq-detail-software-component-sales-iq-price-parent">
                            {/* <TransactionTypeComponent transactionType={currentListingData.tax_transaction_type.name} slug={currentListingData.tax_transaction_type.slug} /> */}
                            <div className="sales-iq-detail-software-component-sales-iq-text83">Starting From</div>
                            <div className="sales-iq-detail-software-component-sales-iq-price2">${formatNumber(currentListingData.rangeMin)}</div>
                            <div className="sales-iq-detail-software-component-sales-iq-dropdown-elements">
                              <div className="sales-iq-detail-software-component-sales-iq-dropdown-options-parent">
                                <div className="sales-iq-detail-software-component-sales-iq-text83">{currentListingData.tax_unit_measure_software.name}</div>
                                <div className="sales-iq-detail-software-component-sales-iq-frame-wrapper3">
                                  <div className="sales-iq-detail-software-component-sales-iq-radar-1-1-container">
                                    <img
                                      className="sales-iq-detail-software-component-sales-iq-magnifier-1-icon"
                                      alt=""
                                      src={radar_1_1_2x}
                                    />

                                    <img
                                      className="sales-iq-detail-software-component-sales-iq-magnifier-1-icon"
                                      alt=""
                                      src={deal_2_1_2x}
                                    />

                                    <img
                                      className="sales-iq-detail-software-component-sales-iq-magnifier-1-icon"
                                      alt=""
                                      src={magnifier_1_2x}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <img className="sales-iq-detail-software-component-sales-iq-divider-icon8" alt="" src={divider2} />

                    <div className="sales-iq-detail-software-component-sales-iq-metric-item-parent">

                      <div className="sales-iq-detail-software-component-sales-iq-metric-item3">
                        <div className="sales-iq-detail-software-component-sales-iq-heading9">Active Engagement</div>
                        <div className="sales-iq-detail-software-component-sales-iq-number-and-chart">
                          <div className="sales-iq-detail-software-component-sales-iq-number-and-badge3">
                            <div className="sales-iq-detail-software-component-sales-iq-heading">{currentListingData.totalActiveEngagement || 0}</div>
                            <div className="sales-iq-detail-software-component-sales-iq-change-and-text3">
                              <div className="sales-iq-detail-software-component-sales-iq-change2">
                                <img
                                  className="sales-iq-detail-software-component-sales-iq-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="sales-iq-detail-software-component-sales-iq-change7">100%</div>
                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="sales-iq-detail-software-component-sales-iq-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}

                          />
                        </div>
                        <div className="sales-iq-detail-software-component-sales-iq-dropdown19">
                          <img
                            className="sales-iq-detail-software-component-sales-iq-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>

                      <div className="sales-iq-detail-software-component-sales-iq-metric-item3">
                        <div className="sales-iq-detail-software-component-sales-iq-heading9">Views</div>
                        <div className="sales-iq-detail-software-component-sales-iq-number-and-chart">
                          <div className="sales-iq-detail-software-component-sales-iq-number-and-badge3">
                            <div className="sales-iq-detail-software-component-sales-iq-heading">{currentListingData.totalCountView}</div>
                            <div className="sales-iq-detail-software-component-sales-iq-change-and-text3">
                              <div className="sales-iq-detail-software-component-sales-iq-change2">
                                <img
                                  className="sales-iq-detail-software-component-sales-iq-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="sales-iq-detail-software-component-sales-iq-change7">100%</div>
                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="sales-iq-detail-software-component-sales-iq-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}

                          />
                        </div>
                        <div className="sales-iq-detail-software-component-sales-iq-dropdown19">
                          <img
                            className="sales-iq-detail-software-component-sales-iq-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                      <div className="sales-iq-detail-software-component-sales-iq-metric-item3">
                        <div className="sales-iq-detail-software-component-sales-iq-heading9">Added to Radar</div>
                        <div className="sales-iq-detail-software-component-sales-iq-number-and-chart">
                          <div className="sales-iq-detail-software-component-sales-iq-number-and-badge3">
                            <div className="sales-iq-detail-software-component-sales-iq-heading">{currentListingData.totalCountRadar}</div>
                            <div className="sales-iq-detail-software-component-sales-iq-change-and-text3">
                              <div className="sales-iq-detail-software-component-sales-iq-change2">
                                <img
                                  className="sales-iq-detail-software-component-sales-iq-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="sales-iq-detail-software-component-sales-iq-change7">100%</div>
                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="sales-iq-detail-software-component-sales-iq-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}
                          />
                        </div>
                        <div className="sales-iq-detail-software-component-sales-iq-dropdown19">
                          <img
                            className="sales-iq-detail-software-component-sales-iq-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                      <div className="sales-iq-detail-software-component-sales-iq-metric-item3">
                        <div className="sales-iq-detail-software-component-sales-iq-heading9">Searches</div>
                        <div className="sales-iq-detail-software-component-sales-iq-number-and-chart">
                          <div className="sales-iq-detail-software-component-sales-iq-number-and-badge3">
                            <div className="sales-iq-detail-software-component-sales-iq-heading">{currentListingData.totalSearch || 0}</div>
                            <div className="sales-iq-detail-software-component-sales-iq-change-and-text3">
                              <div className="sales-iq-detail-software-component-sales-iq-change2">
                                <img
                                  className="sales-iq-detail-software-component-sales-iq-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="sales-iq-detail-software-component-sales-iq-change7">100%</div>
                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="sales-iq-detail-software-component-sales-iq-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}
                          />
                        </div>
                        <div className="sales-iq-detail-software-component-sales-iq-dropdown19">
                          <img
                            className="sales-iq-detail-software-component-sales-iq-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                    </div>

                    <img className="sales-iq-detail-software-component-sales-iq-divider-icon8" alt="" src={divider2} />

                    <div className="sales-iq-detail-software-component-sales-iq-frame-parent71">
                      <div className="sales-iq-detail-software-component-sales-iq-buttonsbutton-parent1">
                        <div className="sales-iq-legend-series">
                          <img
                            className="sales-iq-location-column-child"
                            alt=""
                            src={currentListingData.profileImage}
                          />

                          <div className="sales-iq-location-column-inner">
                            <div className="sales-iq-date-picker-dropdown">
                              <div className="sales-iq-name">{currentListingData.software_business.business_user.displayName}</div>
                              <Link className="sales-iq-role" to={`/business/${currentListingData.software_business.id}/${currentListingData.software_business.businessName}`}>
                                {currentListingData.software_business.businessName}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className='sales-iq-detail-software-component-main-buttons-group'>
                          <div className="sales-iq-detail-software-component-sales-iq-buttonsbutton13">
                            <img
                              className="sales-iq-detail-software-component-sales-iq-arrow-narrow-left-icon"
                              alt=""
                              src={eye}
                            />

                            <div className="sales-iq-detail-software-component-sales-iq-search-input">
                              <div className="sales-iq-detail-software-component-sales-iq-text67">View</div>
                            </div>
                          </div>
                          {/* <div className="sales-iq-detail-software-component-sales-iq-buttonsbutton25">
                            <div className="sales-iq-detail-software-component-sales-iq-icon-wrapper">
                              <img className="sales-iq-detail-software-component-sales-iq-icon1" alt="" src={icon1} />
                            </div>
                            <div className="sales-iq-detail-software-component-sales-iq-search-input">
                              <div className="sales-iq-detail-software-component-sales-iq-text68">Sales IQ</div>
                            </div>
                            <img
                              className="sales-iq-detail-software-component-sales-iq-arrow-narrow-left-icon"
                              alt=""
                              src={lock04}
                            />
                          </div> */}
                          <Link to={"/sales-iq"}>
                            <div className="sales-iq-detail-software-component-sales-iq-buttonsbutton26">
                              <div className="sales-iq-detail-software-component-sales-iq-icon-wrapper">
                                <img className="sales-iq-detail-software-component-sales-iq-icon1" alt="" src={icon1} />
                              </div>
                              <div className="sales-iq-detail-software-component-sales-iq-search-input">
                                <div className="sales-iq-detail-software-component-sales-iq-text68">Sales IQ</div>
                              </div>
                              <img
                                className="sales-iq-detail-software-component-sales-iq-arrow-narrow-left-icon"
                                alt=""
                                src={lockunlocked04}
                              />
                            </div>
                          </Link>
                          <div className="sales-iq-detail-software-component-sales-iq-buttonsbutton27">
                            <img
                              className="sales-iq-detail-software-component-sales-iq-upload-04-icon"
                              alt=""
                              src={messagechatsquare}
                            />

                            <div className="sales-iq-detail-software-component-sales-iq-text145">Messages</div>
                            <div className="sales-iq-detail-software-component-sales-iq-ellipse-parent">
                              <div className="sales-iq-detail-software-component-sales-iq-ellipse-div"></div>
                              <div className="sales-iq-detail-software-component-sales-iq-div">2</div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="sales-iq-detail-software-component-sales-iq-arrow-square-up-right-wrapper1">
                        <img
                          className="sales-iq-detail-software-component-sales-iq-arrow-square-up-right-icon1"
                          alt=""
                          src={arrowsquareupright}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              {/* Active Engagement Table */}


              <SalesIqActiveEngagementTable data={[]} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />
              <br />

              {/* Viewed Table */}

              <SalesIqViewTable data={currentViewData?.view_software} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />

              <br /><br />

              {/* Added to Radar Table */}

              <SalesIqRadarTable data={currentRadarData?.radar_software} isLoading={isRadarsDataLoading} isSuccess={isRadarsDataSuccess} isError={isRadarsDataError} isFetching={isRadarDataFetching} showLoadMore={showRadarDataLoadMore} handleLoadMore={handleRadarDataLoadMore} />

              <br /><br />

              {/* Searched Table */}

              <SalesIqSalesTable data={[]} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />
              <br />



            </div>
          </div>
        </div>

        <br /><br />

        <img className="sales-iq-detail-software-divider-icon6" alt="" src={divider3} />

        <Footer />


      </div>
    )
  }
  else {
    return <Loader />
  }


}

