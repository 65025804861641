import "./ArchiveWantedService.css"
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLazyGetWantedServicesQuery, useDeleteWantedServiceMutation } from "../../../api/WantedServiceAPI";
import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { useNavigate } from 'react-router-dom';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';

import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { NoListingFound } from "../../../components/NoListing/NoListingFound";


export const ArchiveWantedService = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");


  const [deleteWantedService] = useDeleteWantedServiceMutation();
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetWantedService, { data: wantedServiceData,
    isLoading: isWantedServiceLoading,
    isSuccess: isWantedServiceSuccess,
    isError: isWantedServiceError,
    error: wantedServiceError }] = useLazyGetWantedServicesQuery();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  //Delete
  const handleDelete = async (id) => {
    try {

      const response = await deleteWantedService(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete wanted service `, error);
    }
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  // Trigger the get wanted service query only after user data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // Once the user is authenticated and the getUser query is successful, trigger the getWantedService query
      triggerGetWantedService();
    }
  }, [isSuccess, triggerGetWantedService]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }


  if (isWantedServiceSuccess) {

    if (wantedServiceData.data.length > 0) {
      dataLength = wantedServiceData.data.length;
      content = wantedServiceData.data.map(wantedService =>
      (

        <div className="archive-wanted-device-rectangle-group listing-item" key={wantedService.id}>

          <div className="archive-wanted-service-rectangle-container">
            {/* <img
              className="archive-wanted-service-frame-inner"
              alt=""
              src={rectangle_41_2x}
            /> */}

            <div className="archive-wanted-service-dots-vertical-container">
              {/* <img
                className="archive-wanted-service-dots-vertical-icon1"
                alt=""
                src={dotsvertical}
              /> */}

              <div className="archive-wanted-service-text-parent">

                <Link to={`/wanted-service/${wantedService.id}/${wantedService.title}`} className=" archive-wanted-service-text37"><p className="text-overflow-archive-service">{wantedService.title}</p></Link>

                <div className="archive-wanted-service-frame-group">
                  <div className="archive-wanted-service-frame-container">
                    <div className="archive-wanted-service-frame-div">
                      <div className="archive-wanted-service-frame-parent1">
                        <div className="archive-wanted-service-badge-parent">
                          <div className="archive-wanted-service-badge9">
                            <img
                              className="archive-wanted-service-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="archive-wanted-service-price-value">Pending</div>
                          </div>
                          <div className="archive-wanted-service-supporting-text4">ID# {wantedService.id}</div>
                        </div>
                        <div className="archive-wanted-service-deal-type-parent">
                          <div className="archive-wanted-service-completed">
                            <div className="archive-wanted-service-second-value">4.9/5</div>
                          </div>
                          <div className="archive-wanted-service-supporting-text-wrapper">
                            <div className="archive-wanted-service-supporting-text3">99,999 reviews</div>
                          </div>
                        </div>
                        <div className="archive-wanted-service-badge10">
                          <div className="archive-wanted-service-third-badge-count text-overflow-archive-service">
                            {wantedService.tax_service_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-service-deal-location-parent">
                        <div className="archive-wanted-service-dot-item-location-parent">
                          {/* <div className="archive-wanted-service-navigation">
                            <img
                              className="archive-wanted-service-marker-pin-02-icon"
                              alt=""
                              src={markerpin02}
                            />
                          </div> */}
                          {/* <div className="archive-wanted-service-location">India, New Delhi</div> */}
                        </div>
                        <div className="archive-wanted-service-frame-wrapper1">
                          <div className="archive-wanted-service-badge-group">
                            <div className="archive-wanted-service-badge10">
                              <div className="archive-wanted-service-sub-badge text-overflow-archive-service">{wantedService.tax_clinical_application.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-service-card-work">
                        <div className="archive-wanted-service-inner-work">
                          <div className="archive-wanted-service-dot-item-type-parent">
                            <div className="archive-wanted-service-navigation">
                              <img
                                className="archive-wanted-service-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="archive-wanted-service-type-of-work">{getCustomizedDate(wantedService.createdAt)}</div>
                          </div>
                        </div>
                        <div className="archive-wanted-service-badge13 text-overflow-archive-service">
                          <div className="archive-wanted-service-third-badge-count">
                            {wantedService.tax_purpose_use.name}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-service-divider-parent">
            <img className="archive-wanted-service-divider-icon4" alt="" src={divider2} />

            <div className="archive-wanted-service-user-listings">
              <div className="archive-wanted-service-frame-parent3">
                <div className="archive-wanted-service-frame-parent4">
                  <img
                    className="archive-wanted-service-user-content-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="archive-wanted-service-inner-info-wrapper">
                    <div className="archive-wanted-service-inner-info">
                      <div className="archive-wanted-service-name">Olivia Rhye</div>
                      <div className="archive-wanted-service-role1">Business Name</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-service-buttonsbutton-wrapper">
                  <Link to={"/edit-wanted-service/" + wantedService.id}>
                    <button className="archive-wanted-service-buttonsbutton1">

                      <img className="archive-wanted-service-eye-icon" alt="" src={eye} />

                      <div className="archive-wanted-service-navigation">
                        <div className="archive-wanted-service-view-label">Edit</div>
                      </div>
                    </button>
                  </Link>
                </div>
                <div className="archive-wanted-service-logo-wrapper">
                  {/* <Link to={"/delete-wanted-service/" + wantedService.id}> */}
                  <div className="archive-wanted-service-buttonsbutton2 archive-wanted-service-view-label" onClick={() => handleDelete(wantedService.id)}>
                    <div className="archive-wanted-service-icon-wrapper">
                      <img className="archive-wanted-service-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-service-navigation">
                      <div className="archive-wanted-service-lock-label">Delete</div>
                    </div>
                    <img className="archive-wanted-service-eye-icon" alt="" src={lock04} />
                  </div>
                  {/* </Link> */}
                </div>
                <div className="archive-wanted-service-logo-wrapper">
                  <div className="archive-wanted-service-buttonsbutton3">
                    <div className="archive-wanted-service-icon-wrapper">
                      <img className="archive-wanted-service-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-service-navigation">
                      <div className="archive-wanted-service-lock-label">Sales IQ</div>
                    </div>
                    <img
                      className="archive-wanted-service-eye-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                </div>
              </div>
              <div className="archive-wanted-service-arrow-square-up-right-wrapper">
                <img
                  className="archive-wanted-service-frame-child1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>


      ))
    }
    else {
      content = (

        // <div className="content-44" >
        //   <img
        //     className="bifocal-optometry-2"
        //     alt="Bifocal optometry"
        //     src="/img/bifocal-optometry-measurement-service-2.png"
        //   />
        //   <div className="text-and-supporting-16" style={{ alignSelf: "unset !important" }}>
        //     <div className="text-157">No listings found</div>
        //     <p className="supporting-text-36">
        //       Your search “Landing page design” did not match any projects. Please try again.
        //     </p>
        //   </div>
        //   <div className="actions-10">
        //     <div className="buttons-button-37">
        //       <div className="text-padding-18">
        //         <div className="text-158">Clear search</div>
        //       </div>
        //     </div>
        //     <div className="buttons-button-38">
        //       <img className="img-26" alt="Plus" src="/img/plus.svg" />
        //       <div className="text-padding-18">
        //         <Link to={"/add-wanted-service"}>
        //           <div className="text-159">Add Listing</div>
        //         </Link>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <NoListingFound path={"/add-wanted-service"} pathName={"Wanted Service"} />

      )
    }
    // content = <Loader/>
  } else {
    return <Loader />
  }

  return (
    <div className="archive-wanted-service-listings-search-results-list">
      <div className="archive-wanted-service-listings-search-results-list-child"></div>


      <Navbar1 userData={currentUser} navigate={navigate} />


      <div className="archive-wanted-service-filters">
        <div className="archive-wanted-service-main">
          <div className="archive-wanted-service-header-section">
            <div className="archive-wanted-service-container">
              <div className="archive-wanted-service-page-header">
                <div className="archive-wanted-service-breadcrumbs">
                  <div className="archive-wanted-service-tabs">
                    <div className="archive-wanted-service-breadcrumb-button-base">
                      <img
                        className="archive-wanted-service-search-lg-icon"
                        alt=""
                        src={homeline}
                      />
                    </div>
                    <img
                      className="archive-wanted-service-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-service-breadcrumb-button-base1">
                      <a className="archive-wanted-service-text7">Dashboard</a>
                    </div>
                    <img
                      className="archive-wanted-service-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-service-breadcrumb-button-base2">
                      <div className="archive-wanted-service-text8">...</div>
                    </div>
                    <img
                      className="archive-wanted-service-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-service-breadcrumb-button-base3">
                      <div className="archive-wanted-service-text9">Another link</div>
                    </div>
                    <img
                      className="archive-wanted-service-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-wanted-service-breadcrumb-button-base4">
                      <a className="archive-wanted-service-text10">Category</a>
                    </div>
                  </div>
                </div>
                <img className="archive-wanted-service-divider-icon" alt="" src={divider} />
              </div>
            </div>
          </div>
          <div className="archive-wanted-service-main-child"></div>
          <div className="archive-wanted-service-main-item"></div>
        </div>
        <div className="archive-wanted-service-secondary-content">
          <div className="archive-wanted-service-page-header1">
            <div className="archive-wanted-service-breadcrumbs1">
              <div className="archive-wanted-service-tabs1">
                <div className="archive-wanted-service-breadcrumb-button-base">
                  <img
                    className="archive-wanted-service-search-lg-icon"
                    alt=""
                    src={homeline}
                  />
                </div>
                <img
                  className="archive-wanted-service-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-service-breadcrumb-button-base6">
                  <div className="archive-wanted-service-text11">Settings</div>
                </div>
                <img
                  className="archive-wanted-service-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-service-breadcrumb-button-base7">
                  <div className="archive-wanted-service-text8">...</div>
                </div>
                <img
                  className="archive-wanted-service-chevron-right-icon"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-service-breadcrumb-button-base3">
                  <div className="archive-wanted-service-text9">Another link</div>
                </div>
                <img
                  className="archive-wanted-service-chevron-right-icon2"
                  alt=""
                  src={chevronright}
                />

                <div className="archive-wanted-service-breadcrumb-button-base4">
                  <div className="archive-wanted-service-menu-label">Team</div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-service-content4">
              <div className="archive-wanted-service-text-and-supporting-text">
                <div className="archive-wanted-service-page-title">{dataLength} Listings Found</div>
                <div className="archive-wanted-service-supporting-text">
                  Manage your team members and their account permissions here.
                </div>
              </div>
              <div className="archive-wanted-service-input-dropdown">
                <div className="archive-wanted-service-input-with-label1">
                  <div className="archive-wanted-service-label1">Search</div>
                  <div className="archive-wanted-service-input1">
                    <div className="archive-wanted-service-content5">
                      <img
                        className="archive-wanted-service-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <div className="archive-wanted-service-text15">Search</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-service-hint-text">This is a hint text to help user.</div>
              </div>
            </div>
            <img className="archive-wanted-service-divider-icon1" alt="" src={divider1} />
          </div>
        </div>
        <div className="archive-wanted-service-button-group">
          <button className="archive-wanted-service-button-group-base">
            <a className="archive-wanted-service-text16">View all</a>
          </button>
          <div className="archive-wanted-service-button-group-base1">
            <div className="archive-wanted-service-text17">Monitored</div>
          </div>
          <div className="archive-wanted-service-button-group-base2">
            <div className="archive-wanted-service-menu-label">Text</div>
          </div>
          <div className="archive-wanted-service-button-group-base3">
            <div className="archive-wanted-service-menu-label">Text</div>
          </div>
          <div className="archive-wanted-service-button-group-base4">
            <div className="archive-wanted-service-menu-label">Text</div>
          </div>
          <div className="archive-wanted-service-button-group-base5">
            <div className="archive-wanted-service-menu-label">Text</div>
          </div>
          <div className="archive-wanted-service-button-group-base6">
            <div className="archive-wanted-service-menu-label">Text</div>
          </div>
          <div className="archive-wanted-service-button-group-base7">
            <div className="archive-wanted-service-menu-label">Text</div>
          </div>
          <div className="archive-wanted-service-button-group-base1">
            <div className="archive-wanted-service-text24">Unmonitored</div>
          </div>
          {/* <div className="archive-wanted-service-breadcrumbs2">
          <div className="archive-wanted-service-tabs2">
            <div className="archive-wanted-service-breadcrumb-button-base">
              <img
                className="archive-wanted-service-search-lg-icon"
                alt=""
                src={homeline}
              />
            </div>
            <div className="archive-wanted-service-logo-wrapper">
              <input className="archive-wanted-service-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-service-breadcrumb-button-base11">
              <div className="archive-wanted-service-text11">Settings</div>
            </div>
            <div className="archive-wanted-service-logo-wrapper">
              <input className="archive-wanted-service-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-service-breadcrumb-button-base12">
              <div className="archive-wanted-service-text8">...</div>
            </div>
            <div className="archive-wanted-service-logo-wrapper">
              <img
                className="archive-wanted-service-arrow-down-icon"
                alt=""
                src={chevronright}
              />
            </div>
            <div className="archive-wanted-service-breadcrumb-button-base3">
              <div className="archive-wanted-service-text9">Another link</div>
            </div>
            <img
              className="archive-wanted-service-chevron-right-icon2"
              alt=""
              src={chevronright}
            />

            <div className="archive-wanted-service-breadcrumb-button-base14">
              <div className="archive-wanted-service-text28">Team</div>
            </div>
          </div>
        </div> */}
        </div>
        <div className="archive-wanted-service-input-dropdown1">
          <div className="archive-wanted-service-hint-text1">This is a hint text to help user.</div>
          <div className="archive-wanted-service-buttonsbutton">
            <img className="archive-wanted-service-sliders-02-icon" alt="" src={sliders02} />

            <div className="archive-wanted-service-text-padding">
              <div className="archive-wanted-service-menu-label">Sort By : Relevance</div>
              <img
                className="archive-wanted-service-sliders-02-icon"
                alt=""
                src={chevrondown}
              />
            </div>
            <img
              className="archive-wanted-service-placeholder-icon"
              alt=""
              src={placeholder}
            />
          </div>
        </div>
        <img className="archive-wanted-service-divider-icon2" alt="" src={divider2} />

        <img className="archive-wanted-service-divider-icon3" alt="" src={divider2} />

        <div className="archive-wanted-service-supporting-text1">
          You searched based on the following criteria.
        </div>

        {/* <div className="archive-wanted-service-rectangle-group">
        <div className="archive-wanted-service-filter-items"></div>
        <div className="archive-wanted-service-rectangle-container">
          <img
            className="archive-wanted-service-frame-inner"
            alt=""
            src={rectangle_41_2x}
          />

          <div className="archive-wanted-service-dots-vertical-container">
            <img
              className="archive-wanted-service-dots-vertical-icon1"
              alt=""
              src={dotsvertical}
            />

            <div className="archive-wanted-service-text-parent">
              <div className="archive-wanted-service-text37">
                New / Surplus 2021 Philips Healthcare HD11 XE (P/N:23A10) for
                abalatio...
              </div>
              <div className="archive-wanted-service-frame-group">
                <div className="archive-wanted-service-frame-container">
                  <div className="archive-wanted-service-frame-div">
                    <div className="archive-wanted-service-frame-parent1">
                      <div className="archive-wanted-service-badge-parent">
                        <div className="archive-wanted-service-badge9">
                          <img
                            className="archive-wanted-service-dot-icon3"
                            alt=""
                            src={dot1}
                          />

                          <div className="archive-wanted-service-price-value">Pending</div>
                        </div>
                        <div className="archive-wanted-service-supporting-text4">ID# AXJYN</div>
                      </div>
                      <div className="archive-wanted-service-deal-type-parent">
                        <div className="archive-wanted-service-completed">
                          <div className="archive-wanted-service-second-value">4.9/5</div>
                        </div>
                        <div className="archive-wanted-service-supporting-text-wrapper">
                          <div className="archive-wanted-service-supporting-text3">99,999 reviews</div>
                        </div>
                      </div>
                      <div className="archive-wanted-service-badge10">
                        <div className="archive-wanted-service-third-badge-count">
                          Service Category
                        </div>
                      </div>
                    </div>
                    <div className="archive-wanted-service-deal-location-parent">
                      <div className="archive-wanted-service-dot-item-location-parent">
                        <div className="archive-wanted-service-navigation">
                          <img
                            className="archive-wanted-service-marker-pin-02-icon"
                            alt=""
                            src={markerpin02}
                          />
                        </div>
                        <div className="archive-wanted-service-location">India, New Delhi</div>
                      </div>
                      <div className="archive-wanted-service-frame-wrapper1">
                        <div className="archive-wanted-service-badge-group">
                          <div className="archive-wanted-service-badge10">
                            <div className="archive-wanted-service-sub-badge">Clinical Application</div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                    <div className="archive-wanted-service-card-work">
                      <div className="archive-wanted-service-inner-work">
                        <div className="archive-wanted-service-dot-item-type-parent">
                          <div className="archive-wanted-service-navigation">
                            <img
                              className="archive-wanted-service-marker-pin-02-icon"
                              alt=""
                              src={calendar}
                            />
                          </div>
                          <div className="archive-wanted-service-type-of-work">April 09, 2023</div>
                        </div>
                      </div>
                      <div className="archive-wanted-service-badge13">
                        <div className="archive-wanted-service-third-badge-count">
                        Purpose/Use
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="archive-wanted-service-second-price">
                  <div className="archive-wanted-service-price">$9,999,999.99</div>
                  <div className="archive-wanted-service-price-value1">
                    <div className="archive-wanted-service-deal-title">USD - Per Unit</div>
                  </div>
                  <div className="archive-wanted-service-inner-icons-wrapper">
                    <div className="archive-wanted-service-inner-icons">
                      <img
                        className="archive-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />

                      <img
                        className="archive-wanted-service-magnifier-1-icon"
                        alt=""
                        src={deal_2_1_2x}
                      />

                      <img
                        className="archive-wanted-service-magnifier-1-icon"
                        alt=""
                        src={magnifier_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="archive-wanted-service-divider-parent">
          <img className="archive-wanted-service-divider-icon4" alt="" src={divider2} />

          <div className="archive-wanted-service-user-listings">
            <div className="archive-wanted-service-frame-parent3">
              <div className="archive-wanted-service-frame-parent4">
                <img
                  className="archive-wanted-service-user-content-child"
                  alt=""
                  src={group_58_2x}
                />

                <div className="archive-wanted-service-inner-info-wrapper">
                  <div className="archive-wanted-service-inner-info">
                    <div className="archive-wanted-service-name">Olivia Rhye</div>
                    <div className="archive-wanted-service-role1">Business Name</div>
                  </div>
                </div>
              </div>
              <div className="archive-wanted-service-buttonsbutton-wrapper">
                <button className="archive-wanted-service-buttonsbutton1">
                  <img className="archive-wanted-service-eye-icon" alt="" src={eye} />

                  <div className="archive-wanted-service-navigation">
                    <div className="archive-wanted-service-view-label">View</div>
                  </div>
                </button>
              </div>
              <div className="archive-wanted-service-logo-wrapper">
                <div className="archive-wanted-service-buttonsbutton2">
                  <div className="archive-wanted-service-icon-wrapper">
                    <img className="archive-wanted-service-icon1" alt="" src={icon1} />
                  </div>
                  <div className="archive-wanted-service-navigation">
                    <div className="archive-wanted-service-lock-label">Sales IQ</div>
                  </div>
                  <img className="archive-wanted-service-eye-icon" alt="" src={lock04} />
                </div>
              </div>
              <div className="archive-wanted-service-logo-wrapper">
                <div className="archive-wanted-service-buttonsbutton3">
                  <div className="archive-wanted-service-icon-wrapper">
                    <img className="archive-wanted-service-icon1" alt="" src={icon1} />
                  </div>
                  <div className="archive-wanted-service-navigation">
                    <div className="archive-wanted-service-lock-label">Sales IQ</div>
                  </div>
                  <img
                    className="archive-wanted-service-eye-icon"
                    alt=""
                    src={lockunlocked04}
                  />
                </div>
              </div>
            </div>
            <div className="archive-wanted-service-arrow-square-up-right-wrapper">
              <img
                className="archive-wanted-service-frame-child1"
                alt=""
                src={arrowsquareupright}
              />
            </div>
          </div>
        </div>
      </div> */}

        <div className="listings-container">
          {/* <div class="listing-item">
            <h3>Listing 1</h3>
            <p>Description for listing 1.</p>
          </div>
          <div class="listing-item">
            <h3>Listing 2</h3>
            <p>Description for listing 2.</p>
          </div>
          <div class="listing-item">
            <h3>Listing 3</h3>
            <p>Description for listing 3.</p>
          </div> */}
          {content}
        </div>
        <div className="archive-wanted-service-buttonsbutton7">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <a className="archive-wanted-service-text47">All time</a>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-service-buttonsbutton8">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text48">Clinical Applications</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </button>
        <div className="archive-wanted-service-buttonsbutton9">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text49">US, AU, +4</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </div>
        <div className="archive-wanted-service-buttonsbutton10">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text50">All/Employee</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-service-buttonsbutton11">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text48">Clinical Applications</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </button>
        <div className="archive-wanted-service-buttonsbutton12">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-menu-label">Year of Manufacture</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </div>
        <button className="archive-wanted-service-buttonsbutton13">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text53">Warrenty</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-service-buttonsbutton14">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text54">Manufacturer</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-service-buttonsbutton15">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text55">All Applications</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-service-buttonsbutton16">
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-wanted-service-text56">Rent/Lease</div>
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={xclose} />
        </button>
        <button className="archive-wanted-service-buttonsbutton17">
          <img className="archive-wanted-service-sliders-02-icon" alt="" src={filterlines} />

          <div className="archive-wanted-service-text57">More filters</div>
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />
        </button>
        <button className="archive-wanted-service-buttonsbutton18">
          <img
            className="archive-wanted-service-sliders-02-icon"
            alt=""
            src={filterfunnel01}
          />

          <div className="archive-wanted-service-text58">Clear Filter</div>
          <img className="archive-wanted-service-placeholder-icon" alt="" src={placeholder} />
        </button>
        <button className="archive-wanted-service-actions">
          <div className="archive-wanted-service-button">
            <img
              className="archive-wanted-service-placeholder-icon"
              alt=""
              src={placeholder1}
            />

            <div className="archive-wanted-service-text-padding1">
              <div className="archive-wanted-service-text59">Tertiary</div>
            </div>
            <img
              className="archive-wanted-service-placeholder-icon"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="archive-wanted-service-button1">
            <img
              className="archive-wanted-service-placeholder-icon"
              alt=""
              src={placeholder2}
            />

            <div className="archive-wanted-service-text-padding1">
              <div className="archive-wanted-service-text60">Secondary</div>
            </div>
            <img
              className="archive-wanted-service-placeholder-icon"
              alt=""
              src={placeholder2}
            />
          </div>
          <div className="archive-wanted-service-button2">
            <img
              className="archive-wanted-service-sliders-02-icon"
              alt=""
              src={filterlines}
            />

            <div className="archive-wanted-service-text-padding">
              <div className="archive-wanted-service-filter-label">Save Filter</div>
            </div>
            <img
              className="archive-wanted-service-placeholder-icon"
              alt=""
              src={placeholder3}
            />
          </div>
        </button>
      </div>

      <img className="archive-wanted-service-divider-icon6" alt="" src={divider4} />

      <img className="archive-wanted-service-divider-icon7" alt="" src={divider4} />



      <FooterAddForms />




    </div>


  )
}

