import React from 'react';
import "./UserMenu.css";
import user01 from '../../icons/Gerator/dashboard/user01.svg';
import settings011 from '../../icons/Gerator/dashboard/settings011.svg';
import zap from '../../icons/Gerator/dashboard/zap.svg';
import divider from '../../icons/Gerator/dashboard/divider.svg';
import homeline from '../../icons/Gerator/dashboard/homeline.svg';
import userplus01 from '../../icons/Gerator/dashboard/userplus01.svg';
import layerstwo01 from '../../icons/Gerator/dashboard/layerstwo01.svg';
import messagesmilecircle from '../../icons/Gerator/dashboard/messagesmilecircle.svg';
import helpcircle from '../../icons/Gerator/dashboard/helpcircle.svg';
import container from '../../icons/Gerator/dashboard/container.svg';
import logout011 from '../../icons/Gerator/dashboard/logout011.svg';
import users011 from '../../icons/Gerator/dashboard/users011.svg';
import users011 from '../../icons/Gerator/dashboard/users011.svg';


import { Link, useNavigate } from "react-router-dom";
import { logout } from '../../helper/UserHelper';
import { auth, signOut } from '../../screens/Login/FirebaseClient';

export const UserMenuComponent = ({ user, profileImage }) => {
  const navigate = useNavigate();


  const logout = async () => {
    // const auth = getAuth();
    try {
      //Signout from firebase
      await signOut(auth);
      // Handle successful logout, like redirecting to the login page
      console.log("User logged out successfully");
      navigate("/sign-in", { state: { message: "User logged out successfully" } });
    } catch (error) {
      console.log("Error during logout:", error);
      navigate("/sign-in", { state: { message: error.message } });
    }
  };
  return (
    <div className="user-menu-dropdown-user-menu-all-options-fixed">
      <div className="user-menu-dropdown-dropdown-list-header">
        <div className="user-menu-dropdown-avatar-label-group">
          <div className="user-menu-dropdown-avatar22" style={{
            backgroundImage: profileImage
              && `url(${profileImage})`
          }}>
            <div className="user-menu-dropdown-contrast-border22"></div>
            <div className="user-menu-dropdown-avatar-online-indicator6"></div>
          </div>
          <div className="user-menu-dropdown-text-and-supporting-text19 user-menu-dropdown-text-and-supporting-text19-custom-width">
            <div className="user-menu-dropdown-text246">{user.displayName}</div>
            <div className="user-menu-dropdown-supporting-text84">{user.email}</div>
          </div>
        </div>
      </div>
      <section className="user-menu-dropdown-menu-items">
      <div className="user-menu-dropdown-dropdown-list-item">
        <a href="/dashboard" className='link-width-max'>
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img className="user-menu-dropdown-user-01-icon" alt="" src={homeline} />

              <div className="user-menu-dropdown-text247">Dashboard</div>
            </div>
          </div>
          </a>
        </div>
      
        <div className="user-menu-dropdown-dropdown-list-item">
          <a href="/dashboard/settings/profile" className='link-width-max'>
            <div className="user-menu-dropdown-content48">
              <div className="user-menu-dropdown-icon-and-text">
                <img className="user-menu-dropdown-user-01-icon" alt="" src={settings011} />

                <div className="user-menu-dropdown-text247">Settings</div>
              </div>
            </div>
          </a>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item">
          <a href="/dashboard/settings/plan" className='link-width-max'>
            <div className="user-menu-dropdown-content48">
              <div className="user-menu-dropdown-icon-and-text">
                <img
                  className="user-menu-dropdown-user-01-icon"
                  loading="lazy"
                  alt=""
                  src={zap}
                />

                <div className="user-menu-dropdown-text247">Plans & Billing</div>
              </div>
            </div>
          </a>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item3">
          <img
            className="user-menu-dropdown-divider-icon16"
            loading="lazy"
            alt=""
            src={divider}
          />
        </div>
        <div className="user-menu-dropdown-dropdown-list-item">
        <a href="/dashboard/settings/business" className='link-width-max'>
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img
                className="user-menu-dropdown-user-01-icon"
                loading="lazy"
                alt=""
                src={layerstwo01}
              />

                <div className="user-menu-dropdown-text247">My Business</div>
              </div>
            </div>
          </a>
        </div>
        

        <div className="user-menu-dropdown-dropdown-list-item">
          <a href="/dashboard/settings/profile" className='link-width-max'>
            <div className="user-menu-dropdown-content48">
              <div className="user-menu-dropdown-icon-and-text">
                <img className="user-menu-dropdown-user-01-icon" alt="" src={user01} />
                <div className="user-menu-dropdown-text247"><span>My Profile</span></div>
              </div>
            </div>
          </a>
        </div>

        
        <div className="user-menu-dropdown-dropdown-list-item">
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img
                className="user-menu-dropdown-user-01-icon"
                loading="lazy"
                alt=""
                src={userplus01}
              />

              <div className="user-menu-dropdown-text247">Invite colleagues</div>
            </div>
          </div>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item3">
          <img
            className="user-menu-dropdown-divider-icon16"
            loading="lazy"
            alt=""
            src={divider}
          />
        </div>
        <div className="user-menu-dropdown-dropdown-list-item">
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img className="user-menu-dropdown-user-01-icon" alt="" src={users011} />

              <div className="user-menu-dropdown-text247">Team</div>
            </div>
          </div>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item">
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img
                className="user-menu-dropdown-user-01-icon"
                loading="lazy"
                alt=""
                src={messagesmilecircle}
              />

              <div className="user-menu-dropdown-text247">Slack Community</div>
            </div>
          </div>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item10">
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img
                className="user-menu-dropdown-user-01-icon"
                loading="lazy"
                alt=""
                src={helpcircle}
              />

              <div className="user-menu-dropdown-text247">Support</div>
            </div>
          </div>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item11">
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img className="user-menu-dropdown-user-01-icon" alt="" src={container} />

              {/* <input className="user-menu-dropdown-text256" placeholder="API" type="text" /> */}
              <div className="user-menu-dropdown-text247">API</div>
            </div>
          </div>
        </div>
        <div className="user-menu-dropdown-dropdown-list-item12">
          <img
            className="user-menu-dropdown-divider-icon16"
            loading="lazy"
            alt=""
            src={divider}
          />
        </div>
        <div className="user-menu-dropdown-dropdown-list-item13">
          <div className="user-menu-dropdown-content48">
            <div className="user-menu-dropdown-icon-and-text">
              <img className="user-menu-dropdown-user-01-icon" alt="" src={logout011} />

              <div className="user-menu-dropdown-text247" onClick={logout}>Log out</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

