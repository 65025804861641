import React from 'react';
import "./EditJobStep.css"
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevrondown_2 from '../../icons/Gerator/add_form/chevrondown-2.svg';
import help_icon from '../../icons/Gerator/add_form/help-icon.svg';
import dotsvertical_1 from '../../icons/Gerator/add_form/dotsvertical-1.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import save02 from '../../icons/Gerator/add_form/save02.svg';
import { Formik, useField, useFormikContext } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFindGetJobQuery, useFindGetJobWithoutTaxQuery, useGetJobQuery } from "../../api/JobAPI";
import { fillEditJobForm, updateEditJobField } from "./EditJobSlice";
import { updateAppDataEditJob } from "./AppDataEditJobSlice";
import { useParams } from "react-router-dom";
import EditJobFormContext from "../../screens/Job/EditJob/EditJobContext";
import dollar from "../../icons/Gerator/add_form/dollar.svg";
import { formatNumber } from '../../helper/DeviceHelper';

const MyTextArea = ({ validate, label, remainingChars, onChange, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step3-input-with-label42">
        <div>
          <label className="add-service-step3-label47" htmlFor={props.id || props.name}>
            {label}
            <span className="add-service-step-span">*</span>
          </label>

        </div>
        <div className="add-service-step3-content62">
          <div className="add-service-step3-textarea-input-field7">
            <div className="add-service-step3-input-with-label43">
              {/* <div className="add-service-step3-label46">Description</div> */}
              <div className="add-service-step3-input31">
                <textarea
                  className="add-service-step3-text82"
                  // style={inputStyle}  //New code for border colour
                  {...field}
                  {...props}
                  onChange={(e) => {
                    onChange(e); // Call parent onChange to track remaining characters
                  }}

                />
              </div>
            </div>

          </div>
        </div>
      </div>


      <span>{remainingChars} characters remaining</span>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const MyTextInputForCustomSelect = ({ disableBlurValidation, validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label15">
          <label className="add-job-step-transaction-type" htmlFor={props.id || props.name}>
            {label}
          </label>
        </div>
        <div className="add-job-step-input14">
          <div className="add-job-step-content22">
            <div className="add-job-step-text-input">

              <input
                className="add-job-step-first-nested-input add-job-step-first-nested-input-remove-border"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
                onBlur={disableBlurValidation ? (e) => e.preventDefault() : field.onBlur}
              />

            </div>
          </div>
        </div>
      </div>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const MyTextInput = ({ validate, label, showDollarSign = false, showCharCount = false, remainingChars, onChange, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label5">
          <label className="add-job-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-job-step-span">*</span>
          </label>
        </div>
        <div className="add-job-step-input5">

          {showDollarSign && (
            <span className="dollar-sign">
              {/* Using the downloaded SVG file with an img tag */}
              <img src={dollar} alt="Dollar Sign" width={18} height={18} />
            </span>
          )}
          <input
            className="add-job-step-content12"
            // style={inputStyle}  //New code for border colour
            {...field}
            {...props}
            onChange={(e) => {
              onChange(e); // Call parent onChange to track remaining characters
            }}

          />
        </div>
      </div>

      {showCharCount && (
        <span>{remainingChars} characters remaining</span>
      )}


      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label3">
          <label className="add-job-step-transaction-type" htmlFor={props.name}>
            {label} <span className="add-job-step-span">*</span>
          </label>
        </div>
        <div className="add-job-step-input1">
          <div className="add-job-step-content8">
            <div className="add-job-step-text-input">
              <select
                className="add-job-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

const MySelectNotMandatory = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }



  return (
    <>
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label3">
          <label className="add-job-step-transaction-type" htmlFor={props.name}>
            {label}
          </label>
        </div>
        <div className="add-job-step-input1">
          <div className="add-job-step-content8">
            <div className="add-job-step-text-input">
              <select
                className="add-job-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};


export const EditJobStep1 = () => {

  const maxLength = 50;
  const maxLength2 = 1000;

  const [jobTitleCharCount, setjobTitleCharCount] = useState(maxLength);
  const [overviewCharCount, setoverviewCharCount] = useState(maxLength);
  const [deliverablesCharCount, setdeliverablesCharCount] = useState(maxLength2);



  const dispatch = useDispatch();
  const { id } = useParams();

  //For deviceCategory and oem, used for get reference of custom select dropdown
  const ulDeviceCategoryRef = useRef(null);
  // const ulOemRef = useRef(null);


  const formData = useSelector((state) => state.editJob);
  const appData = useSelector((state) => state.appDataEditJob);
  const { validateForm, setFieldTouched, setFieldValue, setValues } = useFormikContext();
  // const {setFeatureImageMeta} = useContext(EditJobFormContext);
  // const formikRef = useRef(null);


  const { setDocumentMultiUploadStatus, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, setEditJobFormInitialData, isRequestFirst, setIsRequestFirst, backendValidation, allTaxonomy } = useContext(EditJobFormContext);


  //useState for make visible custom Select field on form
  const [visibleDeviceCategorySelectField, setVisibleDeviceCategorySelectField] = useState(false)

  const editDataFromDb = useFindGetJobWithoutTaxQuery(id)


  useEffect(() => {
    // Ensure the query is successful and data exists
    if (!editDataFromDb.isSuccess || !editDataFromDb.data) return;

    const { documentLinks } = editDataFromDb.data;

    // Map galleryImageLinks to construct the initial upload status array
    const uploadStatusDocument = documentLinks?.map((postId, index) => ({
      index,
      pending: false,
      success: true,
      isError: false,
      postId,
    })) || [];


    // Update the document multi-upload status
    setDocumentMultiUploadStatus(uploadStatusDocument);


  }, [editDataFromDb.isSuccess, editDataFromDb.data]);

  useEffect(() => {
    console.log("backend validation length" + backendValidation.length);
    if (editDataFromDb.isSuccess && isRequestFirst) {
      const { budget, ...rest } = editDataFromDb.data;

      const editData = {
        ...rest,
        budget: formatNumber(budget),
      };
      dispatch(fillEditJobForm(editData));
      setValues(editData);
      setEditJobFormInitialData(editData);
      (editData.tax_device_category) ? setDeviceCategorySearchField(editData.tax_device_category.name) : setDeviceCategorySearchField("")
      setIsRequestFirst(false);
    }
    // if (isError) {
    //   // Handle the error (e.g., display an error message)
    //   console.error(error);
    // }
  }, [editDataFromDb.isSuccess]); // Dependencies to trigger the effect


  // useEffect(() => {
  //   formikRef.current?.setValues(formData);
  //   // console.log(formikRef.current);
  // }, [formData]);

  //This method used to increment current step of form and update state in appData reducer
  const next = async () => {
    try {
      // const { errors } = useFormikContext();
      const validatedError = await validateForm();
      // console.log(formikUpdated);
      // console.log(validatedError);
      if (Object.keys(validatedError).length > 0) {

        Object.keys(validatedError).forEach(key => {
          // console.log(key);
          setFieldTouched(key, true);
        });
      } else {
        dispatch(updateAppDataEditJob({ case: "CURRENTSTEP", value: (appData.currentStep + 1) }));
      }
      // dispatch(updateAppDataJob({ case: "CURRENTSTEP", value: (appData.currentStep + 1) }));
    }
    catch (err) {
      // console.log(Object.keys(err).length);
      if (Object.keys(err).length > 0) {

        Object.keys(err).forEach(key => {
          console.log(key);
          setFieldTouched(key, true);
        });
      }
    }
    // dispatch(updateAppDataEditJob({ case: "CURRENTSTEP", value:(appData.currentStep + 1)}));
  };

  //This handle method used to capture input entered text and save them into redux state
  const handleChange = async (event) => {
    const { name, value } = event.target;
    //If entered/edited data in deviceCategory field this block will run
    if (name === 'deviceCategory') {

      //Dropdown will be visible
      // setVisibleDeviceCategorySelectField(true)

      //when deviceCategory field's data edited/removed then this block will run
      if (value.length < deviceCategorySearchField.length || value.length > deviceCategorySearchField.length) {
        console.log("inside if");
        //formik form object value set to 0
        setFieldValue(name, "0");
        //deviceCategory field value is set 0 in form object
        dispatch(updateEditJobField({ field: name, value: "0" }));
        //deviceCategory field input value is set
        setDeviceCategorySearchField(value);
        //form field deviceCategory is set to touch. then validation error will show 
        await setFieldTouched(name, true);
        return;
      }
    }

    if (name === 'budget') {
      // Only format when there's actually a value
      if (value) {
        const formattedValue = formatNumber(value);
        setFieldValue(name, formattedValue);
        dispatch(updateEditJobField({ field: name, value: formattedValue }));
      } else {
        // Handle empty input
        setFieldValue(name, '');
        dispatch(updateEditJobField({ field: name, value: '' }));
      }
      return;
    }

    // if (name === 'oem') {

    //   //Dropdown will be visible
    //   // setVisibleDeviceCategorySelectField(true)

    //   //Field value length less than useState varaible then remove data from useState as well
    //   if (value.length < oemSearchField.length || value.length > oemSearchField.length) {

    //     // if (formData.oem !== "") {
    //     //   dispatch(updateWantedDeviceField({ field: name, value: "" }));
    //     // }
    //     //formik form object value set to 0
    //     setFieldValue(name, "0");
    //     //oem field value is set 0 in form object
    //     dispatch(updateEditDeviceField({ field: name, value: "0" }));
    //     //oem field input value is set
    //     setOemSearchField(value);
    //     //form field oem is set to touch. then validation error will show 
    //     await setFieldTouched(name, true);
    //     // console.log(value.length);
    //     return;
    //   }
    // }
    setFieldValue(name, value);
    dispatch(updateEditJobField({ field: name, value }));
  };

  const handleSelect = async (tax, field) => {
    const { id, name } = tax;
    console.log("handle select called");
    //Saving details into form
    if (field === 'deviceCategory' && id != 0) {
      setDeviceCategorySearchField(name);
      setVisibleDeviceCategorySelectField(false);
      setNewDeviceCategoryLength(50)
      await setFieldValue("deviceCategory", id);
      dispatch(updateEditJobField({ field: "deviceCategory", value: id }));
      setFieldTouched("deviceCategory", true);
      return;
    } else {
      dispatch(updateEditJobField({ field: "deviceCategory", value: id }));
      setFieldTouched("deviceCategory", true);
    }

    // if (field === 'oem' && id != 0) {
    //   setOemSearchField(name);
    //   setVisibleOemSelectField(false);
    //   setNewOemLength(50)
    //   await setFieldValue("oem", id);
    //   dispatch(updateEditDeviceField({ field: "oem", value: id }));
    //   setFieldTouched("oem", true);
    //   return;
    // } else {
    //   dispatch(updateEditDeviceField({ field: "oem", value: id }));
    //   setFieldTouched("oem", true);
    // }
  };

  const handleClick = (event) => {
    const name = event.target.name;
    if (name === "deviceCategory") {
      setFieldTouched("deviceCategory", false);
      setVisibleDeviceCategorySelectField(true);
      return;
    }
    // if (name === "oem") {
    //   setFieldTouched("oem", false);
    //   setVisibleOemSelectField(true);
    //   // return;
    // }

    // setDeviceCategorySearchField();
  }

  //useEffect to unselect dropdown when outside of deviceCategory and oem clicked
  useEffect(() => {
    const handleClick = (event) => {
      const deviceCategoryElement = document.getElementById("deviceCategory");
      // const oemElement = document.getElementById("oem");


      // if (ulElement.contains(event.target) || deviceCategoryElement.contains(event.target)) {
      if (!deviceCategoryElement.contains(event.target)) {
        setVisibleDeviceCategorySelectField(false)
      }

      // if (!oemElement.contains(event.target)) {
      //   setVisibleOemSelectField(false)
      // }
      // const dropdown = document.getElementById('custom-dropdown');
    }

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  useEffect(() => {
    const handleScroll = (event) => {

      const ulChildElement = event.target;
      const scrollTop = ulChildElement.scrollTop;
      const scrollHeight = ulChildElement.scrollHeight;
      const clientHeight = ulChildElement.clientHeight;

      // console.log(ulChildElement);
      console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);


      // // Check if the scroll is near the end
      if (scrollTop + clientHeight >= scrollHeight - 10 * (ulChildElement.children[0].offsetHeight)) {
        // const secondLastValue = ulChildElement.children[ulChildElement.children.length - 10].textContent;
        setNewDeviceCategoryLength(newDeviceCategoryLength + ulChildElement.children.length);
        console.log("length of device category " + (newDeviceCategoryLength + ulChildElement.children.length));
      }
      // console.log('ul is being scrolled', event);
    };


    const ulElement = ulDeviceCategoryRef.current;
    // console.log(ulElement);
    if (ulElement) {
      ulElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (ulElement) {
        ulElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [visibleDeviceCategorySelectField]);


  useEffect(() => {
    setjobTitleCharCount(Math.max(maxLength - (formData.jobTitle?.length || 0), 0));
    setoverviewCharCount(Math.max(maxLength - (formData.overview?.length || 0), 0));
    setdeliverablesCharCount(Math.max(maxLength2 - (formData.deliverables?.length || 0), 0));
  }, [formData]);


  return (
    // <form className="add-job-step-section-header-parent">

    <>
      <div className="add-job-step-section-header">
        <div className="add-job-step-content7">

          Basic Information
        </div>


        <img
          className="add-job-step-divider-icon2"
          alt=""
          src={divider1}
        />
      </div>
      <div className="add-job-step-input-field-parent">
        <div className="add-job-step-input-field">
          <MyTextInput
            label="Job Title"
            name="jobTitle"
            id="jobTitle"                                                      //New Code
            type="text"
            placeholder="Eg:Medical Director for a 400 bed super speciality teaching hospital"
            onChange={handleChange}
            validate={backendValidation} value={formData.jobTitle}
            remainingChars={jobTitleCharCount}
            showCharCount={true}
          />

        </div>

      </div>
      <div className="add-job-step-input-field-parent">

        <div className="add-job-step-input-field">
          <MyTextInput
            label="Overview"
            name="overview"                                                          //New Code
            type="text"
            placeholder="What is expected from this role"
            id="overview"
            onChange={handleChange}
            validate={backendValidation} value={formData.overview}
            remainingChars={overviewCharCount}
            showCharCount={true}
          />

        </div>

      </div>

      <section className="add-service-step3-input-field35">
        <MyTextArea
          label="Deliverables"
          name="deliverables"                                                          //New Code
          type="text"
          placeholder="1000 characters"
          id="deliverables"
          onChange={handleChange}
          validate={backendValidation} value={formData.deliverables}
          remainingChars={deliverablesCharCount}
        />
      </section>

      <div className="add-job-step-input-field-parent">
        <div className="add-job-step-input-field">
          <MySelect label="Engagement Type" name="engagementType" id="engagementType" onChange={handleChange} validate={backendValidation} value={formData.engagementType}>
            <option value="0">Select From Dropdown list </option>
            {(allTaxonomy.engagementType.isLoading || allTaxonomy.engagementType.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.engagementType.isSuccess) && (allTaxonomy.engagementType.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>

        </div>


        <div className="add-job-step-input-field">
          <MySelect label="Experience Level" name="experienceLevel" id="experienceLevel" onChange={handleChange} validate={backendValidation} value={formData.experienceLevel}>
            <option value="0">Select From Dropdown list </option>
            {(allTaxonomy.experienceLevel.isLoading || allTaxonomy.experienceLevel.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.experienceLevel.isSuccess) && (allTaxonomy.experienceLevel.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>

        </div>
      </div>


      <div className="add-job-step-input-field-parent">
        <div className="add-job-step-input-field">
          <MySelect label="Occupation Category" name="occupationCategory" id="occupationCategory" onChange={handleChange} validate={backendValidation} value={formData.occupationCategory}>
            <option value="0">Select From Dropdown list </option>
            {(allTaxonomy.occupationCategory?.isLoading || allTaxonomy.occupationCategory?.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.occupationCategory?.isSuccess) && (allTaxonomy.occupationCategory?.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>


        <div className="add-job-step-input-field">
          <MySelect label="Service Category" name="serviceCategory" id="serviceCategory" onChange={handleChange} validate={backendValidation} value={formData.serviceCategory}>
            <option value="0">Select From Dropdown list </option>
            {(allTaxonomy.serviceCategory?.isLoading || allTaxonomy.serviceCategory?.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.serviceCategory?.isSuccess) && (allTaxonomy.serviceCategory?.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
      </div>

      <div className="add-job-step-input-field-parent">
        <div className="add-job-step-input-field">
          <MyTextInput
            label="Budget/Remuneration (Minimum)"
            name="budget"
            type="text"
            placeholder="Any Number"
            id="budget"
            onChange={handleChange} validate={backendValidation} value={formData.budget}

          />
        </div>


        <div className="add-job-step-input-field">
          <MySelect label="Unit of Measure" name="unitOfMeasureService" id="unitOfMeasureService" onChange={handleChange} validate={backendValidation} value={formData.unitOfMeasureService}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.unitOfMeasureService.isLoading || allTaxonomy.unitOfMeasureService.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.unitOfMeasureService.isSuccess) && (allTaxonomy.unitOfMeasureService.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }

          </MySelect>

        </div>
      </div>




      <div className="add-job-step-input-tags">
        <div className="add-job-step-divider"></div>
        <div className="add-job-step-section-header1">
          <div className="add-job-step-content-parent">
            <div className="add-job-step-text-and-supporting-text5">
              <div className="add-job-step-uploads-title">
                Select Tags Based on Device Features / Use
              </div>
            </div>
            <div className="add-job-step-dropdown9">
              <img
                className="add-job-step-dots-vertical-icon"
                alt=""
                src={dotsvertical_1}
              />
            </div>
          </div>
          <img
            className="add-job-step-divider-icon2"
            alt=""
            src={divider1}
          />
        </div>
        <div className="add-job-step-tagged-inputs">
          <div className="add-job-step-input-field12">
            <MySelectNotMandatory label="Clinical Applications" name="clinicalApplications" id="clinicalApplications" onChange={handleChange} validate={backendValidation} value={formData.clinicalApplications}>
              <option value="0">Select From Dropdown List </option>
              {(allTaxonomy.clinicalApplications.isLoading || allTaxonomy.clinicalApplications.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.clinicalApplications.isSuccess) && (allTaxonomy.clinicalApplications.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }

            </MySelectNotMandatory>

          </div>
        </div>

        <div className="add-job-step-input-field13">
          <MySelectNotMandatory label="Device Use" name="purposeUse" id="purposeUse" onChange={handleChange} validate={backendValidation} value={formData.purposeUse} >
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.purposeUse.isLoading || allTaxonomy.purposeUse.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.purposeUse.isSuccess) && (allTaxonomy.purposeUse.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }

          </MySelectNotMandatory>
        </div>

        <div className="add-job-step-input-field13">
          <MyTextInputForCustomSelect
            label="Device Category"
            name="deviceCategory"
            type="text"
            placeholder="Enter Device Category without OEM Brand - max. 30 characters"
            id="deviceCategory"
            autoComplete="off"
            disableBlurValidation={true}
            onChange={handleChange} onClick={handleClick} value={deviceCategorySearchField} validate={backendValidation}
          />
          <div id="deviceCategoryCustomSelect">
            {visibleDeviceCategorySelectField &&
              <ul ref={ulDeviceCategoryRef}>
                {(allTaxonomy.deviceCategory.isLoading || allTaxonomy.deviceCategory.isError) && <li value="0">Loading...</li>}
                {
                  (allTaxonomy.deviceCategory.isSuccess) && (allTaxonomy.deviceCategory.data.map(tax => (
                    <li key={tax.id} onClick={() => handleSelect(tax, 'deviceCategory')}>
                      {tax.name}
                    </li>
                  )))
                }
              </ul>
            }

          </div>

        </div>

        <div className="add-job-step-input-field13">
          <MySelectNotMandatory label="Physical Location" name="physicalLocation" id="physicalLocation" onChange={handleChange} validate={backendValidation} value={formData.physicalLocation}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.physicalLocation.isLoading || allTaxonomy.physicalLocation.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.physicalLocation.isSuccess) && (allTaxonomy.physicalLocation.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }

          </MySelectNotMandatory>

        </div>
      </div>
      <div className="add-job-step-section-footer">
        <img
          className="add-job-step-divider-icon2"
          loading="lazy"
          alt=""
          src={divider1}
        />

        <div className="add-job-step-content31 add-job-step-content31-custom">

          <div className="add-job-step-actions2">

            <button className="add-job-step-button9" type='button' onClick={next}>
              <img
                className="add-job-step-chevron-down-icon"
                alt=""
                src={placeholder3}
              />

              <img
                className="add-job-step-home-line-icon"
                alt=""
                src={save02}
              />

              <div className="add-job-step-text-padding6">
                <div className="add-job-step-text44">Save & Proceed</div>
              </div>
              <img
                className="add-job-step-chevron-down-icon"
                alt=""
                src={placeholder3}
              />
            </button>
          </div>
        </div>
      </div>



    </>

    // </form>
  )
}
