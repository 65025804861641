import React, { useState } from 'react';
import "./AddDevice.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';

import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubProgressStep1 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep1';
import { SideBarSubProgressStep2 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep2';
import { SideBarSubProgressStep3 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep3';
import AddDeviceParent from '../../../components/AddDeviceFormComponent/AddDeviceParent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Loader } from '../../../components/Loader';
import { useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useEffect } from 'react';
import { useRef } from 'react';
import { decryptErrorData } from '../../../helper/AESHelper';
import { Helmet } from 'react-helmet-async';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';
import { useLazyFindGetMyBusinessQuery, useLazyFindIsUserHasBusinessQuery, useLazyGetBusinessAllListingCountQuery } from '../../../api/BusinessAPI';
import { NoBusinessFound } from '../../../components/NoBusiness/NoBusiness';
import { NoAccessAddress } from '../../../components/NoAccessAddress/NoAccessAddress';
import { NoAccessFormUpgradePlan } from '../../../components/NoAccessFormUpgradePlan/NoAccessFormUpgradePlan';
import { useGetPlansForAddressQuery } from '../../../api/PlanAPI';

const renderProgressStep = (step) => {
  switch (step) {
    case 0:
      return <SideBarSubProgressStep1 />;
    case 1:
      return <SideBarSubProgressStep2 />;
    case 2:
      return <SideBarSubProgressStep3 />;
    default:
      return null;
  }
};


export const AddDevice = () => {

  let content = "";
  const appData = useSelector((state) => state.appDataAddDevice);
  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const [currentPlanAllowedListing, setCurrentPlanAllowedListing] = useState("");
  const [currentRemainingListing, setCurrentRemainingListing] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");

  const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindIsUserHasBusinessQuery();

  const { data: planConditionData, isSuccess: isPlanConditionSuccess, error: planConditionError, isLoading: isPlanConditionLoading, isError: isPlanConditionError } = useGetPlansForAddressQuery();

  const [triggerGetListingCount, { data: listingCountData, isError: isListingCountError }] = useLazyGetBusinessAllListingCountQuery();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUserMeta(usermeta);
            triggerGetBusiness();
            triggerGetListingCount();
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  useEffect(() => {
    if (planConditionData && isPlanConditionSuccess && currentUserMeta) {
      console.log(planConditionData);
      const foundObject = planConditionData.data.find(item => item.id === currentUserMeta.userPlan);
      setCurrentPlanAllowedListing(foundObject.planAllowedListing)
    }

  }, [isPlanConditionSuccess, planConditionData, currentUserMeta]);


  useEffect(() => {
    if (!listingCountData || !currentPlanAllowedListing) {
      return;
    }

    const remainingListingOfCurrentPlan = currentPlanAllowedListing - listingCountData.data;
    console.log(remainingListingOfCurrentPlan);
    setCurrentRemainingListing(remainingListingOfCurrentPlan);

  }, [listingCountData, currentPlanAllowedListing]);

  if (isError || isListingCountError) {

    content = <ErrorShowComponent message1={"Internal server error"} />

  }

  if (isSuccess) {
    console.log(data);
    if (data.status && currentRemainingListing) {
      content = <>
        <AddDeviceParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} />
      </>
    } else if (data.status && !currentRemainingListing) {
      content = <NoAccessFormUpgradePlan path={'/dashboard/settings/plan'} pathName={"Upgrade"} />
    }
    else {
      content = <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
    }

  }




  if (content !== "") {
    return (

      <>
        <Helmet>
          <title>Add Device</title>
        </Helmet>
        <div className="forms-add-device forms-add-device-custom-margin">
          {/* <div className="forms-add-device-child"></div> */}


          <Navbar1 userData={currentUser} navigate={navigate} />


          <img className="divider-icon" alt="" src={divider} />

          <main className="content-parent">

            {/* <Sidebar><SideBarSubNormal userData={undefined}/></Sidebar> */}
            <Sidebar>{renderProgressStep(appData.currentStep)}</Sidebar>

            <section className="bottom-actions bottom-actions-custom-padding">
              <div className="progress-container-parent">
                <div className="progress-container">
                  {/* <div className="progress-steps">  */}
                  {/* <div className="form-parent">  */}

                  {/* <ProgressStep1/>  */}
                  {/* <ProgressStep2/> 
                 <ProgressStep3/> 
                 </div> 
                 </div>  */}
                  <div className="content5">
                    <div className="main">

                      <HeaderForms text="Add Device " text1=" Add Device Listing" text2="Your first step to realizing asset value." />




                      <div className="main-child"></div>
                      <div className="main-item"></div>
                    </div>
                    <div className="body">
                      {content}
                    </div>
                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </section>

          </main>
          <img
            className="divider-icon"
            loading="lazy"
            alt=""
            src={divider_light}
          />

          <FooterAddForms />

        </div>
      </>
    )
  } else {
    return <Loader />
  }


}

