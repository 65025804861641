import React from 'react';
import "./NoTableDataDisplay.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import anton from "../../icons/Gerator/dashboard/Anton.png";

 export const NoTableDataDisplay = () => {
  return (
    <div className="no-table-data-display-no-table-data-display">
      <section className="no-table-data-display-empty-state">
        <div className="no-table-data-display-empty-state1">
          <div className="no-table-data-display-content">
            <img
              className="no-table-data-display-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={anton}
            />

            <div className="no-table-data-display-text-and-supporting-text">
              <div className="no-table-data-display-title">Nothing to show here</div>
              <div className="no-table-data-display-supporting-text">
                Ready to work some magic? ✨
              </div>
            </div>
          </div>
          {/* <div className="no-table-data-display-actions">
            <button className="no-table-data-display-buttonsbutton">
              <img
                className="no-table-data-display-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-table-data-display-text-padding">
                <div className="no-table-data-display-text">Clear search</div>
              </div>
              <img
                className="no-table-data-display-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="no-table-data-display-buttonsbutton1"  >
              <Link to={path} className='no-table-data-display-button-link'>
                <img className="no-table-data-display-plus-icon" alt="" src={plus} />

                <div className="no-table-data-display-text-padding">
                  <div className="no-table-data-display-text1">Add {pathName}</div>
                </div>
                <img
                  className="no-table-data-display-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button>
          </div> */}
        </div>
      </section>
    </div>
  )
}


