import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Spare/AddSpare/AddSpareContext";
import { Link, useNavigate } from "react-router-dom";
import { clearForm, updateField } from "../AddSpareFormComponent/AddSpareSlice";
import { updateAppDataSpare, clearAppData } from "../AddSpareFormComponent/AppDataSpareSlice";
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../../api/TaxonomyFormAPI";
import { objectToFormDataForAddSpare } from "../../helper/SpareHelper";
import { AddSpareStep1 } from "./AddSpareStep1";
import { AddSpareStep2 } from "./AddSpareStep2";
import { AddSpareStep3 } from "./AddSpareStep3";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useAddSpareMutation } from "../../api/SpareAPI";
import { decryptErrorData } from "../../helper/AESHelper";
import { useFindGetRelatedAddressNoRemoteQuery } from "../../api/AddressAPI";
import { isValidURL } from "../../helper/DeviceHelper";



const renderStep = (step) => {
  switch (step) {
    case 0:
      return <AddSpareStep1 />;
    case 1:
      return <AddSpareStep2 />;
    case 2:
      return <AddSpareStep3 />;
    default:
      return null;
  }
};

const AddSpareParent = ({ setShowSuccessMessage, currentPlanData }) => {

  const formikRef = useRef(null);
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //Oem helper select field
  const [newOemLength, setNewOemLength] = useState(50);
  const [oemSearchField, setOemSearchField] = useState("");

  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["transactionType", "yourRole", "deviceCategory", "oem", "partName", "statusCondition", "yearOfManufacture", "availability", "partNumber", "serialNumber", "price", "unitOfMeasure", "availableQuantity", "warranty", "shipping", "clinicalApplications", "purposeUse", "physicalLocation",];
  const step2FormKey = ["hardwareHighlights", "softwareUpgradesOsApplicationsworklistHighlights", "featureImageObject", "galleryImageObject", "linkVideo", "location",]
  const step3FormKey = ["documentFileObject", "documentLink", "parentDeviceCompatible", "hardware", "softwareUpgraadesOsApplicationsWorklist", "serviceHistory", "warrantyInput", "packingList", "additionalInformation",]

  const [addSpare, { isLoading, isError, error }] = useAddSpareMutation();
  const navigate = useNavigate();
  const transactionTypeTax = useGetTransactionTypeTaxQuery();
  const yourRoleTax = useGetYourRoleTaxQuery();
  const yearofManufactureTax = useGetYearOfManufactureTaxQuery()
  const availabilityTax = useGetAvailabilityTaxQuery()
  const unitOfMeasureTax = useGetUnitOfMeasureDeviceSpareTaxQuery();
  const warrantyTax = useGetWarrantyTaxQuery();
  const shippingTax = useGetShippingTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery()
  const purposeUseTax = useGetPurposeUseTaxQuery()
  const physicalLocationTax = useGetPhysicalLocationTaxQuery()
  const statusConditionTax = useGetStatusConditionTaxQuery();
  const oemTax = useGetOemTaxQuery({ id: newOemLength, query: oemSearchField });
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const relatedAddressTax = useFindGetRelatedAddressNoRemoteQuery();

  const [allTaxonomy, setAllTaxonomy] = useState({ oem: oemTax, deviceCategory: deviceCategoryTax, statusCondition: statusConditionTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, shipping: shippingTax, warranty: warrantyTax, unitOfMeasure: unitOfMeasureTax, availability: availabilityTax, yearOfManufacture: yearofManufactureTax, yourRole: yourRoleTax, transactionType: transactionTypeTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [oemTax.isSuccess, deviceCategoryTax.isSuccess, statusConditionTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, shippingTax.isSuccess, warrantyTax.isSuccess, unitOfMeasureTax.isSuccess, availabilityTax.isSuccess, yearofManufactureTax.isSuccess, yourRoleTax.isSuccess, transactionTypeTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        oem: oemTax, deviceCategory: deviceCategoryTax, statusCondition: statusConditionTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, shipping: shippingTax, warranty: warrantyTax, unitOfMeasure: unitOfMeasureTax, availability: availabilityTax, yearOfManufacture: yearofManufactureTax, yourRole: yourRoleTax, transactionType: transactionTypeTax, relatedAddress: relatedAddressTax
      });
    }
  }, [
    oemTax.isSuccess, oemTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    transactionTypeTax.isSuccess, transactionTypeTax.data,
    yourRoleTax.isSuccess, yourRoleTax.data,
    yearofManufactureTax.isSuccess, yearofManufactureTax.data,
    availabilityTax.isSuccess, availabilityTax.data,
    unitOfMeasureTax.isSuccess, unitOfMeasureTax.data,
    warrantyTax.isSuccess, warrantyTax.data,
    shippingTax.isSuccess, shippingTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    statusConditionTax.isSuccess, statusConditionTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,

  ]);


  const dispatch = useDispatch();
  const formData = useSelector((state) => state.addSpare);
  // const formikContext =  useFormikContext();

  const appData = useSelector((state) => state.appDataAddSpare);
  const [backendValidation, setBackendValidation] = useState([]);


  //-----------------------Feature Image UseState-----------------------
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false);


  //-----------------------Gallery Image UseState-----------------------
  const [selectedMultiImageFile, setSelectedMultiImageFile] = useState([]);
  //This will pause choose file option while image is uploading
  const [imageMultiUploadStatus, setImageMultiUploadStatus] = useState([]);
  //This will indicate is currently image uploading or not
  const [isImageUploading, setIsImageUploading] = useState(false);
  //While uploading image if got error from backend then this will be true and user will not be able to go next form
  const [isUploadedImageHasErrorShow, setisUploadedImageHasErrorShow] = useState(false);


  //-----------------------Document File UseState------------------------
  const [selectedMultiDocumentFile, setSelectedMultiDocumentFile] = useState("");
  const [documentMultiCheckboxError, setDocumentMultiCheckboxError] = useState("");
  const [documentMultiUploadStatus, setDocumentMultiUploadStatus] = useState([]);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [isUploadedDocumentHasErrorShow, setisUploadedDocumentHasErrorShow] = useState(false);




  //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedImageFile("")
  //   setSelectedMultiImageFile([])
  //   setSelectedDocumentFile("")
  //   setDeviceCategorySearchField("");
  //   setOemSearchField("");
  //   dispatch(clearForm())
  //   dispatch(clearAppData());
  //   formikRef.current.resetForm();
  //   // purgeStorage()
  // }

  const step1Schema = Yup.object({
    transactionType: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),
    yourRole: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    deviceCategory: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    oem: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),

    partName: Yup.string().required("This is a required field").max(30, 'Must be 30 characters or less'),

    statusCondition: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    yearOfManufacture: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    availability: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    modelNumber: Yup.string()
      .max(30, 'Must be 30 characters or less'),

    serialNumber: Yup.string()
      .max(30, 'Must be 30 characters or less'),


    price: Yup.string()
      .required("This is a required field")
      .test(
        'is-numeric',
        'Characters are not allowed, please enter numbers only',
        (value) => {
          if (!value) return true; // Allow empty value for required validation to handle

          // Remove commas and check if the remaining string is a valid number pattern
          const cleanValue = value.replace(/,/g, '');

          // Check if the value contains only valid numeric characters
          return /^-?\d*\.?\d*$/.test(cleanValue);
        }
      )
      .test(
        'decimal-places',
        'Only two decimal places are allowed',
        value => {
          if (!value) return true;
          const cleanValue = value.replace(/,/g, '');
          // First, check if it's just a decimal point
          if (cleanValue === '.') return false;
          const decimalMatch = cleanValue.match(/\.(\d+)/);
          return !decimalMatch || decimalMatch[1].length <= 2;
        }
      )
      .test(
        'non-negative',
        'Field data cannot be negative',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0;
        }
      )
      .test(
        'min-value',
        'Field data must be greater than or equal to 0.01',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0.01;
        }
      )
      .test(
        'max-value',
        'Field data must be less than or equal to 9,999,999.99',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue <= 9999999.99;
        }
      ),

    unitOfMeasure: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    availableQuantity: Yup.string()
      .required("This is a required field")
      .test(
        "no-decimals",
        "No decimal places are allowed",
        (value) => {
          if (!value) return true; // Skip validation if empty
          // Check for decimal point
          return !/[.]/.test(value);
        }
      )
      .test(
        'is-numeric',
        'Characters are not allowed, please enter numbers only',
        (value) => {
          if (!value) return true; // Allow empty value for required validation to handle

          // Remove commas and check if the remaining string is a valid number pattern
          const cleanValue = value.replace(/,/g, '');

          // Check if the value contains only valid numeric characters
          return /^-?\d*\.?\d*$/.test(cleanValue);
        }
      )
      .test(
        'non-negative',
        'Field data cannot be negative',
        value => {
          if (!value) return true;
          const numValue = Number(value.replace(/,/g, ''));
          return numValue >= 0;
        }
      )
      .test(
        "OPTION",
        "Please select a valid option",
        (value) => value !== '0'
      )
      .test(
        "range",
        "Value must be between 1 and 99,999",
        (value) => {
          if (!value) return true; // Skip validation if empty
          const numberValue = Number(value.replace(/,/g, ''));
          return numberValue > 0 && numberValue <= 99999;
        }
      ),

    unitOfMeasure: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),

    unitOfMeasure: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),
    warranty: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    shipping: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    clinicalApplications: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    purposeUse: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
    physicalLocation: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'

    ),
  })

  const step2Schema = Yup.object().shape({
    hardwareHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    softwareUpgradesOsApplicationsworklistHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // accessoriesHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    location: Yup.string().required("This is a required field").test(
      "OPTION", "Please select a valid option",
      (value) => value !== '0'
    ),
    featureImage: Yup.mixed()
      .test(
        'fileType',
        'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
        value => {
          if (!value) return true
          return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
        }
      )
      .test(
        'fileSize',
        'File too large (max size 1MB)',
        value => !value || (value && value.size <= 1024 * 1024)
      )
      .required('A file is required'),

    gallery: Yup.array()
      .of(
        Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only JPEG, PNG allowed)',
            value => {
              if (!value) return true
              // if (isValidURL(value)) return true;
              return ['image/jpeg', 'image/png', 'image/webp'].includes(value.file.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              // if (isValidURL(value)) return true;
              return !value.file || (value.file && value.file.size <= 1024 * 1024)
            }
          )
      )
  }
  )

  const step3Schema = Yup.object().shape({

    parentDeviceCompatible: Yup.string().max(1000, 'Must be 1000 characters or less'),

    hardware: Yup.string().max(1000, 'Must be 1000 characters or less'),

    softwareUpgraadesOsApplicationsWorklist: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // accessories: Yup.string().max(1000, 'Must be 1000 characters or less'),

    serviceHistory: Yup.string().max(1000, 'Must be 1000 characters or less'),

    warrantyInput: Yup.string().max(1000, 'Must be 1000 characters or less'),

    packingList: Yup.string().max(1000, 'Must be 1000 characters or less'),

    additionalInformation: Yup.string().max(2000, 'Must be 2000 characters or less'),

    document: Yup.array()
      .of(
        Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only PDF allowed)',
            value => {
              if (!value) return true
              // if (isValidURL(value)) return true;
              return ['application/pdf'].includes(value.file.type)
            }
          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              // if (isValidURL(value)) return true;
              return !value.file || (value.file && value.file.size <= 1024 * 1024)
            }
          )
      ),
  })

  const matchErrorsAgainstBackendValidationList = (backendValidationError) => {
    if (backendValidationError?.length > 0) {

      setBackendValidation(backendValidationError);

      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataSpare({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataSpare({ case: "CURRENTSTEP", value: 1 }));
          break;
        } else if (step3FormKey.includes(field.path)) {
          console.log("step3 was true");
          dispatch(updateAppDataSpare({ case: "CURRENTSTEP", value: 2 }));
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await addSpare(multiPartData, setSubmitting).unwrap();
      // console.log("Spare added successfully!", response);
      //If form was submitted successfully then isCreated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Spare added successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        dispatch(clearForm());
        dispatch(clearAppData());
        formikRef.current.resetForm();
        navigate('/dashboard');
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };



  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : appData.currentStep === 2 ? step3Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {

        const statusExists = documentMultiUploadStatus.some(status => status.isError === true);

        if (statusExists && !isDocumentUploading) {
          setisUploadedDocumentHasErrorShow(true);
          dispatch(updateAppDataSpare({ case: "CURRENTSTEP", value: 2 }));
          setSubmitting(false);
          return;
        }


        if (appData.isYoutubeChecked) dispatch(updateField({ field: "videoType", value: "Youtube" }));
        if (appData.isVimeoChecked) dispatch(updateField({ field: "videoType", value: "Vimeo" }));
        // Getting FormData object to send multipart request
        multiPartData = objectToFormDataForAddSpare(values, cropedImageFile, imageMultiUploadStatus, documentMultiUploadStatus,)
        //Final submission of form
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);
        // console.log(values);
        // console.log(multiPartData);
        //   for (const [key, value] of multiPartData.entries()) {
        //     console.log(key, value);
        // }
        // setSubmitting(false);

      }}
    >
      {(formik) => (
        <Form className="add-spare-step-section-header-parent">

          <Provider value={{ isUploadedDocumentHasErrorShow, setisUploadedDocumentHasErrorShow, documentMultiUploadStatus, setDocumentMultiUploadStatus, isDocumentUploading, setIsDocumentUploading, isUploadedImageHasErrorShow, setisUploadedImageHasErrorShow, isImageUploading, setIsImageUploading, imageMultiUploadStatus, setImageMultiUploadStatus, currentPlanData, oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, backendValidation, setBackendValidation, documentMultiCheckboxError, setDocumentMultiCheckboxError, isImageSelected, setIsImageSelected, allTaxonomy, cropedImageFile, setCropedImageFile, selectedMultiImageFile, setSelectedMultiImageFile, selectedMultiDocumentFile, setSelectedMultiDocumentFile, selectedImageFile, setSelectedImageFile }}>
            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default AddSpareParent;