import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Business/EditBusiness/EditBusinessContext";
import { useGetBusinessGenreTaxQuery, useGetBusinessSizeTaxQuery, useGetCountryTaxQuery, useGetOwnershipTaxQuery } from '../../api/TaxonomyFormAPI';
// import { getAllTaxonomyData } from "../../helper/EditDeviceHelper";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useEditDeviceMutation } from "../../api/DeviceAPI";
import { objectToFormData } from "../../helper/DeviceHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { EditBusinessForm } from "./EditBusinessForm";
import { isValidURL, objectToBusinessEditFormData, objectToBusinessFormData } from "../../helper/BusinessHelper";
import { useEditBusinessMutation, useFindGetBusinessWithoutTaxQuery } from "../../api/BusinessAPI";
import { useNavigate, useParams } from "react-router-dom";


const EditBusinessParent = ({ setShowSuccessMessage, formikRef, formLoadData }) => {
  const navigate = useNavigate();

  const initialValues =
  {
    businessName: '',
    businessWebsite: '',
    businessExpertiseHighlights: '',
    businessExpertiseDescription: '',
    businessState: '',
    businessTownCity: '',
    businessDistrict: '',
    businessCountry: '',
    businessPostcodeZip: '',
    businessAddress: '',
    businessSize: '',
    businessOwnership: '',
    businessGenre: '',
    logoImage: '',
    bannerImage: '',
  }

  useEffect(() => {
    console.log(formLoadData);

    formikRef.current.setValues(
      {
        id: formLoadData.id,
        businessName: formLoadData.businessName,
        businessWebsite: formLoadData.businessWebsite,
        businessExpertiseHighlights: formLoadData.businessExpertiseHighlights,
        businessExpertiseDescription: formLoadData.businessExpertiseDescription,
        businessState: formLoadData.businessState,
        businessTownCity: formLoadData.businessTownCity,
        businessDistrict: formLoadData.businessDistrict,
        businessCountry: formLoadData.businessCountry,
        businessPostcodeZip: formLoadData.businessPostcodeZip,
        businessBuilding: formLoadData.businessBuilding,
        businessStreet: formLoadData.businessStreet,
        businessSize: formLoadData.businessSize,
        businessOwnership: formLoadData.businessOwnership,
        businessGenre: formLoadData.businessGenre,
        logoImage: formLoadData.logoImage,
        logoImageLink: formLoadData.logoImageLink,
        bannerImage: formLoadData.bannerImage,
        bannerImageLink: formLoadData.bannerImageLink,
        logo_image: formLoadData.logo_image,
        banner_image: formLoadData.banner_image
      }
    )


  }, [formLoadData])



  //Device Category helper select field
  const ownershipTax = useGetOwnershipTaxQuery();
  const businessGenreTax = useGetBusinessGenreTaxQuery();
  const businessSizeTax = useGetBusinessSizeTaxQuery();
  const countryTax = useGetCountryTaxQuery();

  // Progress var when this is true means all taxonomy loaded
  // const [isTaxonomySet, setIsTaxonomySet] = useState(false);

  //useState var to save tax
  const [allTaxonomy, setAllTaxonomy] = useState({ businessSize: businessSizeTax, country: countryTax, businessGenre: businessGenreTax, ownership: ownershipTax, })
  const [editBusiness, { isLoading, isError, error }] = useEditBusinessMutation();

  useEffect(() => {

    const allSuccess = [businessSizeTax.isSuccess, countryTax.isSuccess, businessGenreTax.isSuccess, ownershipTax.isSuccess,].every(Boolean);

    console.log('all success ' + allSuccess);


    if (allSuccess) {
      setAllTaxonomy({
        businessSize: businessSizeTax, country: countryTax, businessGenre: businessGenreTax, ownership: ownershipTax
      });
    }

    // setIsTaxonomySet(true);
  }, [
    ownershipTax.isSuccess, ownershipTax.data,
    businessGenreTax.isSuccess, businessGenreTax.data,
    countryTax.isSuccess, countryTax.data,
    businessSizeTax.isSuccess, businessSizeTax.data,
  ]);


  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [backendValidation, setBackendValidation] = useState([])
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState('');
  const [cropedBannerImageFile, setCropedBannerImageFile] = useState("");
  const [editBusinessFormInitialData, setEditBusinessFormInitialData] = useState(null);
  const [isRequestFirst, setIsRequestFirst] = useState(true);







  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await editBusiness({ body: multiPartData }).unwrap();
      // console.log("Device edited successfully!", response);
      //If form was submitted successfully then isCreated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Business edited successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        // formikRef.current.resetForm();
        navigate('/dashboard/settings/business');
        formikRef.current.resetForm();
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        // matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={Yup.object({

        businessCountry: Yup.string().required("Field is required").test(
          "OPTION", "Please select a valid option",
          (value) => value !== '0'
        ),
        businessGenre: Yup.string().required("Field is required").test(
          "OPTION", "Please select a valid option",
          (value) => value !== '0'
        ),
        businessOwnership: Yup.string().required("Field is required").test(
          "OPTION", "Please select a valid option",
          (value) => value !== '0'
        ),
        businessSize: Yup.string().required("Field is required").test(
          "OPTION", "Please select a valid option",
          (value) => value !== '0'
        ),

        businessName: Yup.string().required("Field is required")
          .matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots')
          .min(3, 'Must be 3 characters or more')
          .max(35, 'Must be 35 characters or less'),

        businessWebsite: Yup.string().required("Field is required").min(3, 'Must be 3 characters or more').max(50, 'Must be 50 characters or less'),

        businessBuilding: Yup.string().required("Field is required").matches(/^[A-Za-z0-9][A-Za-z0-9\s\-\.]+$/, 'Must start with a letter or number and contain only letters, numbers, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(35, 'Must be 35 characters or less'),

        businessState: Yup.string().matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(40, 'Must be 40 characters or less'),

        businessStreet: Yup.string().required("Field is required").matches(/^[A-Za-z0-9][A-Za-z0-9\s\-\.]+$/, 'Must start with a letter or number and contain only letters, numbers, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(40, 'Must be 40 characters or less'),

        businessTownCity: Yup.string().required("Field is required")
          .matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots')
          .min(3, 'Must be 3 characters or more')
          .max(40, 'Must be 40 characters or less'),

        businessDistrict: Yup.string().matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots').min(3, 'Must be 3 characters or more').max(25, 'Must be 25 characters or less'),

        businessPostcodeZip: Yup.string().required("Field is required")
          .matches(/^[a-zA-Z0-9]+$/, 'Must contain only letters and numbers')
          .min(3, 'Must be 3 characters or more')
          .max(10, 'Must be 10 characters or less'),

        businessExpertiseHighlights: Yup.string().required("Field is required").min(10, 'Must be 10 characters or more').max(170, 'Must be 170 characters or less'),

        businessExpertiseDescription: Yup.string().required("Field is required").min(10, 'Must be 10 characters or more').max(500, 'Must be 500 characters or less'),
        logoImage: Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
            value => {
              if (isValidURL(value)) return true;
              if (!value) return true;
              return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
            }

          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              if (isValidURL(value)) return true;
              if (!value) return true;
              return (value && value.size <= 1024 * 1024);
            }
          )
          .required('A file is required'),

        bannerImage: Yup.mixed()
          .test(
            'fileType',
            'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
            value => {
              if (isValidURL(value)) return true;
              if (!value) return true;
              return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
            }

          )
          .test(
            'fileSize',
            'File too large (max size 1MB)',
            value => {
              if (isValidURL(value)) return true;
              if (!value) return true;
              return (value && value.size <= 1024 * 1024);
            }
          )
          .required('A file is required'),

        // logoImage: Yup.mixed()
        //   .test(
        //     'fileType',
        //     'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
        //     value => {
        //       if (!value) return true
        //       return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
        //     }
        //   )
        //   .test(
        //     'fileSize',
        //     'File too large (max size 1MB)',
        //     value => !value || (value && value.size <= 1024 * 1024)
        //   )
        //   .required('A file is required'),

        // bannerImage: Yup.mixed()
        //   .test(
        //     'fileType',
        //     'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
        //     value => {
        //       if (!value) return true
        //       return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type)
        //     }
        //   )
        //   .test(
        //     'fileSize',
        //     'File too large (max size 1MB)',
        //     value => !value || (value && value.size <= 1024 * 1024)
        //   )
        //   .required('A file is required'),

      })}

      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        multiPartData = objectToBusinessEditFormData(values, cropedImageFile, cropedBannerImageFile)

        finalSubmit(multiPartData, setSubmitting);

        // console.log(multiPartData);
        // for (const [key, value] of multiPartData.entries()) {
        //   console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
        // resetForm();
      }}
    >
      {() => (
        <Form className="edit-device-step-section-header-parent">

          <Provider value={{ editBusinessFormInitialData, setEditBusinessFormInitialData, isRequestFirst, setIsRequestFirst, allTaxonomy, backendValidation, setBackendValidation, selectedBannerImageFile, setSelectedBannerImageFile, selectedImageFile, setSelectedImageFile, cropedImageFile, setCropedImageFile, cropedBannerImageFile, setCropedBannerImageFile }}>
            <EditBusinessForm formikRef={formikRef} />
          </Provider>

        </Form>
      )}
    </Formik>

  );
};

export default EditBusinessParent;