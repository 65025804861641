import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  serviceCategory: '',
  serviceValueProposition: '',
  rangeMin: '',
  rangeMax: '',
  unitOfMeasureService: '',
  clinicalApplications: '',
  purposeUse: '',
  deviceCategory: '',
  physicalLocation: '',
  serviceFeatureHighlights: '',
  serviceBenefitsHighlights: '',
  parentDevices: '',
  featureImage: '',
  featureImageLink: '',
  bannerImage: '',
  bannerImageLink: '',
  videoType: '',
  linkVideo: '',
  location: '',
  serviceAndSupportReach: '',
  serviceFeatures: '',
  serviceBenefits: '',
  scopeOfWork: '',
  serviceLevelAgreement: '',
  additionalInformation: '',
  document: [],
  documentLink: '',
}

// export const initialsState = {
//   "serviceCategory",
//   "serviceValueProposition",
//   "rangeMin",
//   "rangeMax",
//   "unitofMeasureService",
//   "clinicalApplications",
//   "purposeUse",
//   "deviceCategory",
//   "physicalLocation",

//   "serviceFeatureHighlights",
//   "serviceBenefitsHighlights",
//   "parentdevices",
//   "featureImage",
//   "featureImageLink",
//   "bannerImage",
//   "bannerImageLink",
//   "videoType",
//   "linkVideo",
//   "location",
//   "serviceAndSupportReach",

//   "serviceFeatures",
//   "serviceBenefits",
//   "scopeofWork",
//   "serviceLevelAgreement",
//   "additionalInformation",
//   "document",
//   "documentLink",

// }




const addServiceSlice = createSlice({
  name: "addService",
  initialState,
  reducers: {
    updateField(state, action) {
      const { field, value } = action.payload
      if (value !== undefined) {
        state[field] = value;
      }
    },
    clearForm() {
      return initialState;
    }
  },
});

export const { updateField, clearForm } = addServiceSlice.actions

export default addServiceSlice.reducer

