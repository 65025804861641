import React from 'react';
import "./AddTeamSlideOutForm.css";
import xclose from "../../icons/Gerator/slide_out/xclose.svg";
import help_icon from "../../icons/Gerator/slide_out/help-icon.svg";
import mail01 from "../../icons/Gerator/slide_out/mail01.svg";
import invite2 from "../../icons/Gerator/slide_out/invite2.svg";

export const AddTeamSlideOutForm = ({onClose}) => {
  return (
    <div className="add-team-slide-out-menu">
      <main className="add-team-panel">
        <section className="add-team-slide-out-menu-header-parent">
          <header className="add-team-slide-out-menu-header">
            <div className="add-team-content71">
              <img
                className="add-team-featured-icon2"
                loading="lazy"
                alt=""
                src={invite2}
              />

              <div className="add-team-text-and-supporting-text26">
                <a className="add-team-title9">Invite People</a>
                {/* <div className="add-team-supporting-text91">
                  This will appear in your funnel
                </div> */}
              </div>
            </div>
            <div className="add-team-button-close-x6" onClick={onClose} >
              <img
                className="add-team-mail-01-icon"
                loading="lazy"
                alt=""
                src={xclose}
              />
            </div>
          </header>
          <div className="add-team-section-container">
            <div className="add-team-section2">
              <div className="add-team-input-field">
                <div className="add-team-input-field">
                  <div className="add-team-label9">Full Name</div>
                  <div className="add-team-input6">
                    <input
                      className="add-team-content72"
                      placeholder="Olivia Rhye"
                      type="text"
                    />

                    <img
                      className="add-team-help-icon2"
                      alt=""
                      src={help_icon}
                    />
                  </div>
                </div>
                <div className="add-team-hint-text6">This is a hint text to help user.</div>
              </div>
              <div className="add-team-form">
                <div className="add-team-input-field1">
                  <div className="add-team-input-field1">
                    <div className="add-team-label10">Email address</div>
                    <div className='add-team-email-card'>
                    <div className="add-team-input7">
                      <div className="add-team-content73">
                        <img
                          className="add-team-mail-01-icon"
                          alt=""
                          src={mail01}
                        />

                        <input
                          className="add-team-text274"
                          placeholder="olivia.rhye"
                          type="text"
                        />
                         <img
                        className="add-team-help-icon3"
                        alt=""
                        src={help_icon}
                      />
                      </div>
                     
                    </div>
                    <div className="add-team-email-domain">
                        @refurbish-med.com
                        </div>
                    </div>
                   
                  </div>
                  {/* <div className="add-team-hint-text6">
                  @refurbish-med.com
                  </div> */}
                </div>
              </div>
              <div className="add-team-input-field">
                <div className="add-team-input-field">
                  <div className="add-team-label11">Work Title</div>
                  <div className="add-team-input6">
                    <input
                      className="add-team-content72"
                      placeholder="Sales Manager"
                      type="text"
                    />

                    <img
                      className="add-team-help-icon2"
                      alt=""
                      src={help_icon}
                    />
                  </div>
                </div>
                <div className="add-team-hint-text6">This is a hint text to help user.</div>
              </div>
              <div className="add-team-input-field">
                <div className="add-team-input-field">
                  <div className="add-team-label12">Role</div>
                  <div className="add-team-input6">
                    <input
                      className="add-team-content72"
                      placeholder="Co-owner"
                      type="text"
                    />

                    <img
                      className="add-team-help-icon2"
                      alt=""
                      src={help_icon}
                    />
                  </div>
                </div>
                <div className="add-team-hint-text6">This is a hint text to help user.</div>
              </div>
            </div>
          </div>
          <div className="add-team-divider-wrapper">
            <div className="add-team-divider12"></div>
          </div>
        </section>
        <footer className="add-team-footer2">
          <div className="add-team-content76">
            <div className="add-team-actions19">
              <button className="add-team-buttonsbutton49">
                <img
                  className="add-team-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="add-team-text-padding51">
                  <div className="add-team-text275">Cancel</div>
                </div>
                <img
                  className="add-team-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </button>
              <button className="add-team-buttonsbutton50">
                <img
                  className="add-team-placeholder-icon91"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="add-team-text-padding51">
                  <div className="add-team-text276">Confirm</div>
                </div>
                <img
                  className="add-team-placeholder-icon91"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </button>
            </div>
          </div>
        </footer>
      </main>
    </div>
  )
}

