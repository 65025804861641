import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Software/EditSoftware/EditSoftwareContext";
import { useNavigate, useParams } from "react-router-dom";
import { clearEditSoftwareForm, fillEditSoftwareForm, updateEditSoftwareField } from "../EditSoftwareFormComponent/EditSoftwareSlice";
import { updateAppDataEditSoftware, clearAppDataEditSoftware } from "../EditSoftwareFormComponent/AppDataEditSoftwareSlice";
import { useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetInstallationLocationTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetSoftwareCategoryTaxQuery, useGetSoftwareTypeTaxQuery, useGetUnitOfMeasureSoftwareTaxQuery, useGetServiceandSupportReachTaxQuery } from "../../api/TaxonomyFormAPI";
// import { getAllTaxonomyData } from "../../helper/EditSoftwareHelper";
import { EditSoftwareStep1 } from "./EditSoftwareStep1";
import { EditSoftwareStep2 } from "./EditSoftwareStep2";
import { EditSoftwareStep3 } from "./EditSoftwareStep3";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEditSoftwareMutation } from "../../api/SoftwareAPI";
import { isValidURL, objectToFormDataForEditSoftware } from "../../helper/SoftwareHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { useFindGetRelatedAddressRemoteQuery } from "../../api/AddressAPI";

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <EditSoftwareStep1 />;
    case 1:
      return <EditSoftwareStep2 />;
    case 2:
      return <EditSoftwareStep3 />;
    default:
      return null;
  }
};

const EditSoftwareParent = ({ setShowSuccessMessage, setIsTaxonomySet }) => {
  const { id } = useParams();

  const formikRef = useRef(null);
  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  console.log("Id of post is " + id);
  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["softwareCategory", "softwareValueProposition", "softwareType", "installationLocation", "rangeMin", "rangeMax", "unitOfMeasureSoftware", "clinicalApplications", "purposeUse", "deviceCategory", "physicalLocation"];
  const step2FormKey = ["softwareFeaturesHighlights", "softwareBenefitsHighlights", "listParentSoftware", "featureImageObject", "galleryImageObject", "linkVideo", "location", "serviceAndSupportReach"]
  const step3FormKey = ["documentFileObject", "documentLink", "softwareFeatures", "softwareBenefits", "systemRequirements", "additionalInformation",]


  const [editSoftware, { isLoading, isError, error }] = useEditSoftwareMutation();
  const navigate = useNavigate();
  const softwareCategoryTax = useGetSoftwareCategoryTaxQuery();
  const softwareTypeTax = useGetSoftwareTypeTaxQuery();
  const installationLocationTax = useGetInstallationLocationTaxQuery();
  const unitOfMeasureSoftwareTax = useGetUnitOfMeasureSoftwareTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery()
  const purposeUseTax = useGetPurposeUseTaxQuery()
  const physicalLocationTax = useGetPhysicalLocationTaxQuery()
  const serviceAndSupportReachTax = useGetServiceandSupportReachTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const relatedAddressTax = useFindGetRelatedAddressRemoteQuery();



  const [allTaxonomy, setAllTaxonomy] = useState({ softwareCategory: softwareCategoryTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureSoftware: unitOfMeasureSoftwareTax, installationLocation: installationLocationTax, softwareType: softwareTypeTax, serviceAndSupportReach: serviceAndSupportReachTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [softwareCategoryTax.isSuccess, deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, unitOfMeasureSoftwareTax.isSuccess, installationLocationTax.isSuccess, softwareTypeTax.isSuccess, serviceAndSupportReachTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        softwareCategory: softwareCategoryTax,
        deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureSoftware: unitOfMeasureSoftwareTax, installationLocation: installationLocationTax, softwareType: softwareTypeTax, serviceAndSupportReach: serviceAndSupportReachTax, relatedAddress: relatedAddressTax
      });
    }
  }, [
    softwareCategoryTax.isSuccess, softwareCategoryTax.data,
    softwareTypeTax.isSuccess, softwareTypeTax.data,
    installationLocationTax.isSuccess, installationLocationTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    unitOfMeasureSoftwareTax.isSuccess, unitOfMeasureSoftwareTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    serviceAndSupportReachTax.isSuccess, serviceAndSupportReachTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,
  ]);


  const dispatch = useDispatch();
  const formData = useSelector((state) => state.editSoftware);
  const appData = useSelector((state) => state.appDataEditSoftware);
  // const [featureImageMeta, setFeatureImageMeta] = useState("");
  const [galleryImageToDelete, setGalleryImageToDelete] = useState([]);
  const [documentFileToDelete, setDocumentFileToDelete] = useState('');
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [selectedMultiImageFile, setSelectedMultiImageFile] = useState([]);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [documentCheckboxError, setDocumentCheckboxError] = useState("")
  const [backendValidation, setBackendValidation] = useState([])
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const [editSoftwareFormInitialData, setEditSoftwareFormInitialData] = useState(null);


  //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedImageFile("")
  //   setSelectedMultiImageFile([])
  //   setSelectedDocumentFile("")
  //   setBackendValidation([])
  //   setShowSuccessMessage("")
  //   setDeviceCategorySearchField(editSoftwareFormInitialData.tax_device_category.name)
  //   dispatch(clearAppDataEditSoftware());
  //   dispatch(fillEditSoftwareForm(editSoftwareFormInitialData))
  //   // formikRef.current.resetForm();
  // }

  const step1Schema = Yup.object({
    //   softwareCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   softwareValueProposition: Yup.string().required("This is a required field").max(70, 'Must be 70 characters or less'),

    //   softwareType: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   installationLocation: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   rangeMin: Yup
    //   .number('Please enter a valid number')
    //   .required('This is a required field')
    //   .typeError('Please enter a valid number')
    //   .test('non-negative', 'Field data cannot be negative', value => value >= 0)
    //   .min(1,'Field data must be greater than or equal to 1')
    //   .test('decimal-places', 'No decimal places are allowed', value => {

    //     const [, decimalPart] = String(value).split('.');
    //     return decimalPart === undefined || decimalPart.length <= 0;
    //   }),

    // rangeMax: Yup
    //   .number('Please enter a valid number')
    //   .typeError('Please enter a valid number')
    //   .required('This is a required field')
    //   .test('non-negative', 'Field data cannot be negative', value => value >= 0)
    //   .test('decimal-places', 'No decimal places are allowed', value => {

    //     const [, decimalPart] = String(value).split('.');
    //     return decimalPart === undefined || decimalPart.length <= 0;
    //   })

    //   .moreThan(Yup.ref('rangeMin'), 'Maximum range must be greater than minimum range'),

    //   unitOfMeasureSoftware: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   clinicalApplications: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   purposeUse: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   deviceCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),


    //   physicalLocation: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
  })

  const step2Schema = Yup.object().shape({

    // softwareFeaturesHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // softwareBenefitsHighlights: Yup.string().required("This is a required field").max(190, 'Must be 190 characters or less'),

    // listParentSoftware: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // location: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),

    // serviceAndSupportReach: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'

    // ),
    // featureImage: Yup.mixed()
    // .test(
    //   'fileType',
    //   'Unsupported File Format (only JPEG, PNG, and WebP allowed)',
    //   value => {

    //     if(isValidURL(value)) return true;
    //     if (!value) return true;
    //     return ['image/jpeg', 'image/png','image/webp'].includes(value.type)
    //   }
    // )
    // .test(
    //   'fileSize',
    //   'File too large (max size 1MB)',
    //   value =>{
    //     if(isValidURL(value)) return true;
    //     !value || (value && value.size <= 1024 * 1024)
    //   } 

    // )
    // .required('A file is required'),

    // gallery: Yup.array()
    //   // .min(3, 'Please select atleast three image') 
    //   .of(
    //     Yup.mixed()
    //       .test(
    //         'fileType',
    //         'Unsupported File Format (only JPEG, PNG  allowed)',
    //         value => {
    //           // if (!value)return true
    //           if(isValidURL(value)) return true;
    //           return ['image/jpeg', 'image/png',].includes(value.file.type)
    //         }
    //       )
    //       .test(
    //         'fileSize',
    //         'File too large (max size 1MB)',
    //         value =>{
    //           if(isValidURL(value)) return true;
    //           !value || (value && value.size <= 1024 * 1024)
    //         } 
    //       )

    //   )

  }
  )

  const step3Schema = Yup.object().shape({

    // softwareFeatures: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // softwareBenefits: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // systemRequirements: Yup.string().max(1000, 'Must be 1000 characters or less'),

    // additionalInformation: Yup.string().max(2000, 'Must be 2000 characters or less'),

    // document: Yup.mixed()
    //   .test(
    //     'fileType',
    //     'Unsupported File Format (only pdf)',
    //     value => {
    //       if(isValidURL(value)) return true;
    //       if (!value) return true
    //       return ['application/pdf'].includes(value.type)
    //     }
    //   )
    //   .test(
    //     'fileSize',
    //     'File too large (max size 1MB)',
    //     value =>{
    //       if(isValidURL(value)) return true;
    //      return !value || (value && value.size <= 1024 * 1024)
    //     } 
    //   )
  })

  const matchErrorsAgainstBackendValidationList = async (backendValidationError) => {
    if (backendValidationError?.length > 0) {



      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataEditSoftware({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataEditSoftware({ case: "CURRENTSTEP", value: 1 }));
          break;
        } else if (step3FormKey.includes(field.path)) {
          console.log("step3 was true");
          dispatch(updateAppDataEditSoftware({ case: "CURRENTSTEP", value: 2 }));
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await editSoftware({ id: id, body: multiPartData }).unwrap();
      //If form was submitted successfully then isUpdated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Software edited successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        navigate('/dashboard');
        formikRef.current.resetForm();
        // window.location.reload();
        // navigate('/devices');
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : appData.currentStep === 2 ? step3Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {
        // Getting FormData object to send multipart request
        if (appData.isYoutubeChecked) { dispatch(updateEditSoftwareField({ field: "videoType", value: "Youtube" })) }
        if (appData.isVimeoChecked) dispatch(updateEditSoftwareField({ field: "videoType", value: "Vimeo" }));
        multiPartData = objectToFormDataForEditSoftware(values, documentFileToDelete, galleryImageToDelete, cropedImageFile, selectedMultiImageFile, selectedDocumentFile)
        // console.log(multiPartData);
        //   for (const [key, value] of multiPartData.entries()) {
        //     console.log(key, value);
        // }
        // Final submission of form
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);
        //   for (const [key, value] of multiPartData.entries()) {
        //     console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
        // console.log(values);
        // console.log(values);
      }}
    >
      {(formik) => (
        <Form className="add-software-step-section-header-parent">


          <Provider value={{ deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, editSoftwareFormInitialData, setEditSoftwareFormInitialData, documentFileToDelete, setDocumentFileToDelete, isRequestFirst, setIsRequestFirst, galleryImageToDelete, setGalleryImageToDelete, backendValidation, setBackendValidation, documentCheckboxError, setDocumentCheckboxError, isImageSelected, setIsImageSelected, allTaxonomy, cropedImageFile, setCropedImageFile, selectedMultiImageFile, setSelectedMultiImageFile, selectedDocumentFile, setSelectedDocumentFile, selectedImageFile, setSelectedImageFile }}>


            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default EditSoftwareParent;