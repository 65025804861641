import React, { useEffect, useRef, useState } from 'react';
import "./SalesIQJobDetailPageComponent.css"
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/sales_iq/calendar1.svg';
import arrowsquareupright1 from '../../../icons/Gerator/sales_iq/arrowsquareupright1.svg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import markerpin02 from '../../../icons/Gerator/sales_iq/markerpin02.svg';
import arrowup1 from '../../../icons/Gerator/sales_iq/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/sales_iq/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/sales_iq/placeholder2.svg';
import xclose from '../../../icons/Gerator/sales_iq/xclose.svg';
import filterlines from '../../../icons/Gerator/sales_iq/filterlines.svg';
import sliders02 from '../../../icons/Gerator/sales_iq/sliders02.svg';
import searchlg from '../../../icons/Gerator/sales_iq/searchlg.svg';
import d8595f4b711e503bc72fe396e5043e0c2 from '../../../icons/Gerator/sales_iq/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import group_58_2x from '../../../icons/Gerator/sales_iq/group-58@2x.png';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import DashboardFilterButtonGroup from '../../DashboardFilterButtonGroup/DashboardFilterButtonGroup';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/JobHelper';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetUserDashboardJobsQuery } from '../../../api/JobAPI';
import { formatNumber } from '../../../helper/DeviceHelper';


export const SalesIQJobDetailPageComponent = () => {



  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountPublished, settotalCountPublished] = useState("");
  const [totalCountPending, settotalCountPending] = useState("");
  const [totalCountArchived, settotalCountArchived] = useState("");
  const [totalCountExpired, settotalCountExpired] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardJobsQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.jobs.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountPublished(data.totalCountPublished);
      settotalCountArchived(data.totalCountArchived);
      settotalCountPending(data.totalCountPending);
      settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-job"} pathName={"Job"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.jobs.length > 0) {
      // dataLength = data.jobs.length;
      content = data.jobs.map((job, index) =>
      (
        <div className="sales-iq-detail-job-component-sales-iq-content-items" key={index}>
          <div className="sales-iq-detail-job-component-sales-iq-rectangle-parent10">
            <div className="sales-iq-detail-job-component-sales-iq-frame-child12"></div>
            <div className="sales-iq-detail-job-component-sales-iq-frame-wrapper25">
              <div className="sales-iq-detail-job-component-sales-iq-element-details-parent">
                <div className="sales-iq-detail-job-component-sales-iq-element-details">
                  <div className="sales-iq-detail-job-component-sales-iq-element-details-child"></div>
                  <img
                    className="sales-iq-detail-job-component-sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon"
                    alt=""
                    src={job.logoImage}
                  />
                </div>
                <div className="sales-iq-detail-job-component-sales-iq-element-actions">
                  <Link to={`/job/${job.id}/${job.title}`} className="sales-iq-detail-job-component-sales-iq-text78">
                    {job.title}
                  </Link>
                  <div className="sales-iq-detail-job-component-sales-iq-action-icons">
                
                      <div className="sales-iq-detail-job-component-sales-iq-date-icon">
                        <div className="sales-iq-detail-job-component-sales-iq-badge-parent">
                          <div className="sales-iq-detail-job-component-sales-iq-supporting-text12">
                            {job.job_business.businessName}
                          </div>
                          
                        </div>
                        <div className="sales-iq-detail-job-component-sales-iq-type-badge-elements">
                          <div className="sales-iq-detail-job-component-sales-iq-end-label">
                            <div className="sales-iq-detail-job-component-sales-iq-search-icon-input">4.9/5</div>
                          </div>
                          <div className="sales-iq-detail-job-component-sales-iq-type-support">
                            <div className="sales-iq-detail-job-component-sales-iq-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                       
                      </div>
                      <div className="sales-iq-detail-job-component-sales-iq-work-type-icon">

                      <div className="sales-iq-detail-job-component-sales-iq-search-input">
                            <img
                              className="sales-iq-detail-job-component-sales-iq-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>

                        <div className="sales-iq-detail-job-component-sales-iq-location3">
                          {
                            (job.job_address.tax_address_type.slug === 'remote') && job.job_address.tax_address_type.name
                          }

                          {
                            (job.job_address.tax_address_type.slug === 'address' && `${job.job_address.addressTownCity}, ${job.job_address.tax_country.name}`)
                          }
                        </div>
                      
                      </div>
                  
                    <div className="sales-iq-detail-job-component-sales-iq-work-type-label">
                      <div className="sales-iq-detail-job-component-sales-iq-search-input">
                        <img
                          className="sales-iq-detail-job-component-sales-iq-marker-pin-02-icon2"
                          alt=""
                          src={calendar1}
                        />
                      </div>
                      <div className="sales-iq-detail-job-component-sales-iq-type-of-work2">{getCustomizedDate(job.createdAt)}</div>
                    </div>
                  </div>

                  <div className="sales-iq-detail-job-component-sales-iq-small-card">

                  <div className="sales-iq-detail-job-component-sales-iq-work-type-value">
                      <div className="sales-iq-detail-job-component-sales-iq-price-parent">
                          <div className="sales-iq-detail-job-component-sales-iq-text100">{job.tax_engagement_type.name}</div>
                          <div className="sales-iq-detail-job-component-sales-iq-supporting-text15">
                            Engagement Type
                          </div>
                       </div>
                   </div>

                        <div className="sales-iq-detail-job-component-sales-iq-work-type-value">
                          <div className="sales-iq-detail-job-component-sales-iq-price-parent">
                            <div className="sales-iq-detail-job-component-sales-iq-text100">{job.tax_experience_level.name}</div>
                            <div className="sales-iq-detail-job-component-sales-iq-supporting-text15">Experience</div>
                          </div>
                        </div>

                  </div>
                </div>
                <div className="sales-iq-detail-job-component-sales-iq-dropdown-parent1">
              {/* {(job.tax_post_status.slug !== 'pending') ? */}
              <div className="sales-iq-detail-job-component-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                <img
                  className="sales-iq-detail-job-component-sales-iq-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
                {activeThreeDotId === index && <ThreeDotsListingComponent url={`job/${job.id}`} />}
                {/* {isThreeDotsVisible && <ThreeDotsListingComponent url={`job/${job.id}`} />} */}
              </div>
              {/* : */}
              {/* <div className="sales-iq-detail-job-component-sales-iq-dropdown10" >
                  <img
                    className="sales-iq-detail-job-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src=""
                  />
                </div> */}
              {/* } */}
              <div className="sales-iq-detail-job-component-sales-iq-filter-elements-inner">
                <div className="sales-iq-detail-job-component-sales-iq-frame-parent31">
                  <div className="sales-iq-detail-job-component-sales-iq-text-wrapper10">
                    <div className="sales-iq-detail-job-component-sales-iq-text101">From</div>
                  </div>
                  <div className="sales-iq-detail-job-component-sales-iq-price4">${formatNumber(job.budget)}</div>
                  <div className="sales-iq-detail-job-component-sales-iq-trend-chart">
                    <div className="sales-iq-detail-job-component-sales-iq-text83">{job.tax_unit_measure_service.name}</div>
                    <div className="sales-iq-detail-job-component-sales-iq-trend-chart-inner">
                      <div className="sales-iq-detail-job-component-sales-iq-frame-parent32">
                        <div className="sales-iq-detail-job-component-sales-iq-frame-wrapper27">
                          <div className="sales-iq-detail-job-component-sales-iq-radar-1-1-container">
                            <img
                              className="sales-iq-detail-job-component-sales-iq-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />

                            <img
                              className="sales-iq-detail-job-component-sales-iq-magnifier-1-icon"
                              alt=""
                              src={deal_2_1_2x}
                            />

                            <img
                              className="sales-iq-detail-job-component-sales-iq-magnifier-1-icon"
                              alt=""
                              src={magnifier_1_2x}
                            />
                          </div>
                        </div>
                        <img
                          className="sales-iq-detail-job-component-sales-iq-arrow-square-up-right-icon1"
                          alt=""
                          src={arrowsquareupright1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </div>
            </div>
          
          </div>
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path={"/add-job"} pathName={"Job"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
   

      {/* Listing Container */}
      <div className="sales-iq-detail-job-component-listing-container">

        {/* Listing layout start    */}
        {content}

      </div>

      {/* Load more button  */}
      <div className="sales-iq-frame-wrapper44">
        <div className="sales-iq-frame-parent72">
          <div className="sales-iq-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='sales-iq-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="sales-iq-buttonsbutton28">
                  <img
                    className="sales-iq-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="sales-iq-search-input">
                    <div className="sales-iq-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

