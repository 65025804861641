import React, { useEffect, useRef, useState } from 'react';
import "./FunnelJobComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import arrowsquareupright1 from '../../../icons/Gerator/sales_iq/arrowsquareupright1.svg';


import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { formatNumber, getCustomizedDate } from '../../../helper/DeviceHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetUserDashboardWantedDevicesQuery } from '../../../api/WantedDeviceAPI';


// Temporary 

import playstation from '../../../icons/Gerator/sales_iq/playstation.jpeg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import FunnelDeviceCardTabs from '../../FunnelCardTabs/FunnelDeviceCardTabs';
import FunnelJobCardTabs from '../../FunnelCardTabs/FunnelJobCardTabs';
import { useFindGetAllCountFunnelDashboardQuery } from '../../../api/Funnel/FunnelWantedDeviceAPI';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import { NoListingFoundForFilter } from '../../NoListingFoundForFilter/NoListingFoundForFilter';
import { useGetSalesIqDashboardFunnelJobsQuery } from '../../../api/Funnel/FunnelJobAPI';


export const FunnelJobComponent = () => {



  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");
  const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data

  const { data: countData, isSuccess: isCountSuccess, isError: isCountError } = useFindGetAllCountFunnelDashboardQuery();

  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetSalesIqDashboardFunnelJobsQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.funnel_job.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // // Set totalcount in listing counter layout
      // setTotalCount(data.totalCount);
      // settotalCountWantedSpare(data.totalCountWantedSpare);
      // settotalCountWantedService(data.totalCountWantedService);
      // settotalCountWantedSoftware(data.totalCountWantedSoftware);
      // settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const clearFilters = () => {
    setOffset(0);
    setSortBy('date_desc');
    setSearchTerm('');
    setSelectedSortOption('');
    setDropdownVisible(false);
  }


  if (isError) {
    // content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
    content = <ErrorShowComponent message1={'Internal server error'} />

  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.funnel_job.length > 0) {
      // dataLength = data.funnel_job.length;
      content = data.funnel_job.map((job, index) =>
      (

        <div className="sales-iq-job-component-sales-iq-content-items" key={index}>
          <div className="sales-iq-job-component-sales-iq-rectangle-parent10">
            <div className="sales-iq-job-component-sales-iq-frame-child12"></div>
            <div className="sales-iq-job-component-sales-iq-frame-wrapper25">
              <div className="sales-iq-job-component-sales-iq-element-details-parent">
                <div className="sales-iq-job-component-sales-iq-element-details">
                  <div className="sales-iq-job-component-sales-iq-element-details-child"></div>
                  <img
                    className="sales-iq-job-component-sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon"
                    alt=""
                    src={job.logoImage}
                  />
                </div>
                <div className="sales-iq-job-component-sales-iq-element-actions">
                  <Link to={`/job/${job.id}/${job.title}`} className="sales-iq-job-component-sales-iq-text78">
                    {job.title}
                  </Link>
                  <div className="sales-iq-job-component-sales-iq-action-icons">

                    <div className="sales-iq-job-component-sales-iq-date-icon">
                      <div className="sales-iq-job-component-sales-iq-badge-parent">
                        <div className="sales-iq-job-component-sales-iq-supporting-text12">
                          {job.job_business.businessName}
                        </div>

                      </div>
                      <div className="sales-iq-job-component-sales-iq-type-badge-elements">
                        <div className="sales-iq-job-component-sales-iq-end-label">
                          <div className="sales-iq-job-component-sales-iq-search-icon-input">4.9/5</div>
                        </div>
                        <div className="sales-iq-job-component-sales-iq-type-support">
                          <div className="sales-iq-job-component-sales-iq-supporting-text9">
                            99,999 reviews
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="sales-iq-job-component-sales-iq-work-type-icon">

                      <div className="sales-iq-job-component-sales-iq-search-input">
                        <img
                          className="sales-iq-job-component-sales-iq-marker-pin-02-icon2"
                          alt=""
                          src={markerpin02}
                        />
                      </div>

                      <div className="sales-iq-job-component-sales-iq-location3">
                        {
                          (job.job_address.tax_address_type.slug === 'remote') && job.job_address.tax_address_type.name
                        }

                        {
                          (job.job_address.tax_address_type.slug === 'address' && `${job.job_address.addressTownCity}, ${job.job_address.tax_country.name}`)
                        }
                      </div>

                    </div>

                    <div className="sales-iq-job-component-sales-iq-work-type-label">
                      <div className="sales-iq-job-component-sales-iq-search-input">
                        <img
                          className="sales-iq-job-component-sales-iq-marker-pin-02-icon2"
                          alt=""
                          src={calendar1}
                        />
                      </div>
                      <div className="sales-iq-job-component-sales-iq-type-of-work2">{getCustomizedDate(job.createdAt)}</div>
                    </div>
                  </div>

                  <div className="sales-iq-job-component-sales-iq-small-card">

                    <div className="sales-iq-job-component-sales-iq-work-type-value">
                      <div className="sales-iq-job-component-sales-iq-price-parent">
                        <div className="sales-iq-job-component-sales-iq-text100">{job.tax_engagement_type.name}</div>
                        <div className="sales-iq-job-component-sales-iq-supporting-text15">
                          Engagement Type
                        </div>
                      </div>
                    </div>

                    <div className="sales-iq-job-component-sales-iq-work-type-value">
                      <div className="sales-iq-job-component-sales-iq-price-parent">
                        <div className="sales-iq-job-component-sales-iq-text100">{job.tax_experience_level.name}</div>
                        <div className="sales-iq-job-component-sales-iq-supporting-text15">Experience</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="sales-iq-job-component-sales-iq-dropdown-parent1">
                  {/* {(job.tax_post_status.slug !== 'pending') ? */}
                  <div className="sales-iq-job-component-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <img
                      className="sales-iq-job-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotId === index && <ThreeDotsListingComponent url={`job/${job.id}`} />}
                    {/* {isThreeDotsVisible && <ThreeDotsListingComponent url={`job/${job.id}`} />} */}
                  </div>
                  {/* : */}
                  {/* <div className="sales-iq-job-component-sales-iq-dropdown10" >
                  <img
                    className="sales-iq-job-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src=""
                  />
                </div> */}
                  {/* } */}
                  <div className="sales-iq-job-component-sales-iq-filter-elements-inner">
                    <div className="sales-iq-job-component-sales-iq-frame-parent31">
                      <div className="sales-iq-job-component-sales-iq-text-wrapper10">
                        <div className="sales-iq-job-component-sales-iq-text101">From</div>
                      </div>
                      <div className="sales-iq-job-component-sales-iq-price4">${formatNumber(job.budget)}</div>
                      <div className="sales-iq-job-component-sales-iq-trend-chart">
                        <div className="sales-iq-job-component-sales-iq-text83">{job.tax_unit_measure_service.name}</div>
                        <div className="sales-iq-job-component-sales-iq-trend-chart-inner">
                          <div className="sales-iq-job-component-sales-iq-frame-parent32">
                            <div className="sales-iq-job-component-sales-iq-frame-wrapper27">
                              <div className="sales-iq-job-component-sales-iq-radar-1-1-container">
                                <img
                                  className="sales-iq-job-component-sales-iq-magnifier-1-icon"
                                  alt=""
                                  src={radar_1_1_2x}
                                />

                                <img
                                  className="sales-iq-job-component-sales-iq-magnifier-1-icon"
                                  alt=""
                                  src={deal_2_1_2x}
                                />

                                <img
                                  className="sales-iq-job-component-sales-iq-magnifier-1-icon"
                                  alt=""
                                  src={magnifier_1_2x}
                                />
                              </div>
                            </div>
                            <img
                              className="sales-iq-job-component-sales-iq-arrow-square-up-right-icon1"
                              alt=""
                              src={arrowsquareupright1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="sales-iq-device-component-sales-iq-divider-icon8" alt="" src={divider2} />

              <div className="sales-iq-device-component-sales-iq-metric-item-parent">

                <div className="sales-iq-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-device-component-sales-iq-heading9">Active engagement</div>
                  <div className="sales-iq-device-component-sales-iq-number-and-chart">
                    <div className="sales-iq-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-device-component-sales-iq-heading">{job.totalActiveEngagement || 0}</div>
                      <div className="sales-iq-device-component-sales-iq-change-and-text3">
                        <div className="sales-iq-device-component-sales-iq-change2">
                          <img
                            className="sales-iq-device-component-sales-iq-upload-04-icon"
                            alt=""
                            src={arrowup}
                          />

                          <div className="sales-iq-device-component-sales-iq-change7">100%</div>
                        </div>
                        <div className="sales-iq-device-component-sales-iq-text139">vs last month</div>
                      </div>
                    </div>
                    <img
                      className="sales-iq-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}

                    />
                  </div>
                  <div className="sales-iq-device-component-sales-iq-dropdown19">
                    <img
                      className="sales-iq-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                  </div>
                </div>

                <div className="sales-iq-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-device-component-sales-iq-heading9">Views</div>
                  <div className="sales-iq-device-component-sales-iq-number-and-chart">
                    <div className="sales-iq-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-device-component-sales-iq-heading">{job.totalCountView}</div>
                      <div className="sales-iq-device-component-sales-iq-change-and-text3">
                        <div className="sales-iq-device-component-sales-iq-change2">
                          <img
                            className="sales-iq-device-component-sales-iq-upload-04-icon"
                            alt=""
                            src={arrowup}
                          />

                          <div className="sales-iq-device-component-sales-iq-change7">100%</div>
                        </div>
                        <div className="sales-iq-device-component-sales-iq-text139">vs last month</div>
                      </div>
                    </div>
                    <img
                      className="sales-iq-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}

                    />
                  </div>
                  <div className="sales-iq-device-component-sales-iq-dropdown19">
                    <img
                      className="sales-iq-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                  </div>
                </div>
                <div className="sales-iq-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-device-component-sales-iq-heading9">Added to Radar</div>
                  <div className="sales-iq-device-component-sales-iq-number-and-chart">
                    <div className="sales-iq-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-device-component-sales-iq-heading">{job.totalCountRadar}</div>
                      <div className="sales-iq-device-component-sales-iq-change-and-text3">
                        <div className="sales-iq-device-component-sales-iq-change2">
                          <img
                            className="sales-iq-device-component-sales-iq-upload-04-icon"
                            alt=""
                            src={arrowup}
                          />

                          <div className="sales-iq-device-component-sales-iq-change7">100%</div>
                        </div>
                        <div className="sales-iq-device-component-sales-iq-text139">vs last month</div>
                      </div>
                    </div>
                    <img
                      className="sales-iq-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}
                    />
                  </div>
                  <div className="sales-iq-device-component-sales-iq-dropdown19">
                    <img
                      className="sales-iq-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                  </div>
                </div>
                <div className="sales-iq-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-device-component-sales-iq-heading9">Searches</div>
                  <div className="sales-iq-device-component-sales-iq-number-and-chart">
                    <div className="sales-iq-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-device-component-sales-iq-heading">{job.totalSearch || 0}</div>
                      <div className="sales-iq-device-component-sales-iq-change-and-text3">
                        <div className="sales-iq-device-component-sales-iq-change2">
                          <img
                            className="sales-iq-device-component-sales-iq-upload-04-icon"
                            alt=""
                            src={arrowup}
                          />

                          <div className="sales-iq-device-component-sales-iq-change7">100%</div>
                        </div>
                        <div className="sales-iq-device-component-sales-iq-text139">vs last month</div>
                      </div>
                    </div>
                    <img
                      className="sales-iq-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}
                    />
                  </div>
                  <div className="sales-iq-device-component-sales-iq-dropdown19">
                    <img
                      className="sales-iq-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                  </div>
                </div>
              </div>

              <img className="sales-iq-device-component-sales-iq-divider-icon8" alt="" src={divider2} />

              <div className="sales-iq-device-component-sales-iq-frame-parent71">
                <div className="sales-iq-device-component-sales-iq-buttonsbutton-parent1">
                  <div className="sales-iq-legend-series">
                    <img
                      className="sales-iq-location-column-child"
                      alt=""
                      src={job.profileImage}
                    />

                    <div className="sales-iq-location-column-inner">
                      <div className="sales-iq-date-picker-dropdown">
                        <div className="sales-iq-name">{job.job_business.business_user.displayName}</div>
                        <Link className="sales-iq-role" to={`/business/${job.job_business.id}/${job.job_business.businessName}`}>
                          {job.job_business.businessName}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='sales-iq-device-component-main-buttons-group'>
                    <div className="sales-iq-device-component-sales-iq-buttonsbutton13">
                      <img
                        className="sales-iq-device-component-sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={eye}
                      />

                      <div className="sales-iq-device-component-sales-iq-search-input">
                        <div className="sales-iq-device-component-sales-iq-text67">View</div>
                      </div>
                    </div>
                    {/* <div className="sales-iq-device-component-sales-iq-buttonsbutton25">
                    <div className="sales-iq-device-component-sales-iq-icon-wrapper">
                      <img className="sales-iq-device-component-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="sales-iq-device-component-sales-iq-search-input">
                      <div className="sales-iq-device-component-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="sales-iq-device-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}

                    <Link to={`/sales-iq/job/${job.id}/${job.title}`}>
                      <div className="sales-iq-device-component-sales-iq-buttonsbutton26">
                        <div className="sales-iq-device-component-sales-iq-icon-wrapper">
                          <img className="sales-iq-device-component-sales-iq-icon1" alt="" src={icon1} />
                        </div>
                        <div className="sales-iq-device-component-sales-iq-search-input">
                          <div className="sales-iq-device-component-sales-iq-text68">Sales IQ</div>
                        </div>
                        <img
                          className="sales-iq-device-component-sales-iq-arrow-narrow-left-icon"
                          alt=""
                          src={lockunlocked04}
                        />
                      </div>
                    </Link>


                    <div className="sales-iq-device-component-sales-iq-buttonsbutton27">
                      <img
                        className="sales-iq-device-component-sales-iq-upload-04-icon"
                        alt=""
                        src={messagechatsquare}
                      />

                      <div className="sales-iq-device-component-sales-iq-text145">Messages</div>
                      <div className="sales-iq-device-component-sales-iq-ellipse-parent">
                        <div className="sales-iq-device-component-sales-iq-ellipse-div"></div>
                        <div className="sales-iq-device-component-sales-iq-div">2</div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="sales-iq-device-component-sales-iq-arrow-square-up-right-wrapper1">
                  <img
                    className="sales-iq-device-component-sales-iq-arrow-square-up-right-icon1"
                    alt=""
                    src={arrowsquareupright}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>

      ))
    }
    else {
      // content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
      content = <NoListingFoundForFilter clearFilters={clearFilters} />

    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  if (countData) {
    return (
      <>
        <div className="funnel-frame-wrapper">
          <div className="funnel-frame-group">
            <div className="funnel-rectangle-group">
              <div className="funnel-frame-item"></div>
              <div className="funnel-metric-card-row">
                <div className="funnel-metric-card">
                  <div className="funnel-metric-card-title">
                    <div className="funnel-heading4">Devices</div>
                  </div>
                  <div className="funnel-dropdown3">
                    <img
                      className="funnel-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                  </div>
                </div>
                <div className="funnel-number3">{countData.totalCountFunnelDevice || 0}</div>
              </div>
              {/* <div className="funnel-badge11">
              <div className="funnel-options-icon">
                <img
                  className="funnel-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="funnel-text58">20 slots available</div>
            </div> */}
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-parent">
                  <div className="funnel-heading5">Spares</div>
                  <div className="funnel-number4">{countData.totalCountFunnelSpare || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-options-icon">
                <div className="funnel-heading-group">
                  <div className="funnel-heading5">Services</div>
                  <div className="funnel-number4">{countData.totalCountFunnelService || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-container">
                  <div className="funnel-heading5">Software</div>
                  <div className="funnel-number4">{countData.totalCountFunnelSoftware || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
            <div className="funnel-rectangle-container">
              <div className="funnel-frame-inner"></div>
              <div className="funnel-frame-container">
                <div className="funnel-heading-container">
                  <div className="funnel-heading5">Jobs</div>
                  <div className="funnel-number4">{countData.totalCountFunnelJob || 0}</div>
                  {/* <div className="funnel-badge12">
                  <div className="funnel-options-icon">
                    <img
                      className="funnel-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="funnel-change-amount">20%</div>
                </div> */}
                </div>
              </div>
              <div className="funnel-dropdown3">
                <img
                  className="funnel-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="funnel-section-wrapper">
          <div className="funnel-section1">
            <div className="funnel-container1">
              <div className="funnel-filters-bar">
                <div className="funnel-content17">

                  <div className="funnel-input-dropdown2">
                    {/* <div className="funnel-input-with-label2">
                    <div className="funnel-label">Search</div>
                    <div className="funnel-input2">
                      <div className="funnel-content3">
                        <img
                          className="funnel-search-lg-icon funnel-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="funnel-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                    <SearchInputListingComponent onSearch={handleSearch} />

                    <div className="funnel-buttonsbutton1" onClick={toggleDropdown}>
                      <img
                        className="funnel-upload-04-icon"
                        alt=""
                        src={sliders02}
                      />
                      <div className="funnel-text-padding6" ref={dropdownRef}>
                        <div className="funnel-text65">
                          Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                        </div>
                        {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                      </div>
                      <img
                        className="funnel-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />

                    </div>
                  </div>

                  {/* <div className="funnel-dropdowns">

                    <FunnelJobCardTabs />

                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Listing Container */}

        <div className="funnel-device-component-listing-container">
          {/* Listing layout start    */}
          {content}
        </div>


        {/* Load more button  */}
        <div className="funnel-frame-wrapper44">
          <div className="funnel-frame-parent72">
            <div className="funnel-buttonsbutton-wrapper1">
              {showLoadMore && (
                <button className='funnel-buttonsbutton-wrapper1-child'
                  onClick={handleLoadMore}
                  disabled={isFetching}
                >
                  <div className="funnel-buttonsbutton28">
                    <img
                      className="funnel-arrow-narrow-left-icon"
                      alt=""
                      src={arrowdown1}
                    />
                    <div className="funnel-search-input">
                      <div className="funnel-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  } else if (isCountError) {
    return <ErrorShowComponent message1={'Internal server error'} />
  }

}

