import React, { useState } from 'react';
import './TeamMemberTableTabs.css'; // Import your CSS file
import { ActiveTeamMemberTableComponent } from '../ActiveTeamMemberTableComponent/ActiveTeamMemberTableComponent';
import { InvitedTeamMemberTableComponent } from '../InvitedTeamMemberTableComponent/InvitedTeamMemberTableComponent';
import { InactiveTeamMemberTableComponent } from '../InactiveTeamMemberTableComponent/InactiveTeamMemberTableComponent';

export const TeamMemberTableTabs = () => {
    const [activeButton, setActiveButton] = useState('Active');

    const handleButtonClick = (switchButton) => {
        setActiveButton(switchButton);
    };

    const renderComponent = () => {
        switch (activeButton) {
            case 'Active':
                return <ActiveTeamMemberTableComponent />;
            case 'Pending':
                return <InvitedTeamMemberTableComponent />;
            case 'Inactive':
                return <InactiveTeamMemberTableComponent />;
            default:
                return null;
        }
        };


    return (
        <div className='team-display-main-container'>
            <div className='team-display-tab'> 
            <div className="team-display-button-group custom-cursor">
                <div
                    className={`team-display-button-group-base1 ${activeButton === 'Active' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Active')}
                >
                    <div className="team-display-text31">Active</div>
                </div>
                <div
                    className={`team-display-button-group-base1 ${activeButton === 'Pending' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Pending')}
                >
                    <div className="team-display-text32">Pending</div>
                </div>
                <div
                    className={`team-display-button-group-base1 ${activeButton === 'Inactive' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Inactive')}
                >
                    <div className="team-display-text29">Inactive</div>
                </div>
            </div>
        
            </div>

            <div className="component-display-area">
            {renderComponent()}
            </div>

        </div>
);
};


export default TeamMemberTableTabs;
