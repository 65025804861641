import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { auth, app, getAuth } from '../screens/Login/FirebaseClient';
import Cookies from 'js-cookie';
import { decryptUserData, decryptUserData, encryptData, } from '../helper/AESHelper';


export const LoginRegisterAPI = createApi({
    reducerPath: 'users',
    tagTypes: ['users'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: async (headers) => {
            let idToken;

            // Check if the user is available
            const user = auth.currentUser;

            if (user) {
                // Get the ID token from Firebase
                idToken = await user.getIdToken();
                if (idToken) {
                    console.log("Got idToken using auth.currentUser");
                }

            }

            if (idToken) {
                headers.set('Authorization', `Bearer ${idToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({

        getUser: builder.query({
            query: () => ({
                url: 'get-user',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                console.log(response);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        addUser: builder.mutation({
            query: (body) => {
                // Encrypt request body
                // console.log(body);
                const encryptedData = encryptData(body);
                console.log(encryptedData);
                return {
                    url: '/add-user',
                    method: 'POST',
                    // body: body,
                    body: JSON.stringify({ encryptedData }),
                    headers: { 'Content-Type': 'application/json' },
                };
            },
            // transformResponse: (response) => {
            //     // Decrypt response data
            //     // console.log(response.data);
            //     return decryptUserData(response);
            // },
            invalidatesTags: ['users'],
        }),
        addUserGoogle: builder.query({
            query: () => ({
                url: 'add-user-google',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        addUserFacebook: builder.query({
            query: () => ({
                url: 'add-user-facebook',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        setEmailVerify: builder.query({
            query: () => ({
                url: 'set-email-verify',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        setEmailVerifySSO: builder.query({
            query: () => ({
                url: 'set-email-verify-sso',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        sendMail: builder.mutation({
            query: (body) => {
                // Encrypt request body
                // console.log(body);
                const encryptedData = encryptData(body);
                console.log(encryptedData);
                return {
                    url: '/send-mail',
                    method: 'POST',
                    // body: body,
                    body: JSON.stringify({ encryptedData }),
                    headers: { 'Content-Type': 'application/json' },
                };
            },
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response.data);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        getUserForSignUp: builder.query({
            query: () => ({
                url: 'get-user-sign-up',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response);
                return decryptUserData(response);
            },
            invalidatesTags: ['users'],
        }),
        getProfileImageLink: builder.query({
            query: () => ({
                url: 'profile-image-link',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            // transformResponse: (response) => {
            //     // Decrypt response data
            //     // console.log(response);
            //     return decryptUserData(response);
            // },
            invalidatesTags: ['users'],
        }),

    }),
});

export const {
    useAddUserMutation,
    useGetUserQuery,
    useLazyGetUserQuery,
    useLazyAddUserGoogleQuery,
    useLazyAddUserFacebookQuery,
    useLazySetEmailVerifyQuery,
    useLazySetEmailVerifySSOQuery,
    useSendMailMutation,
    useLazyGetUserForSignUpQuery,
    useLazyGetProfileImageLinkQuery,
} = LoginRegisterAPI;
