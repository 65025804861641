// HeaderNavDropdown.js
import React from 'react';
import { Link } from 'react-router-dom';
import './AddButtonDropdown.css';

const ButtonDropdown = ({ items }) => {
  return (
    <div className="button-dropdown-menu">
      <div className="button-dropdown-content">
        {items.map((item, index) => (
          <Link to={`/${item.link}`} key={index}>
            <ButtonDropdownItem
              title={item.title}
              // description={item.description}
              // iconClass={item.icon}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

const ButtonDropdownItem = ({ title, description, iconClass }) => {
  return (
    <div className="button-dropdown-navMenuItem">

      <div className="button-dropdown-itemContent">
        <div className="button-dropdown-textAndSupportingText">
          <div className="button-dropdown-textAndBadge">
            <div className="button-dropdown-text" >{title}</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ButtonDropdown;
