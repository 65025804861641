import React, { useState } from 'react';
import "./AddWantedService.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { AddWantedServiceForm } from '../../../components/AddWantedServiceFormComponent/AddWantedServiceForm';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
// import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import {SideBarSubSettings} from "../../../components/SideBars-Sub/SideBarSubSettings/SideBarSubSettings";

import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { useEffect } from 'react';
import { useRef } from 'react';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useNavigate } from 'react-router-dom';
import { useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import { decryptData, decryptErrorData } from '../../../helper/AESHelper';
import { Provider } from "./AddWantedServiceContext";
import { useGetDeviceCategoryTaxQuery, useGetClinicalApplicationsTaxQuery, useGetServiceCategoryTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery } from "../../../api/TaxonomyFormAPI";


export const AddWantedService = () => {

  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");



  const serviceCategoryTax = useGetServiceCategoryTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery();
  const purposeUseTax = useGetPurposeUseTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const physicalLocationTax = useGetPhysicalLocationTaxQuery();

  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);


  const [allTaxonomy, setAllTaxonomy] = useState({ serviceCategory: serviceCategoryTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, })

  useEffect(() => {

    const allSuccess = [deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        serviceCategory: serviceCategoryTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax
      });
    }

    setIsTaxonomySet(true);
  }, [
    serviceCategoryTax.isSuccess, serviceCategoryTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
  ]);


  const [backendValidation, setBackendValidation] = useState([])
  const formikRef = useRef(null);


  //
  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }


  if (isSuccess && isTaxonomySet) {
    return (


      <div className="add-wanted-service-forms-add-wanted-service add-wanted-service-forms-add-wanted-service-custom-margin">
        <div className="add-wanted-service-forms-add-wanted-service-child"></div>


        <Navbar1 userData={currentUser} navigate={navigate}/>

        <img className="divider-icon" alt="" src={divider} />


        <main className="add-wanted-service-content-parent">

        <Sidebar><SideBarSubSettings userData={currentUser} /></Sidebar>









          <section className="add-wanted-service-bottom-actions">
            <div className="add-wanted-service-progress-container-parent">
              <div className="add-wanted-service-progress-container">
                {/* <div className="add-wanted-service-progress-steps">
                <div className="add-wanted-service-form-parent">
                 
  
  
  
                </div>
              </div> */}
                {/* {renderProgressStep(step)} */}
                {/* <ProgressStep1/> */}
                {/* <ProgressStep2/> */}
                {/* <ProgressStep3/> */}
                <div className="add-wanted-service-content5">
                  <div className="add-wanted-service-main">


                    <HeaderForms text="Add Wanted Service " text1=" Add Wanted Service Listing" text2="Your first step to realizing asset value." />




                    <div className="add-wanted-service-main-child"></div>
                    <div className="add-wanted-service-main-item"></div>
                  </div>
                  <div className="add-wanted-service-body">
                    <Provider value={{ deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, allTaxonomy, backendValidation, setBackendValidation }}>
                      <AddWantedServiceForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} />

                    </Provider>

                    {/* {renderFormStep(step,setStep)} */}
                    {/* <AddWantedServiceStep/> */}
                    {/* <AddWantedServiceStep2/> */}
                    {/* <AddWantedServiceStep3/> */}




                  </div>

                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
        <img
          className="add-wanted-service-divider-icon10"
          loading="lazy"
          alt=""
          src={divider_light}
        />
        <FooterAddForms />
      </div>
    )
  } else {
    return <Loader />;
  }


}

