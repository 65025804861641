import React, { useEffect, useRef, useState } from 'react';
import "./VerificationBusiness.css"
import { decryptErrorData } from '../../../helper/AESHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { Footer } from '../../../components/Footer/Footer';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { useLazyFindGetBusinessNameQuery, useLazyFindGetBusinessQuery } from '../../../api/BusinessAPI';
import AddBusinessVerificationParent from '../../../components/AddBusinessVerificationFormComponent/AddBusinessVerificationParent';
import { ErrorShowComponent } from '../../../components/ErrorShowComponent/ErrorShowComponent';

export const VerificationBusiness = () => {

  const { id } = useParams();
  let content = "";
  const [triggerGetBusinessName, { data, isSuccess, error, isLoading, isError }] = useLazyFindGetBusinessNameQuery();
  const [currentUser, setCurrentUser] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  // const [formLoadData, setFormLoadData] = useState("");
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            if (usermeta.userPlan === 1 || usermeta.userPlan === 14 || usermeta.businessVerification === 3) {
              navigate('/dashboard/settings/business');
              return;
            }

            // if (usermeta.businessVerification === 3) {
            //   // content = <ErrorShowComponent message1={"Awaiting Verification"} message2={"Please wait..., while we verify your business."} />
            //   navigate('/dashboard/settings/business');
            //   return;
            // }
            // await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            triggerGetBusinessName();
            // User is authenticated
          }
        } else {
          content = <ErrorShowComponent message1={"Internal server error"} />
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  //
  if (isError) {
    console.log(error);
    content = <ErrorShowComponent message1={"Internal server error"} />
  }

  if (isSuccess) {
    content = <AddBusinessVerificationParent businessId={data.user_business.id} setShowSuccessMessage={setShowSuccessMessage} />
  }


  if ((content !== "") && profileImageLink) {
    return (
      <div className="business-verification-dashboard-frame-layout-setti">
        <main className="business-verification-dashboard-frame-layout-setti1">
          <div className="business-verification-dashboard-frame-layout-setti-child"></div>
          <section className="business-verification-dashboard-frame-layout-setti-inner">
            <header className="business-verification-rectangle-parent">
              <div className="business-verification-frame-child"></div>

              <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            </header>
          </section>
          <section className="business-verification-divider-wrapper">
            <img
              className="business-verification-divider-icon"
              loading="lazy"
              alt=""
              src="/profile_settings/divider.svg"
            />
          </section>
          <section className="business-verification-sidebar-navigation-parent">
            <div className="business-verification-sidebar-navigation">

              <Sidebar><SideBarSubNormal userData={currentUser} /></Sidebar>


            </div>
            <div className="business-verification-main-parent">
              <div className="business-verification-main">
                <div className="business-verification-header-section">
                  <div className="business-verification-container">
                    <div className="business-verification-page-header">
                      <div className="business-verification-breadcrumbs">
                        <div className="business-verification-tabs">
                          <div className="business-verification-breadcrumb-button-base">
                            <img
                              className="business-verification-search-lg-icon"
                              alt=""
                              src="/profile_settings/homeline-1.svg"
                            />
                          </div>
                          <img
                            className="business-verification-chevron-right-icon"
                            alt=""
                            src="/profile_settings/chevronright.svg"
                          />

                          <div className="business-verification-breadcrumb-button-base1">
                            <a className="business-verification-text22">Dashboard</a>
                          </div>
                          <img
                            className="business-verification-chevron-right-icon"
                            alt=""
                            src="/profile_settings/chevronright.svg"
                          />

                          <div className="business-verification-breadcrumb-button-base2">
                            <div className="business-verification-text23">...</div>
                          </div>
                          <img
                            className="business-verification-chevron-right-icon2"
                            alt=""
                            src="/profile_settings/chevronright.svg"
                          />

                          <div className="business-verification-breadcrumb-button-base3">
                            <div className="business-verification-text24">Another link</div>
                          </div>
                          <img
                            className="business-verification-chevron-right-icon2"
                            alt=""
                            src="/profile_settings/chevronright.svg"
                          />

                          <div className="business-verification-breadcrumb-button-base4">
                            <a className="business-verification-text25">Settings</a>
                          </div>
                        </div>
                      </div>
                      <img
                        className="business-verification-divider-icon1"
                        alt=""
                        src="/profile_settings/divider1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="business-verification-main-child"></div>
                <div className="business-verification-main-item"></div>
              </div>
              <div className="business-verification-main-wrapper">
                <div className="business-verification-main1">
                  {/* <div className="business-verification-header-content">
                    <div className="business-verification-header-section1">
                      <div className="business-verification-container1">
                        <div className="business-verification-page-header1">
                          <div className="business-verification-breadcrumbs1">
                            <div className="business-verification-tabs1">
                              <div className="business-verification-breadcrumb-button-base">
                                <img
                                  className="business-verification-search-lg-icon"
                                  alt=""
                                  src="/profile_settings/homeline-1.svg"
                                />
                              </div>
                              <img
                                className="business-verification-chevron-right-icon"
                                alt=""
                                src="/profile_settings/chevronright.svg"
                              />
  
                              <div className="business-verification-breadcrumb-button-base6">
                                <div className="business-verification-text26">Settings</div>
                              </div>
                              <img
                                className="business-verification-chevron-right-icon"
                                alt=""
                                src="/profile_settings/chevronright.svg"
                              />
  
                              <div className="business-verification-breadcrumb-button-base7">
                                <div className="business-verification-text23">...</div>
                              </div>
                              <img
                                className="business-verification-chevron-right-icon"
                                alt=""
                                src="/profile_settings/chevronright.svg"
                              />
  
                              <div className="business-verification-breadcrumb-button-base3">
                                <div className="business-verification-text24">Another link</div>
                              </div>
                              <img
                                className="business-verification-chevron-right-icon2"
                                alt=""
                                src="/profile_settings/chevronright.svg"
                              />
  
                              <div className="business-verification-breadcrumb-button-base4">
                                <div className="business-verification-text29">Team</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-verification-content13">
                            <div className="business-verification-text-and-supporting-text">
                              <h1 className="business-verification-page-title">Settings</h1>
                              <div className="business-verification-supporting-text1">
                                Manage your team members and their account
                                permissions here.
                              </div>
                            </div>
                            <div className="business-verification-actions">
                              <div className="business-verification-button">
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder.svg"
                                />
  
                                <div className="business-verification-text-padding">
                                  <div className="business-verification-title">Tertiary</div>
                                </div>
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder.svg"
                                />
                              </div>
                              <div className="business-verification-button1">
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder1.svg"
                                />
  
                                <div className="business-verification-text-padding">
                                  <div className="business-verification-title">Secondary</div>
                                </div>
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder1.svg"
                                />
                              </div>
                              <div className="business-verification-button2">
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder2.svg"
                                />
  
                                <div className="business-verification-text-padding">
                                  <div className="business-verification-title">Secondary</div>
                                </div>
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder2.svg"
                                />
                              </div>
                              <div className="business-verification-button3">
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
  
                                <div className="business-verification-text-padding">
                                  <div className="business-verification-title">Primary</div>
                                </div>
                                <img
                                  className="business-verification-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                              </div>
                            </div>
                            <div className="business-verification-input-dropdown">
                              <div className="business-verification-input-with-label1">
                                <div className="business-verification-label">Search</div>
                                <div className="business-verification-input1">
                                  <div className="business-verification-content3">
                                    <img
                                      className="business-verification-search-lg-icon"
                                      alt=""
                                      src="/profile_settings/searchlg.svg"
                                    />
  
                                    <input
                                      className="business-verification-text34"
                                      placeholder="Search"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="business-verification-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-verification-divider-icon2"
                            alt=""
                            src="/profile_settings/divider2.svg"
                          />
                        </div>
                        <div className="business-verification-horizontal-tabs">
                          <div className="business-verification-tabs2">
                            <div className="business-verification-tab-button-base1">
                              <a className="business-verification-text35">Profile</a>
                            </div>
                            <div className="business-verification-tab-button-base">
                              <a className="business-verification-text36">Business</a>
                            </div>
                            <div className="business-verification-tab-button-base1">
                              <a className="business-verification-text25">Address</a>
                            </div>
                            <div className="business-verification-tab-button-base3">
                              <div className="business-verification-text38">Team</div>
                              <div className="business-verification-badge10">
                                <div className="business-verification-badge-count">4</div>
                              </div>
                            </div>
                            <div className="business-verification-tab-button-base1">
                              <div className="business-verification-text39">Plan</div>
                            </div>
                            <div className="business-verification-tab-button-base3">
                              <a className="business-verification-text40">Billing</a>
                              <div className="business-verification-badge10">
                                <div className="business-verification-badge-count">2</div>
                              </div>
                            </div>
                            <div className="business-verification-tab-button-base1">
                              <a className="business-verification-text40">Social</a>
                            </div>
                            <div className="business-verification-tab-button-base7">
                              <a className="business-verification-text43">Preferences</a>
                              <div className="business-verification-badge10">
                                <div className="business-verification-badge-count">2</div>
                              </div>
                            </div>
                            <div className="business-verification-tab-button-base1">
                              <div className="business-verification-text45">Integrations</div>
                            </div>
                            <div className="business-verification-tab-button-base9">
                              <div className="business-verification-text46">API</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="business-verification-button4">
                    <img
                      className="business-verification-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder.svg"
                    />

                    <div className="business-verification-text-padding4">
                      <div className="business-verification-text47">Tertiary</div>
                    </div>
                    <img
                      className="business-verification-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder.svg"
                    />
                  </div>
                  <div className="business-verification-button5">
                    <img
                      className="business-verification-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder1.svg"
                    />

                    <div className="business-verification-text-padding4">
                      <div className="business-verification-text47">Secondary</div>
                    </div>
                    <img
                      className="business-verification-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder1.svg"
                    />
                  </div>
                  <div className="business-verification-dropdown">
                    <img
                      className="business-verification-dots-vertical-icon"
                      alt=""
                      src="/profile_settings/dotsvertical.svg"
                    />
                  </div>
                  <div className="business-verification-form-content-wrapper">
                    <div className="business-verification-form-content">
                      <div className="business-verification-toggle-header-parent">
                        <div className="business-verification-toggle-header">
                          <div className="business-verification-toggle-title">Apply for Business verification</div>
                          {/* <div className="business-verification-supporting-text2">
                          Update your photo and personal details here.
                        </div> */}
                        </div>

                      </div>
                      <div className="business-verification-divider-container">
                        <img
                          className="business-verification-divider-icon3"
                          alt=""
                          src="/profile_settings/divider2.svg"
                        />
                      </div>

                      {/* <div className="business-verification-divider"></div> */}
                      {data &&
                        <div className="business-verification-content18">
                          <div className="business-verification-section-label1">
                            <div className="business-verification-title">Business (this field cannot be edited)</div>
                          </div>
                          <div className="business-verification-input-field1 input-disabled">
                            <div className="business-verification-textarea-input-field">

                              <div className="business-verification-input4 input-disabled">
                                <div className="business-verification-content19 input-disabled">


                                  <input
                                    className="business-verification-extra-input-info inpur-disabled-color"
                                    value={data.user_business.businessName}
                                    disabled
                                  />
                                </div>


                                <img
                                  className="business-verification-help-icon"
                                  alt=""
                                  src="/profile_settings/help-icon.svg"
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      }

                      <div className="business-verification-divider"></div>
                    </div>
                  </div>

                  {content}
                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* <img className="business-verification-divider-icon5" alt="" src="/profile_settings/divider.svg" />
          <Footer /> */}

        </main>
      </div>
    )
  } else {
    return <Loader />
  }
}

