import "./AddDeviceStep.css"
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevrondown_2 from '../../icons/Gerator/add_form/chevrondown-2.svg';
import help_icon from '../../icons/Gerator/add_form/help-icon.svg';
import dotsvertical_1 from '../../icons/Gerator/add_form/dotsvertical-1.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import save02 from '../../icons/Gerator/add_form/save02.svg';
import { updateAppDataDevice } from "./AppDataDeviceSlice";
import { updateField } from "./AddDeviceSlice";
import AddDeviceFormContext from "../../screens/Device/AddDevice/AddDeviceContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";


const MyTextInputForCustomSelect = ({ disableBlurValidation, validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label15">
          <label className="add-service-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-service-step-span">*</span>
          </label>
        </div>
        <div className="add-service-step-input14">
          <div className="add-service-step-content22 add-device-step-content22-custom">
            <div className="add-service-step-text-input">

              <input
                className="add-service-step-first-nested-input add-job-step-first-nested-input-remove-border"
                // style={inputStyle}  //New code for border colour
                {...field}
                {...props}
                onBlur={disableBlurValidation ? (e) => e.preventDefault() : field.onBlur}
              />

            </div>
          </div>
        </div>
      </div>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }



  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label5">
          <label className="add-service-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-service-step-span">*</span>
          </label>
        </div>
        <div className="add-service-step-input5">
          <input
            className="add-service-step-content12"
            // style={inputStyle}  //New code for border colour
            {...field}
            {...props}

          />
        </div>
      </div>


      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const MyTextInputNotMandatory = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label5">
          <label className="add-service-step-transaction-type" htmlFor={props.id || props.name}>
            {label}
          </label>
        </div>
        <div className="add-service-step-input5">
          <input
            className="add-service-step-content12"
            // style={inputStyle}  //New code for border colour
            {...field}
            {...props}

          />
        </div>
      </div>


      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label3">
          <label className="add-service-step-transaction-type" htmlFor={props.name}>
            {label} <span className="add-service-step-span">*</span>
          </label>
        </div>
        <div className="add-service-step-input1">
          <div className="add-service-step-content8 add-device-step-content22-custom">
            <div className="add-service-step-text-input">
              <select
                className="add-service-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

export const AddDeviceStep1 = () => {

  // const formikContext = useFormikContext();
  const dispatch = useDispatch();
  //For deviceCategory and oem, used for get reference of custom select dropdown
  const ulDeviceCategoryRef = useRef(null);
  const ulOemRef = useRef(null);
  //Get form field data from redux state
  const formData = useSelector((state) => state.addDevice);
  const appData = useSelector((state) => state.appDataAddDevice);

  //Getting all taxonomy from AddDeviceParent(Context)
  const { oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, backendValidation, allTaxonomy } = useContext(AddDeviceFormContext);
  const { validateForm, setFieldTouched, setFieldValue } = useFormikContext();

  //useState for make visible custom Select field on form
  const [visibleDeviceCategorySelectField, setVisibleDeviceCategorySelectField] = useState(false)
  const [visibleOemSelectField, setVisibleOemSelectField] = useState(false)

  //This method used to increment current step of form and update state in appData reducer
  const next = async () => {

    // dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: (appData.currentStep + 1) }));

    try {
      // const { errors } = useFormikContext();
      const validatedError = await validateForm();
      // console.log(formikUpdated);
      // console.log(validatedError);
      if (Object.keys(validatedError).length > 0) {

        Object.keys(validatedError).forEach(key => {
          console.log(key);
          setFieldTouched(key, true);
        });
      } else {
        dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: (appData.currentStep + 1) }));
      }
      // dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: (appData.currentStep + 1) }));
    }
    catch (err) {
      // console.log(Object.keys(err).length);
      if (Object.keys(err).length > 0) {

        Object.keys(err).forEach(key => {
          // console.log(key);
          setFieldTouched(key, true);
        });
      }
    }
  };

  //This handle method used to capture input entered text and save them into redux state
  const handleChange = async (event) => {
    const { name, value } = event.target;

    //If entered/edited data in deviceCategory field this block will run
    if (name === 'deviceCategory') {

      //Dropdown will be visible
      // setVisibleDeviceCategorySelectField(true)

      //when deviceCategory field's data edited/removed then this block will run
      if (value.length < deviceCategorySearchField.length || value.length > deviceCategorySearchField.length) {
        console.log("inside if");
        //formik form object value set to 0
        setFieldValue(name, "0");
        //deviceCategory field value is set 0 in form object
        dispatch(updateField({ field: name, value: "0" }));
        //deviceCategory field input value is set
        setDeviceCategorySearchField(value);
        //form field deviceCategory is set to touch. then validation error will show 
        await setFieldTouched(name, true);
        return;
      }
    }

    if (name === 'oem') {

      //when oem field's data edited/removed then this block will run
      if (value.length < oemSearchField.length || value.length > oemSearchField.length) {

        // if (formData.oem !== "") {
        //   dispatch(updateWantedDeviceField({ field: name, value: "" }));
        // }
        //formik form object value set to 0
        setFieldValue(name, "0");
        //oem field value is set 0 in form object
        dispatch(updateField({ field: name, value: "0" }));
        //oem field input value is set
        setOemSearchField(value);
        //form field oem is set to touch. then validation error will show 
        await setFieldTouched(name, true);
        // console.log(value.length);
        return;
      }
    }
    //Saving details into form
    setFieldValue(name, value);
    dispatch(updateField({ field: name, value }));
  };

  //This handle method used to select input from select field 
  const handleSelect = async (tax, field) => {
    const { id, name } = tax;
    console.log("handle select called");
    //Saving details into form
    if (field === 'deviceCategory' && id != 0) {
      setDeviceCategorySearchField(name);
      setVisibleDeviceCategorySelectField(false);
      setNewDeviceCategoryLength(50)
      await setFieldValue("deviceCategory", id);
      dispatch(updateField({ field: "deviceCategory", value: id }));
      setFieldTouched("deviceCategory", true);
      return;
    } else {
      dispatch(updateField({ field: "deviceCategory", value: id }));
      setFieldTouched("deviceCategory", true);
    }

    if (field === 'oem') {
      setOemSearchField(name);
      setVisibleOemSelectField(false);
      setNewOemLength(50)
      await setFieldValue("oem", id);
      dispatch(updateField({ field: "oem", value: id }));
      setFieldTouched("oem", true);
      // return;
    } else {
      dispatch(updateField({ field: "oem", value: id }));
      setFieldTouched("oem", true);
    }
  };

  const handleClick = (event) => {
    const name = event.target.name;
    if (name === "deviceCategory") {
      setFieldTouched("deviceCategory", false);
      setVisibleDeviceCategorySelectField(true);
      return;
    }
    if (name === "oem") {
      setFieldTouched("oem", false);
      setVisibleOemSelectField(true);
      // return;
    }

    // setDeviceCategorySearchField();
  }

  //useEffect to unselect dropdown when outside of deviceCategory and oem clicked
  useEffect(() => {
    const handleClick = (event) => {
      const deviceCategoryElement = document.getElementById("deviceCategory");
      const oemElement = document.getElementById("oem");


      // if (ulElement.contains(event.target) || deviceCategoryElement.contains(event.target)) {
      if (!deviceCategoryElement.contains(event.target)) {
        setVisibleDeviceCategorySelectField(false)
      }

      if (!oemElement.contains(event.target)) {
        setVisibleOemSelectField(false)
      }
      // const dropdown = document.getElementById('custom-dropdown');
    }

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  useEffect(() => {
    const handleScroll = (event) => {

      const ulChildElement = event.target;
      const scrollTop = ulChildElement.scrollTop;
      const scrollHeight = ulChildElement.scrollHeight;
      const clientHeight = ulChildElement.clientHeight;

      // console.log(ulChildElement);
      console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);


      // // Check if the scroll is near the end
      if (scrollTop + clientHeight >= scrollHeight - 10 * (ulChildElement.children[0].offsetHeight)) {
        // const secondLastValue = ulChildElement.children[ulChildElement.children.length - 10].textContent;
        setNewDeviceCategoryLength(newDeviceCategoryLength + ulChildElement.children.length);
        console.log("length of device category " + (newDeviceCategoryLength + ulChildElement.children.length));
      }
      // console.log('ul is being scrolled', event);
    };


    const ulElement = ulDeviceCategoryRef.current;
    // console.log(ulElement);
    if (ulElement) {
      ulElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (ulElement) {
        ulElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [visibleDeviceCategorySelectField]);

  useEffect(() => {
    const handleScroll = (event) => {

      const ulChildElement = event.target;
      const scrollTop = ulChildElement.scrollTop;
      const scrollHeight = ulChildElement.scrollHeight;
      const clientHeight = ulChildElement.clientHeight;

      // console.log(ulChildElement);
      console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);


      // // Check if the scroll is near the end
      if (scrollTop + clientHeight >= scrollHeight - 10 * (ulChildElement.children[0].offsetHeight)) {
        // const secondLastValue = ulChildElement.children[ulChildElement.children.length - 10].textContent;
        setNewOemLength(newOemLength + ulChildElement.children.length);
        console.log("length of oem " + (newOemLength + ulChildElement.children.length));
      }
      // console.log('ul is being scrolled', event);
    };


    const ulElement = ulOemRef.current;
    // console.log(ulElement);
    if (ulElement) {
      ulElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (ulElement) {
        ulElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [visibleOemSelectField]);

  return (
    <>
      <div className="add-device-step-section-header">
        

        <div className="add-device-step-content7">Basic Information</div>

        <img
          className="add-device-step-divider-icon2"
          alt=""
          src={divider1}
        />
      </div>
      <div className="add-device-step-input-field-parent">
        <div className="add-device-step-input-field">
          <MySelect label="Transaction Type" onChange={handleChange} validate={backendValidation} value={formData.transactionType} name="transactionType" id="transactionType" >
            <option value="">Select From Dropdown list </option>
            {(allTaxonomy.transactionType.isLoading || allTaxonomy.transactionType.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.transactionType.isSuccess) && (allTaxonomy.transactionType.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
            {/* <option value="rentlease">Rent/Lease</option>
                      <option value="sale">sale</option> */}
          </MySelect>
        </div>
        <div className="add-device-step-input-field">
          <MySelect label="Your Role (in relation to Device listed)" name="yourRole" id="yourRole" onChange={handleChange} validate={backendValidation} value={formData.yourRole}>
            <option value="0">Select From Dropdown list </option>
            {(allTaxonomy.yourRole.isLoading || allTaxonomy.yourRole.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.yourRole.isSuccess) && (allTaxonomy.yourRole.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }

          </MySelect>
        </div>
      </div>
      <div className="add-device-step-input-field-parent">
        <div className="add-device-step-input-field">
          <MyTextInputForCustomSelect
            label="Device Category"
            name="deviceCategory"
            type="text"
            placeholder="Enter Device Category without OEM Brand - max. 30 characters"
            id="deviceCategory"
            autoComplete="off"
            disableBlurValidation={true}
            onChange={handleChange} onClick={handleClick} value={deviceCategorySearchField} validate={backendValidation}
          />
          <div id="deviceCategoryCustomSelect">
            {visibleDeviceCategorySelectField &&
              <ul ref={ulDeviceCategoryRef}>
                {(allTaxonomy.deviceCategory.isLoading || allTaxonomy.deviceCategory.isError) && <li value="0">Loading...</li>}
                {
                  (allTaxonomy.deviceCategory.isSuccess) && (allTaxonomy.deviceCategory.data.map(tax => (
                    <li key={tax.id} onClick={() => handleSelect(tax, 'deviceCategory')}>
                      {tax.name}
                    </li>
                  )))
                }
              </ul>
            }

          </div>
        </div>
        <div className="add-device-step-input-field">
          <MyTextInputForCustomSelect
            placeholder="Enter OEM Brand - max. 30 characters"
            type="text"
            autoComplete="off"
            disableBlurValidation={true}
            label="Original Equipment Manufacture (OEM)&nbsp;&nbsp;" name="oem" id="oem" onChange={handleChange} onClick={handleClick} value={oemSearchField} validate={backendValidation}
          />

          <div id="oemCustomSelect">
            {visibleOemSelectField &&
              <ul ref={ulOemRef}>
                {(allTaxonomy.oem.isLoading || allTaxonomy.oem.isError) && <li value="0">Loading...</li>}
                {
                  (allTaxonomy.oem.isSuccess) && (allTaxonomy.oem.data.map(tax => (
                    <li key={tax.id} onClick={() => handleSelect(tax, 'oem')}>
                      {tax.name}
                    </li>
                  )))
                }
              </ul>
            }

          </div>
        </div>
      </div>
      <div className="add-device-step-input-field-parent">
        <div className="add-device-step-input-field">

          <MySelect label="Year of Manufacture" name="yearOfManufacture" id="yearOfManufacture" onChange={handleChange} validate={backendValidation} value={formData.yearofManufacture}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.yearOfManufacture.isLoading || allTaxonomy.yearOfManufacture.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.yearOfManufacture.isSuccess) && (allTaxonomy.yearOfManufacture.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
        <div className="add-device-step-input-field">
          <MySelect label="Availability" name="availability" id="availability" onChange={handleChange} validate={backendValidation} value={formData.availability}>
            <option value="0">Select From Dropdown List </option>

            {(allTaxonomy.availability.isLoading || allTaxonomy.availability.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.availability.isSuccess) && (allTaxonomy.availability.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
      </div>
      <div className="add-device-step-input-field-parent">
        <div className="add-device-step-input-field">

          <MyTextInput
            label="Model Name"
            name="modelName"
            id="modelName"
            type="text"
            onChange={handleChange} validate={backendValidation} value={formData.modelName}
            placeholder="Enter Model Name without OEM Brand - max. 30 characters"
          />
        </div>
        <div className="add-device-step-input-field">
          <MySelect label="Status/Condition" name="statusCondition" id="statusCondition" onChange={handleChange} validate={backendValidation} value={formData.statusCondition}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.statusCondition.isLoading || allTaxonomy.statusCondition.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.statusCondition.isSuccess) && (allTaxonomy.statusCondition.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
      </div>
      <div className="add-device-step-input-field-parent">
        <div className="add-device-step-input-field">
          <MyTextInputNotMandatory
            label="Model Number"
            id="modelNumber"
            name="modelNumber"
            type="text"
            onChange={handleChange} validate={backendValidation} value={formData.modelNumber}
            placeholder="Max .30 characters"
          />
        </div>
        <div className="add-device-step-input-field">
          <MyTextInputNotMandatory
            label="Serial Number"
            name="serialNumber"
            id="serialNumber"
            type="text"
            onChange={handleChange} validate={backendValidation} value={formData.serialNumber}
            placeholder="Max .30 characters"
          />
        </div>
      </div>
      <div className="add-device-step-single-input-parent">
        <div className="add-device-step-single-input">
          <MyTextInput
            label="Price (EXW) in USD / unit "
            name="price"
            id="price"
            onChange={handleChange} validate={backendValidation} value={formData.price}
            type="number"
            placeholder="Price (EXW) in USD"

          />

        </div>
        <div className="add-device-step-input-field8">
          <MySelect label="Unit of Measure" name="unitOfMeasure" id="unitOfMeasure" validate={backendValidation} onChange={handleChange} value={formData.unitOfMeasure}>
            <option value="0">Select From Dropdown List </option>

            {(allTaxonomy.unitOfMeasure.isLoading || allTaxonomy.unitOfMeasure.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.unitOfMeasure.isSuccess) && (allTaxonomy.unitOfMeasure.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
        <div className="add-device-step-input-field8">
          <MyTextInput
            label="Available Quantity"
            name="availableQuantity"
            id="availableQuantity"
            onChange={handleChange}
            validate={backendValidation}
            // showError={showErrors}
            value={formData.availableQuantity}
            type="text"
            placeholder="Any Number between 0 and 99"

          />
        </div>
      </div>
      <div className="add-device-step-nested-inputs">
        <div className="add-device-step-nested-input-fields">
          <div className="add-device-step-input-field">
            <MySelect label="Warranty" name="warranty" id="warranty" validate={backendValidation} onChange={handleChange} value={formData.warranty}>
              <option value="0">Select From Dropdown List </option>

              {(allTaxonomy.warranty.isLoading || allTaxonomy.warranty.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.warranty.isSuccess) && (allTaxonomy.warranty.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }
            </MySelect>

          </div>
          <div className="add-device-step-input-field">
            <MySelect label="Shipping" name="shipping" id="shipping" validate={backendValidation} onChange={handleChange} value={formData.shipping}>
              <option value="0">Select From Dropdown List </option>

              {(allTaxonomy.shipping.isLoading || allTaxonomy.shipping.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.shipping.isSuccess) && (allTaxonomy.shipping.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }
            </MySelect>
          </div>
        </div>
      </div>
      <div className="add-device-step-input-tags">
        <div className="add-device-step-divider"></div>
        <div className="add-device-step-section-header1">
          <div className="add-device-step-content-parent">
            <div className="add-device-step-text-and-supporting-text5">
              <div className="add-device-step-uploads-title">
                Select Tags Based on Device Features / Use
              </div>
            </div>
            <div className="add-device-step-dropdown9">
              <img
                className="add-device-step-dots-vertical-icon"
                alt=""
                src={dotsvertical_1}
              />
            </div>
          </div>
          <img
            className="add-device-step-divider-icon2"
            alt=""
            src={divider1}
          />
        </div>
        <div className="add-device-step-tagged-inputs">
          <div className="add-device-step-input-field12">
            <MySelect label="Clinical Applications" validate={backendValidation} name="clinicalApplications" id="clinicalApplications" onChange={handleChange} value={formData.clinicalApplications}>
              <option value="0">Select From Dropdown List </option>

              {(allTaxonomy.clinicalApplications.isLoading || allTaxonomy.clinicalApplications.isError) && <option value="0">Loading...</option>}
              {
                (allTaxonomy.clinicalApplications.isSuccess) && (allTaxonomy.clinicalApplications.data.map(tax => (
                  <option key={tax.id} value={tax.id}>{tax.name}</option>
                )))
              }
            </MySelect>
          </div>
        </div>
        <div className="add-device-step-input-field13">
          <MySelect label="Device Use" name="purposeUse" validate={backendValidation} id="purposeUse" onChange={handleChange} value={formData.purposeUse}>
            <option value="0">Select From Dropdown List </option>

            {(allTaxonomy.purposeUse.isLoading || allTaxonomy.purposeUse.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.purposeUse.isSuccess) && (allTaxonomy.purposeUse.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
        <div className="add-device-step-input-field13">
          <MySelect label="Physical Location" validate={backendValidation} name="physicalLocation" id="physicalLocation" onChange={handleChange} value={formData.physicalLocation}>
            <option value="0">Select From Dropdown List </option>

            {(allTaxonomy.physicalLocation.isLoading || allTaxonomy.physicalLocation.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.physicalLocation.isSuccess) && (allTaxonomy.physicalLocation.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }
          </MySelect>
        </div>
      </div>
      <div className="add-device-step-section-footer">
        <img
          className="add-device-step-divider-icon2"
          loading="lazy"
          alt=""
          src={divider1}
        />

        <div className="add-device-step-content31 add-device-step-content31-custom-padding">

          <div className="add-device-step-actions2">

            <button className="add-device-step-button9" type="button" onClick={next}>
              <img
                className="add-device-step-chevron-down-icon"
                alt=""
                src={placeholder3}
              />

              <img
                className="add-device-step-home-line-icon"
                alt=""
                src={save02}
              />

              <div className="add-device-step-text-padding6">
                <div className="add-device-step-text44" >Save & Proceed</div>
              </div>
              <img
                className="add-device-step-chevron-down-icon"
                alt=""
                src={placeholder3}
              />
            </button>
          </div>
        </div>
      </div>

    </>




    // </form>
  )
}
