import React, { useRef } from 'react';
import "./ArchiveWantedDeviceFinal.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';
import divider12 from '../../../icons/Gerator/archive_page/divider-12.svg';
import divider13 from '../../../icons/Gerator/archive_page/divider-13.svg';
import divider21 from '../../../icons/Gerator/archive_page/divider-21.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import dot from '../../../icons/Gerator/archive_page/-dot.svg';

import help_icon2 from '../../../icons/Gerator/archive_page/help-icon2.svg';
import help_icon from '../../../icons/Gerator/archive_page/help-icon.svg';


import featured_icon1 from '../../../icons/Gerator/archive_page/featured-icon1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import xclose10 from '../../../icons/Gerator/archive_page/xclose-10.svg';

import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { useDeleteDeviceMutation, useLazyFilterDevicesQuery, useLazyGetDevicesQuery } from '../../../api/DeviceAPI';
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery, useGetYearOfManufactureFilterTaxQuery, useGetClinicalApplicationsFilterTaxQuery, useGetPhysicalLocationFilterTaxQuery, useGetPurposeUseFilterTaxQuery, useGetCountryFilterTaxQuery } from "../../../api/TaxonomyFormAPI";
import { NoListingFound } from '../../../components/NoListing/NoListingFound';
import { NoListingFoundForFilter } from '../../../components/NoListingFoundForFilter/NoListingFoundForFilter';
import * as Yup from 'yup';
import { useAddRadarDeviceMutation, useDeleteRadarDeviceMutation } from '../../../api/Radar/RadarDeviceAPI';
import { Helmet } from 'react-helmet-async';
import { PostStatusLIstingComponent } from '../../../components/PostStatusLIstingComponent/PostStatusLIstingComponent';
import { ThreeDotsListingComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsListingComponent';
import { TransactionTypeComponent } from '../../../components/TransactionTypeComponent/TransactionTypeComponent';
// import { startOfDay, endOfDay } from 'date-fns';

// Temporary import

import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';



// function checkIfAllEmpty(obj) {
//   for (const key in obj) {
//     if (Array.isArray(obj[key])) {
//       // Check if array is not empty
//       if (obj[key].length !== 0) {
//         return false;
//       }
//     } else if (typeof obj[key] === 'object' && obj[key] !== null) {
//       // Recursively check nested objects
//       for (const nestedKey in obj[key]) {
//         if (obj[key][nestedKey] !== '') {
//           return false;
//         }
//       }
//     }
//   }
//   return true;
// }

// Yup validation schema
const priceValidationSchema = Yup.object().shape({
  minPrice: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value)
    .nullable()
    .required('Minimum price is required')
    .min(0, 'Min price cannot be less than 0'),
  maxPrice: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value)
    .nullable()
    .required('Maximum price is required')
    .min(0, 'Max price cannot be less than 0')
    .test('is-greater-than-min', 'Max price should be greater than Min price',
      function (value) {
        const { minPrice } = this.parent;
        if (value === null || minPrice === null) return false;
        return value >= minPrice;
      }),
});

function checkIfAllEmpty(obj) {
  for (const key in obj) {
    const value = obj[key];

    if (Array.isArray(value)) {
      // If the array is not empty, return false
      if (value.length !== 0) {
        return false;
      }
    } else if (value !== '') {
      // If the value is a non-empty string, return false
      return false;
    }
  }
  return true;
}

const useScrollListener = (ref, visibleField, setLength, prevLength) => {
  useEffect(() => {
    const handleScroll = (event) => {
      const ulChildElement = event.target;
      const scrollTop = ulChildElement.scrollTop;
      const scrollHeight = ulChildElement.scrollHeight;
      const clientHeight = ulChildElement.clientHeight;

      console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);

      // Check if the scroll is near the end
      if (scrollTop + clientHeight >= scrollHeight - 10 * ulChildElement.children[0].offsetHeight) {
        setLength(prevLength + ulChildElement.children.length);
        console.log(`Updated length: ${prevLength + ulChildElement.children.length}`);
      }
    };

    const ulElement = ref.current;
    if (ulElement) {
      ulElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (ulElement) {
        ulElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [visibleField]);
};


const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


export const ArchiveWantedDeviceFinal = () => {

  const [toggledImages, setToggledImages] = useState({});

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);


  //Used to get reference of custom select dropdown searchfield
  const ulCountryRef = useRef(null);
  const ulDeviceCategoryRef = useRef(null);
  const ulOemRef = useRef(null);
  const ulYearOfManufactureRef = useRef(null);
  const ulClinicalApplicationsRef = useRef(null);
  const ulPurposeUseRef = useRef(null);
  const ulPhysicalLocationRef = useRef(null);

  //useState for make visible custom Select field on form
  const [visibleCountrySelectField, setVisibleCountrySelectField] = useState(false);
  const [visibleDeviceCategorySelectField, setVisibleDeviceCategorySelectField] = useState(false);
  const [visibleOemSelectField, setVisibleOemSelectField] = useState(false);
  const [visibleYearOfManufactureSelectField, setVisibleYearOfManufactureSelectField] = useState(false);
  const [visibleClinicalApplicationsSelectField, setVisibleClinicalApplicationsSelectField] = useState(false);
  const [visiblePurposeUseSelectField, setVisiblePurposeUseSelectField] = useState(false);
  const [visiblePhysicalLocationSelectField, setVisiblePhysicalLocationSelectField] = useState(false);


  //Length var for scroll result
  const [newCountryLength, setNewCountryLength] = useState(15);
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [newOemLength, setNewOemLength] = useState(50);
  const [newYearOfManufactureLength, setNewYearOfManufactureLength] = useState(20);
  const [newClinicalApplicationsLength, setNewClinicalApplicationsLength] = useState(20);
  const [newPurposeUseLength, setNewPurposeUseLength] = useState(20);
  const [newPhysicalLocationLength, setNewPhysicalLocationLength] = useState(20);

  //Search field data saving var
  const [countrySearchField, setCountrySearchField] = useState("");
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");
  const [oemSearchField, setOemSearchField] = useState("");
  const [yearOfManufactureSearchField, setYearOfManufactureSearchField] = useState("");
  const [clinicalApplicationsSearchField, setClinicalApplicationsSearchField] = useState("");
  const [purposeUseSearchField, setPurposeUseSearchField] = useState("");
  const [physicalLocationSearchField, setPhysicalLocationSearchField] = useState("");

  //Price Picker
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [priceError, setPriceError] = useState(null);

  //Date Picker 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date();

  //To show/hide each filter menu
  const [collapsedSections, setCollapsedSections] = useState({
    price: true,
    datePosted: true,
    yourRole: true,
    transactionType: true,
    statusCondition: true,
    warranty: true,
    shipping: true,
    country: true,
    deviceCategory: true,
    oem: true,
    yearOfManufacture: true,
    clinicalApplications: true,
    deviceUse: true,
    physicalLocation: true
  });

  //Filter query variable
  const [filters, setFilters] = useState({
    price: [],
    date: [],
    yourRole: [],
    transactionType: [],
    statusCondition: [],
    warranty: [],
    shipping: [],
    country: '',
    deviceCategory: '',
    oem: '',
    yearOfManufacture: '',
    clinicalApplications: '',
    deviceUse: '',
    physicalLocation: '',
  });

  //Used to show selected filter in above of listing container
  const [showMoreFilters, setShowMoreFilters] = useState(false);


  // useEffect(() => {
  //   console.log(collapsedSections);
  // }, [collapsedSections])


  //Taxonomy
  const yourRoleTax = useGetYourRoleTaxQuery();
  const transactionTypeTax = useGetTransactionTypeTaxQuery();
  const availabilityTax = useGetAvailabilityTaxQuery()
  const unitOfMeasureTax = useGetUnitOfMeasureDeviceSpareTaxQuery();
  const warrantyTax = useGetWarrantyTaxQuery();
  const shippingTax = useGetShippingTaxQuery();
  const statusConditionTax = useGetStatusConditionTaxQuery();
  const countryTax = useGetCountryFilterTaxQuery({ id: newCountryLength, query: countrySearchField });
  const oemTax = useGetOemTaxQuery({ id: newOemLength, query: oemSearchField });
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const yearofManufactureTax = useGetYearOfManufactureFilterTaxQuery({ id: newYearOfManufactureLength, query: yearOfManufactureSearchField })
  const clinicalApplicationsTax = useGetClinicalApplicationsFilterTaxQuery({ id: newClinicalApplicationsLength, query: clinicalApplicationsSearchField })
  const purposeUseTax = useGetPurposeUseFilterTaxQuery({ id: newPurposeUseLength, query: purposeUseSearchField })
  const physicalLocationTax = useGetPhysicalLocationFilterTaxQuery({ id: newPhysicalLocationLength, query: physicalLocationSearchField })


  const [allTaxonomy, setAllTaxonomy] = useState({
    oem: oemTax,
    deviceCategory: deviceCategoryTax,
    physicalLocation: physicalLocationTax,
    purposeUse: purposeUseTax,
    clinicalApplications: clinicalApplicationsTax,
    yearOfManufacture: yearofManufactureTax,
    statusCondition: statusConditionTax,
    shipping: shippingTax,
    warranty: warrantyTax,
    unitOfMeasure: unitOfMeasureTax,
    availability: availabilityTax,
    yourRole: yourRoleTax,
    transactionType: transactionTypeTax,
    country: countryTax,
  })

  // Get date and add in filter
  useEffect(() => {
    console.log("filter");
    console.log("Filter after date " + filters);
  }, [filters])

  useEffect(() => {
    if (startDate && endDate) {
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          ["date"]: [startDate, endDate],
        };
      });
    }

  }, [startDate, endDate])

  useEffect(() => {

    const allSuccess = [
      oemTax.isSuccess,
      deviceCategoryTax.isSuccess,
      physicalLocationTax.isSuccess,
      purposeUseTax.isSuccess,
      clinicalApplicationsTax.isSuccess,
      yearofManufactureTax.isSuccess,
      countryTax.isSuccess,
      statusConditionTax.isSuccess,
      shippingTax.isSuccess,
      warrantyTax.isSuccess,
      unitOfMeasureTax.isSuccess,
      availabilityTax.isSuccess,
      yourRoleTax.isSuccess,
      transactionTypeTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        oem: oemTax,
        deviceCategory: deviceCategoryTax,
        physicalLocation: physicalLocationTax,
        purposeUse: purposeUseTax,
        clinicalApplications: clinicalApplicationsTax,
        yearOfManufacture: yearofManufactureTax,
        country: countryTax,
        statusCondition: statusConditionTax,
        shipping: shippingTax,
        warranty: warrantyTax,
        unitOfMeasure: unitOfMeasureTax,
        availability: availabilityTax,
        yourRole: yourRoleTax,
        transactionType: transactionTypeTax
      });
    }
  }, [
    transactionTypeTax.isSuccess, transactionTypeTax.data,
    yourRoleTax.isSuccess, yourRoleTax.data,
    availabilityTax.isSuccess, availabilityTax.data,
    unitOfMeasureTax.isSuccess, unitOfMeasureTax.data,
    warrantyTax.isSuccess, warrantyTax.data,
    shippingTax.isSuccess, shippingTax.data,
    statusConditionTax.isSuccess, statusConditionTax.data,
    countryTax.isSuccess, countryTax.data,
    yearofManufactureTax.isSuccess, yearofManufactureTax.data,
    oemTax.isSuccess, oemTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
  ]);

  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  //Main Var
  let dataLength = 0;

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [showFilterLayout, setShowFilterLayout] = useState(false)

  const [triggerFilterDeviceResult, { data: filterDeviceData, isSuccess: isFilterDeviceSuccess, error: filterDeviceError, isLoading: isFilterDeviceLoading, isError: isFilterDeviceError }] = useLazyFilterDevicesQuery();
  const [deleteDevice] = useDeleteDeviceMutation();

  const [addToRadar] = useAddRadarDeviceMutation();

  const [deleteFromRadar] = useDeleteRadarDeviceMutation();

  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetDevice, { data: deviceData, isSuccess, error, isLoading, isError }] = useLazyGetDevicesQuery();




  let content;

  //Code to hide Filter layout when no option selected from filters
  // useEffect(() => {
  //   if (checkIfAllEmpty(filters)) setShowFilterLayout(false);
  //   // else
  // }, [filters])

  // Radar click 
  // const handleImageClick = async (deviceId) => {
  //   setToggledImages(prev => ({
  //     ...prev,
  //     [deviceId]: !prev[deviceId]
  //   }));
  //   await addToRadar({ id: deviceId });
  // };

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleRemoveRadar = async (deviceId) => {
    await deleteFromRadar(deviceId);
    window.location.reload();
  };

  const handleAddRadar = async (deviceId) => {
    await addToRadar(deviceId);
    window.location.reload();
  };

  // handleRemoveRadar

  // //Delete listings from default layout
  // const handleDelete = async (id) => {
  //   try {

  //     const response = await deleteDevice(id).unwrap();

  //     if (response.status) {
  //       // setShowSuccessMessage(response.message)
  //       // await new Promise(resolve => setTimeout(resolve, 1500));
  //       // Reseting form
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error(`Failed to delete wanted device `, error);
  //   }
  // };



  // For user session management
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetDevice();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
    // }, [navigate]);
  }, [navigate, triggerGetDevice]);

  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  //Use Effect for scrolling in custom dropdown
  useScrollListener(ulCountryRef, visibleCountrySelectField, setNewCountryLength, newCountryLength);
  useScrollListener(ulDeviceCategoryRef, visibleDeviceCategorySelectField, setNewDeviceCategoryLength, newDeviceCategoryLength);
  useScrollListener(ulOemRef, visibleOemSelectField, setNewOemLength, newOemLength);
  useScrollListener(ulYearOfManufactureRef, visibleYearOfManufactureSelectField, setNewYearOfManufactureLength, newYearOfManufactureLength);
  useScrollListener(ulClinicalApplicationsRef, visibleClinicalApplicationsSelectField, setNewClinicalApplicationsLength, newClinicalApplicationsLength);
  useScrollListener(ulPurposeUseRef, visiblePurposeUseSelectField, setNewPurposeUseLength, newPurposeUseLength);
  useScrollListener(ulPhysicalLocationRef, visiblePhysicalLocationSelectField, setNewPhysicalLocationLength, newPhysicalLocationLength);

  // Handle checkbox change for a specific section
  const handleCheckboxChange = (section, value) => {

    const sectionFilters = filters[section];

    if (sectionFilters.includes(value)) {
      // Remove filter if already selected
      setFilters({
        ...filters,
        [section]: sectionFilters.filter(filter => filter !== value),
      });
    } else {
      // Add filter if not selected
      setFilters({
        ...filters,
        [section]: [...sectionFilters, value],
      });
    }


  };


  // Error handling for price
  const validatePrices = async () => {
    try {
      await priceValidationSchema.validate(
        { minPrice, maxPrice },
        { abortEarly: false }
      );
      return true;
    } catch (error) {
      setPriceError(error.errors[0]);
      return false;
    }
  };


  // Your API call or filter logic here
  const callFilterDeviceResult = (updatedFilters) => {
    console.log("Applying filters:", updatedFilters);
    triggerFilterDeviceResult(updatedFilters);
  };

  // Apply filter and get data
  const applyFilter = async (e) => {

    if (minPrice !== null || maxPrice !== null) {
      const isValid = await validatePrices();
      if (!isValid) {
        return; // Stop if validation fails
      }
      // Create the updated filters object
      // Use state var update values late that's why added some method so value will be updated
      const updatedFilters = {
        ...filters,
        price: [minPrice, maxPrice],
      };

      // Update state and trigger filter with new values
      setFilters(updatedFilters);
      setPriceError(null);
      //Api call
      callFilterDeviceResult(updatedFilters);
      //To view filter layout
      setShowFilterLayout(true);
    }
    // Check if filters are empty
    else if (checkIfAllEmpty(filters)) {
      setShowFilterLayout(false);
      return;
    } else {
      // Default case when price not touched and filter not empty
      triggerFilterDeviceResult(filters);
      setShowFilterLayout(true);
    }


  }


  // This method responsible to set data in filter fields
  const handleSelect = (tax, fieldName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: tax.id,
    }));

    switch (fieldName) {
      case "country":
        setCountrySearchField(tax.name);
        setVisibleCountrySelectField(false);
        setNewCountryLength(50)
        break;
      case "deviceCategory":
        setDeviceCategorySearchField(tax.name);
        setVisibleDeviceCategorySelectField(false);
        setNewDeviceCategoryLength(50)
        break;
      case "oem":
        setVisibleOemSelectField(false);
        setOemSearchField(tax.name);
        setNewOemLength(50)
        break;
      case "yearOfManufacture":
        setVisibleYearOfManufactureSelectField(false);
        setYearOfManufactureSearchField(tax.name);
        setNewYearOfManufactureLength(10);
        break;
      case "clinicalApplications":
        setVisibleClinicalApplicationsSelectField(false);
        setClinicalApplicationsSearchField(tax.name);
        setNewClinicalApplicationsLength(10);
        break;
      case "purposeUse":
        setVisiblePurposeUseSelectField(false);
        setPurposeUseSearchField(tax.name);
        setNewPurposeUseLength(10);
        break;
      default:
        setVisiblePhysicalLocationSelectField(false);
        setPhysicalLocationSearchField(tax.name);
        setNewPhysicalLocationLength(10);
        break;
    }

  };

  // Saves price into price useState var
  const handlePrice = (event) => {
    const { name, value } = event.target;
    const parsedValue = value === '' ? null : Number(value);

    if (name === 'minPrice') {
      setMinPrice(parsedValue);
    } else if (name === 'maxPrice') {
      setMaxPrice(parsedValue);
    }
  };

  //This handle method used to capture input entered text and save them into redux state
  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log('handler change called');

    switch (name) {
      case 'country':
        // When country field's data is edited/removed then this block will run
        if (value.length < countrySearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setCountrySearchField('');
          return;
        }
        setCountrySearchField(value);
        setVisibleCountrySelectField(true);
        break;

      case 'deviceCategory':
        // When deviceCategory field's data is edited/removed then this block will run
        if (value.length < deviceCategorySearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setDeviceCategorySearchField('');
          return;
        }
        setDeviceCategorySearchField(value);
        setVisibleDeviceCategorySelectField(true);
        break;

      case 'oem':
        // When oem field's data is edited/removed then this block will run
        if (value.length < oemSearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setOemSearchField('');
          return;
        }
        setOemSearchField(value);
        setVisibleOemSelectField(true);
        break;

      case 'yearOfManufacture':
        // When YearOfManufacture field's data is edited/removed then this block will run
        if (value.length < yearOfManufactureSearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setYearOfManufactureSearchField('');
          return;
        }
        setYearOfManufactureSearchField(value);
        setVisibleYearOfManufactureSelectField(true);
        break;

      case 'clinicalApplications':
        // When ClinicalApplications field's data is edited/removed then this block will run
        if (value.length < clinicalApplicationsSearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setClinicalApplicationsSearchField('');
          return;
        }
        setClinicalApplicationsSearchField(value);
        setVisibleClinicalApplicationsSelectField(true);
        break;

      case 'purposeUse':
        // When PurposeUse field's data is edited/removed then this block will run
        if (value.length < purposeUseSearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setPurposeUseSearchField('');
          return;
        }
        setPurposeUseSearchField(value);
        setVisiblePurposeUseSelectField(true);
        break;

      default:
        // Default case if none of the above cases match
        if (value.length < physicalLocationSearchField.length) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: "",
          }));
          setPhysicalLocationSearchField('');
          return;
        }
        setPhysicalLocationSearchField(value);
        setVisiblePhysicalLocationSelectField(true);
        break;
    }

    //Saving details into form
    // setFieldValue(name, value);
    // dispatch(updateField({ field: name, value }));
  };

  // Oem and Device Category handle click method
  const handleClick = (event) => {
    const name = event.target.name;
    console.log(name);

    switch (name) {
      case "country":
        setVisibleCountrySelectField(true);
        break;
      case "deviceCategory":
        setVisibleDeviceCategorySelectField(true);
        break;
      case "oem":
        setVisibleOemSelectField(true);
        break;
      case "yearOfManufacture":
        setVisibleYearOfManufactureSelectField(true);
        break;
      case "clinicalApplications":
        setVisibleClinicalApplicationsSelectField(true);
        break;
      case "purposeUse":
        setVisiblePurposeUseSelectField(true);
        break;
      default:
        setVisiblePhysicalLocationSelectField(true);
        break;
    }

  }

  // Remove filter card
  const removeFilterCard = (section, value) => {
    switch (section) {
      //country has single value in filter var needed to add seperate setFilters
      case 'date':
        setFilters({
          ...filters,
          [section]: [],
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case 'price':
        setFilters({
          ...filters,
          [section]: [],
        });
        setMinPrice(null);
        setMaxPrice(null);
        break;
      case 'country':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisibleCountrySelectField(false);
        setCountrySearchField("");
        setNewCountryLength(15);
        break;
      case 'deviceCategory':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisibleDeviceCategorySelectField(false);
        setDeviceCategorySearchField("");
        setNewDeviceCategoryLength(50);
        break;
      case 'oem':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisibleOemSelectField(false);
        setOemSearchField("");
        setNewOemLength(50);
        break;
      case 'yearOfManufacture':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisibleYearOfManufactureSelectField(false);
        setYearOfManufactureSearchField("");
        setNewYearOfManufactureLength(50);
        break;
      case 'clinicalApplications':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisibleClinicalApplicationsSelectField(false);
        setClinicalApplicationsSearchField("");
        setNewClinicalApplicationsLength(50);
        break;
      case 'purposeUse':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisiblePurposeUseSelectField(false);
        setPurposeUseSearchField("");
        setNewPurposeUseLength(50);
        break;
      case 'physicalLocation':
        setFilters({
          ...filters,
          [section]: '',
        });
        setVisiblePhysicalLocationSelectField(false);
        setPhysicalLocationSearchField("");
        setNewPhysicalLocationLength(50);
        break;

      default:
        //all checkbox has array value then this setFilter is universal for them
        setFilters({
          ...filters,
          [section]: filters[section].filter(filter => filter !== value),
        });
        break;
    }

  };

  const MAX_VISIBLE_CARDS = 7; // Number of visible filter cards before the 'More Filters' button

  // Combine all filters into a single array to manage rendering
  const allFilters = [
    ...(filters.date.length ? [{
      section: 'date',
      value: filters.date,
      label: `Date : ${formatDate(startDate)} to ${formatDate(endDate)}`,
    }] : []),
    ...(filters.price.length ? [{
      section: 'price',
      value: filters.price,
      label: `Price : ${minPrice} to ${maxPrice}`,
    }] : []),
    ...filters.yourRole.map((filter) => ({
      section: 'yourRole',
      value: filter,
      label: allTaxonomy.yourRole.data.find(opt => opt.id === filter).name,
    })),
    ...filters.transactionType.map((filter) => ({
      section: 'transactionType',
      value: filter,
      label: allTaxonomy.transactionType.data.find(opt => opt.id === filter).name,
    })),
    ...filters.statusCondition.map((filter) => ({
      section: 'statusCondition',
      value: filter,
      label: allTaxonomy.statusCondition.data.find(opt => opt.id === filter).name,
    })),
    ...filters.warranty.map((filter) => ({
      section: 'warranty',
      value: filter,
      label: allTaxonomy.warranty.data.find(opt => opt.id === filter).name,
    })),
    ...filters.shipping.map((filter) => ({
      section: 'shipping',
      value: filter,
      label: allTaxonomy.shipping.data.find(opt => opt.id === filter).name,
    })),
    ...(filters.country ? [{
      section: 'country',
      value: filters.country,
      label: countrySearchField,
    }] : []),
    ...(filters.deviceCategory ? [{
      section: 'deviceCategory',
      value: filters.deviceCategory,
      label: deviceCategorySearchField,
    }] : []),
    ...(filters.oem ? [{
      section: 'oem',
      value: filters.oem,
      label: oemSearchField,
    }] : []),
    ...(filters.yearOfManufacture ? [{
      section: 'yearOfManufacture',
      value: filters.yearOfManufacture,
      label: yearOfManufactureSearchField,
    }] : []),
    ...(filters.clinicalApplications ? [{
      section: 'clinicalApplications',
      value: filters.clinicalApplications,
      label: clinicalApplicationsSearchField,
    }] : []),
    ...(filters.purposeUse ? [{
      section: 'purposeUse',
      value: filters.purposeUse,
      label: purposeUseSearchField,
    }] : []),
    ...(filters.physicalLocation ? [{
      section: 'physicalLocation',
      value: filters.physicalLocation,
      label: physicalLocationSearchField,
    }] : []),
    // ...(filters.searchText ? [{
    //   section: 'search',
    //   value: filters.searchText,
    //   label: `Search: ${filters.searchText}`,
    // }] : []),
  ];

  const visibleFilters = allFilters.slice(0, MAX_VISIBLE_CARDS);
  const hiddenFilters = allFilters.slice(MAX_VISIBLE_CARDS);

  //Clear filters
  const clearFilters = () => {
    setFilters({
      price: [],
      date: [],
      yourRole: [],
      transactionType: [],
      statusCondition: [],
      warranty: [],
      shipping: [],
      country: '',
      deviceCategory: '',
      oem: '',
      yearOfManufacture: '',
      clinicalApplications: '',
      deviceUse: '',
      physicalLocation: '',
    });
    setMinPrice(null);
    setMaxPrice(null);
    setPriceError(null);
    setStartDate(null);
    setEndDate(null);
    setCountrySearchField("");
    setDeviceCategorySearchField("");
    setOemSearchField("");
    setYearOfManufactureSearchField("");
    setClinicalApplicationsSearchField("");
    setPurposeUseSearchField("");
    setPhysicalLocationSearchField("");
    setShowFilterLayout(false);
  };

  //Dropdown filter section collapse
  const toggleCollapse = (section) => {
    setCollapsedSections((prevState) => (
      {
        ...prevState,
        [section]: !prevState[section],
      }));

  };


  // useEffect to unselect dropdown when outside of deviceCategory and oem etc clicked
  useEffect(() => {
    const handleClick = (event) => {
      const countryElement = document.getElementById("filterCountry");
      const deviceCategoryElement = document.getElementById("filterDeviceCategory");
      const oemElement = document.getElementById("filterOem");
      const yearOfManufactureElement = document.getElementById("filterYearOfManufacture");
      const clinicalApplicationsElement = document.getElementById("filterClinicalApplications");
      const purposeUseElement = document.getElementById("filterPurposeUse");
      const physicalLocationElement = document.getElementById("filterPhysicalLocation");

      // if (ulElement.contains(event.target) || deviceCategoryElement.contains(event.target)) {

      if (!countryElement.contains(event.target)) {
        setVisibleCountrySelectField(false)
      }

      if (!deviceCategoryElement.contains(event.target)) {
        setVisibleDeviceCategorySelectField(false)
      }

      if (!oemElement.contains(event.target)) {
        setVisibleOemSelectField(false)
      }

      if (!yearOfManufactureElement.contains(event.target)) {
        setVisibleYearOfManufactureSelectField(false)
      }

      if (!clinicalApplicationsElement.contains(event.target)) {
        setVisibleClinicalApplicationsSelectField(false)
      }

      if (!purposeUseElement.contains(event.target)) {
        setVisiblePurposeUseSelectField(false)
      }

      if (!physicalLocationElement.contains(event.target)) {
        setVisiblePhysicalLocationSelectField(false)
      }



      // const dropdown = document.getElementById('custom-dropdown');
    }

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])










  //Get all Devices
  if (showFilterLayout === false) {
    if (isLoading) {
      content = <Loader />
    } else if (isSuccess) {

    //   if (deviceData.data.length > 0) {
    //     dataLength = deviceData.data.length;
    //     content = deviceData.data.map((device, index) =>
    //     (
    //       <div className="dashboard-device-component-dashboard-content-items" key={index}>
    //         <div className="dashboard-device-component-dashboard-divider-container1">
    //           <div className="dashboard-device-component-dashboard-rectangle-parent5">
    //             <img
    //               className="dashboard-device-component-dashboard-frame-child7"
    //               alt=""
    //               src={device.featureImage}
    //             />

    //             <div className="dashboard-device-component-dashboard-frame-parent1">
    //               <div className="dashboard-device-component-dashboard-action-fields">
    //                 <div className="dashboard-device-component-dashboard-input-elements">
    //                   <Link to={`/device/${device.id}/${device.title}`} className="dashboard-device-component-dashboard-text78">
    //                     {device.title}
    //                   </Link>
    //                   <div className="dashboard-device-component-dashboard-frame-parent21">
    //                     <div className="dashboard-device-component-dashboard-badge-item-parent">
    //                       <div className="dashboard-device-component-dashboard-badge-parent">

    //                         <PostStatusLIstingComponent postStatus={device.tax_post_status.name} postSlug={device.tax_post_status.slug} postId={device.id} />

    //                       </div>
    //                       <div className="dashboard-device-component-dashboard-frame-parent4">
    //                         <div className="dashboard-device-component-dashboard-end-label">
    //                           <div className="dashboard-device-component-dashboard-search-icon-input">4.9/5</div>
    //                         </div>
    //                         <div className="dashboard-device-component-dashboard-type-support">
    //                           <div className="dashboard-device-component-dashboard-supporting-text9">
    //                             99,999 reviews
    //                           </div>
    //                         </div>
    //                       </div>
    //                       <div className="dashboard-device-component-dashboard-badge33">
    //                         <div className="dashboard-device-component-dashboard-card-badge-number">
    //                           {device.tax_device_category.name}
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className="dashboard-device-component-dashboard-frame-parent24">
    //                       <div className="dashboard-device-component-dashboard-location-pin-parent">
    //                         <div className="dashboard-device-component-dashboard-search-input">
    //                           <img
    //                             className="dashboard-device-component-dashboard-marker-pin-02-icon2"
    //                             alt=""
    //                             src={markerpin02}
    //                           />
    //                         </div>
    //                         <div className="dashboard-device-component-dashboard-location3">{device.device_address.addressTownCity}, {device.device_address.tax_country.name}</div>
    //                       </div>
    //                       <div className="dashboard-device-component-dashboard-frame-wrapper18">
    //                         <div className="dashboard-device-component-dashboard-badge-elements-copy">
    //                           <div className="dashboard-device-component-dashboard-badge36">
    //                             <div className="dashboard-device-component-dashboard-badge-number-element">
    //                               11 Health
    //                             </div>
    //                           </div>
    //                           <div className="dashboard-device-component-dashboard-badge37">
    //                             <div className="dashboard-device-component-dashboard-second-badge-count">{device.tax_oem.name}</div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className="dashboard-device-component-dashboard-frame-parent26">
    //                       <div className="dashboard-device-component-dashboard-frame-wrapper19">
    //                         <div className="dashboard-device-component-dashboard-legend-series">
    //                           <div className="dashboard-device-component-dashboard-search-input">
    //                             <img
    //                               className="dashboard-device-component-dashboard-marker-pin-02-icon2"
    //                               alt=""
    //                               src={calendar}
    //                             />
    //                           </div>
    //                           <div className="dashboard-device-component-dashboard-type-of-work2">{getCustomizedDate(device.createdAt)}</div>
    //                         </div>
    //                       </div>
    //                       <div className="dashboard-device-component-dashboard-badge38">
    //                         <div className="dashboard-device-component-dashboard-third-badge-number">
    //                           {device.tax_clinical_application.name}
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className="dashboard-device-component-dashboard-badge39">
    //                       <div className="dashboard-device-component-dashboard-location-badge-elements">
    //                         Accounting
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="dashboard-device-component-dashboard-filter-elements">
    //                 <div className="dashboard-device-component-dashboard-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
    //                   <img
    //                     className="dashboard-device-component-dashboard-dots-vertical-icon"
    //                     alt=""
    //                     src={dotsvertical}
    //                   />
    //                   {activeThreeDotId === index && <ThreeDotsListingComponent url={`#`} />}
    //                 </div>
    //                 <div className="dashboard-device-component-dashboard-filter-elements-inner">
    //                   <div className="dashboard-device-component-dashboard-price-parent">
    //                     <TransactionTypeComponent transactionType={device.tax_transaction_type.name} slug={device.tax_transaction_type.slug} />
    //                     <div className="dashboard-device-component-dashboard-price2">${device.price}</div>
    //                     <div className="dashboard-device-component-dashboard-dropdown-elements">
    //                       <div className="dashboard-device-component-dashboard-dropdown-options-parent">
    //                         <div className="dashboard-device-component-dashboard-text83">{device.tax_unit_measure_device_spare.name}</div>
    //                         <div className="dashboard-device-component-dashboard-frame-wrapper3">
    //                           <div className="dashboard-device-component-dashboard-radar-1-1-container">
    //                             {/* <img
    //                     className="dashboard-device-component-dashboard-magnifier-1-icon"
    //                     alt=""
    //                     src={radar_1_1_2x}
    //                   /> */}
    //                             {!device.isAuthor && (
    //                               device.addedToRadar ? (
    //                                 <img
    //                                   className="dashboard-device-component-dashboard-magnifier-1-icon"
    //                                   alt=""
    //                                   onClick={() => handleRemoveRadar(device.id)}
    //                                   src={target_04}
    //                                 />
    //                               ) : (
    //                                 <img
    //                                   className="dashboard-device-component-dashboard-magnifier-1-icon"
    //                                   alt=""
    //                                   onClick={() => handleAddRadar(device.id)}
    //                                   src={radar_1_1_2x}
    //                                 />
    //                               )
    //                             )}
    //                             <img
    //                               className="dashboard-device-component-dashboard-magnifier-1-icon"
    //                               alt=""
    //                               src={deal_2_1_2x}
    //                             />

    //                             <img
    //                               className="dashboard-device-component-dashboard-magnifier-1-icon"
    //                               alt=""
    //                               src={magnifier_1_2x}
    //                             />
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <img className="dashboard-device-component-dashboard-divider-icon8" alt="" src={divider2} />

    //           <div className="dashboard-device-component-dashboard-frame-parent71">
    //             <div className="dashboard-device-component-dashboard-buttonsbutton-parent1">
    //               <div className="dashboard-legend-series">
    //                 <img
    //                   className="dashboard-location-column-child"
    //                   alt=""
    //                   src={group_58_2x}
    //                 />

    //                 <div className="dashboard-location-column-inner">
    //                   <div className="dashboard-date-picker-dropdown">
    //                     <div className="dashboard-name">{device.device_business.business_user.displayName}</div>
    //                     {/* <div className="dashboard-role">{device.device_business.businessName}</div> */}
    //                     <Link className="detailed-role" to={`/business/${device.device_business.id}/${device.device_business.businessName}`}>
    //                       {device.device_business.businessName}
    //                     </Link>
    //                   </div>
    //                 </div>
    //               </div>
    //               {/* <div className='dashboard-device-component-main-buttons-group'>
    //       <div className="dashboard-device-component-dashboard-buttonsbutton13">
    //         <img
    //           className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
    //           alt=""
    //           src={eye}
    //         />

    //         <div className="dashboard-device-component-dashboard-search-input">
    //           <div className="dashboard-device-component-dashboard-text67">View</div>
    //         </div>
    //       </div>
    //       <div className="dashboard-device-component-dashboard-buttonsbutton25">
    //         <div className="dashboard-device-component-dashboard-icon-wrapper">
    //           <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
    //         </div>
    //         <div className="dashboard-device-component-dashboard-search-input">
    //           <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
    //         </div>
    //         <img
    //           className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
    //           alt=""
    //           src={lock04}
    //         />
    //       </div>
    //       <div className="dashboard-device-component-dashboard-buttonsbutton26">
    //         <div className="dashboard-device-component-dashboard-icon-wrapper">
    //           <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
    //         </div>
    //         <div className="dashboard-device-component-dashboard-search-input">
    //           <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
    //         </div>
    //         <img
    //           className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
    //           alt=""
    //           src={lockunlocked04}
    //         />
    //       </div>
    //       <div className="dashboard-device-component-dashboard-buttonsbutton27">
    //         <img
    //           className="dashboard-device-component-dashboard-upload-04-icon"
    //           alt=""
    //           src={messagechatsquare}
    //         />

    //         <div className="dashboard-device-component-dashboard-text145">Messages</div>
    //         <div className="dashboard-device-component-dashboard-ellipse-parent">
    //           <div className="dashboard-device-component-dashboard-ellipse-div"></div>
    //           <div className="dashboard-device-component-dashboard-div">2</div>
    //         </div>
    //       </div>
    //     </div> */}

    //             </div>
    //             <div className="dashboard-device-component-dashboard-arrow-square-up-right-wrapper1">
    //               <img
    //                 className="dashboard-device-component-dashboard-arrow-square-up-right-icon1"
    //                 alt=""
    //                 src={arrowsquareupright}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //     ))
    //   }
    //   else {
    //     content = (

    //       <NoListingFound path="/add-device" />

    //     )
    //   }

    } else if (isError) {
      content = <div style={{ color: "white" }}>{error.originalStatus + " " + error.data}</div>
    }
  }



  //Get filtered all Devices
  if (showFilterLayout === true) {
    if (isFilterDeviceLoading) {
      content = <Loader />
    } else if (isFilterDeviceSuccess) {
      console.log(filterDeviceData);

      if (filterDeviceData.length > 0) {
        dataLength = filterDeviceData.length;
        content = filterDeviceData.map((device, index) =>
        (
          <div className="dashboard-device-component-dashboard-content-items" key={index}>
            <div className="dashboard-device-component-dashboard-divider-container1">
              <div className="dashboard-device-component-dashboard-rectangle-parent5">
                <img
                  className="dashboard-device-component-dashboard-frame-child7"
                  alt=""
                  src={device.featureImage}
                />

                <div className="dashboard-device-component-dashboard-frame-parent1">
                  <div className="dashboard-device-component-dashboard-action-fields">
                    <div className="dashboard-device-component-dashboard-input-elements">
                      <Link to={`/device/${device.id}/${device.title}`} className="dashboard-device-component-dashboard-text78">
                        {device.title}
                      </Link>
                      <div className="dashboard-device-component-dashboard-frame-parent21">
                        <div className="dashboard-device-component-dashboard-badge-item-parent">
                          <div className="dashboard-device-component-dashboard-badge-parent">

                            <PostStatusLIstingComponent postStatus={device.tax_post_status.name} postSlug={device.tax_post_status.slug} postId={device.id} />

                          </div>
                          <div className="dashboard-device-component-dashboard-frame-parent4">
                            <div className="dashboard-device-component-dashboard-end-label">
                              <div className="dashboard-device-component-dashboard-search-icon-input">4.9/5</div>
                            </div>
                            <div className="dashboard-device-component-dashboard-type-support">
                              <div className="dashboard-device-component-dashboard-supporting-text9">
                                99,999 reviews
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-device-component-dashboard-badge33">
                            <div className="dashboard-device-component-dashboard-card-badge-number">
                              {device.tax_device_category.name}
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-device-component-dashboard-frame-parent24">
                          <div className="dashboard-device-component-dashboard-location-pin-parent">
                            <div className="dashboard-device-component-dashboard-search-input">
                              <img
                                className="dashboard-device-component-dashboard-marker-pin-02-icon2"
                                alt=""
                                src={markerpin02}
                              />
                            </div>
                            <div className="dashboard-device-component-dashboard-location3">{device.device_address.addressTownCity}, {device.device_address.tax_country.name}</div>
                          </div>
                          <div className="dashboard-device-component-dashboard-frame-wrapper18">
                            <div className="dashboard-device-component-dashboard-badge-elements-copy">
                              <div className="dashboard-device-component-dashboard-badge36">
                                <div className="dashboard-device-component-dashboard-badge-number-element">
                                  11 Health
                                </div>
                              </div>
                              <div className="dashboard-device-component-dashboard-badge37">
                                <div className="dashboard-device-component-dashboard-second-badge-count">{device.tax_oem.name}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-device-component-dashboard-frame-parent26">
                          <div className="dashboard-device-component-dashboard-frame-wrapper19">
                            <div className="dashboard-device-component-dashboard-legend-series">
                              <div className="dashboard-device-component-dashboard-search-input">
                                <img
                                  className="dashboard-device-component-dashboard-marker-pin-02-icon2"
                                  alt=""
                                  src={calendar}
                                />
                              </div>
                              <div className="dashboard-device-component-dashboard-type-of-work2">{getCustomizedDate(device.createdAt)}</div>
                            </div>
                          </div>
                          <div className="dashboard-device-component-dashboard-badge38">
                            <div className="dashboard-device-component-dashboard-third-badge-number">
                              {device.tax_clinical_application.name}
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-device-component-dashboard-badge39">
                          <div className="dashboard-device-component-dashboard-location-badge-elements">
                            Accounting
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-device-component-dashboard-filter-elements">
                    <div className="dashboard-device-component-dashboard-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                      <img
                        className="dashboard-device-component-dashboard-dots-vertical-icon"
                        alt=""
                        src={dotsvertical}
                      />
                      {activeThreeDotId === index && <ThreeDotsListingComponent url={`#`} />}
                    </div>
                    <div className="dashboard-device-component-dashboard-filter-elements-inner">
                      <div className="dashboard-device-component-dashboard-price-parent">
                        <TransactionTypeComponent transactionType={device.tax_transaction_type.name} slug={device.tax_transaction_type.slug} />
                        <div className="dashboard-device-component-dashboard-price2">${device.price}</div>
                        <div className="dashboard-device-component-dashboard-dropdown-elements">
                          <div className="dashboard-device-component-dashboard-dropdown-options-parent">
                            <div className="dashboard-device-component-dashboard-text83">{device.tax_unit_measure_device_spare.name}</div>
                            <div className="dashboard-device-component-dashboard-frame-wrapper3">
                              <div className="dashboard-device-component-dashboard-radar-1-1-container">
                                {/* <img
                        className="dashboard-device-component-dashboard-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      /> */}
                                {!device.isAuthor && (
                                  device.addedToRadar ? (
                                    <img
                                      className="dashboard-device-component-dashboard-magnifier-1-icon"
                                      alt=""
                                      onClick={() => handleRemoveRadar(device.id)}
                                      src={target_04}
                                    />
                                  ) : (
                                    <img
                                      className="dashboard-device-component-dashboard-magnifier-1-icon"
                                      alt=""
                                      onClick={() => handleAddRadar(device.id)}
                                      src={radar_1_1_2x}
                                    />
                                  )
                                )}
                                <img
                                  className="dashboard-device-component-dashboard-magnifier-1-icon"
                                  alt=""
                                  src={deal_2_1_2x}
                                />

                                <img
                                  className="dashboard-device-component-dashboard-magnifier-1-icon"
                                  alt=""
                                  src={magnifier_1_2x}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="dashboard-device-component-dashboard-divider-icon8" alt="" src={divider2} />

              <div className="dashboard-device-component-dashboard-frame-parent71">
                <div className="dashboard-device-component-dashboard-buttonsbutton-parent1">
                  <div className="dashboard-legend-series">
                    <img
                      className="dashboard-location-column-child"
                      alt=""
                      src={group_58_2x}
                    />

                    <div className="dashboard-location-column-inner">
                      <div className="dashboard-date-picker-dropdown">
                        <div className="dashboard-name">{device.device_business.business_user.displayName}</div>
                        <div className="dashboard-role">{device.device_business.businessName}</div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='dashboard-device-component-main-buttons-group'>
          <div className="dashboard-device-component-dashboard-buttonsbutton13">
            <img
              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
              alt=""
              src={eye}
            />

            <div className="dashboard-device-component-dashboard-search-input">
              <div className="dashboard-device-component-dashboard-text67">View</div>
            </div>
          </div>
          <div className="dashboard-device-component-dashboard-buttonsbutton25">
            <div className="dashboard-device-component-dashboard-icon-wrapper">
              <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
            </div>
            <div className="dashboard-device-component-dashboard-search-input">
              <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
            </div>
            <img
              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
              alt=""
              src={lock04}
            />
          </div>
          <div className="dashboard-device-component-dashboard-buttonsbutton26">
            <div className="dashboard-device-component-dashboard-icon-wrapper">
              <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
            </div>
            <div className="dashboard-device-component-dashboard-search-input">
              <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
            </div>
            <img
              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
              alt=""
              src={lockunlocked04}
            />
          </div>
          <div className="dashboard-device-component-dashboard-buttonsbutton27">
            <img
              className="dashboard-device-component-dashboard-upload-04-icon"
              alt=""
              src={messagechatsquare}
            />

            <div className="dashboard-device-component-dashboard-text145">Messages</div>
            <div className="dashboard-device-component-dashboard-ellipse-parent">
              <div className="dashboard-device-component-dashboard-ellipse-div"></div>
              <div className="dashboard-device-component-dashboard-div">2</div>
            </div>
          </div>
        </div> */}

                </div>
                <div className="dashboard-device-component-dashboard-arrow-square-up-right-wrapper1">
                  <img
                    className="dashboard-device-component-dashboard-arrow-square-up-right-icon1"
                    alt=""
                    src={arrowsquareupright}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      }
      else {
        content = (
          <NoListingFoundForFilter clearFilters={clearFilters} />
        )
      }
    } else if (isFilterDeviceError) {
      content = <div style={{ color: "white" }}>{filterDeviceError.originalStatus + " " + filterDeviceError.data}</div>
    }
  }






  return (
    <>
      <Helmet>
        <title>Archive - Wanted Devices</title>
      </Helmet>
      <div className="archive-wanted-device1-listings-search-results-list">

        <Navbar1 userData={currentUser} navigate={navigate} />
        <div className='archive-wanted-device1-mid-container'>


          <div className="archive-wanted-device1-slide-out">
            <img className="archive-wanted-device1-divider-icon14" alt="" src={divider12} />


            <div className="archive-wanted-device-rectangle-parent4">
              <div className="archive-wanted-device-frame-child8"></div>
              <div className="archive-wanted-device-frame-child9"></div>
              <img className="archive-wanted-device-dot-icon7" alt="" src={dot} />

              <img className="archive-wanted-device-dot-icon8" alt="" src={dot} />

              <img className="archive-wanted-device-dot-icon9" alt="" src={dot} />

              <img className="archive-wanted-device-dot-icon10" alt="" src={dot} />

              <img className="archive-wanted-device-dot-icon11" alt="" src={dot} />

              <img className="archive-wanted-device-dot-icon12" alt="" src={dot} />

              <img className="archive-wanted-device-dot-icon13" alt="" src={dot} />

              <div className="archive-wanted-device-frame-parent-28">
                <div className="archive-wanted-device-slide-out-menu-header-parent">
                  <div className="archive-wanted-device-slide-out-menu-header">
                    <div className="archive-wanted-device-content24">
                      <img
                        className="archive-wanted-device-featured-icon1"
                        alt=""
                        src={featured_icon1}
                      />

                      <div className="archive-wanted-device-text-and-supporting-text6">
                        <a className="archive-wanted-device-text184">Filters</a>
                        <div className="archive-wanted-device-supporting-text57">
                          Apply filters to table data.
                        </div>
                      </div>
                    </div>
                    <div className="archive-wanted-device-button-close-x">
                      <img
                        className="archive-wanted-device-search-lg-icon"
                        alt=""
                        src={xclose}
                      />
                    </div>
                  </div>
                  <div className="archive-wanted-device-frame-wrapper13" onClick={() => toggleCollapse('price')} style={{ cursor: 'pointer' }}>
                    <div className="archive-wanted-device-slide-out-title-parent">
                      <a className="archive-wanted-device-slide-out-title">Price</a>
                      <div className="archive-wanted-device-navigation">
                        <img

                          className="archive-wanted-device-marker-pin-02-icon2"
                          alt=""
                          src={chevrondown}

                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-device-frame-parent29">
                  <div className="archive-wanted-device-frame-parent30"

                    style={{
                      maxHeight: collapsedSections.price ? '0px' : '500px',
                      overflow: 'hidden',
                      transition: 'max-height 0.3s ease-in-out',
                    }}
                  >
                    <div className="archive-wanted-device-frame-wrapper14">
                      <div className="archive-wanted-device-frame-parent-31">
                        <div className="archive-wanted-device-input-fields-parent" >
                          <div className="archive-wanted-device-input-fields">
                            <div className="archive-wanted-device-input-field">
                              <div className="archive-wanted-device-input-with-label4">
                                <input
                                  type="text"
                                  className="archive-wanted-device-input4"
                                  name='minPrice'
                                  value={minPrice ?? ''}
                                  placeholder='Min Value'
                                  style={{ width: "50%" }}
                                  onChange={handlePrice}
                                />

                              </div>

                            </div>
                            <div className="archive-wanted-device-text188">–</div>
                            <div className="archive-wanted-device-input-field">
                              <div className="archive-wanted-device-input-with-label4" >

                                <input
                                  type="text"
                                  className="archive-wanted-device-input4"
                                  name='maxPrice'
                                  value={maxPrice ?? ''}
                                  placeholder='Max Value'
                                  style={{ width: "50%" }}
                                  onChange={handlePrice}
                                />
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      {
                        (priceError && <div className="archive-wanted-device-error-text">
                          {priceError}.
                        </div>)
                      }
                      {/*  */}
                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom"  >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-section">
                        <div className="archive-wanted-device-content-parent" onClick={() => toggleCollapse('datePosted')} style={{ cursor: 'pointer' }}>
                          <div className="archive-wanted-device-content29">
                            <img className="archive-wanted-device-dot-icon" alt="" src={dot} />

                            <div className="archive-wanted-device-text194">Date Posted</div>
                          </div>
                          <img

                            className="archive-wanted-device-search-lg-icon"
                            alt=""
                            src={chevrondown}

                          />
                        </div>
                      </div>
                      <div className="archive-wanted-device-input-fields"

                        style={{
                          maxHeight: collapsedSections.datePosted ? '0px' : '500px',
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out',
                        }}
                      >
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4">
                            <DatePicker
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              selectsStart
                              maxDate={today}
                              startDate={startDate}
                              endDate={endDate}
                              placeholderText="Start Date"
                              className="custom-datepicker"
                            />
                          </div>

                        </div>
                        <div className="archive-wanted-device-text188">–</div>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4" >

                            <DatePicker
                              selected={endDate}
                              onChange={date => setEndDate(date)}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              maxDate={today}
                              placeholderText=" End Date"
                              className="custom-datepicker"
                            />

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />




                  <div className="archive-wanted-device-section-wrapper"   >
                    <div className="archive-wanted-device-section1">
                      <div className="archive-wanted-device-content-parent" onClick={() => toggleCollapse('yourRole')} style={{ cursor: 'pointer' }}  >
                        <div className="archive-wanted-device-content29">
                          <img className="archive-wanted-device-dot-icon" alt="" src={dot} />

                          <div className="archive-wanted-device-text198">Seller Role</div>
                        </div>
                        <img

                          className="archive-wanted-device-search-lg-icon"
                          alt=""
                          // src={`/archive_page/${isCollapsed ? 'chevronup.svg' : 'chevrondown.svg'}`}
                          src={chevrondown}

                        />
                      </div>



                      <div className="archive-wanted-device-checkboxes1"

                        style={{
                          maxHeight: collapsedSections.yourRole ? '0px' : '500px',
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out',
                        }}
                      >
                        {(allTaxonomy.yourRole.isLoading || allTaxonomy.yourRole.isError) && <p >Loading...</p>}
                        {
                          (allTaxonomy.yourRole.isSuccess) && (allTaxonomy.yourRole.data.map(tax => (
                            <div className="archive-wanted-device-collapsed-names" key={tax.id}>
                              <input
                                className="archive-wanted-device-input6"
                                type="checkbox"
                                checked={filters.yourRole.includes(tax.id)}
                                onChange={() => handleCheckboxChange('yourRole', tax.id)}
                                value={tax.id}
                              />
                              <div className="archive-wanted-device-price-parent">
                                <div className="archive-wanted-device-text8">{tax.name}</div>
                              </div>
                            </div>
                          )))
                        }
                      </div>


                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-section-wrapper" >
                    <div className="archive-wanted-device-section1">
                      <div className="archive-wanted-device-content-parent" onClick={() => toggleCollapse('transactionType')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-content29">
                          <img className="archive-wanted-device-dot-icon" alt="" src={dot} />

                          <div className="archive-wanted-device-text203">Transaction Type</div>
                        </div>
                        <img

                          className="archive-wanted-device-search-lg-icon1"
                          alt=""
                          src={chevrondown}

                        />
                      </div>

                      <div className="archive-wanted-device-checkboxes1"

                        style={{
                          maxHeight: collapsedSections.transactionType ? '0px' : '500px',
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out',
                        }}
                      >
                        {(allTaxonomy.transactionType.isLoading || allTaxonomy.transactionType.isError) && <p >Loading...</p>}
                        {
                          (allTaxonomy.transactionType.isSuccess) && (allTaxonomy.transactionType.data.map(tax => (
                            <div className="archive-wanted-device-collapsed-names" key={tax.id}>
                              <input
                                className="archive-wanted-device-input6"
                                type="checkbox"
                                checked={filters.transactionType.includes(tax.id)}
                                onChange={() => handleCheckboxChange('transactionType', tax.id)}
                                value={tax.id}
                              />
                              <div className="archive-wanted-device-price-parent">
                                <div className="archive-wanted-device-text8">{tax.name}</div>
                              </div>
                            </div>
                          )))
                        }
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-section-wrapper" >
                    <div className="archive-wanted-device-section1">
                      <div className="archive-wanted-device-content-parent" onClick={() => toggleCollapse('statusCondition')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-content29">
                          <img className="archive-wanted-device-dot-icon" alt="" src={dot} />

                          <div className="archive-wanted-device-text203">Status</div>
                        </div>
                        <img


                          className="archive-wanted-device-search-lg-icon"
                          alt=""
                          src={chevrondown}

                        />
                      </div>
                      <div className="archive-wanted-device-checkboxes1"

                        style={{
                          maxHeight: collapsedSections.statusCondition ? '0px' : '500px',
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out',
                        }}
                      >
                        {(allTaxonomy.statusCondition.isLoading || allTaxonomy.statusCondition.isError) && <p >Loading...</p>}
                        {
                          (allTaxonomy.statusCondition.isSuccess) && (allTaxonomy.statusCondition.data.map(tax => (
                            <div className="archive-wanted-device-collapsed-names" key={tax.id}>
                              <input
                                className="archive-wanted-device-input6"
                                type="checkbox"
                                checked={filters.statusCondition.includes(tax.id)}
                                onChange={() => handleCheckboxChange('statusCondition', tax.id)}
                                value={tax.id}
                              />
                              <div className="archive-wanted-device-price-parent">
                                <div className="archive-wanted-device-text8">{tax.name}</div>
                              </div>
                            </div>
                          )))
                        }
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-section-wrapper"  >
                    <div className="archive-wanted-device-section1">
                      <div className="archive-wanted-device-content-parent" onClick={() => toggleCollapse('warranty')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-content29">
                          <img className="archive-wanted-device-dot-icon" alt="" src={dot} />

                          <div className="archive-wanted-device-text203">Warranty</div>
                        </div>
                        <img

                          className="archive-wanted-device-search-lg-icon"
                          alt=""
                          src={chevrondown}

                        />
                      </div>
                      <div className="archive-wanted-device-checkboxes1"

                        style={{
                          maxHeight: collapsedSections.warranty ? '0px' : '500px',
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out',
                        }}
                      >
                        {(allTaxonomy.warranty.isLoading || allTaxonomy.warranty.isError) && <p >Loading...</p>}
                        {
                          (allTaxonomy.warranty.isSuccess) && (allTaxonomy.warranty.data.map(tax => (
                            <div className="archive-wanted-device-collapsed-names" key={tax.id}>
                              <input
                                className="archive-wanted-device-input6"
                                type="checkbox"
                                checked={filters.warranty.includes(tax.id)}
                                onChange={() => handleCheckboxChange('warranty', tax.id)}
                                value={tax.id}
                              />
                              <div className="archive-wanted-device-price-parent">
                                <div className="archive-wanted-device-text8">{tax.name}</div>
                              </div>
                            </div>
                          )))
                        }
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-section-wrapper" >
                    <div className="archive-wanted-device-section1">
                      <div className="archive-wanted-device-content-parent" onClick={() => toggleCollapse('shipping')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-content29">
                          <img className="archive-wanted-device-dot-icon" alt="" src={dot} />

                          <div className="archive-wanted-device-text203">Shipping</div>
                        </div>
                        <img

                          className="archive-wanted-device-search-lg-icon"
                          alt=""
                          src={chevrondown}

                        />
                      </div>
                      <div className="archive-wanted-device-checkboxes1"

                        style={{
                          maxHeight: collapsedSections.shipping ? '0px' : '500px',
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out',
                        }}
                      >
                        {(allTaxonomy.shipping.isLoading || allTaxonomy.shipping.isError) && <p >Loading...</p>}
                        {
                          (allTaxonomy.shipping.isSuccess) && (allTaxonomy.shipping.data.map(tax => (
                            <div className="archive-wanted-device-collapsed-names" key={tax.id}>
                              <input
                                className="archive-wanted-device-input6"
                                type="checkbox"
                                checked={filters.shipping.includes(tax.id)}
                                onChange={() => handleCheckboxChange('shipping', tax.id)}
                                value={tax.id}
                              />
                              <div className="archive-wanted-device-price-parent">
                                <div className="archive-wanted-device-text8">{tax.name}</div>
                              </div>
                            </div>
                          )))
                        }
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-wanted-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-section-wrapper" >
                    <div className="archive-wanted-device-section-parent"  >
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('country')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">

                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210" >Country</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterCountry'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.country ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}
                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                id='country'
                                name='country'
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                autoComplete="off"
                                // value={filters.searchFields.country}
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={countrySearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id="countryArchiveSelect">
                          {visibleCountrySelectField &&
                            <ul ref={ulCountryRef}>
                              {(allTaxonomy.country.isLoading || allTaxonomy.country.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.country.isSuccess) && (allTaxonomy.country.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'country')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }} >
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom"  >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('deviceCategory')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">
                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210">Device Category</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterDeviceCategory'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.deviceCategory ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}

                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                id="deviceCategory"
                                name="deviceCategory"
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                // value={filters.searchFields.deviceCategory}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={deviceCategorySearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id="deviceCategoryArchiveSelect">
                          {visibleDeviceCategorySelectField &&
                            <ul ref={ulDeviceCategoryRef}>
                              {(allTaxonomy.deviceCategory.isLoading || allTaxonomy.deviceCategory.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.deviceCategory.isSuccess) && (allTaxonomy.deviceCategory.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'deviceCategory')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }} >
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom" >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('oem')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">
                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210">OEM Brand</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterOem'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.oem ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}

                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                id="oem"
                                name="oem"
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                // value={filters.searchFields.oem}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={oemSearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id="oemArchiveSelect">
                          {visibleOemSelectField &&
                            <ul ref={ulOemRef}>
                              {(allTaxonomy.oem.isLoading || allTaxonomy.oem.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.oem.isSuccess) && (allTaxonomy.oem.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'oem')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }} >
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom" >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('yearOfManufacture')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">
                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210">Year of Manufacture</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterYearOfManufacture'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.yearOfManufacture ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}

                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                id="yearOfManufacture"
                                name="yearOfManufacture"
                                // value={filters.searchFields.yearOfManufacture}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={yearOfManufactureSearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id='yearOfManufactureArchiveSelect'>
                          {visibleYearOfManufactureSelectField &&
                            <ul ref={ulYearOfManufactureRef}>
                              {(allTaxonomy.yearOfManufacture.isLoading || allTaxonomy.yearOfManufacture.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.yearOfManufacture.isSuccess) && (allTaxonomy.yearOfManufacture.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'yearOfManufacture')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }}>
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom"  >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('clinicalApplications')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">
                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210">Clinical Applications</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterClinicalApplications'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.clinicalApplications ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}

                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                id="clinicalApplications"
                                name="clinicalApplications"
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                // value={filters.searchFields.clinicalApplications}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={clinicalApplicationsSearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id="clinicalApplicationsArchiveSelect">
                          {visibleClinicalApplicationsSelectField &&
                            <ul ref={ulClinicalApplicationsRef}>
                              {(allTaxonomy.clinicalApplications.isLoading || allTaxonomy.clinicalApplications.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.clinicalApplications.isSuccess) && (allTaxonomy.clinicalApplications.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'clinicalApplications')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }}  >
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom" >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('deviceUse')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">
                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210">Device Use</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterPurposeUse'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.deviceUse ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}
                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                id="purposeUse"
                                name="purposeUse"
                                // value={filters.searchFields.deviceUse}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={purposeUseSearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id="purposeUseArchiveSelect">
                          {visiblePurposeUseSelectField &&
                            <ul ref={ulPurposeUseRef}>
                              {(allTaxonomy.purposeUse.isLoading || allTaxonomy.purposeUse.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.purposeUse.isSuccess) && (allTaxonomy.purposeUse.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'purposeUse')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }} >
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                  <div className="archive-wanted-device-section-wrapper archive-wanted-device-section-wrapper-custom"  >
                    <div className="archive-wanted-device-section-parent">
                      <div className="archive-wanted-device-actions6" onClick={() => toggleCollapse('physicalLocation')} style={{ cursor: 'pointer' }}>
                        <div className="archive-wanted-device-navigation">
                          <img

                            className="archive-wanted-device-search-lg"
                            src={searchlg}
                            alt=''

                          />
                        </div>
                        <div className="archive-wanted-device-text210">Physical Location</div>
                      </div>
                      <div className="archive-wanted-device-input-fields2 archive-wanted-device-input-fields2-custom" id='filterPhysicalLocation'>
                        <div className="archive-wanted-device-input-field2">
                          <div className="archive-wanted-device-input-with-label4"

                            style={{
                              maxHeight: collapsedSections.physicalLocation ? '0px' : '500px',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease-in-out',
                            }}
                          >
                            <div className="archive-wanted-device-input19">
                              <input
                                id="physicalLocation"
                                name="physicalLocation"
                                className="archive-wanted-device-content37"
                                placeholder="Search"
                                type="text"
                                // value={filters.searchFields.physicalLocation}
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                onClick={handleClick}
                                value={physicalLocationSearchField}
                              />

                              <img
                                className="archive-wanted-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          {/* <div className="archive-wanted-device-hint-text">
                          This is a hint text to help user.
                        </div> */}
                        </div>
                        <div id="physicalLocationArchiveSelect">
                          {visiblePhysicalLocationSelectField &&
                            <ul ref={ulPhysicalLocationRef}>
                              {(allTaxonomy.physicalLocation.isLoading || allTaxonomy.physicalLocation.isError) && <li value="0">Loading...</li>}
                              {
                                (allTaxonomy.physicalLocation.isSuccess) && (allTaxonomy.physicalLocation.data.map(tax => (
                                  <li key={tax.id} onClick={() => handleSelect(tax, 'physicalLocation')}>
                                    {tax.name}
                                  </li>
                                )))
                              }
                            </ul>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-device-second-badge-divider" style={{ zIndex: "0" }}>


                    <img
                      className="archive-wanted-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>

                </div>
              </div>

              <div className="archive-wanted-device-frame-parent41">
                <div className="archive-wanted-device-divider-parent6">
                  <img
                    className="archive-wanted-device-divider-icon35"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-wanted-device-footer1">
                    <div className="archive-wanted-device-content38">
                      <div className="archive-wanted-device-logo1">
                        <div className="archive-wanted-device-buttonsbutton37">
                          <img
                            className="archive-wanted-device-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />

                          <div className="archive-wanted-device-text217">Save filter</div>
                          <img
                            className="archive-wanted-device-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />
                        </div>
                      </div>
                      <div className="archive-wanted-device-actions9">
                        <button className="archive-wanted-device-buttonsbutton38">
                          <img
                            className="archive-wanted-device-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />

                          <div className="archive-wanted-device-text-padding">
                            <div className="archive-wanted-device-text218">Cancel</div>
                          </div>
                          <img
                            className="archive-wanted-device-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />
                        </button>
                        <button className="archive-wanted-device-buttonsbutton39" onClick={applyFilter}>
                          <img
                            className="archive-wanted-device-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />

                          <div className="archive-wanted-device-text-padding" >
                            <div className="archive-wanted-device-text219">Apply</div>
                          </div>
                          <img
                            className="archive-wanted-device-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-device-dot-item-innermost">
                  <div className="archive-wanted-device-collapsed-content">
                    <img
                      className="archive-wanted-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-wanted-device-divider-icon37"
                      alt=""
                      src={divider21}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="archive-wanted-device1-filters">
            <div className='archive-wanted-device1-filter-selected-div'>
              <div className="archive-wanted-device1-main">
                <div className="archive-wanted-device1-header-section">
                  <div className="archive-wanted-device1-container">
                    <div className="archive-wanted-device1-page-header">
                      <div className="archive-wanted-device1-breadcrumbs">
                        <div className="archive-wanted-device1-tabs">
                          <div className="archive-wanted-device1-breadcrumb-button-base">
                            <img
                              className="archive-wanted-device1-search-lg-icon"
                              alt=""
                              src={homeline}
                            />
                          </div>
                          <img
                            className="archive-wanted-device1-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-wanted-device1-breadcrumb-button-base1">
                            <a className="archive-wanted-device1-text7">Dashboard</a>
                          </div>
                          <img
                            className="archive-wanted-device1-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-wanted-device1-breadcrumb-button-base2">
                            <div className="archive-wanted-device1-text8">...</div>
                          </div>
                          <img
                            className="archive-wanted-device1-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-wanted-device1-breadcrumb-button-base3">
                            <div className="archive-wanted-device1-text9">Another link</div>
                          </div>
                          <img
                            className="archive-wanted-device1-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-wanted-device1-breadcrumb-button-base4">
                            <a className="archive-wanted-device1-text10">Category</a>
                          </div>
                        </div>
                      </div>
                      <img className="archive-wanted-device1-divider-icon" alt="" src={divider} />
                    </div>
                  </div>
                </div>
               
              </div>
              <div className="archive-wanted-device1-secondary-content">
                <div className="archive-wanted-device1-page-header1">
                  
                  <div className="archive-wanted-device1-content4">
                    <div className="archive-wanted-device1-text-and-supporting-text">
                      <div className="archive-wanted-device1-page-title">{dataLength} Listings Found</div>
                      <div className="archive-wanted-device1-supporting-text1">
                          You searched based on the following criteria.
                      </div>

                     
                   
                    </div>

                    <button className="archive-wanted-device1-actions">
             
             <div className="archive-wanted-device1-button2">
               <img
                 className="archive-wanted-device1-sliders-02-icon"
                 alt=""
                 src={filterlines}
               />

               <div className="archive-wanted-device1-text-padding">
                 <div className="archive-wanted-device1-filter-label">Save Filter</div>
               </div>
               
             </div>
           </button>
                  
                  </div>
             

                    
            
                </div>
              </div>
             
             

             

             
              <div className="archive-wanted-device-filter-cards-container">

                {visibleFilters.map((filter) => (
                  <div className="archive-wanted-device-buttons-button7" key={`${filter.section}-${filter.value}`}>
                    <a className="archive-wanted-device-text47">{filter.label}</a>
                    <img
                      className="archive-wanted-device-sliders-02-icon"
                      alt="Remove filter"
                      src={xclose}
                      onClick={() => removeFilterCard(filter.section, filter.value)}
                    />
                  </div>
                ))}


                {showMoreFilters && hiddenFilters.map((filter) => (
                  <div className="archive-wanted-device-buttons-button7" key={`${filter.section}-${filter.value}`}>
                    <a className="archive-wanted-device-text47">{filter.label}</a>
                    <img
                      className="archive-wanted-device-sliders-02-icon"
                      alt="Remove filter"
                      src={xclose}
                      onClick={() => removeFilterCard(filter.section, filter.value)}
                    />
                  </div>
                ))}

              </div>

              {hiddenFilters.length > 0 && (

                <div className='archive-wanted-device1-hiddenbutton-container'>
                <button className="archive-wanted-device1-buttonsbutton17 archive-wanted-device-buttonsbutton171-custom" onClick={() => setShowMoreFilters(!showMoreFilters)}>
                  <img className="archive-wanted-device-sliders-02-icon" alt="" src={filterlines} />

                  <div className="archive-wanted-device-text57">  {showMoreFilters ? 'Show Less' : 'More Filters'}</div>

                  {/* <img className="archive-wanted-device-placeholder-icon" alt="" src={placeholder} /> */}
                </button>
                </div>
              )}

              <br />

              <div className='archive-wanted-device1-button-container'>
              <button className="archive-wanted-device1-buttonsbutton18 archive-wanted-device1-buttonsbutton18-custom" onClick={applyFilter}>
                <img
                className="archive-wanted-device1-sliders-02-icon"
                alt=""
                src={filterfunnel01}
              />

                <div className="archive-wanted-device1-text58">Apply Filter</div>
                <img className="archive-wanted-device1-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-wanted-device1-buttonsbutton18" onClick={clearFilters}>
                <img
                  className="archive-wanted-device1-sliders-02-icon"
                  alt=""
                  src={filterfunnel01}
                />

                <div className="archive-wanted-device1-text58">Clear Filter</div>
                <img className="archive-wanted-device1-placeholder-icon" alt="" src={placeholder} />
              </button>
              </div>
      
              <img className="archive-wanted-device1-divider-icon3" alt="" src={divider2} />

             
             <div className='archive-wanted-device1-sort-container'>

             <div className="archive-wanted-device1-button-group">
                <button className="archive-wanted-device1-button-group-base">
                  <a className="archive-wanted-device1-text16">View all</a>
                </button>
                <div className="archive-wanted-device1-button-group-base1">
                  <div className="archive-wanted-device1-text17">Monitored</div>
                </div>
           
                <div className="archive-wanted-device1-button-group-base1">
                  <div className="archive-wanted-device1-text24">Unmonitored</div>
                </div>

              </div>
              <div className="archive-wanted-device1-input-dropdown1">
                <div className="archive-wanted-device1-hint-text1">This is a hint text to help user.</div>
                <div className="archive-wanted-device1-buttonsbutton">
                  <img className="archive-wanted-device1-sliders-02-icon" alt="" src={sliders02} />

                  <div className="archive-wanted-device1-text-padding">
                    <div className="archive-wanted-device1-menu-label">Sort By : Relevance</div>
                    <img
                      className="archive-wanted-device1-sliders-02-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                  <img
                    className="archive-wanted-device1-placeholder-icon"
                    alt=""
                    src={placeholder}
                  />
                </div>
              </div>
             </div>
              

                <img className="archive-wanted-device1-divider-icon2" alt="" src={divider2} /> 
            
            </div>

            
            {/* <div className="archive-wanted-device1-listing-container archive-wanted-device-listing-container-custom"> */}
            <div className="archive-wanted-device1-listing-container">


              {/* {
                content
              } */}

               {/* <div className="funnel-device-component-funnel-content-items" key={index}> */}
        <div className="archive-wanted-device-component-archive-wanted-content-items" >
          <div className="funnel-device-component-funnel-divider-container1">
            <div className="funnel-device-component-funnel-divider-container-child"></div>
            <div className="funnel-device-component-funnel-rectangle-parent5">


              <div className="funnel-device-component-funnel-frame-parent1">
                <div className="funnel-device-component-funnel-action-fields">
                  <div className="funnel-device-component-funnel-input-elements">
                    {/* <Link to={`/wanted-device/${wantedDevice.id}/${wantedDevice.title}`} className="funnel-device-component-funnel-text78">
                      {wantedDevice.title}
                    </Link> */}
                     <div className="funnel-device-component-funnel-text78">
                      WANTED - NEW / SURPLUS 1 VIVIT DSFS FOR 1/25-DIHYDROXY VITAMIN D3 IVD / CALIBRATOR
                    </div>
                    <div className="funnel-device-component-funnel-frame-parent21">
                      <div className="funnel-device-component-funnel-badge-item-parent">
                     
                      


                        <div className="funnel-device-component-funnel-frame-wrapper19">
                          <div className="funnel-device-component-funnel-legend-series">
                            <div className="funnel-device-component-funnel-search-input">
                              <img
                                className="funnel-device-component-funnel-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            {/* <div className="funnel-device-component-funnel-type-of-work2">{getCustomizedDate(wantedDevice.createdAt)}</div> */}
                            <div className="funnel-device-component-funnel-type-of-work2">November 28, 2024</div>

                          </div>
                        </div>

                        <div className="funnel-device-component-funnel-frame-parent4">
                          <div className="funnel-device-component-funnel-end-label">
                            <div className="funnel-device-component-funnel-search-icon-input">4.9/5</div>
                          </div>
                          <div className="funnel-device-component-funnel-type-support">
                            <div className="funnel-device-component-funnel-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>

                

                       
                        
                      
                      </div>
                    
                    

                    </div>
                    <div className= "funnel-device-component-funnel-small-card">

                            <div className="funnel-device-component-funnel-badge33">
                                    {/* <div className="funnel-device-component-funnel-third-badge-number">
                                        {wantedDevice.tax_device_category.name} 1/25-DIHYDROXY VITAMIN D3 IVD / CALIBRATOR
                                    </div> */}
                                    <div className="funnel-device-component-funnel-third-badge-number">
                                    1/25-DIHYDROXY VITAMIN D3 IVD / CALIBRATOR
                                    </div>
                            </div>

                            <div className="funnel-device-component-funnel-badge36">
                                        {/* <div className="funnel-device-component-funnel-third-badge-number">
                                            {wantedDevice.tax_oem.name}
                                        </div> */}
                                        <div className="funnel-device-component-funnel-third-badge-number">
                                            1 Vivit
                                        </div>
                            </div>

                            <div className="funnel-device-component-funnel-badge37">
                                    {/* <div className="funnel-device-component-funnel-third-badge-number">{wantedDevice.tax_clinical_application.name}</div> */}
                                    <div className="funnel-device-component-funnel-third-badge-number">Physiotherapy</div>

                            </div>

                            <div className="funnel-device-component-funnel-badge38">
                                {/* <div className="funnel-device-component-funnel-third-badge-number">
                                    {wantedDevice.tax_purpose_use.name}
                                </div> */}
                                <div className="funnel-device-component-funnel-third-badge-number">
                                    Sample Preparation
                                </div>
                            </div>

                            {/* <div className="funnel-device-component-funnel-frame-parent24">
                           



                            <div className="funnel-device-component-funnel-frame-wrapper18">
                            <div className="funnel-device-component-funnel-badge-elements-copy">
                               
                               
                            </div>
                            </div>
                            </div>
                            <div className="funnel-device-component-funnel-frame-parent26">

                               
                            </div> */}

                            </div>
                  </div>
                  
                </div>
                <div className="funnel-device-component-funnel-filter-elements">
                  {/* <div className="funnel-device-component-funnel-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}> */}
                  <div className="funnel-device-component-funnel-dropdown10" >

                    <img
                      className="funnel-device-component-funnel-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {/* {activeThreeDotId === index && <ThreeDotsListingComponent url={`wanted-device/${wantedDevice.id}`} />} */}
                  </div>

                </div>
              </div>
            </div>
           
          </div>
        </div>

            </div>
          </div>
        </div>
        <img className="archive-wanted-device1-divider-icon6" alt="" src={divider4} />

        <img className="archive-wanted-device1-divider-icon7 archive-wanted-device1-divider-icon7-cstm" alt="" src={divider4} />





        <FooterAddForms />




      </div>
    </>


  )
}

